import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getFirestore, doc, collection, getDocs, getDoc } from 'firebase/firestore';
import { app } from '../../plugins/firebase';

const db = getFirestore(app);

// 初期状態
const initialState = {
  questions: [],
  detailQuestions: [],
  loading: false,
  error: null,
};

// 非同期アクション: 質問の一覧を取得
export const getQuestions = createAsyncThunk('questions/getQuestions', async ({ user }, { rejectWithValue }) => {
  try {
    const now = Date.now();
    const questionsSnapshot = await getDocs(collection(db, 'questions'));
    const allQuestionsActive = [];
    const allQuestions = [];

    questionsSnapshot.forEach((doc) => {
      const question = doc.data();
      if (question.state !== 'delete' && question.start < now && now < question.end) {
        const start = new Date(question.start);
        const end = new Date(question.end);
        question.start = `${start.getFullYear()}/${('00' + (start.getMonth() + 1)).slice(-2)}/${('00' + start.getDate()).slice(-2)}`;
        question.end = `${end.getFullYear()}/${('00' + (end.getMonth() + 1)).slice(-2)}/${('00' + end.getDate()).slice(-2)}`;
        allQuestionsActive.push(question);
      }
    });

    for (const element of allQuestionsActive) {
      const stateGet = await getDoc(doc(db, 'questions', element.qid, 'answer', user.uid));
      if (!stateGet.exists) {
        element.answer = '';
        element.state = 1;
      } else {
        const stateData = stateGet.data();
        element.answer = stateData.answer;
        element.state = stateData.state;
      }
      allQuestions.push(element);
    }

    return allQuestions;
  } catch (error) {
    console.error(error);
    return rejectWithValue(error.message);
  }
});

// 非同期アクション: 詳細質問を設定
export const setDetailQuestion = createAsyncThunk('questions/setDetailQuestion', async (question, { rejectWithValue }) => {
  try {
    // 追加処理を必要に応じて行う
    return question;
  } catch (error) {
    console.error(error);
    return rejectWithValue(error.message);
  }
});

// Redux Toolkit の Slice
const questionsSlice = createSlice({
  name: 'questions',
  initialState,
  reducers: {
    initQuestions(state) {
      state.questions = [];
      state.detailQuestions = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getQuestions.pending, (state) => {
        state.loading = true;
      })
      .addCase(getQuestions.fulfilled, (state, action) => {
        state.questions = action.payload;
        state.loading = false;
      })
      .addCase(getQuestions.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(setDetailQuestion.pending, (state) => {
        state.loading = true;
      })
      .addCase(setDetailQuestion.fulfilled, (state, action) => {
        state.detailQuestions = action.payload;
        state.loading = false;
      })
      .addCase(setDetailQuestion.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

// Reducerとアクションをエクスポート
export const { initQuestions } = questionsSlice.actions;
export default questionsSlice.reducer;
