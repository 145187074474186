import React, { useState, useEffect } from "react"; 
import {
  VictoryChart,
  VictoryLine,
  VictoryAxis,
  VictoryTooltip,
  VictoryVoronoiContainer,
  VictoryStack,
  VictoryBar,
  VictoryLabel,
  VictoryLegend,
} from "victory";
import { View, Text, StyleSheet, FlatList, TouchableOpacity, Image, Platform, ScrollView, Dimensions } from 'react-native';

const formatDate = (dateStr) => {
  const d = new Date(dateStr);
  return d.toLocaleDateString("ja-JP", {
    year: "numeric",
    month: "long",
    day: "numeric",
    weekday: "short"
  });
};

const CustomFlyout = ({ x, y, datum }) => {
  const tooltipWidth = 130;
  const tooltipHeight = 60;

  return (
    <g>
      {/* データポイントとの線 */}
      <line
        x1={x + -tooltipWidth / 4}
        y1={y - tooltipHeight / 2}
        x2={x}
        y2={y}
        style={{
          stroke: "#f5d4d4",
          strokeWidth: 2,
          strokeLinecap: "round",
        }}
      />
      {/* データポイントの外側サークル */}
      <circle cx={x} cy={y} r={5} fill="#f5d4d4" />
      {/* データポイントの内側サークル */}
      <circle cx={x} cy={y} r={3} fill="#cd2829" />
      {/* ツールチップのボックス */}
      <foreignObject
        x={x - tooltipWidth / 1.5}
        y={y - tooltipHeight - 10}
        width={tooltipWidth}
        height={tooltipHeight}
      >
        <div
          style={{
            backgroundColor: "#f5d4d4",
            borderRadius: "5px",
            padding: "5px",
            textAlign: "center",
            boxSizing: "border-box",
            fontFamily: "Helvetica, sans-serif",
          }}
        >
          <div style={{ fontSize: "10px", color: "#555", marginBottom: 5 }}>
            {formatDate(datum.x)} {/* 日付 */}
          </div>
          <div style={{ fontSize: "12px", color: "#555" }}>
            合計<span style={{ fontSize: "18px", fontWeight: "bold", color: "#555" }}>
              {datum.total}
            </span>
            g {/* 合計値 */}
          </div>
        </div>
      </foreignObject>
    </g>
  );
};

const Graph = ({ data, graphType }) => {
  const [isMobileScreen, setIsMobileScreen] = useState(false);
  useEffect(() => {
    const updateLayout = () => {
      const screenWidth = Dimensions.get("window").width;
      setIsMobileScreen(screenWidth < 768); // 768px未満をモバイル画面と判定
    };

    Dimensions.addEventListener("change", updateLayout);
    updateLayout(); // 初期チェック

    return () => Dimensions.removeEventListener("change", updateLayout);
  }, []);

  const [activePoint, setActivePoint] = useState(null);
  const spawningCountData = Array.isArray(data.spawningCount) ? data.spawningCount : [];
  const yValues = spawningCountData.map((d) => d.y || 0);
  const maxY = Math.max(...yValues, 1);
  const isWeightSinglePoint = Array.isArray(data) && data.length === 1;
  const yDomain = isWeightSinglePoint
    ? [
        Math.floor(data[0].y - 5),
        Math.ceil(data[0].y + 5),
      ]
    : undefined;
  const formattedData = Array.isArray(data.formattedData) ? data.formattedData : [];
  const allCategories = Object.keys(
    formattedData.reduce((acc, data) => {
      if (data.y) {
        Object.keys(data.y).forEach((category) => {
          acc[category] = true; // 各カテゴリを収集
        });
      }
      return acc;
    }, {})
  );

  const categoryColors = {
    "主食シード": "#f29c9f",
    "主食ペレット": "#7ecef4",
    "副食": "#cce198",
    "おやつ": "#f19ec2",
    "サプリメント": "#eaeaea",
  };

  const formatTooltipContent = (datum, graphType, dataKey = "status") => {
    const formattedDate = formatDate(datum.x || datum.date);
    let value;
  
    if (graphType === "weight") {
      value = `${datum.y.toFixed(1)}g`;
    } else if (graphType === "poop") {
      if (dataKey === "status") {
        value =
          datum.y === 0
            ? "水っぽい"
            : datum.y === 1
            ? "ゆるい"
            : datum.y === 2
            ? "普通"
            : datum.y === 3
            ? "固い"
            : "不明";
      } else if (dataKey === "amount") {
        value =
          datum.y === 1
            ? "少ない"
            : datum.y === 2
            ? "普通"
            : datum.y === 3
            ? "多い"
            : "不明";
      }
    } else if (graphType === "state") {
      // 状態（換羽、発情、産卵など）
      if (dataKey === "featherChange") {
        value =
          datum.y === 0
            ? "無し"
            : datum.y === 1
            ? "少ない"
            : datum.y === 2
            ? "普通"
            : "多い";
      } else if (dataKey === "estrusStatus") {
        value =
          datum.y === 0
            ? "無"
            : datum.y === 1
            ? "弱い"
            : datum.y === 2
            ? "普通"
            : "強い";
      } else if (dataKey === "spawningCount") {
        value = datum.y > 0 ? `${datum.y}個` : "無し";
      }
    } else if (graphType === "environment") {
      // 環境データの詳細
      if (dataKey === "outsideTemperature") {
        value = `${datum.y}°C`;
      } else if (dataKey === "insideTemperature") {
        value = `${datum.temperature}°C`;
      } else if (dataKey === "insideHumidity") {
        value = `${datum.humidity}%`;
      } else if (dataKey === "cageTemperature") {
        value = `${datum.temperature}°C`;
      } else if (dataKey === "cageHumidity") {
        value = `${datum.humidity}%`;
      } else {
        value = `${datum.y}°C`;
      }
    } else if (graphType === "food") {
      value = `${datum.category}: ${datum.y}g`;
    } else {
      value = `${datum.y}`;
    }
  
    return `${formattedDate}\n${value}`;
  };
  
  const CustomVictoryTooltip = (props) => {
    const tooltipWidth = 130;
    const tooltipHeight = 60;
    const margin = 10;
  
    // グラフのサイズ
    const graphWidth = 400;
    const graphHeight = 300;
  
    // props.text が関数の場合に実行して値を取得
    const text =
      typeof props.text === "function"
        ? props.text(props) // 関数を実行して配列を取得
        : props.text || ["日付不明", "値なし"];
  
    // 安全に背景色を決定
    const backgroundColor =
      typeof text[1] === "string" && text[1].includes("%")
        ? "#d3e9f4" // 湿度用（青背景）
        : "#f5d4d4"; // 温度用（赤背景）
  
    // ツールチップ位置の計算
    const adjustedX = Math.max(
      margin,
      Math.min(props.x - tooltipWidth / 2, graphWidth - tooltipWidth - margin)
    );
    const adjustedY = Math.max(
      margin,
      Math.min(props.y - tooltipHeight - 20, graphHeight - tooltipHeight - margin)
    );
  
    return props.active ? (
      <g>
        {/* データポイントとの線 */}
        <line
          x1={adjustedX + tooltipWidth / 2}
          y1={adjustedY + tooltipHeight / 2}
          x2={props.x}
          y2={props.y}
          style={{
            stroke: backgroundColor,
            strokeWidth: 2,
            strokeLinecap: "round",
          }}
        />
        {/* データポイントのマーカー */}
        <circle cx={props.x} cy={props.y} r={5} fill={backgroundColor} />
        <circle
          cx={props.x}
          cy={props.y}
          r={3}
          fill={text[1] && text[1].includes("%") ? "#02bbf2" : "#cd2829"}
        />
        {/* foreignObject (ツールチップ本体) */}
        <foreignObject
          x={adjustedX}
          y={adjustedY}
          width={tooltipWidth}
          height={tooltipHeight}
        >
          <div
            style={{
              backgroundColor: backgroundColor,
              borderRadius: "5px",
              padding: "5px",
              textAlign: "center",
              boxSizing: "border-box",
              fontFamily: "Helvetica, sans-serif",
            }}
          >
            <div style={{ fontSize: "10px", color: "#555555", marginBottom: 5 }}>
              {text[0]} {/* 日付 */}
            </div>
            <div style={{ fontSize: "14px", fontWeight: "bold", color: "#555" }}>
              {text[1]} {/* 値 */}
            </div>
          </div>
        </foreignObject>
      </g>
    ) : null;
  };
  
  // 「体重」の場合
  if (graphType === "weight") {
    return (
    <div style={styles.graphCon}>
      <View style={styles.titleBlock}>
        <Text
          style={[
            styles.title,
            isMobileScreen ? styles.titleSP : styles.titlePC,
          ]}
        >
        体重
        </Text>
      </View>
      <VictoryChart
        domainPadding={{ x: 3, y: 3 }}
        padding={{ top: 10, bottom: 30, left: 40, right: 20 }}
        style={{
          parent: { height: 'auto',width: '100%' },
        }}
        containerComponent={
          <VictoryVoronoiContainer
            labels={({ datum }) => formatTooltipContent(datum, graphType)}
            labelComponent={<CustomVictoryTooltip />}
            onActivated={(points) => {
              setActivePoint(points[0]);
            }}
            onDeactivated={() => setActivePoint(null)} // ホバー解除でリセット
          />
        }
      >
      <VictoryAxis
        tickFormat={(x, index) => {
          const totalTicks = 7; // 表示したい最大ラベル数
          const dataLength = data.length; // データの長さ
          const step = Math.ceil(dataLength / totalTicks); // 等間隔のステップ計算

          // 等間隔のラベルのみ表示
          if (index % step === 0) {
            const date = new Date(x);
            const month = date.getMonth() + 1; // 月（1〜12）
            const day = date.getDate(); // 日（1〜31）
            return `${month}/${day}`;
          }
          return ""; // 他のラベルは非表示
        }}
        style={{
          axis: { stroke: "#f4f4f4" },
          tickLabels: { fontSize: 10, padding: 5 },
          grid: { stroke: "transparent" },
          ticks: { stroke: "transparent" },
        }}
      />
        <VictoryAxis
          dependentAxis
          domain={yDomain}
          tickFormat={(y) => `${Math.round(y)}g`}
          style={{
            axis: { stroke: "transparent" },
            tickLabels: { fontSize: 10, padding: 5, fill: "#333" },
            grid: { stroke: "#eaeaea" },
            ticks: { stroke: "transparent" },
          }}
        />
        <VictoryLine
          data={data}
          x="date"
          y="y"
          style={{
            data: { stroke: "#cd2829", strokeWidth: 2 },
          }}
        />
        {activePoint && (
          <circle
            cx={activePoint._voronoiX} // X座標
            cy={activePoint._voronoiY} // Y座標
            r={6} // 円の半径
            fill="#ff0000" // 塗りつぶし色
            stroke="#000000" // 輪郭線の色
            strokeWidth={1} // 輪郭線の太さ
          />
        )}
      </VictoryChart>
    </div>
    );
  }

  // 「うんち」の場合
  if (graphType === "poop") {
    return (
      <View
        style={[
          styles.graphflex,
          isMobileScreen ? styles.graphColumn : styles.graphRow,
        ]}
      >
        {/* うんちの状態のグラフ */}
        <div
          style={{
            ...styles.graphCon,
            marginRight: "5px",
          }}
        >
          <View style={styles.titleBlock}>
            <Text
              style={[
                styles.title,
                isMobileScreen ? styles.titleSP : styles.titlePC,
              ]}
            >
            うんちの状態
            </Text>
          </View>
          <VictoryChart
            domainPadding={{ x: 3, y: 3 }}
            padding={{ top: 20, bottom: 30, left: 60, right: 20 }}
            style={{
              parent: { height: 'auto' },
            }}
            containerComponent={
              <VictoryVoronoiContainer
                labels={({ datum }) => formatTooltipContent(datum, graphType, "status")}
                labelComponent={<CustomVictoryTooltip />}
                onActivated={(points) => {
                  setActivePoint(points[0]);
                }}
                onDeactivated={() => setActivePoint(null)}
              />
            }
          >
            <VictoryAxis
              dependentAxis
              tickValues={[0, 1, 2, 3]} // 水っぽい, ゆるい, 普通, 固い
              tickFormat={(y) =>
                y === 0 ? "水っぽい" : y === 1 ? "ゆるい" : y === 2 ? "普通" : "固い"
              }
              tickLabelComponent={
                <VictoryLabel dx={-40} textAnchor="start" style={{ fontSize: 10, fill: "#333" }} />
              }
              style={{
                axis: { stroke: "transparent" },
                tickLabels: { fontSize: 10, padding: 5, fill: "#333" },
                grid: { stroke: "#eaeaea" },
                ticks: { stroke: "transparent" },
              }}
            />
            <VictoryAxis
              tickFormat={(x, index) => {
                const totalTicks = 7; // 表示したい最大ラベル数
                const dataLength = data.status.length; // データの長さ
                const step = Math.ceil(dataLength / totalTicks); // 等間隔のステップ計算

                // 等間隔でラベルを表示
                if (index % step === 0) {
                  const date = new Date(x);
                  const month = date.getMonth() + 1; // 月 (1〜12)
                  const day = date.getDate(); // 日 (1〜31)
                  return `${month}/${day}`; // "MM/DD" の形式
                }
                return ""; // 他のラベルは非表示
              }}
              style={{
                axis: { stroke: "#f4f4f4" },
                tickLabels: { fontSize: 10, padding: 5 },
                grid: { stroke: "transparent" },
                ticks: { stroke: "transparent" },
              }}
            />
            <VictoryLine
              data={data.status}
              x="x"
              y="y"
              style={{
                data: { stroke: "#cd2829", strokeWidth: 2 },
              }}
            />
          </VictoryChart>
        </div>

        {/* うんちの量のグラフ */}
        <div style={styles.graphCon}>
          <View style={styles.titleBlock}>
            <Text
              style={[
                styles.title,
                isMobileScreen ? styles.titleSP : styles.titlePC,
              ]}
            >
            うんちの量
            </Text>
          </View>
          <VictoryChart
            domainPadding={{ x: 3, y: 3 }}
            padding={{ top: 20, bottom: 30, left: 60, right: 20 }}
            style={{
              parent: { height: "auto" },
            }}
            containerComponent={
              <VictoryVoronoiContainer
                labels={({ datum }) => formatTooltipContent(datum, graphType, "amount")}
                labelComponent={<CustomVictoryTooltip />}
                onActivated={(points) => {
                  setActivePoint(points[0]);
                }}
                onDeactivated={() => setActivePoint(null)}
              />
            }
          >
            {/* y軸: 依存軸 */}
            <VictoryAxis
              dependentAxis
              tickValues={[1, 2, 3]} // 少ない, 普通, 多い
              tickFormat={(y) => (y === 1 ? "少ない" : y === 2 ? "普通" : "多い")}
              tickLabelComponent={
                <VictoryLabel dx={-40} textAnchor="start" style={{ fontSize: 10, fill: "#333" }} />
              }
              style={{
                axis: { stroke: "transparent" },
                tickLabels: { fontSize: 10, padding: 5, fill: "#333" },
                grid: { stroke: "#eaeaea" },
                ticks: { stroke: "transparent" },
              }}
            />

            {/* x軸: 日付 */}
            <VictoryAxis
              tickFormat={(x, index) => {
                const totalTicks = 7; // 表示したい最大ラベル数
                const dataLength = data.amount.length; // データの長さ
                const step = Math.ceil(dataLength / totalTicks); // 等間隔のステップ計算

                // 等間隔でラベルを表示
                if (index % step === 0) {
                  const date = new Date(x);
                  const month = date.getMonth() + 1; // 月 (1〜12)
                  const day = date.getDate(); // 日 (1〜31)
                  return `${month}/${day}`; // "MM/DD" の形式
                }
                return ""; // 他のラベルは非表示
              }}
              style={{
                axis: { stroke: "#f4f4f4" },
                tickLabels: { fontSize: 10, padding: 5 },
                grid: { stroke: "transparent" },
                ticks: { stroke: "transparent" },
              }}
            />

            {/* データの描画 */}
            <VictoryLine
              data={data.amount}
              x="x"
              y="y"
              style={{
                data: { stroke: "#cd2829", strokeWidth: 2 },
              }}
            />
          </VictoryChart>
        </div>
      </View>
    );
  }
  // 「状態」の場合
  if (graphType === "state") {
    return (
    <View
      style={[
        styles.graphflex,
        isMobileScreen ? styles.graphColumn : styles.graphRow,
      ]}
    >
      {/* 換羽のグラフ */}
      <div
          style={{
            ...styles.graphCon,
            marginRight: "5px",
          }}
        >
        <View style={styles.titleBlock}>
          <Text
              style={[
                styles.title,
                isMobileScreen ? styles.titleSP : styles.titlePC,
              ]}
            >
            換羽
          </Text>
        </View>
        <VictoryChart
          domainPadding={{ x: 3, y: 3 }}
          padding={{ top: 20, bottom: 40, left: 50, right: 25 }}
          style={{
            parent: { height: 'auto' },
          }}
          containerComponent={
            <VictoryVoronoiContainer
              labels={({ datum }) =>
                formatTooltipContent(datum, "state", "featherChange")
              }
              labelComponent={<CustomVictoryTooltip />}
            />
          }
        >
          <VictoryAxis
            tickFormat={(x, index) => {
              const totalTicks = 7;  // 最大ラベル数
              const dataLength = data.featherChange.length;  // データの長さ
              const step = Math.floor(dataLength / (totalTicks - 1));  // 最大ラベル数に基づいてステップを決定

              // 等間隔のラベルを表示
              if (index % step === 0) {
                const date = new Date(x);
                const month = date.getMonth() + 1; // 月（1〜12）
                const day = date.getDate(); // 日（1〜31）
                return `${month}/${day}`;
              }
              return ""; // 他のラベルは非表示
            }}
            style={{
              axis: { stroke: "#f4f4f4" },
              tickLabels: { fontSize: 10, padding: 5, },
              grid: { stroke: "transparent" },
              ticks: { stroke: "transparent" },
            }}
          />
          <VictoryAxis
            dependentAxis
            tickValues={[0, 1, 2, 3]}
            tickFormat={(y) =>
              y === 0 ? "無し" : y === 1 ? "少ない" : y === 2 ? "普通" : "多い"
            }
            style={{
              axis: { stroke: "transparent" },
              tickLabels: { fontSize: 10, padding: 5, fill: "#333" },
              grid: { stroke: "#eaeaea" },
              ticks: { stroke: "transparent" },
            }}
            tickLabelComponent={
              <VictoryLabel dx={-30} textAnchor="start" style={{ fontSize: 10, fill: "#333" }} />
            }
          />
          <VictoryLine
            data={data.featherChange}
            x="x"
            y="y"
            style={{
              data: { stroke: "#cd2829", strokeWidth: 2 },
            }}
          />
        </VictoryChart>
      </div>

      {/* 発情のグラフ */}
      <div
          style={{
            ...styles.graphCon,
            marginRight: "5px",
          }}
        >
        <View style={styles.titleBlock}>
          <Text
              style={[
                styles.title,
                isMobileScreen ? styles.titleSP : styles.titlePC,
              ]}
            >
            発情
          </Text>
        </View>
        <VictoryChart
          domainPadding={{ x: 3, y: 3 }}
          padding={{ top: 20, bottom: 40, left: 50, right: 25 }}
          style={{
            parent: { height: 'auto' },
          }}
          containerComponent={
            <VictoryVoronoiContainer
              labels={({ datum }) =>
                formatTooltipContent(datum, "state", "estrusStatus")
              }
              labelComponent={<CustomVictoryTooltip />}
            />
          }
        >
          <VictoryAxis
            tickFormat={(x, index) => {
              const totalTicks = 7;  // 最大ラベル数
              const dataLength = data.estrusStatus.length;  // データの長さ
              const step = Math.floor(dataLength / (totalTicks - 1));  // 最大ラベル数に基づいてステップを決定

              // 等間隔のラベルを表示
              if (index % step === 0) {
                const date = new Date(x);
                const month = date.getMonth() + 1; // 月（1〜12）
                const day = date.getDate(); // 日（1〜31）
                return `${month}/${day}`;
              }
              return ""; // 他のラベルは非表示
            }}
            style={{
              axis: { stroke: "#f4f4f4" },
              tickLabels: { fontSize: 10, padding: 5, },
              grid: { stroke: "transparent" },
              ticks: { stroke: "transparent" },
            }}
          />
          <VictoryAxis
            dependentAxis
            tickValues={[0, 1, 2, 3]}
            tickFormat={(y) =>
              y === 0 ? "無" : y === 1 ? "弱い" : y === 2 ? "普通" : "強い"
            }
            style={{
              axis: { stroke: "transparent" },
              tickLabels: { fontSize: 10, padding: 5, fill: "#333" },
              grid: { stroke: "#eaeaea" },
              ticks: { stroke: "transparent" },
            }}
            tickLabelComponent={
              <VictoryLabel dx={-30} textAnchor="start" style={{ fontSize: 10, fill: "#333" }} />
            }
          />
          <VictoryLine
            data={data.estrusStatus}
            x="x"
            y="y"
            style={{
              data: { stroke: "#cd2829", strokeWidth: 2 },
            }}
          />
        </VictoryChart>
      </div>

      {/* 産卵のグラフ */}
      <div style={styles.graphCon}>
        <View style={styles.titleBlock}>
          <Text
              style={[
                styles.title,
                isMobileScreen ? styles.titleSP : styles.titlePC,
              ]}
            >
            産卵
          </Text>
        </View>
        <VictoryChart
          domainPadding={{ x: 3, y: 3 }}
          padding={{ top: 20, bottom: 40, left: 40, right: 25 }}
          style={{
            parent: { height: 'auto' },
          }}
          containerComponent={
            <VictoryVoronoiContainer
              labels={({ datum }) =>
                formatTooltipContent(datum, "state", "spawningCount")
              }
              labelComponent={<CustomVictoryTooltip />}
            />
          }
        >
        <VictoryAxis
          tickFormat={(x, index) => {
            const totalTicks = 7;  // 最大ラベル数
            const dataLength = data.spawningCount.length;  // データの長さ
            const step = Math.floor(dataLength / (totalTicks - 1));  // 最大ラベル数に基づいてステップを決定

            // 等間隔のラベルを表示
            if (index % step === 0) {
              const date = new Date(x);
              const month = date.getMonth() + 1; // 月（1〜12）
              const day = date.getDate(); // 日（1〜31）
              return `${month}/${day}`;
            }
            return ""; // 他のラベルは非表示
          }}
          style={{
            axis: { stroke: "#f4f4f4" },
            tickLabels: { fontSize: 10, padding: 5, },
            grid: { stroke: "transparent" },
            ticks: { stroke: "transparent" },
          }}
          tickLabelComponent={
            <VictoryLabel dx={-30} textAnchor="start" style={{ fontSize: 10, fill: "#333" }} />
          }
        />
        <VictoryAxis
          dependentAxis
          domain={yDomain} // y軸の範囲
          tickValues={Array.from({ length: maxY + 1 }, (_, i) => i)} // 0からmaxYまでの整数のみを設定
          tickFormat={(y) => `${y}個`}
          style={{
            axis: { stroke: "transparent" },
            tickLabels: { fontSize: 10, padding: 5, fill: "#333" },
            grid: { stroke: "#eaeaea" },
            ticks: { stroke: "transparent" },
          }}
        />
        <VictoryLine
          data={data.spawningCount}
          x="x"
          y="y"
          style={{
            data: { stroke: "#cd2829", strokeWidth: 2 },
          }}
        />
        </VictoryChart>
      </div>
    </View>
    );
  }
  // 「環境」の場合
  if (graphType === "environment") {
    return (
      <View
        style={[
          styles.graphflex,
          isMobileScreen ? styles.graphColumn : styles.graphRow,
        ]}
      >
        {/* 外気温 */}
        <div
          style={{
            ...styles.graphCon,
            marginRight: "5px",
          }}
        >
          <View style={styles.titleBlock2}>
            <Text
                style={[
                  styles.title,
                  isMobileScreen ? styles.titleSP : styles.titlePC,
                ]}
              >
              外気温
            </Text>
          </View>
          <VictoryChart
            domainPadding={{ x: 3, y: 3 }}
            padding={{ top: 20, bottom: 40, left: 40, right: 40 }}
            style={{
              parent: { height: 'auto' },
            }}
            containerComponent={
              <VictoryVoronoiContainer
                labels={({ datum }) => [
                  formatTooltipContent(datum, "environment", "outsideTemperature")
                ]}
                labelComponent={<CustomVictoryTooltip />}
              />
            }
          >
            <VictoryAxis
              tickFormat={(x, index) => {
                const totalTicks = 7;  // 最大ラベル数
                const dataLength = data.outsideTemperature.length;  // データの長さ
                const step = Math.floor(dataLength / (totalTicks - 1));  // 最大ラベル数に基づいてステップを決定

                // 等間隔のラベルを表示
                if (index % step === 0) {
                  const date = new Date(x);
                  const month = date.getMonth() + 1; // 月（1〜12）
                  const day = date.getDate(); // 日（1〜31）
                  return `${month}/${day}`;
                }
                return ""; // 他のラベルは非表示
              }}
              style={{
                axis: { stroke: "#f4f4f4" },
                tickLabels: { fontSize: 10, padding: 5, },
                grid: { stroke: "transparent" },
                ticks: { stroke: "transparent" },
              }}
            />
            <VictoryAxis
              dependentAxis
              tickFormat={(y) => `${y}°C`} // Y軸のフォーマット
              style={{
                axis: { stroke: "transparent" },
                tickLabels: { fontSize: 10, padding: 5, fill: "#555" },
                grid: { stroke: "#eaeaea", strokeDasharray: "1,0" },
              }}
            />
            <VictoryLine
              data={data.outsideTemperature}
              x="x"
              y="y"
              style={{
                data: { stroke: "#cd2829", strokeWidth: 2 },
              }}
            />
          </VictoryChart>
        </div>
        {/* 室内温度と湿度 */}
        <div
          style={{
            ...styles.graphCon,
            marginRight: "5px",
          }}
        >
          <View style={styles.titleBlock}>
            <Text
                style={[
                  styles.title,
                  isMobileScreen ? styles.titleSP : styles.titlePC,
                ]}
              >
              室内温度・湿度
            </Text>
            <View style={styles.legend}>
              <View style={styles.legendItem}>
                <Text style={styles.legendText}>温度</Text>
                <View style={styles.legendLineTemperature} />
              </View>
              <View style={[styles.legendItem, styles.legendItemLast]}>
                <Text style={styles.legendText}>湿度</Text>
                <View style={styles.legendLineHumidity} />
              </View>
            </View>
          </View>
          <VictoryChart
            domainPadding={{ x: 3, y: 3 }}
            padding={{ top: 20, bottom: 40, left: 40, right: 40 }}
            style={{
              parent: { height: 'auto' },
            }}
            containerComponent={
              <VictoryVoronoiContainer
                labels={({ datum }) =>
                  datum.temperature !== undefined
                    ? formatTooltipContent(datum, "environment", "insideTemperature")
                    : formatTooltipContent(datum, "environment", "insideHumidity")
                }
                labelComponent={<CustomVictoryTooltip />}
              />
            }
          >
            <VictoryAxis
              tickFormat={(x, index) => {
                const totalTicks = 7;  // 最大ラベル数
                const dataLength = data.insideTemperatureHumidity.length;  // データの長さ
                const step = Math.floor(dataLength / (totalTicks - 1));  // 最大ラベル数に基づいてステップを決定

                // 等間隔のラベルを表示
                if (index % step === 0) {
                  const date = new Date(x);
                  const month = date.getMonth() + 1; // 月（1〜12）
                  const day = date.getDate(); // 日（1〜31）
                  return `${month}/${day}`;
                }
                return ""; // 他のラベルは非表示
              }}
              style={{
                axis: { stroke: "#f4f4f4" },
                tickLabels: { fontSize: 10, padding: 5, },
                grid: { stroke: "transparent" },
                ticks: { stroke: "transparent" },
              }}
            />
            <VictoryAxis
              dependentAxis
              tickFormat={(y) => `${y}°C`} // Y軸のフォーマット
              style={{
                axis: { stroke: "transparent" },
                tickLabels: { fontSize: 10, padding: 5, fill: "#555" },
                grid: { stroke: "#eaeaea", strokeDasharray: "1,0" },
              }}
            />

            {/* 右側の湿度用軸 */}
            <VictoryAxis
              dependentAxis
              orientation="right"
              tickFormat={(y) => `${y}%`}
              style={{
                axis: { stroke: "transparent" },
                tickLabels: { fill: "#8d8d8d", fontSize: 10, padding: 5 },
                ticks: { stroke: "#8d8d8d" },
              }}
            />

            {/* 温度のグラフ（赤色、実線） */}
            <VictoryLine
              data={data.insideTemperatureHumidity.map((item) => ({
                x: item.x,
                y: item.temperature,
                temperature: item.temperature,
              }))}
              x="x"
              y="y"
              style={{
                data: { stroke: "#cd2829", strokeWidth: 2 },
              }}
            />

            {/* 湿度のグラフ（青色、実線） */}
            <VictoryLine
              data={data.insideTemperatureHumidity.map((item) => ({
                x: item.x,
                y: item.humidity,
                humidity: item.humidity,
              }))}
              x="x"
              y="y"
              style={{
                data: { stroke: "#02bbf2", strokeWidth: 2 },
              }}
            />
          </VictoryChart>
        </div>
        {/* ケージ内温度と湿度 */}
        <div style={styles.graphCon}>
          <View style={styles.titleBlock}>
            <Text
                style={[
                  styles.title,
                  isMobileScreen ? styles.titleSP : styles.titlePC,
                ]}
              >
              ケージ温度・湿度
            </Text>
            <View style={styles.legend}>
              <View style={styles.legendItem}>
                <Text style={styles.legendText}>温度</Text>
                <View style={styles.legendLineTemperature} />
              </View>
              <View style={[styles.legendItem, styles.legendItemLast]}>
                <Text style={styles.legendText}>湿度</Text>
                <View style={styles.legendLineHumidity} />
              </View>
            </View>
          </View>
          <VictoryChart
            domainPadding={{ x: 3, y: 3 }}
            padding={{ top: 20, bottom: 40, left: 40, right: 40 }}
            style={{
              parent: { height: 'auto' },
            }}
            containerComponent={
              <VictoryVoronoiContainer
                labels={({ datum }) =>
                  datum.temperature !== undefined
                    ? formatTooltipContent(datum, "environment", "cageTemperature")
                    : formatTooltipContent(datum, "environment", "cageHumidity")
                }
                labelComponent={<CustomVictoryTooltip />}
              />
            }
          >
            <VictoryAxis
              tickFormat={(x, index) => {
                const totalTicks = 7;  // 最大ラベル数
                const dataLength = data.cageTemperatureHumidity.length;  // データの長さ
                const step = Math.floor(dataLength / (totalTicks - 1));  // 最大ラベル数に基づいてステップを決定

                // 等間隔のラベルを表示
                if (index % step === 0) {
                  const date = new Date(x);
                  const month = date.getMonth() + 1; // 月（1〜12）
                  const day = date.getDate(); // 日（1〜31）
                  return `${month}/${day}`;
                }
                return ""; // 他のラベルは非表示
              }}
              style={{
                axis: { stroke: "#f4f4f4" },
                tickLabels: { fontSize: 10, padding: 5, },
                grid: { stroke: "transparent" },
                ticks: { stroke: "transparent" },
              }}
            />
            <VictoryAxis
              dependentAxis
              tickFormat={(y) => `${y}°C`} // Y軸のフォーマット
              style={{
                axis: { stroke: "transparent" },
                tickLabels: { fontSize: 10, padding: 5, fill: "#555" },
                grid: { stroke: "#eaeaea", strokeDasharray: "1,0" },
              }}
            />
            {/* 右側の湿度用軸 */}
            <VictoryAxis
              dependentAxis
              orientation="right"
              tickFormat={(y) => `${y}%`}
              style={{
                axis: { stroke: "transparent" },
                tickLabels: { fill: "#8d8d8d", fontSize: 10, padding: 5 },
                ticks: { stroke: "#8d8d8d" },
              }}
            />

            {/* 温度のグラフ（赤色、実線） */}
            <VictoryLine
              data={data.cageTemperatureHumidity.map((item) => ({
                x: item.x,
                y: item.temperature,
                temperature: item.temperature,
              }))}
              x="x"
              y="y"
              style={{
                data: { stroke: "#cd2829", strokeWidth: 2 },
              }}
            />

            {/* 湿度のグラフ（青色、実線） */}
            <VictoryLine
              data={data.cageTemperatureHumidity.map((item) => ({
                x: item.x,
                y: item.humidity,
                humidity: item.humidity,
              }))}
              x="x"
              y="y"
              style={{
                data: { stroke: "#02bbf2", strokeWidth: 2 },
              }}
            />
          </VictoryChart>
        </div>
      </View>
    );
  }

// 「ごはん」の場合
if (graphType === "food") {
  // 各日の合計値を計算
  const totalGramsPerDay = formattedData.map((data) => {
    const total = allCategories.reduce((sum, category) => {
      return sum + (data.y[category] || 0);
    }, 0);
    return { x: data.x, total };
  });

  const yValues = totalGramsPerDay.map((data) => data.total);
  const maxY = Math.max(...yValues, 0);

  const hideAxisLabels = totalGramsPerDay.length === 0; // データがない場合に軸のラベルを非表示にする

  return (
    <div style={styles.graphCon}>
      <View style={styles.titleBlock}>
        <Text
          style={[
            styles.title,
            isMobileScreen ? styles.titleSP : styles.titlePC,
          ]}
        >
          ごはん
        </Text>
      </View>
      <View style={styles.legend}>
        <View style={styles.legendItem}>
          <View style={[styles.legendBox, styles.legendBoxSeed]} />
          <Text style={styles.legendText}>主食シード</Text>
        </View>
        <View style={styles.legendItem}>
          <View style={[styles.legendBox, styles.legendBoxPellet]} />
          <Text style={styles.legendText}>主食ペレット</Text>
        </View>
      </View>
      <View style={styles.legend}>
        <View style={styles.legendItem}>
          <View style={[styles.legendBox, styles.legendBoxSupplement]} />
          <Text style={styles.legendText}>副食</Text>
        </View>
        <View style={styles.legendItem}>
          <View style={[styles.legendBox, styles.legendBoxSnack]} />
          <Text style={styles.legendText}>おやつ</Text>
        </View>
        <View style={styles.legendItem}>
          <View style={[styles.legendBox, styles.legendBoxSubfood]} />
          <Text style={styles.legendText}>サプリメント</Text>
        </View>
      </View>
      <VictoryChart
        domainPadding={{ x: 3, y: 3 }}
        padding={{ top: 10, bottom: 35, left: 40, right: 15 }}
        style={{
          parent: { height: 'auto' },
        }}
      >
        <VictoryAxis
          tickFormat={(x, index) => {
            if (hideAxisLabels) return ""; // データがない場合は何も表示しない
            const totalTicks = 7; // 最大ラベル数
            const dataLength = Math.max(1, data.formattedData.length); // データの長さ
            const step = Math.max(1, Math.floor(dataLength / (totalTicks - 1))); // ステップを最低1にする

            if (index % step === 0) {
              const date = new Date(x);
              if (!isNaN(date.getTime())) {
                const month = date.getMonth() + 1;
                const day = date.getDate();
                return `${month}/${day}`;
              }
            }
            return ""; // 他のラベルは非表示
          }}
          style={{
            axis: { stroke: hideAxisLabels ? "transparent" : "#f4f4f4" }, // 軸を透明化
            tickLabels: {
              fontSize: 10,
              padding: 5,
              fill: hideAxisLabels ? "transparent" : "#555", // ラベルを透明化
            },
            grid: { stroke: "transparent" },
            ticks: { stroke: "transparent" },
          }}
        />
        <VictoryAxis
          dependentAxis
          tickValues={Array.from(
            { length: Math.min(5, Math.ceil(maxY) + 1) },
            (_, i) => Math.round((maxY / Math.min(5, Math.ceil(maxY))) * i) // 等間隔で整数化
          )}
          tickFormat={(y) => (hideAxisLabels ? "" : `${y}g`)} // ラベルを非表示
          style={{
            axis: { stroke: "transparent" },
            tickLabels: {
              fontSize: 10,
              padding: 5,
              fill: hideAxisLabels ? "transparent" : "#555", // ラベルを透明化
            },
            grid: { stroke: hideAxisLabels ? "transparent" : "#eaeaea", strokeDasharray: "1,0" },
          }}
        />
        <VictoryStack>
          {allCategories.map((category, i) => (
            <VictoryBar
              key={i}
              data={formattedData.map((data) => ({
                x: data.x, // 日付
                y: data.y[category] || 0, // カテゴリごとの値（存在しない場合は0）
              }))}
              style={{
                data: { fill: categoryColors[category] || "#cccccc" }, // カテゴリ名を使って色を指定
              }}
              barWidth={5}
            />
          ))}
        </VictoryStack>

        {/* 透明なバーを追加 */}
        <VictoryBar
          data={totalGramsPerDay}
          x="x"
          y="total"
          barWidth={5}
          style={{
            data: { fill: "transparent" }, // 透明なバー
          }}
          labels={({ datum }) => ``}
          labelComponent={
            <VictoryTooltip
              flyoutComponent={<CustomFlyout />} // カスタム Flyout を指定
              style={{
                fontSize: 10,
                fill: "#555",
              }}
            />
          }
        />
      </VictoryChart>
    </div>
  );
}

  return <div style={styles.nograph}>データがありません</div>;
};

const styles = StyleSheet.create({
  graphCon: {
    backgroundColor: '#fff',
    borderRadius: 20,
    padding: 5,
    marginBottom: 30,
    overflow: 'hidden',
    marginHorizontal: 20,
    width: '100%',
    boxSizing: 'border-box',
  },
  titleBlock: {
    padding: 15,
  },
  titleBlock2: {
    padding: 15,
    marginBottom: 40,
  },
  title: {
    marginBottom: 10,
    fontWeight: 'bold',
    color: "#555",
  },
  titleSP: {
    fontSize: 18,
  },
  titlePC: {
    fontSize: 21,
  },
  legend: {
    flexDirection: "row", // 横並び
    alignItems: "center",
    marginBottom: 10,
  },
  legendItem: {
    flexDirection: "row", // 横並び
    alignItems: "center",
    marginRight: 20,
  },
  legendItemLast: {
    marginRight: 0, // 最後の項目は余白を削除
  },
  legendText: {
    fontSize: 18,
    color: "#8d8d8d",
    marginRight: 5,
  },
  legendLineTemperature: {
    width: 30,
    height: 3,
    backgroundColor: "#cd2829", // 温度の線色
  },
  legendLineHumidity: {
    width: 30,
    height: 3,
    backgroundColor: "#02bbf2", // 湿度の線色
  },
  legend: {
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
    marginBottom: 10,
    paddingLeft: 20,
  },
  legendItem: {
    flexDirection: "row",
    alignItems: "center",
    marginRight: 20, // 各アイテム間の余白
    marginBottom: 10, // 改行時の余白
  },
  legendText: {
    fontSize: 14,
    color: "#555",
    marginLeft: 5, // ボックスとテキストの間の余白
  },
  legendBox: {
    width: 15,
    height: 15,
  },
  legendBoxSeed: {
    backgroundColor: "#f29c9f",
  },
  legendBoxPellet: {
    backgroundColor: "#7ecef4",
  },
  legendBoxSupplement: {
    backgroundColor: "#cce198",
  },
  legendBoxSnack: {
    backgroundColor: "#f19ec2",
  },
  legendBoxSubfood: {
    backgroundColor: "#eaeaea",
  },
  nograph: {
    textAlign: 'center',
    marginVertical: 20,
    color: '#555',
    fontFamily: Platform.OS === 'ios' ? 'Helvetica' : 'sans-serif',
  },
  graphRow: {
    flexDirection: "row", // 横並び
    justifyContent: "space-between",
  },
  graphColumn: {
    flexDirection: "column", // 縦並び
  },
});

export default Graph;
