import React, { useState, useEffect, useRef } from 'react';
import { View, Text, ScrollView, TextInput, TouchableOpacity, Image, Platform, ImageBackground, Dimensions, Keyboard, ActivityIndicator } from 'react-native';
import Swiper from 'react-native-web-swiper';
import { useSelector, useDispatch } from 'react-redux';
import { getBirds, setIndex } from '../store/modules/birds';
import { getRecords, addRecord, setSelectedDateString } from '../store/modules/records';
import { Calendar, LocaleConfig } from 'react-native-calendars';
import Collapsible from 'react-native-collapsible';
import { dateToStr } from '../plugins/utils';
import Icon from 'react-native-vector-icons/MaterialIcons';
import { Picker } from '@react-native-picker/picker';
import { food_list_table, maker_list_table } from '../plugins/table'
import { Camera } from 'expo-camera';
import BarcodeScannerWeb from '../components/BarcodeScannerWeb';
import { editFood, syncFoods ,removeFood } from '../store/modules/foods';
import * as ImagePicker from 'expo-image-picker';
import { getStorage, ref, uploadBytesResumable, getDownloadURL, uploadBytes } from 'firebase/storage';
import imageCompression from 'browser-image-compression';
import { MdChevronLeft } from 'react-icons/md';
import { doc, getDoc, onSnapshot } from "firebase/firestore";
import { firestore } from '../plugins/firebase';
import { useNavigation } from '@react-navigation/native';
import { useNavigate } from 'react-router-dom'; // React Router用
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import heic2any from "heic2any";

LocaleConfig.locales['ja'] = {
  monthNames: [
    '1月', '2月', '3月', '4月', '5月', '6月',
    '7月', '8月', '9月', '10月', '11月', '12月'
  ],
  monthNamesShort: [
    '1月', '2月', '3月', '4月', '5月', '6月',
    '7月', '8月', '9月', '10月', '11月', '12月'
  ],
  dayNames: [
    '日曜日', '月曜日', '火曜日', '水曜日', '木曜日', '金曜日', '土曜日'
  ],
  dayNamesShort: [
    '日', '月', '火', '水', '木', '金', '土'
  ],
  today: '今日'
};

// 日本語をデフォルトに設定
LocaleConfig.defaultLocale = 'ja';

const screenWidth = Dimensions.get('window').width;
const isMobileScreen = screenWidth < 768; // モバイル端末の判定

const UpdateScreen = () => {
  const [windowDimensions, setWindowDimensions] = useState({ width: 0, height: 0 });
  // コンポーネントがマウントされたときに、画面サイズを取得する
  useEffect(() => {
    const { width, height } = Dimensions.get('window');
    setWindowDimensions({ width, height });

    // リサイズ時に画面サイズを更新するリスナーを追加
    const onChange = ({ window }) => {
      setWindowDimensions({ width: window.width, height: window.height });
    };
    Dimensions.addEventListener('change', onChange);

    // クリーンアップ
    return () => {
      Dimensions.removeEventListener('change', onChange);
    };
  }, []);

  const dispatch = useDispatch();

  const [isWeb, setIsWeb] = useState(false);
  const account = useSelector((state) => state.accounts.account);
  const unsortedBirds = useSelector((state) => state.birds.birds || []);
  const birds = React.useMemo(() => {
    return [...unsortedBirds].sort((a, b) => {
      if (a.order === undefined && b.order === undefined) return 0;
      if (a.order === undefined) return 1;
      if (b.order === undefined) return -1;
      return a.order - b.order;
    });
  }, [unsortedBirds]);
  const index = useSelector((state) => state.birds.index || 0);
  const records = useSelector((state) => state.records.records || []);
  const [premiumFlag, setPremiumFlag] = useState(false);

  const [isFoodSectionVisible, setIsFoodSectionVisible] = useState(false);

  const toggleFoodSection = () => {
    setIsFoodCollapsed(!isFoodCollapsed);
    setIsFoodSectionVisible(!isFoodSectionVisible);
    setIsHealthyCollapsed(true);
    setIsEnvironmentCollapsed(true);
    setIsDiaryCollapsed(true);
    setTimeout(() => {
      if (scrollViewRef.current) {
        scrollViewRef.current.scrollTo({ y: 0, animated: true });
      }
    }, 50);
  };
  

  const hideFoodSection = () => {
    setIsFoodSectionVisible(false);
    setShouldScrollToFoodSection(true);
  };

  const reduxDate = useSelector((state) => state.records.selectedDateString);
  const todayStr = new Date().toISOString().split('T')[0]; // "YYYY-MM-DD"
  const [selectedDateString, setSelectedDateString] = useState(reduxDate || todayStr);
  
  useEffect(() => {
    if (!reduxDate) {
      dispatch({ type: 'SET_SELECTED_DATE_STRING', payload: todayStr });
    } else if (reduxDate !== selectedDateString) {
      setSelectedDateString(reduxDate);
    }
  }, [reduxDate]);
  
  useEffect(() => {
    if (selectedDateString !== reduxDate) {
      dispatch({
        type: 'SET_SELECTED_DATE_STRING',
        payload: selectedDateString,
      });
    }
  }, [selectedDateString, reduxDate, dispatch]);
  

  const [memo, setMemo] = useState('');

  const [isCalendarVisible, setIsCalendarVisible] = useState(true);

  useEffect(() => {
    // Web環境かどうかを判断するためのフラグ
    if (typeof window !== 'undefined') {
      setIsWeb(true);
    }

    const fetchData = async () => {
      await dispatch(getBirds());
    };
    fetchData();
  }, [dispatch]);

  useEffect(() => {
    if (account) {
      dispatch(syncFoods({ user: account }));
    }
  }, [account, dispatch]);

  useEffect(() => {
    const fetchPremiumFlag = async () => {
      if (account?.uid) {
        try {
          const accountDocRef = doc(firestore, "accounts", account.uid); // accountsコレクションから特定のドキュメントを取得
          const accountDocSnap = await getDoc(accountDocRef);

          if (accountDocSnap.exists()) {
            const accountData = accountDocSnap.data();
            setPremiumFlag(accountData.premiumFlag || false); // premiumFlag を更新、デフォルトは false
          } else {
            console.log("該当するアカウント情報が存在しません");
          }
        } catch (error) {
          console.error("プレミアムフラグの取得中にエラーが発生しました:", error);
        }
      } else {
        console.log("アカウント情報がありません。ログインしているか確認してください。");
      }
    };
    fetchPremiumFlag();
  }, [account]);

  const navigation = useNavigation(); // React Navigation用
  const navigate = useNavigate(); // React Router用
  const linkPremium = () => {
    if (Platform.OS === 'web') {
      navigate('/premium'); // Web用のナビゲーション
    } else {
      navigation.navigate('Premium'); // モバイル用のナビゲーション
    }
  };

  const [feedList, setFeedList] = useState([]);
  const [editFeedIndex, setEditFeedIndex] = useState(-1);
  const [isHealthyCollapsed, setIsHealthyCollapsed] = useState(true);
  const [isEnvironmentCollapsed, setIsEnvironmentCollapsed] = useState(true);
  const [isFoodCollapsed, setIsFoodCollapsed] = useState(true);
  const [isDiaryCollapsed, setIsDiaryCollapsed] = useState(true);
  const [foodType, setFoodType] = useState('');
  const [foodTime, setFoodTime] = useState('');
  const [foodAmount, setFoodAmount] = useState('');
  const [foodProductName, setFoodProductName] = useState('');
  const foods = useSelector((state) => state.foods || {
    main_seed: [],
    main_pellet: [],
    sub: [],
    snack: [],
    supplement: [],
  });
  // 空チェック対象のキーのみを取得
  const foodKeys = ["main_seed", "main_pellet", "sub", "snack", "supplement"];
  // 指定したキーの値だけをチェック
  const isFoodsEmpty = foodKeys.every(key => Array.isArray(foods[key]) && foods[key].length === 0);

// デバッグ用ログ
  const [isAddFeedShown, setIsAddFeedShown] = useState(false);

  const toggleCalendar = () => {
    setIsCalendarVisible(!isCalendarVisible);
  };

  const handleFoodTypeChange = (itemValue) => {
    setFoodType(itemValue);
  };
  const handleFoodProductChange = (itemValue) => {
    setFoodProductName(itemValue);
  };

  const clearAddFeed = () => {
    setFoodTime('');
    setFoodType('');
    setFoodAmount('');
    setFoodProductName('');
    setIsAddFeedShown(false); // フォームを非表示に
    setEditFeedIndex(-1); // 編集モードを終了
  };

  const [weight, setWeight] = useState({
    weight_1st: '',
    weight_2nd: '',
    weight_3rd: '',
  });
  const [healty, setHealthy] = useState({
    droppings_status: '',
    droppings_amount: '',
    feather_change: '',
    estrus_status: '',
    estrus_type: '',
    spawning_status: '',
    spawning_type: '',
  });
  const [environment, setEnvironment] = useState({
    outside_temperture: '',
    inside_temperture: '',
    cage_temperture: '',
    cage_clean: '',
    inside_humidity: '',
    cage_humidity: '',
  });

  const resetState = () => {
    setWeight({
      weight_1st: '',
      weight_2nd: '',
      weight_3rd: '',
    });
    setHealthy({
      droppings_status: '',
      droppings_amount: '',
      feather_change: '',
      estrus_status: '',
      estrus_type: '',
      spawning_status: '',
      spawning_type: '',
    });
    setEnvironment({
      outside_temperture: '',
      inside_temperture: '',
      cage_temperture: '',
      cage_clean: '',
      inside_humidity: '',
      cage_humidity: '',
    });
    setFeedList([]);  // フードのリストもリセット
    setMemo('');  // メモのリセット
    setPhotoURL('');
    setDroppingsPhotoURL([]);
    setFoodTime('');
    setFoodType('');
    setFoodAmount('');
    setFoodProductName('');
  };
  const resetStateHealthy = () => {
    setWeight({
      weight_1st: '',
      weight_2nd: '',
      weight_3rd: '',
    });
    setHealthy({
      droppings_status: '',
      droppings_amount: '',
      feather_change: '',
      estrus_status: '',
      estrus_type: '',
      spawning_status: '',
      spawning_type: '',
    });
    setDroppingsPhotoURL([]);
  };
  const resetStateEnvironment = () => {
    setEnvironment({
      outside_temperture: '',
      inside_temperture: '',
      cage_temperture: '',
      cage_clean: '',
      inside_humidity: '',
      cage_humidity: '',
    });
  };
  const resetFood = () => {
    setFoodTime('');
    setFoodType('');
    setFoodAmount('');
    setFoodProductName('');
  };
  const resetStateDiary = () => {
    setMemo('');  // メモのリセット
    setPhotoURL('');
  };

  const setRecordData = (record) => {
    setMemo(record.memo || ''); // 日記（メモ）をセット
    setPhotoURL(record.photoURL || ''); // 写真のURLをセット
    setDroppingsPhotoURL(record.droppingsphotoURL || []);
    setWeight({
        weight_1st: record.weight?.weight_1st || '',
        weight_2nd: record.weight?.weight_2nd || '',
        weight_3rd: record.weight?.weight_3rd || '',
    });
    setHealthy({
        droppings_status: record.healty?.droppings_status || '',
        droppings_amount: record.healty?.droppings_amount || '',
        feather_change: record.healty?.feather_change || '',
        estrus_status: record.healty?.estrus_status || '',
        estrus_type: record.healty?.estrus_type || '',
        spawning_status: record.healty?.spawning_status || '',
        spawning_type: record.healty?.spawning_type || '',
    });
    setEnvironment({
        outside_temperture: record.environment?.outside_temperture || '',
        inside_temperture: record.environment?.inside_temperture || '',
        cage_temperture: record.environment?.cage_temperture || '',
        cage_clean: record.environment?.cage_clean || '',
        inside_humidity: record.environment?.inside_humidity || '',
        cage_humidity: record.environment?.cage_humidity || '',

    });
    setFeedList(record.food || []);
  };

  // 編集開始
  //const editFeedRecord = (index) => {
  //  if (editFeedIndex !== -1) {
  //    alert("編集中の項目を確定してください。");
  //    return;
  //  }
  //  const feed = feedList[index];
  //  setFoodTime(feed.time || '');
  //  setFoodType(feed.type || '');
  //  setFoodAmount(feed.amount || '');
  //  setFoodProductName(feed.product || '');
  //  setEditFeedIndex(index);
  //};

  const removeFeedRecord = async (index) => {
    try {
      if (!feedList || feedList.length === 0) {
        alert("削除する項目がありません");
        return;
      }
      if (index < 0 || index >= feedList.length) {
        alert("無効なインデックスです");
        return;
      }

      const updatedFeedList = [...feedList];
      updatedFeedList.splice(index, 1);

      const bird = birds[index];
      if (!bird) {
        alert("該当する鳥が見つかりません");
        return;
      }

      const response = await dispatch(getRecords(bird));
      const records = response.payload;

      const selectedRecord = records.find((record) => record.rid === selectedDateString);
      if (!selectedRecord) {
        alert("該当する日付のレコードが見つかりません");
        return;
      }

      await dispatch(
        addRecord({
          user: account,
          bird,
          record: {
            ...selectedRecord,
            food: updatedFeedList,
          },
        })
      );

      setFeedList(updatedFeedList);
      setEditFeedIndex(-1);
      resetFoodFields();
    } catch (error) {
      console.error("削除処理に失敗しました:", error);
      alert("削除処理に失敗しました");
    }
  };

  const resetFoodFields = () => {
    setFoodType(''); // 食事タイプのリセット
    setFoodTime(''); // 時刻のリセット
    setFoodAmount(''); // 量のリセット
    setFoodProductName(''); // 商品名のリセット
  };

  const getFoodList = () => {
    switch (foodType) {
      case '主食シード':
        return foods.main_seed;
      case '主食ペレット':
        return foods.main_pellet;
      case '副食':
        return foods.sub;
      case 'おやつ':
        return foods.snack;
      case 'サプリメント':
        return foods.supplement;
      default:
        return [];
    }
  };

  const scrollViewRef = useRef(null);
  const [shouldScrollToFoodSection, setShouldScrollToFoodSection] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [progress, setProgress] = useState(0);

  const saveRecord = async () => {
    setIsLoading(true);
    setProgress(0);
    document.activeElement.blur();

    let scrollTop = scrollViewRef.current
      ? scrollViewRef.current.scrollTop || scrollViewRef.current.scrollTo?.y || 0
      : 0;

    if (!isMobileScreen) {
      scrollTop -= 16;
    }

    const bird = birds[index];
    if (!bird) {
      alert("愛鳥が選択されていません");
      setIsLoading(false);
      return;
    }

    try {
      console.log("保存する鳥のデータ:", bird);
      let currentProgress = 0;
      const progressInterval = setInterval(() => {
      if (currentProgress >= 90) {
        clearInterval(progressInterval);
      } else {
        currentProgress += 10;
        setProgress(currentProgress);
        }
      }, 500);
      // ========================
      // 0. 既存レコードを取得して比較の準備
      // ========================
      // すでにReduxのrecordsには全レコードが格納されているので、
      // 今回保存したい日付(selectedDateString)に対応するレコードを検索する
      const existingRecord = records.find(
        (rec) => rec.rid === selectedDateString
      );

      // ================================
      // 1. 画像アップロードを変更分だけ実施
      // ================================
      const compressAndUploadImage = async (imageUri, folder) => {
        if (!imageUri) return imageUri; // nullやundefinedなら何もしない

        try {
          console.log(`画像圧縮・アップロード開始: ${folder}`);
          let fileType = "image/jpeg";

          // HEIC/HEIF 判定
          if (imageUri.endsWith(".heic") || imageUri.endsWith(".heif")) {
            console.log("HEIC/HEIF 画像を検出 - 変換開始");
            const convertedImageUri = await convertHEICToJPG(imageUri, fileType);
            if (!convertedImageUri) {
              console.error("HEIC 画像の変換に失敗");
              return imageUri;
            }
            imageUri = convertedImageUri;
          }

          // Blob化
          const response = await fetch(imageUri);
          const originalBlob = await response.blob();

          // 圧縮
          const options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 800,
            useWebWorker: true,
          };
          const compressedBlob = await imageCompression(originalBlob, options);

          // Firebase Storage へアップロード
          const storage = getStorage();
          const fileName = `${folder}/${Date.now()}.jpg`;
          const storageRef = ref(storage, fileName);

          await uploadBytes(storageRef, compressedBlob);
          console.log("アップロード完了:", fileName);

          const downloadURL = await getDownloadURL(storageRef);
          console.log("取得した画像URL:", downloadURL);
          return downloadURL;
        } catch (error) {
          console.error(`${folder} の画像アップロードエラー:`, error);
          // エラー時は元のURLをそのまま返す
          return imageUri;
        }
      };

      // 既存レコードがあって、photoURL が同じならスキップ
      let finalPhotoURL = photoURL;
      if (
        existingRecord &&
        photoURL === existingRecord.photoURL
      ) {
        console.log("同じ日記写真URLなので再アップロードをスキップします");
      } else if (photoURL) {
        // 違うURLのみアップロード
        finalPhotoURL = await compressAndUploadImage(photoURL, "diary-images");
      }

      // うんち写真は最大3枚なので各要素を比較してアップロード
      let finalDroppingsPhotoURLs = [];
      if (droppingsphotoURL && droppingsphotoURL.length > 0) {
        // 既存レコードがある場合のみ比較、なければ全アップロード
        const oldDroppings = existingRecord ? existingRecord.droppingsphotoURL || [] : [];
        for (let i = 0; i < droppingsphotoURL.length; i++) {
          const newUri = droppingsphotoURL[i];
          if (oldDroppings[i] && newUri === oldDroppings[i]) {
            console.log(`うんち写真${i+1}枚目：同じURLなのでアップロード不要`);
            finalDroppingsPhotoURLs.push(newUri);
          } else {
            const uploadedUri = await compressAndUploadImage(newUri, "droppings-images");
            finalDroppingsPhotoURLs.push(uploadedUri);
          }
        }
      }

      // ================================
      // 2. フードデータ更新
      // ================================
      const newFood = {
        time: foodTime || "",
        type: foodType || "",
        amount: foodAmount ? foodAmount.toString() : "",
        product: foodProductName || "",
      };

      const isAnyFieldFilled = newFood.time || newFood.type || newFood.amount || newFood.product;
      if (isAnyFieldFilled && (!newFood.time || !newFood.type || !newFood.amount || !newFood.product)) {
        toast.error("ごはんに全ての項目を入力してください。", {
          position: "top-center",
          autoClose: 1000,
          style: { position: "absolute", width: "100%", top: scrollTop + 20 },
        });
        setIsLoading(false);
        return;
      }
      const updatedFoodList = isAnyFieldFilled ? [...feedList, newFood] : feedList;

      // ================================
      // 3. 共通環境 & フォーマット
      // ================================
      const formattedWeight = {
        weight_1st: weight.weight_1st.toString(),
        weight_2nd: weight.weight_2nd.toString(),
        weight_3rd: weight.weight_3rd.toString(),
      };
      const commonEnvironment = {
        outside_temperture: environment?.outside_temperture?.toString() || "",
        inside_temperture: environment?.inside_temperture?.toString() || "",
        inside_humidity: environment?.inside_humidity?.toString() || "",
      };
      const individualEnvironment = { 
        cage_clean: environment?.cage_clean || "",
        cage_temperture: environment?.cage_temperture?.toString() || "",
        cage_humidity: environment?.cage_humidity?.toString() || "",
      };
      const selectedDateTimestamp = new Date(selectedDateString).getTime();

      // ================================
      // 4. 今回保存するレコード内容を組み立て
      // ================================
      const newRecord = {
        rid: selectedDateString,
        date: selectedDateTimestamp,
        healty,
        weight: formattedWeight,
        environment: { ...commonEnvironment, ...individualEnvironment },
        food: updatedFoodList,
        memo,
        photoURL: finalPhotoURL,
        droppingsphotoURL: finalDroppingsPhotoURLs,
      };

      // ================================
      // 5. 全く同じ内容なら保存スキップ
      // ================================
      if (existingRecord) {
        // JSON.stringify で簡易比較
        const oldJSON = JSON.stringify(existingRecord);
        const newJSON = JSON.stringify(newRecord);
        if (oldJSON === newJSON) {
          console.log("既存レコードと変更点がないため保存をスキップします");
          setIsLoading(false);
          toast.info("変更がありませんでした。", {
            position: "top-center",
            autoClose: 1000,
            style: { position: "absolute", width: "100%", top: scrollTop + 20 },
          });
          return; // 保存処理を打ち切り
        }
      }

      // ================================
      // 6. 全鳥に共通環境データを保存
      // ================================
      const saveCommonDataForAllBirds = async () => {
        return Promise.all(
          birds.map(async (targetBird) => {
            // （1）もし「フォームから更新中の鳥(targetBird)」なら、ここでは更新スキップする。
            //      すでに newRecord で保存済みだから。
            if (targetBird.bid === bird.bid) {
              return;
            }

            // （2）既存レコードを検索 (Reduxの records から or Firestoreから直接取得)
            const existing = records.find(
              (rec) =>
                rec.rid === selectedDateString &&
                // rec に bid があるなら、targetBird.bid と一致するか確認
                // Redux の実装次第で、rec.bid が無い場合は別の比較手段が必要
                rec.bid === targetBird.bid
            );

            // 既存レコードが無い場合は空オブジェクトで初期化（新規作成のイメージ）
            const oldRecord = existing || {};

            // 既存 environment
            const oldEnv = oldRecord.environment || {};

            // （3）共通環境だけ上書きした environment を作る
            const mergedEnv = {
              ...oldEnv,
              ...commonEnvironment,
            };

            // （4）ほかのフィールドは existing からそのまま引き継ぎ、environment だけ差し替え
            const mergedRecord = {
              ...oldRecord,
              // rid, date など最低限必要なものがなければ追加する
              rid: selectedDateString,
              date: selectedDateTimestamp,
              environment: mergedEnv,
            };

            // （5）addRecord で保存
            await dispatch(
              addRecord({ user: account, bird: targetBird, record: mergedRecord })
            );
          })
        );
      };

      // ================================
      // 7. 個別レコードを保存 + 共通保存
      // ================================
      await Promise.all([
        saveCommonDataForAllBirds(),
        dispatch(addRecord({ user: account, bird, record: newRecord })),
      ]);

      // ================================
      // 8. マーカー更新
      // ================================
      setRecordedDays((prevDays) => ({
        ...prevDays,
        [selectedDateString]: { selected: true, marked: true, selectedColor: "#cd2829" },
      }));

      // 状態リセット
      resetState();
      setProgress(100);
      // ================================
      // 9. 保存後のレコードを再取得
      // ================================
      const updatedRecords = await dispatch(getRecords(bird));
      const filteredRecords = updatedRecords.payload.filter(
        (record) => dateToStr(record.date, "YYYY-MM-DD") === selectedDateString
      );
      if (filteredRecords.length > 0) {
        setRecordData(filteredRecords[0]);
      }
    } catch (error) {
      console.error("保存中にエラーが発生:", error);
      setIsLoading(false);
      toast.error("保存に失敗しました", {
        position: "top-center",
        autoClose: 2000,
        style: { position: "absolute", width: "100%", top: scrollTop + 20 },
      });
    }
    setIsLoading(false);
    // ================================
    // 10. 保存成功トースト
    // ================================
    toast.success("保存しました。", {
      position: "top-center",
      autoClose: 1000,
      style: {
        position: "fixed",
        width: '100%',
        top: scrollTop + "px",
        zIndex: 9999,
      },
    });
  };

// 確定処理
// const confirmFeedRecord = async () => {
//   const scrollTop = scrollViewRef.current
//   ? scrollViewRef.current.scrollTop || scrollViewRef.current.scrollTo?.y || 0
//   : 0;

//   if (foodType === '' || foodTime === '' || foodAmount === '' || foodProductName === '') {
//     alert('すべての項目を入力してください');
//     return;
//   }

//   // 新しいフィードデータを作成
//   const newFeed = {
//     time: foodTime,
//     type: foodType,
//     amount: foodAmount,
//     product: foodProductName,
//   };

//   try {
//     const bird = birds[index]; // 現在選択中の鳥
//     if (!bird) {
//       alert('愛鳥が選択されていません');
//       return;
//     }

//     // レコードを取得
//     const response = await dispatch(getRecords(bird));
//     const records = response.payload;

//     // 現在選択された日付に対応するレコードを探す
//     let selectedRecord = records.find((record) => record.rid === selectedDateString);

//     // 食事リストを更新
//     const existingFood = selectedRecord?.food || [];
//     const updatedFoodList = [...existingFood, newFeed];

//     // 新規作成 or 更新
//     const record = {
//       rid: selectedDateString, // 日付キー
//       date: new Date(selectedDateString).getTime(), // タイムスタンプ
//       food: updatedFoodList, // 更新された食事リスト
//       memo: selectedRecord?.memo || '', // 既存のメモデータを維持
//       photoURL: selectedRecord?.photoURL || '', // 既存の写真URLを維持
//       droppingsphotoURL: Array.isArray(selectedRecord?.droppingsphotoURL) ? selectedRecord.droppingsphotoURL : [],
//       healty: selectedRecord?.healty || {}, // 他のデータも維持
//       weight: selectedRecord?.weight || {},
//       environment: selectedRecord?.environment || {},
//     };

//     // データベースに保存
//     await dispatch(
//       addRecord({
//         user: account,
//         bird,
//         record,
//       })
//     );

//     // フィードリストを状態に更新
//     setFeedList(updatedFoodList);

//     // UI更新
//     setRecordedDays((prevDays) => ({
//       ...prevDays,
//       [selectedDateString]: { selected: true, marked: true, selectedColor: '#cd2829' },
//     }));

//     toast.success("保存しました", {
//       position: "top-center",
//       autoClose: 5000,
//       style: {
//         position: "absolute",
//         width: '100%',
//         top: scrollTop + 0,
//       },
//     });
//   } catch (error) {
//     console.error('保存処理に失敗しました:', error);
//     toast.error("保存に失敗しました", {
//       position: "top-center",
//       autoClose: 5000,
//       style: {
//         position: "absolute",
//         width: '100%',
//         top: scrollTop + 20,
//       },
//     });
//   } finally {
//     clearAddFeed(); // フォームをリセット
//     setEditFeedIndex(-1); // 編集モードを終了
//   }
// };


  // テキストボックスの値の変更時に数値のみを許可するバリデーションを行う
  const handleInputChange = (setter, key, value) => {
    // 数字と小数点のみ許可
    const numericValue = value.replace(/[^0-9.]/g, '');

    // 小数点が複数入ることを防止
    const validValue = numericValue.split('.').length > 2 ? numericValue.slice(0, -1) : numericValue;

    if (key) {
      // 状態がオブジェクト型の場合
      setter((prevState) => ({ ...prevState, [key]: validValue }));
    } else {
      // 状態が単純な型の場合
      setter(validValue);
    }
  };

  useEffect(() => {
    resetState(); // レコードがない場合はフォームをリセット
    const targetDate = selectedDateString.replace(/年|月/g, '-').replace('日', '');
    const fetchRecordByDate = () => {
      if (records.length === 0) {
        console.log('No records available, resetting state');
        return;
      }

      // 日付でフィルタリング
      const filteredRecords = records.filter(
        (record) => dateToStr(record.rid, 'YYYY-MM-DD') === targetDate
      );

      // 該当するデータが見つかれば設定
      if (filteredRecords.length > 0) {
        const selectedRecord = filteredRecords[0];
        // データがあれば、それをセット
        setWeight({
          weight_1st: selectedRecord.weight?.weight_1st || '',
          weight_2nd: selectedRecord.weight?.weight_2nd || '',
          weight_3rd: selectedRecord.weight?.weight_3rd || '',
        });
        setHealthy({
          droppings_status: selectedRecord.healty?.droppings_status || '',
          droppings_amount: selectedRecord.healty?.droppings_amount || '',
          feather_change: selectedRecord.healty?.feather_change || '',
          estrus_status: selectedRecord.healty?.estrus_status || '',
          estrus_type: selectedRecord.healty?.estrus_type || '',
          spawning_status: selectedRecord.healty?.spawning_status || '',
          spawning_type: selectedRecord.healty?.spawning_type || '',
        });
        setEnvironment({
          outside_temperture: selectedRecord.environment?.outside_temperture || '',
          inside_temperture: selectedRecord.environment?.inside_temperture || '',
          cage_temperture: selectedRecord.environment?.cage_temperture || '',
          cage_clean: selectedRecord.environment?.cage_clean || '',
          inside_humidity: selectedRecord.environment?.inside_humidity || '',
          cage_humidity: selectedRecord.environment?.cage_humidity || '',
        });
        setMemo(selectedRecord.memo || ''); // メモをセット
        setPhotoURL(selectedRecord.photoURL || ''); // 画像URLをセット
        setDroppingsPhotoURL(Array.isArray(selectedRecord?.droppingsphotoURL) ? selectedRecord.droppingsphotoURL : []); // 画像URLを配列としてセット

        const existingFood = selectedRecord.food || [];
        setFeedList(existingFood);
      }
    };

    fetchRecordByDate();
  }, [records, selectedDateString]);

  const handleBirdChange = async (newIndex) => {

    // スワイパーで鳥を切り替える際に状態をリセット
    resetState();

    // 新しいインデックスをReduxに保存
    dispatch(setIndex(newIndex));
    dispatch(setIndex(idx)); // Reduxでインデックスを更新
    setCurrentIndex(idx); // Swiperの現在のインデックスを更新
    const selectedBird = birds[newIndex]; // 現在の鳥を取得

    if (selectedBird) {
      // Reduxストアから取得したselectedDateStringを使用
      const formattedDate = selectedDateString; // 選択された日付をフォーマット済みの日付に変換

      try {
          // 鳥に基づいてその日付のレコードを取得
          const response = await dispatch(getRecords(selectedBird));

          // 取得したレコードをフィルタリング
          const filteredRecords = response.payload.filter((record) => {
              let recordDateFormatted = dateToStr(new Date(record.date), 'YYYY年MM月DD日');
              return recordDateFormatted === formattedDate;
          });

          // 該当するレコードがあれば表示
          if (filteredRecords.length > 0) {
              const latestRecord = filteredRecords[0]; // 最も新しいレコードを取得
              console.log("該当するレコード:", latestRecord);
              setRecordData(latestRecord);  // 該当するレコードがあれば表示
          } else {
              resetState();  // 該当するレコードがない場合は状態をリセット
          }
      } catch (error) {
          console.error("レコードの取得に失敗しました:", error);
          resetState();  // エラー時も状態をリセット
      }
    }
  };

  const [recordedDays, setRecordedDays] = useState({});

  useEffect(() => {
    const fetchRidAndSetRecordedDays = async () => {

      const bird = birds[index]; // 現在スワイパーで選択されている鳥を取得
      try {
        // レコードを取得
        const response = await dispatch(getRecords(bird));
        const recordsData = response.payload;

        if (recordsData && recordsData.length > 0) {
          // recordedDaysに設定
          const recorded = {};
          recordsData.forEach((record) => {
            const rid = record.rid; // レコードID (日付)
            recorded[rid] = {
              marked: true,
              dotColor: '#cd2829',
              selectedColor: '#cd2829',
            };
          });

          setRecordedDays(recorded); // カレンダーのマーカーに設定
        } else {
          console.warn('レコードが見つかりませんでした。');
          setRecordedDays({});
        }
      } catch (error) {
        console.error('レコード取得エラー:', error);
      }
    };

    fetchRidAndSetRecordedDays();
  }, [birds, index, dispatch]); // 鳥の変更やスワイパーの切り替え時に実行

  const [photoURL, setPhotoURL] = useState(null); // 画像URLを保持
  const [droppingsphotoURL, setDroppingsPhotoURL] = useState([]);
  const convertHEICToJPG = async (imageUri, fileType) => {
    try {
      let blob;

      // `data URI` を `Blob` に変換
      const base64Data = imageUri.split(",")[1]; // Base64 部分を取得
      const byteCharacters = atob(base64Data);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      blob = new Blob([byteArray], { type: fileType });

      console.log("取得した HEIC Blob:", blob);

      // HEIC → JPEG 変換
      const convertedBlob = await heic2any({
        blob: blob,
        toType: "image/jpeg",
        quality: 0.8,
      });

      console.log("変換された JPEG Blob:", convertedBlob);

      return URL.createObjectURL(convertedBlob);
    } catch (conversionError) {
      console.error("HEIC変換エラー:", conversionError);
      alert("HEIC 画像の変換に失敗しました。");
      return null;
    }
  };

  const handlePhotoUpload = async () => {
    try {
      console.log("画像選択開始");

      // 画像選択
      const result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        allowsEditing: true,
        quality: 1,
        base64: true, // Base64データを取得
      });

      console.log("選択結果:", result);

      if (result.canceled || !result.assets || result.assets.length === 0) {
        console.log("画像が選択されなかった");
        return;
      }

      let imageUri = result.assets[0].uri;
      let fileType = result.assets[0].type || "";

      console.log("選択された画像URI:", imageUri);
      console.log("ファイルタイプ:", fileType);

      // `data:application/octet-stream;base64,` の場合、HEIC/HEIF かどうかを判定
      const isHEIC = imageUri.startsWith("data:application/octet-stream;base64,");
      if (!fileType && isHEIC) {
        console.log("データ形式から HEIC/HEIF を検出");
        fileType = "image/heic"; // デフォルトを HEIC に設定
      }

      // ファイル名の拡張子でもHEIC/HEIFを判定
      const fileName = result.assets[0].uri.split("/").pop().toLowerCase();
      if (fileName.endsWith(".heic") || fileName.endsWith(".heif")) {
        fileType = "image/heic"; // MIMEタイプが空でも HEIC に設定
      }

      // HEIC/HEIF の場合はJPEGに変換
      if (fileType.includes("heic") || fileType.includes("heif") || isHEIC) {
        console.log("HEIC/HEIF 画像を変換開始");
        const convertedImageUri = await convertHEICToJPG(imageUri, fileType);
        if (!convertedImageUri) return;
        imageUri = convertedImageUri;
      }

      // 変換した画像（または元画像）を保存
      console.log("画像を保存:", imageUri);
      setPhotoURL(imageUri);
    } catch (error) {
      console.error("画像選択エラー:", error);
      alert("画像の選択に失敗しました。");
    }
  };

  const handleDroppingsPhotoUpload = async () => {
    try {
      if (droppingsphotoURL.length >= 3) {
        alert("うんちの写真は最大3枚までアップロード可能です。");
        return;
      }

      console.log("写真選択を開始");

      // 画像選択
      const result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        allowsEditing: true,
        quality: 1,
        base64: true, // Base64データを取得
      });

      console.log("選択結果:", result);

      if (result.canceled || !result.assets || result.assets.length === 0) {
        console.log("画像が選択されなかった");
        return;
      }

      let imageUri = result.assets[0].uri;
      let fileType = result.assets[0].type || "";

      console.log("選択された画像URI:", imageUri);
      console.log("ファイルタイプ:", fileType);

      // `data:application/octet-stream;base64,` の場合、HEIC/HEIF かどうかを判定
      const isHEIC = imageUri.startsWith("data:application/octet-stream;base64,");
      if (!fileType && isHEIC) {
        console.log("データ形式から HEIC/HEIF を検出");
        fileType = "image/heic";
      }

      // ファイル名の拡張子でもHEIC/HEIFを判定
      const fileName = result.assets[0].uri.split("/").pop().toLowerCase();
      if (fileName.endsWith(".heic") || fileName.endsWith(".heif")) {
        fileType = "image/heic";
      }

      // HEIC/HEIF の場合はJPEGに変換
      if (fileType.includes("heic") || fileType.includes("heif") || isHEIC) {
        console.log("HEIC/HEIF 画像を変換開始");
        const convertedImageUri = await convertHEICToJPG(imageUri, fileType);
        if (!convertedImageUri) return;
        imageUri = convertedImageUri;
      }

      // 変換した画像（または元画像）を保存
      console.log("画像を保存:", imageUri);
      setDroppingsPhotoURL((prevURLs) => [...prevURLs, imageUri]);
    } catch (error) {
      console.error("うんちの画像選択エラー:", error);
      alert("うんちの画像の選択に失敗しました。");
    }
  };

  const [sortedBirds, setSortedBirds] = useState([...birds]);
  const [currentIndex, setCurrentIndex] = useState(0);

  // Swiperのインデックスが変わった際の処理
  const onIndexChanged = (idx) => {
    dispatch(setIndex(idx)); // Reduxでインデックスを更新
    setCurrentIndex(idx); // Swiperの現在のインデックスを更新
  };

  useEffect(() => {
    if (!birds[index] || !birds[index].bid) {
      return;
    }
    const bird = birds[index];
    const birdRef = doc(firestore, "birds", bird.bid);
    const recordRef = doc(firestore, "birds", bird.bid, "records", selectedDateString);

    // 鳥データのリアルタイム監視
    const unsubscribeBird = onSnapshot(
      birdRef,
      (docSnapshot) => {
        if (docSnapshot.exists()) {
          const updatedBirdData = docSnapshot.data();
          // Reduxの状態を更新
          dispatch({
            type: "UPDATE_BIRD",
            payload: { ...updatedBirdData, bid: bird.bid },
          });
          const updatedBirds = birds.map((b) =>
            b.bid === bird.bid ? { ...b, ...updatedBirdData } : b
          );
          // 並び替え処理
          const sortedBirdsData = updatedBirds.slice().sort((a, b) => {
            if (a.order === undefined && b.order === undefined) return 0;
            if (a.order === undefined) return 1;
            if (b.order === undefined) return -1;
            return a.order - b.order;
          });
          // 現在の鳥のインデックスを再計算
          const currentBird = sortedBirds[currentIndex];
          const newIndex = sortedBirdsData.findIndex((b) => b.bid === currentBird?.bid);

          setSortedBirds(sortedBirdsData);

          if (newIndex !== -1) {
            setCurrentIndex(newIndex);
          }
        } else {
          console.log("鳥データが存在しません");
        }
      },
      (error) => {
        console.error("鳥データのリアルタイム監視エラー:", error);
      }
    );

    return () => {
      unsubscribeBird();
    };
  }, [birds, index, selectedDateString, dispatch, currentIndex]);

  // 各セクションの開閉後のスクロール
  const handlePressHealthy = () => {
    setIsHealthyCollapsed(!isHealthyCollapsed);
    if (isHealthyCollapsed) {
      setTimeout(() => {
        const el = document.getElementById('healthySection');
        if (el) {
          el.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      }, 200);
    }
  };
  const handlePressEnvironment = () => {
    setIsEnvironmentCollapsed(!isEnvironmentCollapsed);
    if (isEnvironmentCollapsed) {
      setTimeout(() => {
        const el = document.getElementById('environmentSection');
        if (el) {
          el.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      }, 200);
    }
  };
  const handlePressFood = () => {
    setIsFoodCollapsed(!isFoodCollapsed);
    if (isFoodCollapsed) {
      setTimeout(() => {
        const el = document.getElementById('foodSection');
        if (el) {
          el.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      }, 200);
    }
  };
  const handlePressDiary = () => {
    setIsDiaryCollapsed(!isDiaryCollapsed);
    if (isDiaryCollapsed) {
      setTimeout(() => {
        const el = document.getElementById('diarySection');
        if (el) {
          el.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      }, 200);
    }
  };

  useEffect(() => {
    if (!isFoodSectionVisible && shouldScrollToFoodSection) {
      setIsFoodCollapsed(false);
      const scrollToFoodSection = setTimeout(() => {
        const el = document.getElementById('foodSection');
        if (el) {
          el.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
        setShouldScrollToFoodSection(false);
      }, 300);
      return () => clearTimeout(scrollToFoodSection);
    }
  }, [isFoodSectionVisible]);

  const getCurrentTime = () => {
    const now = new Date();
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    return `${hours}:${minutes}`;
  };

  const swiperRef = useRef(null);
  useEffect(() => {
    if (swiperRef.current && typeof swiperRef.current.goTo === 'function') {
      swiperRef.current.goTo(index, true);
    }
  }, [index]);

  return (
    <View style={{ flex: 1 }}>
      <ScrollView
        nestedScrollEnabled={true}
        showsVerticalScrollIndicator={false}
        ref={scrollViewRef}
      >
      <ToastContainer />
        <View style={styles.container}>
        {!isFoodSectionVisible && (
          <>
              {birds.length === 0 ? (
                <View style={{ alignItems: 'center' }}>
                  <Text style={{ fontSize: 16, color: '#cd2829', marginVertical: 40 }}>
                    <TouchableOpacity onPress={() => navigation.navigate('Bird')}>
                      愛鳥管理画面から愛鳥登録をしてください
                    </TouchableOpacity>
                  </Text>
              </View>
              ) : (
                <View style={styles.SwiperCon}>
                  <View style={styles.SwiperBlock}>
                    <Swiper
                    ref={swiperRef}
                    key={sortedBirds.map(bird => bird.bid).join('-')} 
                    from={index}
                    showsButtons={true}
                    loop={false}
                    onIndexChanged={(newIndex) => {
                      dispatch(setIndex(newIndex)); // Reduxに反映
                      setCurrentIndex(newIndex);    // ローカルステートにも反映
                    }}
                    controlsProps={{
                      dotActiveStyle: {
                        backgroundColor: '#cd2829',
                      },
                      dotsWrapperStyle: {
                        position: 'absolute',
                        bottom: isMobileScreen ? 17 : 70,
                        right:
                          windowDimensions.width >= 1600
                            ? -windowDimensions.width * 0.15 // 1600px以上の場合
                            : windowDimensions.width >= 1280
                            ? -windowDimensions.width * 0.22 // 1280px以上の場合
                            : isMobileScreen
                            ? -windowDimensions.width * 0.36 // モバイル画面の場合
                            : -windowDimensions.width * 0.28, // その他の場合
                      },
                      NextComponent: ({ onPress }) => (
                        <TouchableOpacity
                          style={styles.SwiperBtnBlock1}
                          onPress={onPress}
                        >
                          <Image
                            source={require('../assets/images/next_icon.png')} // 次へボタンの画像URL
                            style={styles.SwiperBtn}
                            resizeMode="contain"
                          />
                        </TouchableOpacity>
                      ),
                      PrevComponent: ({ onPress }) => (
                        <TouchableOpacity
                          style={styles.SwiperBtnBlock2}
                          onPress={onPress}
                        >
                          <Image
                            source={require('../assets/images/prev_icon.png')}
                            style={styles.SwiperBtn}
                            resizeMode="contain"
                          />
                        </TouchableOpacity>
                      ),
                    }}
                  >
                    {sortedBirds.map((bird, idx) => (
                      <View key={idx} style={styles.swiperSlide}>
                        <ImageBackground
                          source={require('../assets/images/swiper_back.png')} // 背景画像を指定
                          style={styles.backgroundImage}
                        >
                          <View style={styles.contentContainer}>
                            <View style={styles.photo_space_middle}>
                              <View style={styles.photo_space_circle}>
                                <Image
                                  source={{ uri: bird?.photo || '' }}
                                  style={styles.photo_space_image}
                                />
                              </View>
                            </View>
                            <Text style={styles.name}>
                              {(bird?.name || '名前').length > (isMobileScreen ? 8 : 16)
                                ? (bird?.name || '名前').slice(0, isMobileScreen ? 8 : 16) + "..."
                                : bird?.name || '名前'}
                            </Text>
                          </View>
                        </ImageBackground>
                      </View>
                    ))}
                  </Swiper>
                </View>
              </View>

              )}
          <View style={styles.Calendar}>
            <View style={styles.CalendarBlock}>
              <Calendar
                hideExtraDays={true}  // 空の日を非表示
                monthFormat={'yyyy年MM月'} // 年月の表示フォーマットを指定
                renderArrow={(direction) => {
                  return direction === 'left' ? (
                    <Image
                      source={require('../assets/images/arrow_left.png')}
                      style={{ width: 18, height: 18 , resizeMode: 'contain'}}
                    />
                  ) : (
                    <Image
                      source={require('../assets/images/arrow_right.png')}
                      style={{ width: 18, height: 18, resizeMode: 'contain'}}
                    />
                  );
                }}
                onPressArrowLeft={(subtractMonth) => subtractMonth()} // 必須
                onPressArrowRight={(addMonth) => addMonth()} // 必須
                dayComponent={({ date, state }) => {
                  const isSunday = new Date(date.dateString).getDay() === 0; // 日曜日
                  const isSaturday = new Date(date.dateString).getDay() === 6; // 土曜日
                  const marker = recordedDays[date.dateString]; // マーカーの情報を取得

                  // 今日の日付と選択された日付を比較
                  const isToday = date.dateString === dateToStr(new Date(), 'YYYY-MM-DD'); // 今日の日付
                  const isSelected = date.dateString === selectedDateString; // 選択された日付

                  return (
                    <TouchableOpacity
                      onPress={() => {
                        setSelectedDateString(date.dateString); // 日付を更新
                        dispatch({
                          type: 'records/setSelectedDateString', // ← Reduxも更新
                          payload: date.dateString,
                        });
                      }}
                    >
                      <View style={{ position: 'relative' }}>
                        {/* 選択された日付にのみ背景を設定 */}
                        {isSelected && (
                          <View
                            style={{
                              position: 'absolute',
                              top: 0,
                              left: 0,
                              right: 0,
                              bottom: 0,
                              backgroundColor: '#cd2829',
                              borderRadius: "50%",
                              justifyContent: 'center',
                              alignItems: 'center',
                              zIndex: 0,
                              width: 25,
                              height: 25,
                              left: '50%',
                              transform: [{ translateX: '-50%' }],
                            }}
                          />
                        )}

                        {/* マーカーの表示 */}
                        {marker && marker.marked && !isSelected && ( // 選択されていない日付のマーカー
                          <View
                            style={{
                              position: 'absolute',
                              bottom: -5, // 日付の下に配置
                              left: '50%',
                              transform: [{ translateX: -2 }], // 横方向の中央に配置
                              width: 5,
                              height: 5,
                              borderRadius: 5,
                              backgroundColor: marker.dotColor, // 通常のマーカー色
                            }}
                          />
                        )}

                        {/* 日付の表示 */}
                        <Text
                          style={{
                            textAlign: 'center',
                            color:
                              isSelected
                              ? '#ffffff' // 選択された日付の文字色
                              : state === 'disabled'
                              ? '#d9e1e8' // 無効な日付の文字色
                              : isSunday
                              ? '#cd2829' // 日曜日
                              : isSaturday
                              ? '#02bbf2' // 土曜日
                              : '#555555', // 平日の文字色
                            zIndex: 1,
                            fontSize: 16,
                          }}
                        >
                          {date.day}
                        </Text>
                      </View>
                    </TouchableOpacity>
                  );
                }}
                theme={{
                  'stylesheet.calendar.header': {
                    dayTextAtIndex0: { color: '#cd2829' }, // 日曜日
                    dayTextAtIndex6: { color: '#02bbf2' }, // 土曜日
                    dayTextAtIndex1: { color: '#555555' }, // 月曜日
                    dayTextAtIndex2: { color: '#555555' }, // 火曜日
                    dayTextAtIndex3: { color: '#555555' }, // 水曜日
                    dayTextAtIndex4: { color: '#555555' }, // 木曜日
                    dayTextAtIndex5: { color: '#555555' }, // 金曜日
                  },
                  textMonthFontSize: isMobileScreen ? 18 : 21,
                  textDayHeaderFontSize: isMobileScreen ? 16 : 16,
                }}
              />
            </View>
          </View>

          <View style={styles.mainContainer}>
            {/* 体調セクション */}
            <View style={styles.sectionContainer} nativeID="healthySection">
              <TouchableOpacity
                style={styles.sectionHeader}
                onPress={handlePressHealthy}
              >
                <View style={styles.headerLeft}>
                  <Image
                    source={require('../assets/images/home_icon1.png')}
                    style={styles.iconImage}
                  />
                  <Text style={styles.sectionHeaderText}>体調</Text>
                </View>
                <Image
                  source={
                    isHealthyCollapsed
                      ? require('../assets/images/plus_icon.png') // 閉じているときはプラスアイコン
                      : require('../assets/images/minus_icon.png') // 開いているときはマイナスアイコン
                  }
                  style={styles.iconImage2}
                />
              </TouchableOpacity>

              <Collapsible
                collapsed={isHealthyCollapsed}
                style={styles.sectionContent}
              >
                <View style={styles.listItemCategory}>
                  <Text style={styles.listItemTtl}>体重</Text>
                </View>

                <View style={styles.listItemblock}>
                  <View style={styles.listItem}>
                    <View style={styles.listItemweight}>
                      <TextInput
                        style={[
                          styles.listItemData,
                          weight.weight_1st ? styles.inputActive : styles.inputPlaceholder,
                        ]}
                        keyboardType={isWeb ? "decimal-pad" : "numeric"}
                        placeholder="0.0"
                        value={weight.weight_1st}
                        onChangeText={(text) => handleInputChange(setWeight, 'weight_1st', text)}
                      />
                      <Text style={styles.listItemUnit}>g</Text>
                    </View>
                  </View>
                </View>
                <View>
                  {/* うんちの状態 */}
                  <View style={styles.listItemCategoryBlock}>
                    <View style={styles.listItemCategory}>
                      <Text style={styles.listItemTtl}>うんち</Text>
                    </View>
                    <View style={styles.row}>
                      <View style={styles.radioContainer}>
                        <Text style={styles.radioItemHeader}>状態</Text>
                        <TouchableOpacity onPress={() => setHealthy({ ...healty, droppings_status: '通常' })}>
                          <Text style={healty.droppings_status === '通常' ? styles.radioSelected : styles.radio}>通常</Text>
                        </TouchableOpacity>
                        <TouchableOpacity onPress={() => setHealthy({ ...healty, droppings_status: 'ゆるい' })}>
                          <Text style={healty.droppings_status === 'ゆるい' ? styles.radioSelected : styles.radio}>ゆるい</Text>
                        </TouchableOpacity>
                        <TouchableOpacity onPress={() => setHealthy({ ...healty, droppings_status: '水っぽい' })}>
                          <Text style={healty.droppings_status === '水っぽい' ? styles.radioSelected : styles.radio}>水っぽい</Text>
                        </TouchableOpacity>
                        <TouchableOpacity onPress={() => setHealthy({ ...healty, droppings_status: '固い' })}>
                          <Text style={healty.droppings_status === '固い' ? styles.radioSelected : styles.radio}>固い</Text>
                        </TouchableOpacity>
                      </View>
                    </View>
                    {/* うんちの量 */}
                    <View style={styles.row}>
                      <View style={styles.radioContainer}>
                        <Text style={styles.radioItemHeader}>量</Text>
                        <TouchableOpacity onPress={() => setHealthy({ ...healty, droppings_amount: '通常' })}>
                          <Text style={healty.droppings_amount === '通常' ? styles.radioSelected : styles.radio}>通常</Text>
                        </TouchableOpacity>
                        <TouchableOpacity onPress={() => setHealthy({ ...healty, droppings_amount: '少ない' })}>
                          <Text style={healty.droppings_amount === '少ない' ? styles.radioSelected : styles.radio}>少ない</Text>
                        </TouchableOpacity>
                        <TouchableOpacity onPress={() => setHealthy({ ...healty, droppings_amount: '多い' })}>
                          <Text style={healty.droppings_amount === '多い' ? styles.radioSelected : styles.radio}>多い</Text>
                        </TouchableOpacity>
                      </View>
                    </View>
                    {/* うんちの写真 */}
                    <Text style={styles.listItemHeader}>うんちの写真</Text>
                    <View style={styles.row}>
                      {premiumFlag ? (
                        <View style={styles.uploadButton2Block}>
                          <TouchableOpacity onPress={handleDroppingsPhotoUpload} style={styles.uploadButton2}>
                            <Image
                              source={require('../assets/images/photo_icon.png')}
                              style={styles.iconPhoto2}
                            />
                            <Text style={styles.uploadButtonText}>写真をアップロード</Text>
                          </TouchableOpacity>
                          {droppingsphotoURL && droppingsphotoURL.length > 0 ? (
                            <View style={styles.droppingsphotoBlock}>
                              {droppingsphotoURL.slice(0, 3).map((url, index) => (
                                <View
                                  key={index}
                                  style={[
                                    styles.droppingsphotoImg,
                                    index === 2 && styles.specialDroppingsPhoto, // 3番目だけ特別なスタイルを適用
                                  ]}
                                >
                                  <Image
                                    source={{ uri: url }}
                                    style={styles.uploadedPhoto} // 画像用スタイル
                                  />
                                </View>
                              ))}
                            </View>
                          ) : null}
                        </View>
                      ) : (
                        <TouchableOpacity
                          onPress={linkPremium}
                          style={styles.premiumLinkBtn}
                        >
                          <Image
                            source={require('../assets/images/update_premium3.png')}
                            style={styles.premiumImage1}
                          />
                        </TouchableOpacity>
                      )}
                    </View>
                  </View>

                  {/* 換羽 */}
                  <View style={styles.listItemCategoryBlock}>

                    <View style={styles.listItemCategory}>
                      <Text style={styles.listItemTtl}>状態</Text>
                    </View>
                    <View style={styles.row}>
                      <View style={styles.radioContainer}>
                        <Text style={styles.radioItemHeader}>換羽</Text>
                        <TouchableOpacity onPress={() => setHealthy({ ...healty, feather_change: '無' })}>
                          <Text style={healty.feather_change === '無' ? styles.radioSelected : styles.radio}>無</Text>
                        </TouchableOpacity>
                        <TouchableOpacity onPress={() => setHealthy({ ...healty, feather_change: '少ない' })}>
                          <Text style={healty.feather_change === '少ない' ? styles.radioSelected : styles.radio}>少ない</Text>
                        </TouchableOpacity>
                        <TouchableOpacity onPress={() => setHealthy({ ...healty, feather_change: '通常' })}>
                          <Text style={healty.feather_change === '通常' ? styles.radioSelected : styles.radio}>通常</Text>
                        </TouchableOpacity>
                        <TouchableOpacity onPress={() => setHealthy({ ...healty, feather_change: '多い' })}>
                          <Text style={healty.feather_change === '多い' ? styles.radioSelected : styles.radio}>多い</Text>
                        </TouchableOpacity>
                      </View>
                    </View>

                    {/* 発情 */}
                    <View style={styles.row}>
                      <View style={styles.radioContainer}>
                        <Text style={styles.radioItemHeader}>発情</Text>
                        <TouchableOpacity onPress={() => setHealthy({ ...healty, estrus_status: '無' })}>
                          <Text style={healty.estrus_status === '無' ? styles.radioSelected : styles.radio}>無</Text>
                        </TouchableOpacity>
                        <TouchableOpacity onPress={() => setHealthy({ ...healty, estrus_status: '弱い' })}>
                          <Text style={healty.estrus_status === '弱い' ? styles.radioSelected : styles.radio}>弱い</Text>
                        </TouchableOpacity>
                        <TouchableOpacity onPress={() => setHealthy({ ...healty, estrus_status: '強い' })}>
                          <Text style={healty.estrus_status === '強い' ? styles.radioSelected : styles.radio}>強い</Text>
                        </TouchableOpacity>
                      </View>
                    </View>

                    {/* 産卵 */}
                    <View style={styles.row}>
                      <View style={styles.radioContainer}>
                        <Text style={styles.radioItemHeader}>産卵</Text>
                        <TouchableOpacity onPress={() => setHealthy({ ...healty, spawning_status: '無', spawning_count: '' })}>
                          <Text style={healty.spawning_status === '無' ? styles.radioSelected : styles.radio}>無</Text>
                        </TouchableOpacity>
                        <TouchableOpacity 
                          onPress={() => {
                            setHealthy({
                              ...healty,
                              spawning_status: '有',
                              spawning_type: healty.spawning_type || '1',
                            });
                          }}
                        >
                          <Text style={healty.spawning_status === '有' ? styles.radioSelected : styles.radio}>
                            有
                          </Text>
                        </TouchableOpacity>
                        {/* 産卵ありの場合に個数入力フィールドを表示 */}
                        {healty.spawning_status === '有' && (
                          <View style={styles.rowSpawning}>
                            <TextInput
                              style={[
                                styles.inputSpawning,
                                healty.spawning_type ? styles.inputActive : styles.inputPlaceholder,
                              ]}
                              placeholder="0"
                              keyboardType="numeric"
                              value={healty.spawning_type}
                              onChangeText={(text) => {
                                const numericValue = text.replace(/[^0-9]/g, '');
                                setHealthy({ ...healty, spawning_type: numericValue });
                              }}
                            />
                            <Text style={styles.textSpawning}>個</Text>
                          </View>
                        )}
                      </View>
                    </View>
                  </View>
                  {/* ボタン */}
                  <View style={styles.control}>
                    <View style={styles.controlBlock}>
                      <TouchableOpacity style={styles.controlBtnSave} onPress={saveRecord}>
                        <Text style={styles.controlBtnSaveText}>保存</Text>
                      </TouchableOpacity>
                    </View>
                    <View style={styles.controlBlock}>
                      <TouchableOpacity style={styles.controlBtnCancel} onPress={resetStateHealthy}>
                        <Text style={styles.controlBtnCancelText}>キャンセル</Text>
                      </TouchableOpacity>
                    </View>
                  </View>
                </View>
              </Collapsible>
            </View>

            {/* 環境セクション */}
            <View style={styles.sectionContainer} nativeID="environmentSection">
              <TouchableOpacity
                style={styles.sectionHeader}
                onPress={handlePressEnvironment}
              >
                <View style={styles.headerLeft}>
                  <Image
                    source={require('../assets/images/home_icon2.png')}
                    style={styles.iconImage}
                  />
                  <Text style={styles.sectionHeaderText}>環境</Text>
                </View>
                <Image
                  source={
                    isEnvironmentCollapsed
                      ? require('../assets/images/plus_icon.png') // 閉じているときはプラスアイコン
                      : require('../assets/images/minus_icon.png') // 開いているときはマイナスアイコン
                  }
                  style={styles.iconImage2}
                />
              </TouchableOpacity>
              <Collapsible
                collapsed={isEnvironmentCollapsed}
                style={styles.sectionContent}
              >
                <View>
                  {/* 外気温と清掃 */}
                  <View style={styles.envrow}>
                    <View style={styles.column}>
                      <Text style={styles.listItemHeader}>外気温</Text>
                      <View style={styles.envItem}>
                        <TextInput
                          style={[
                            styles.input,
                            environment.outside_temperture ? styles.inputActive : styles.inputPlaceholder,
                          ]}
                          keyboardType="numeric"
                          placeholder="00.0"
                          value={environment.outside_temperture}
                          onChangeText={(text) => handleInputChange(setEnvironment, 'outside_temperture', text)}
                        />
                        <Text style={styles.unit}>℃</Text>
                      </View>
                    </View>
                    <View style={styles.column2}>
                      <Text style={styles.listItemHeader}>清掃</Text>
                      <View style={styles.radioContainer2}>
                        <TouchableOpacity onPress={() => setEnvironment({ ...environment, cage_clean: '未' })}>
                          <Text style={environment.cage_clean === '未' ? styles.radioSelected : styles.radio}>
                            未
                          </Text>
                        </TouchableOpacity>
                        <TouchableOpacity onPress={() => setEnvironment({ ...environment, cage_clean: '済' })}>
                          <Text style={environment.cage_clean === '済' ? styles.radioSelected : styles.radio}>
                            済
                          </Text>
                        </TouchableOpacity>
                      </View>
                    </View>
                  </View>

                  {/* 室内温度と湿度 */}
                  <View style={styles.envrow}>
                    <View style={styles.column}>
                      <Text style={styles.listItemHeader}>室内温度</Text>
                      <View style={styles.envItem}>
                        <TextInput
                          style={[
                            styles.input,
                            environment.inside_temperture ? styles.inputActive : styles.inputPlaceholder,
                          ]}
                          keyboardType="numeric"
                          placeholder="00.0"
                          value={environment.inside_temperture}
                          onChangeText={(text) => handleInputChange(setEnvironment, 'inside_temperture', text)}
                        />
                        <Text style={styles.unit}>℃</Text>
                      </View>
                    </View>
                    <View style={styles.column}>
                      <Text style={styles.listItemHeader}>室内湿度</Text>
                      <View style={styles.envItem}>
                        <TextInput
                          style={[
                            styles.input,
                            environment.inside_humidity ? styles.inputActive : styles.inputPlaceholder,
                          ]}
                          keyboardType="numeric"
                          placeholder="00.0"
                          value={environment.inside_humidity}
                          onChangeText={(text) => handleInputChange(setEnvironment, 'inside_humidity', text)}
                        />
                        <Text style={styles.unit}>％</Text>
                      </View>
                    </View>
                  </View>

                  {/* ケージ温度と湿度 */}
                  <View style={styles.envrow}>
                    <View style={styles.column}>
                      <Text style={styles.listItemHeader}>ケージ温度</Text>
                      <View style={styles.envItem}>
                        <TextInput
                          style={[
                            styles.input,
                            environment.cage_temperture ? styles.inputActive : styles.inputPlaceholder,
                          ]}
                          keyboardType="numeric"
                          placeholder="00.0"
                          value={environment.cage_temperture}
                          onChangeText={(text) => handleInputChange(setEnvironment, 'cage_temperture', text)}
                        />
                        <Text style={styles.unit}>℃</Text>
                      </View>
                    </View>
                    <View style={styles.column}>
                      <Text style={styles.listItemHeader}>ケージ湿度</Text>
                      <View style={styles.envItem}>
                        <TextInput
                          style={[
                            styles.input,
                            environment.cage_humidity ? styles.inputActive : styles.inputPlaceholder,
                          ]}
                          keyboardType="numeric"
                          placeholder="00.0"
                          value={environment.cage_humidity}
                          onChangeText={(text) => handleInputChange(setEnvironment, 'cage_humidity', text)}
                        />
                        <Text style={styles.unit}>％</Text>
                      </View>
                    </View>
                  </View>

                  {/* 保存とキャンセルボタン */}
                  <View style={styles.control}>
                    <View style={styles.controlBlock}>
                      <TouchableOpacity style={styles.controlBtnSave} onPress={saveRecord}>
                        <Text style={styles.controlBtnSaveText}>保存</Text>
                      </TouchableOpacity>
                    </View>
                    <View style={styles.controlBlock}>
                      <TouchableOpacity style={styles.controlBtnCancel} onPress={resetStateEnvironment}>
                        <Text style={styles.controlBtnCancelText}>キャンセル</Text>
                      </TouchableOpacity>
                    </View>
                  </View>
                </View>
              </Collapsible>
            </View>

            {/* 食事セクション */}
            <View style={styles.sectionContainer} nativeID="foodSection">
              <TouchableOpacity 
                style={styles.sectionHeader} 
                onPress={handlePressFood}
                >
                <View style={styles.headerLeft}>
                  <Image
                    source={require('../assets/images/home_icon3.png')}
                    style={styles.iconImage}
                  />
                  <Text style={styles.sectionHeaderText}>ごはん</Text>
                </View>
                <Image
                  source={
                    isFoodCollapsed
                      ? require('../assets/images/plus_icon.png') // 閉じているときはプラスアイコン
                      : require('../assets/images/minus_icon.png') // 開いているときはマイナスアイコン
                  }
                  style={styles.iconImage2}
                />
              </TouchableOpacity>
              <Collapsible
                collapsed={isFoodCollapsed}
                style={styles.sectionContent}
              >
                <View>
                    <View style={styles.formContainer}>
                      {/* 「＋ごはん編集」ボタンを表示 */}
                      <TouchableOpacity style={styles.addFoodButton} onPress={toggleFoodSection}>
                        <Text style={styles.addFoodButtonText}>＋ごはん編集</Text>
                      </TouchableOpacity>
                      <>
                      {/* foods にデータがある場合のみ表示 */}
                      {!isFoodsEmpty && (
                        <>
                      <View style={styles.formContainerrow}>
                        <View style={styles.formblock}>
                          <Text style={styles.formText}>時刻</Text>
                          {isWeb && (
                            <input
                              type="time"
                              value={foodTime || getCurrentTime()} // 現在時刻をデフォルト値にする
                              onFocus={(e) => {
                                if (!foodTime) {
                                  const currentTime = getCurrentTime();
                                  setFoodTime(currentTime);
                                  e.target.value = currentTime; // ここでピッカーの値も変更
                                }
                              }}
                              onChange={(e) => setFoodTime(e.target.value)}
                              style={{
                                ...styles.inputTime,
                                ...(foodTime ? styles.inputActive : styles.inputPlaceholder),
                              }}
                            />
                          )}
                        </View>
                        <View style={styles.formblock}>
                          <Text style={styles.formText}>分類</Text>
                          <Picker
                            selectedValue={foodType}
                            onValueChange={handleFoodTypeChange}
                            style={[
                              styles.foodPicker,
                              foodType ? styles.inputActive : styles.inputPlaceholder,
                            ]}
                          >
                            <Picker.Item label="選択してください" value="" />
                            <Picker.Item label="主食シード" value="主食シード" />
                            <Picker.Item label="主食ペレット" value="主食ペレット" />
                            <Picker.Item label="副食" value="副食" />
                            <Picker.Item label="おやつ" value="おやつ" />
                            <Picker.Item label="サプリメント" value="サプリメント" />
                          </Picker>
                        </View>
                        <View style={styles.formblock}>
                          <Text style={styles.formText}>量</Text>
                          <TextInput
                            style={[
                              styles.inputAmount,
                              foodAmount ? styles.inputActive : styles.inputPlaceholder,
                            ]}
                            placeholder="00g"
                            keyboardType="numeric"
                            value={foodAmount}
                            onChangeText={(text) => handleInputChange(setFoodAmount, null, text)}
                          />
                        </View>
                      </View>
                      <View>
                        <Text style={styles.formText}>ごはん</Text>
                        <Picker
                          selectedValue={foodProductName}
                          style={[
                            styles.foodPicker,
                            foodProductName ? styles.inputActive : styles.inputPlaceholder,
                          ]}
                          onValueChange={(value) => {
                            handleFoodProductChange(value);
                          }}
                        >
                          <Picker.Item label="選択してください" value="" />
                          {getFoodList().map((food, index) => (
                            <Picker.Item key={index} label={food.product} value={food.product} />
                          ))}
                        </Picker>
                      </View>
                      <View style={styles.control}>
                        <View style={styles.controlBlock}>
                          <TouchableOpacity style={styles.controlBtnSave} onPress={saveRecord}>
                            <Text style={styles.controlBtnSaveText}>保存</Text>
                          </TouchableOpacity>
                        </View>
                        <View style={styles.controlBlock}>
                          <TouchableOpacity style={styles.controlBtnCancel} onPress={resetFood}>
                            <Text style={styles.controlBtnCancelText}>キャンセル</Text>
                          </TouchableOpacity>
                        </View>
                      </View>
                      </>
                    )}
                  </>
                </View>
                  {/* 確定されたフードリストを表示 */}
                  {feedList.map((feed, index) => (
                    <View key={index} style={styles.feedItem}>
                      {editFeedIndex === index ? (
                        // 編集モード：フォームを表示
                        <View style={styles.formContainer}>
                          <TextInput
                            value={foodTime}
                            onChangeText={(text) => setFoodTime(text)}
                            placeholder="時刻"
                            style={styles.input}
                          />
                          <Picker
                            selectedValue={foodType}
                            onValueChange={(itemValue) => setFoodType(itemValue)}
                          >
                            <Picker.Item label="主食シード" value="主食シード" />
                            <Picker.Item label="主食ペレット" value="主食ペレット" />
                            <Picker.Item label="副食" value="副食" />
                            <Picker.Item label="おやつ" value="おやつ" />
                            <Picker.Item label="サプリメント" value="サプリメント" />
                          </Picker>
                          <Picker
                            selectedValue={foodProductName}
                            onValueChange={(itemValue) => setFoodProductName(itemValue)}
                          >
                            <Picker.Item label="商品名" value="" />
                            {getFoodList().map((food, idx) => (
                              <Picker.Item key={idx} label={food.product} value={food.product} />
                            ))}
                          </Picker>
                          <TextInput
                            style={[
                              styles.input,
                              environment.foodAmount ? styles.inputActive : styles.inputPlaceholder,
                            ]}
                            placeholder="食事の量 (g)"
                            keyboardType="numeric"
                            value={foodAmount}
                            onChangeText={(text) => handleInputChange(setFoodAmount, 'foodAmount', text)}
                          />
                          <TouchableOpacity onPress={confirmFeedRecord}>
                            <Text>確定</Text>
                          </TouchableOpacity>
                        </View>
                      ) : (
                        // 通常表示モード
                        <View>
                          <View key={index} style={styles.foodCard}>
                            {/* 時間と食事タイプ */}
                            <View style={styles.foodTimeContainer}>
                              <View style={styles.foodTypeBlock}>
                                <Text style={styles.foodTime}>{feed.time || ''}</Text>
                                <Text style={styles.foodType}>{feed.type || ''}</Text>
                                <Text style={styles.foodAmount}>{`${feed.amount || '0'}g`}</Text>
                              </View>
                              <TouchableOpacity
                                onPress={() => removeFeedRecord(index)}
                                style={styles.removeFeedRecord}
                              >
                                <Text style={styles.removeFeedRecordText}>削除</Text>
                              </TouchableOpacity>
                            </View>
                            {/* 製品情報 */}
                            <View style={styles.foodDetailsBlock}>
                              <Text style={styles.foodDetails}>
                                {`${feed.product || ''} ${feed.maker ? `(${feed.maker})` : ''}`}
                              </Text>
                            </View>
                          </View>
                          {/*
                          <TouchableOpacity onPress={() => editFeedRecord(index)}>
                            <Text>編集</Text>
                          </TouchableOpacity>
                          */}
                        </View>
                      )}
                    </View>
                  ))}
                </View>
              </Collapsible>
            </View>

            <View style={styles.sectionContainer} nativeID="diarySection">
              <TouchableOpacity
                style={styles.sectionHeader}
                onPress={handlePressDiary}
              >
                <View style={styles.headerLeft}>
                  <Image
                    source={require('../assets/images/home_icon4.png')}
                    style={styles.iconImage}
                  />
                  <Text style={styles.sectionHeaderText}>日記・写真</Text>
                </View>
                <Image
                  source={
                    isDiaryCollapsed
                      ? require('../assets/images/plus_icon.png') // 閉じているときはプラスアイコン
                      : require('../assets/images/minus_icon.png') // 開いているときはマイナスアイコン
                  }
                  style={styles.iconImage2}
                />
              </TouchableOpacity>
              <Collapsible
                collapsed={isDiaryCollapsed}
                style={styles.sectionContent2}
              >
                <View style={styles.listItemCategory2}>
                  {premiumFlag ? (
                      photoURL ? (
                      <View style={styles.PhotoBlock1}>
                        {/* プレビュー表示 */}
                        <TouchableOpacity
                          onPress={handlePhotoUpload}
                          style={{ backgroundColor: '#ffffff', borderRadius: 10, height: '100%', width: '100%' }}
                        >
                          {/* アップロードされた画像をクリック可能に */}
                          <Image
                            source={{ uri: photoURL }}
                            style={{ backgroundColor: '#ffffff', borderRadius: 10, height: '100%', width: '100%' }}
                          />
                        </TouchableOpacity>
                        </View>
                      ) : (
                        <View style={styles.PhotoBlock1}>
                          <TouchableOpacity onPress={handlePhotoUpload} style={styles.uploadButton}>
                            <Image
                              source={require('../assets/images/photo_icon.png')}
                              style={styles.iconPhoto}
                            />
                            <Text style={styles.uploadButtonText}>写真をアップロード</Text>
                          </TouchableOpacity>
                        </View>
                      )
                    ) : (
                      // premiumFlag が false または存在しない場合、テキストまたはリンクを表示
                      <View style={styles.PhotoBlock2}>
                        <TouchableOpacity
                          onPress={linkPremium}
                          style={styles.premiumLinkBtn}
                        >
                          <Image
                            source={require('../assets/images/update_premium2.png')}
                            style={styles.premiumImage2}
                          />
                        </TouchableOpacity>
                      </View>
                    )}

                  {/* メモの入力欄 */}
                  <TextInput
                    style={[
                      styles.memoInput,
                      memo ? styles.inputActive : styles.inputPlaceholder,
                    ]}
                    placeholder="コメントを入力する"
                    value={memo} // 選択された日記内容が反映
                    onChangeText={(text) => setMemo(text)} // 編集可能
                    multiline={true}
                  />
                </View>
                <View style={styles.control}>
                  <View style={styles.controlBlock}>
                    <TouchableOpacity style={styles.controlBtnSave} onPress={saveRecord}>
                      <Text style={styles.controlBtnSaveText}>保存</Text>
                    </TouchableOpacity>
                  </View>
                  <View style={styles.controlBlock}>
                    <TouchableOpacity style={styles.controlBtnCancel} onPress={resetStateDiary}>
                      <Text style={styles.controlBtnCancelText}>キャンセル</Text>
                    </TouchableOpacity>
                  </View>
                </View>
              </Collapsible>
            </View>
          </View>
        </>
        )}
        {isFoodSectionVisible && <FoodSection hideFoodSection={hideFoodSection} nestedScrollEnabled={true}/>}
        </View>
      </ScrollView>
      {isLoading && (
        <View style={styles.loadingOverlay}>
          <View style={styles.loadingBackground} />
          <ActivityIndicator size="large" color="#cd2829" />
          <Text style={{ color: '#555', marginTop: 10 }}>
            保存中... ({progress}% 完了)
          </Text>
        </View>
      )}
    </View>
  );
};

const FoodSection = ({ hideFoodSection }) => {
  const dispatch = useDispatch();
  const account = useSelector((state) => state.accounts.account);

  const [registerFoodType, setRegisterFoodType] = useState('');
  const main_seed = useSelector((state) => state.foods.main_seed || []);
  const main_pellet = useSelector((state) => state.foods.main_pellet || []);
  const sub = useSelector((state) => state.foods.sub || []);
  const snack = useSelector((state) => state.foods.snack || []);
  const supplement = useSelector((state) => state.foods.supplement || []);
  const [hasPermission, setHasPermission] = useState(null);
  const [scanned, setScanned] = useState(false);
  const [registerMakerName, setRegisterMakerName] = useState('');
  const [registerProductName, setRegisterProductName] = useState('');
  const [cameraOpen, setCameraOpen] = useState(false);
  const [isWeb, setIsWeb] = useState(false);
  const [makerList, setMakerList] = useState([]);
  const [foodList, setFoodList] = useState([]);
  const [feedList, setFeedList] = useState([]);

  const initialState = {
    main_seed: [],
    main_pellet: [],
    sub: [],
    snack: [],
    supplement: [],
  };

  useEffect(() => {
    // Web環境かどうかを判断するためのフラグ
    if (typeof window !== 'undefined') {
      setIsWeb(true);
    }

    // 重複のないメーカーリストを設定
    setMakerList([...new Set(maker_list_table)]);
  }, []);

  useEffect(() => {
    console.log("FeedList Updated:", feedList);
  }, [feedList]);

  useEffect(() => {
    const loadExistingFoodData = async () => {
      try {
        await dispatch(syncFoods({ user: account }));
      } catch (error) {
        console.error('フードデータの同期に失敗しました:', error);
      }
    };
    loadExistingFoodData();
  }, [dispatch, account]);

  // モバイル用バーコードがスキャンされたときの処理
  const handleBarCodeScanned = ({ type, data }) => {
    setScanned(true);

    // スキャンされたバーコードに基づいてフード情報を取得
    let food = food_list_table.find((item) => item.JAN === data);

    if (food) {
      setRegisterMakerName(food.maker); // メーカー名を設定
      setRegisterProductName(food.SKU); // 商品名を設定
    } else {
      // 該当する商品がない場合、空欄のままにする（エラー表示が必要ならここで追加可能）
      setRegisterMakerName('');
      setRegisterProductName('');
    }

    setCameraOpen(false); // カメラを閉じる
    hideFoodSection(); // フードセクションを閉じる
  };

  // Web用バーコードスキャン結果処理
  const handleBarcodeDetectedWeb = (code) => {
    if (code === "_cancel_") {
      setCameraOpen(false);
      return;
    }

    let food = food_list_table.find((item) => item.JAN === code);

    if (food) {
      setFoodList(food_list_table.filter((item) => item.maker === food.maker));

      setRegisterMakerName(food.maker); // メーカー名を設定
      setRegisterProductName(food.SKU); // 商品名を設定

      setCameraOpen(false); // カメラを閉じて、FoodSectionのUIに戻る
    } else {
      // 該当する商品がない場合、空欄のままにする
      setRegisterMakerName('');
      setRegisterProductName('');
      setCameraOpen(false);
      alert('該当する商品がありません');
    }
  };

  const handleAddFood = async () => {
    if (registerMakerName && registerProductName && registerFoodType) {
      const newFood = {
        maker: registerMakerName,
        product: registerProductName,
      };

      // フードタイプごとのリストを更新
      let updatedMainSeed = [...main_seed];
      let updatedMainPellet = [...main_pellet];
      let updatedSub = [...sub];
      let updatedSnack = [...snack];
      let updatedSupplement = [...supplement];

      if (registerFoodType === "主食シード") {
        updatedMainSeed.push(newFood);
      } else if (registerFoodType === "主食ペレット") {
        updatedMainPellet.push(newFood);
      } else if (registerFoodType === "副食") {
        updatedSub.push(newFood);
      } else if (registerFoodType === "おやつ") {
        updatedSnack.push(newFood);
      } else if (registerFoodType === "サプリメント") {
        updatedSupplement.push(newFood);
      }

      try {
        console.log("Dispatching editFood with:", {
          user: account,
          food: {
            main_seed: updatedMainSeed,
            main_pellet: updatedMainPellet,
            sub: updatedSub,
            snack: updatedSnack,
            supplement: updatedSupplement,
          },
        });
        // Firestore に保存
        await dispatch(editFood({
          user: account,
          food: {
            main_seed: updatedMainSeed,
            main_pellet: updatedMainPellet,
            sub: updatedSub,
            snack: updatedSnack,
            supplement: updatedSupplement,
          }
        }));

        // 入力フィールドをリセット
        setRegisterMakerName('');
        setRegisterProductName('');
        setRegisterFoodType('');
      } catch (error) {
        console.error('フードの登録に失敗しました:', error);
      }
    } else {
      alert('メーカー名、ごはん名、分類を選択してください');
    }
  };

  const handleFoodDelete = async (foodType, index) => {
    let updatedMainSeed = [...main_seed];
    let updatedMainPellet = [...main_pellet];
    let updatedSub = [...sub];
    let updatedSnack = [...snack];
    let updatedSupplement = [...supplement];

    // 削除する食事タイプに応じてリストを更新
    switch (foodType) {
      case '主食シード': // 'main_seed' の代わりに '主食シード' を使用
        updatedMainSeed.splice(index, 1);
        break;
      case '主食ペレット': // 'main_pellet' の代わりに '主食ペレット' を使用
        updatedMainPellet.splice(index, 1);
        break;
      case '副食': // 'sub'
        updatedSub.splice(index, 1);
        break;
      case 'おやつ': // 'snack'
        updatedSnack.splice(index, 1);
        break;
      case 'サプリメント': // 'supplement'
        updatedSupplement.splice(index, 1);
        break;
      default:
        return; // 何もしない
    }

    // 更新された食事データのオブジェクトを作成
    const updatedFood = {
      main_seed: updatedMainSeed,
      main_pellet: updatedMainPellet,
      sub: updatedSub,
      snack: updatedSnack,
      supplement: updatedSupplement,
    };

    try {
      // Redux の `editFood` アクションをディスパッチして更新
      const result = await dispatch(editFood({ user: account, food: updatedFood }));

      if (result.error) {
        // エラーハンドリング
        Toast.show({
          position: 'top',
          type: 'warning',
          text: '削除に失敗しました',
          buttonText: 'OK',
          duration: 3000,
        });
      } else {
        // 成功時の処理
        Toast.show({
          position: 'top',
          type: 'success',
          text: '削除しました',
          buttonText: 'OK',
          duration: 3000,
        });
      }
    } catch (error) {
      alert('削除しました');
    }
  };

  // カメラを開く処理、必要な場合にカメラの許可を確認（モバイル用）
  const openCamera = async () => {
    const { status } = await Camera.requestCameraPermissionsAsync();
    console.log('カメラの許可ステータス:', status);
    if (status === 'granted') {
      setHasPermission(true); // 権限を許可されたら true に設定
      setCameraOpen(true);
      setScanned(false);  // スキャンのリセット
    } else {
      setHasPermission(false); // 権限が拒否されたら false に設定
      alert('カメラのアクセスが許可されていません');
    }
  };

  const handleMakerSelection = (selectedMaker) => {
    setRegisterMakerName(selectedMaker);
    setRegisterProductName(''); // 商品名をリセット

    // メーカーに対応する商品のみを表示
    setFoodList(food_list_table.filter((item) => item.maker === selectedMaker));
  };


  return (
    <ScrollView>
      <View style={styles.controlFoodEditTop}>
        <TouchableOpacity style={styles.controlBtnBack} onPress={hideFoodSection}>
          <MdChevronLeft size={20} color="#cd2829" />
          <Text style={styles.controlBtnBackText}>戻る</Text>
        </TouchableOpacity>
      </View>
      <View style={styles.FoodSection}>
        {/* カメラを表示する部分 */}
        {cameraOpen && hasPermission && !isWeb ? (
          <Camera
            onBarCodeScanned={scanned ? undefined : handleBarCodeScanned}
            style={{ flex: 1, height: 400 }} // カメラビューの高さを設定
            barCodeScannerSettings={{
              barCodeTypes: [Camera.Constants.BarCodeType.ean13], // スキャンするバーコードの種類
            }}
          >
            <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
              <Text style={{ color: '#fff' }}>バーコードをスキャンしてください</Text>
            </View>
          </Camera>
          ) : isWeb && cameraOpen ? (
            // Web用のバーコードスキャナ
            <BarcodeScannerWeb onScanComplete={handleBarcodeDetectedWeb} />
          ) : (
          <>
        <View style={styles.FoodListBlock}>
          <View style={styles.editFoodListItemCategory}>
            <Text style={styles.editFoodItemHeader1}>バーコードで読み取る</Text>
          </View>
          <View style={styles.editFoodButtonBlock}>
            <TouchableOpacity style={styles.editFoodButton} onPress={openCamera}>
              <Image
                source={require('../assets/images/photo_icon.png')}
                style={styles.iconCamera}
              />
              <Text style={styles.editFoodButtonText}>カメラ起動</Text>
            </TouchableOpacity>
          </View>
        </View>

        <View style={styles.FoodListBlock}>
          <View style={styles.editFoodListItemCategory}>
            <Text style={styles.editFoodItemHeader2}>手動で登録する</Text>
          </View>

          {/* メーカー名 */}
          <View style={styles.editFoodlistItem}>
            <Text style={styles.editFoodlistItemHeader}>メーカー名</Text>
            <Picker
              selectedValue={registerMakerName}
              onValueChange={(itemValue) => handleMakerSelection(itemValue)}
              style={[
                styles.editfoodPicker,
                { color: registerMakerName ? '#555' : '#bfbfbf' }, // 入力状況に応じて文字色変更
              ]}
            >
              <Picker.Item label="メーカー名" value="" />
              {makerList.map((item, index) => (
                <Picker.Item key={index} label={item} value={item} />
              ))}
            </Picker>
          </View>

          {/* 商品名 */}
          <View style={styles.editFoodlistItem}>
            <Text style={styles.editFoodlistItemHeader}>ごはん</Text>
            <Picker
              selectedValue={registerProductName}
              onValueChange={(itemValue) => setRegisterProductName(itemValue)}
              style={[
                styles.editfoodPicker,
                { color: registerProductName ? '#555' : '#bfbfbf' }, // 入力状況に応じて文字色変更
              ]}
            >
              <Picker.Item label="ごはん" value="" />
              {foodList.map((item, index) => (
                <Picker.Item key={index} label={item.SKU} value={item.SKU} />
              ))}
            </Picker>
          </View>

          {/* 分類 */}
          <View style={styles.editFoodlistItem}>
            <Text style={styles.editFoodlistItemHeader}>分類</Text>
            <Picker
              selectedValue={registerFoodType}
              onValueChange={(itemValue) => setRegisterFoodType(itemValue)}
              style={[
                styles.editfoodPicker,
                { color: registerFoodType ? '#555' : '#bfbfbf' }, // 入力状況に応じて文字色変更
              ]}
            >
              <Picker.Item label="分類" value="" />
              <Picker.Item label="主食シード" value="主食シード" />
              <Picker.Item label="主食ペレット" value="主食ペレット" />
              <Picker.Item label="副食" value="副食" />
              <Picker.Item label="おやつ" value="おやつ" />
              <Picker.Item label="サプリメント" value="サプリメント" />
            </Picker>
          </View>

          <View style={styles.editFoodButtonBlock}>
            <TouchableOpacity style={styles.editFoodButton} onPress={handleAddFood}>
              <Text style={styles.editFoodButtonText}>登録</Text>
            </TouchableOpacity>
          </View>
        </View>

        <View style={styles.editFoodListItemCategory}>
          <Text style={styles.editFoodItemHeader3}>登録済みフード</Text>
        </View>

        <View style={styles.editListItem}>
          <Text style={styles.editFoodListItemTitle}>主食シード</Text>
        </View>
        {main_seed
          .map((food, index) => (
            <View key={index} style={styles.listItemFood}>
              <Text style={styles.listItemFoodProduct}>{food.product}</Text>
              <Text style={styles.listItemFoodMaker}>({food.maker})</Text>
              <TouchableOpacity
                style={styles.controlBtnFoodDelete}
                onPress={() => handleFoodDelete('主食シード', index)}
              >
                <Text style={styles.controlBtnFoodDeleteText}>削除</Text>
              </TouchableOpacity>
            </View>
          ))}
        <View style={styles.editListItem}>
          <Text style={styles.editFoodListItemTitle}>主食ペレット</Text>
        </View>
        {main_pellet
          .map((food, index) => (
            <View key={index} style={styles.listItemFood}>
              <Text style={styles.listItemFoodProduct}>{food.product}</Text>
              <Text style={styles.listItemFoodMaker}>({food.maker})</Text>
              <TouchableOpacity
                style={styles.controlBtnFoodDelete}
                onPress={() => handleFoodDelete('主食ペレット', index)}
              >
                <Text style={styles.controlBtnFoodDeleteText}>削除</Text>
              </TouchableOpacity>
            </View>
          ))}
        <View style={styles.editListItem}>
          <Text style={styles.editFoodListItemTitle}>副食</Text>
        </View>
        {sub
          .map((food, index) => (
            <View key={index} style={styles.listItemFood}>
              <Text style={styles.listItemFoodProduct}>{food.product}</Text>
              <Text style={styles.listItemFoodMaker}>({food.maker})</Text>
              <TouchableOpacity
                style={styles.controlBtnFoodDelete}
                onPress={() => handleFoodDelete('副食', index)}
              >
                <Text style={styles.controlBtnFoodDeleteText}>削除</Text>
              </TouchableOpacity>
            </View>
          ))}
        <View style={styles.editListItem}>
          <Text style={styles.editFoodListItemTitle}>おやつ</Text>
        </View>
        {snack
          .map((food, index) => (
            <View key={index} style={styles.listItemFood}>
              <Text style={styles.listItemFoodProduct}>{food.product}</Text>
              <Text style={styles.listItemFoodMaker}>({food.maker})</Text>
              <TouchableOpacity
                style={styles.controlBtnFoodDelete}
                onPress={() => handleFoodDelete('おやつ', index)}
              >
                <Text style={styles.controlBtnFoodDeleteText}>削除</Text>
              </TouchableOpacity>
            </View>
          ))}
        <View style={styles.editListItem}>
          <Text style={styles.editFoodListItemTitle}>サプリメント</Text>
        </View>
        {supplement
          .map((food, index) => (
            <View key={index} style={styles.listItemFood}>
              <Text style={styles.listItemFoodProduct}>{food.product}</Text>
              <Text style={styles.listItemFoodMaker}>({food.maker})</Text>
              <TouchableOpacity
                style={styles.controlBtnFoodDelete}
                onPress={() => handleFoodDelete('サプリメント', index)}
              >
                <Text style={styles.controlBtnFoodDeleteText}>削除</Text>
              </TouchableOpacity>
            </View>
          ))}
      </>
      )}
      </View>
    </ScrollView>
  );
};

const styles = {
  photo_space_middle: {
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
  },
  photo_space_circle: {
    backgroundColor: '#fff',
    borderRadius: '50%',
    width: isMobileScreen ? 130 : 270,
    height: isMobileScreen ? 130 : 270,
    marginVertical: isMobileScreen ? 15 : 30,
  },
  photo_space_image: {
    borderRadius: '50%',
    width: isMobileScreen ? 130 : 270,
    height: isMobileScreen ? 130 : 270,
  },
  container: {
    flex: 1,
  },
  noBirdContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#FFF'
  },
  noBirdText: {
    fontSize: 16,
    color: '#cd2829',
    marginVertical: 40,
  },
  birdContainer: {
    flex: 1,
    alignItems: 'center',
    height: 250,
  },
  title: {
    fontSize: 14,
    color: '#555555',
    marginBottom: 13,
    textAlign: 'center',
  },
  dateText: {
    fontSize: 16,
    color: '#555555',
    textAlign: 'center',
    marginBottom: 10,
  },
  nameInput: {
    fontSize: 18,
    fontWeight: 'bold',
    color: '#555555',
    textAlign: 'center',
  },
  listItemCategoryBlock: {
    marginBottom: 20,
  },
  listItemCategory: {
    width: '100%',
    marginBottom: 10,
  },
  listItemCategory2: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 10,
  },
  listItemTtl: {
    fontSize: 18,
    width: '100%',
  },
  listItemblock: {
    marginBottom: 20,
  },
  listItem: {
    flexDirection: 'row',
    paddingTop: 15,
    paddingLeft: 10,
    width: '100%',
    borderBottomColor: '#ddd',
    borderBottomWidth: 1,
  },
  listItemweight: {
    flexDirection: 'row',
    textAlign: 'center',
    marginHorizontal: 'auto',
    alignItems: 'baseline',
  },
  radioItemHeader: {
    fontSize: 15,
    color: '#555555',
    flex: 1,
    minWidth: 35,
  },
  listItemHeader: {
    fontSize: 18,
    color: '#555555',
    flex: 1,
    marginBottom: 10,
  },
  listItemData: {
    flex: 2,
    padding: 5,
    fontSize: 28,
    fontWeight: 'bold',
    maxWidth: 80,
    textAlign: 'right',
  },
  listItemUnit: {
    fontSize: 16,
    color: '#555555',
    paddingRight: 10,
  },
  control: {
    marginTop: 20,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  controlBlock:{
    width: '49%',
  },
  controlBtnSaveText: {
    color: '#cd2829',
    fontSize: 16,
  },
  listItemCollapsible: {
    borderBottomWidth: 1,
    borderBottomColor: '#ddd',
    marginBottom: 10,
  },
  listItemCollapsibleTitle: {
    padding: 15,
    backgroundColor: '#f8f8f8',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  listItemRadio: {
    borderColor: '#555',
    borderWidth: 1,
    borderRadius: 5,
    padding: 5,
    textAlign: 'center',
    marginRight: 5,
  },
  radioContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 10,
    width: '60%',
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginVertical: 10,
  },
  sectionHeader: {
    flexDirection: 'row',
    backgroundColor: '#ffe5e5',
    paddingTop: 15,
    paddingLeft: 10,
    width: '100%',
    color: '#434343',
    fontSize: 17,
  },
  sectionHeaderText: {
    fontSize: 17,
    color: '#555',
  },
  input: {
    padding: 5,
    fontSize: 28,
    textAlign: 'right',
    maxWidth: 80,
    fontWeight: 'bold',
    marginBottom: 0,
  },
  unit: {
    fontSize: 16,
    color: '#555',
  },
  addFoodButton: {
    backgroundColor: '#f8f8f8',
    padding: 10,
    borderRadius: 10,
    maxWidth: 280,
    marginLeft: 'auto',
  },
  addFoodButtonText: {
    color: '#cd2829',
    textAlign: 'center',
    fontSize: 18,
  },
  memoInput: {
    borderColor: '#ddd',
    borderWidth: 1,
    padding: 10,
    fontSize: 16,
    color: '#555555',
    height: 80,
    marginBottom: 20,
  },

  controlFoodEditTop: {
    marginTop: 12,
    flexDirection: 'row',
    marginBottom: 10,
    width: 120,
    paddingLeft: 5,
  },
  controlBtnBack: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  controlBtnBackText: {
    color: '#cd2829',
    fontSize: 18,
    marginLeft: 10,
  },
  editFoodListItemCategory: {
    paddingVertical: 14,
  },
  editFoodItemHeader1: {
    fontSize: 20,
    color: '#555555',
    textAlign: 'center'
  },
  editFoodItemHeader2: {
    fontSize: 20,
    color: '#555555',
  },
  editFoodItemHeader3: {
    fontSize: 20,
    color: '#555555',
    paddingLeft: 15,
  },
  listItem: {
    flexDirection: 'row',
    alignItems: 'center',
    borderBottomColor: '#ddd',
    borderBottomWidth: 1,
  },
  listItemHeader: {
    fontSize: 16,
    color: '#555555',
    flex: 1,
  },
  picker: {
    width: 250,
  },
  listItemFood: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 14,
    paddingLeft: 15,
    borderBottomColor: '#ddd',
    borderBottomWidth: 1,
  },
  listItemFoodProduct: {
    width: '50%',
    marginRight: 15,
    fontSize: 14,
    color: '#555555',
  },
  listItemFoodMaker: {
    width: '30%',
    fontSize: 14,
    color: '#555555',
  },
  controlBtnFoodDelete: {
    position: 'absolute',
    right: 10,
    bottom: 12,
    borderColor: '#cd2829',
    borderWidth: 1,
    borderRadius: 5,
    padding: 5,
  },
  controlBtnFoodDeleteText: {
    color: '#cd2829',
    fontSize: 12,
  },
  editListItem: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 15,
    paddingLeft: 15,
    borderBottomColor: '#ddd',
    borderBottomWidth: 1,
    backgroundColor: '#ffe5e5',
  },
  editFoodListItemTitle: {
    fontSize: 14,
    color: '#555555',
  },
  feedItemText: {
    fontSize: 16,
    color: '#555555',
    marginVertical: 5,
  },
  noFeedText: {
    fontSize: 16,
    color: '#cd2829',
    marginVertical: 10,
  },

  sectionHeader: {
    flexDirection: 'row', // アイコンとテキストを横並び
    justifyContent: 'space-between', // 左右に配置
    alignItems: 'center', // 垂直方向の位置を中央揃え
    marginBottom: 5,
  },
  headerLeft: {
    flexDirection: 'row', // アイコンとテキストを横並び
    alignItems: 'center', // 垂直方向の位置を中央揃え
  },
  sectionHeaderText: {
    fontSize: 20,
    color: '#555',
    marginLeft: 10, // アイコンとテキストの間隔を調整
  },
  row: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    marginVertical: 10,
  },
  radioContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    flex: 2,
    alignItems: 'center'
  },
  radioContainer2: {
    flexDirection: 'row',
    justifyContent: 'center',
    flex: 2,
  },
  radio: {
    borderWidth: 1,
    borderColor: '#eaeaea',
    borderRadius: 20,
    paddingVertical: 5,
    paddingHorizontal: 10,
    marginRight: 5,
    backgroundColor: '#eaeaea',
    color: '#333',
    textAlign: 'center',
  },
  radioSelected: {
    borderWidth: 1,
    borderColor: '#cd2829',
    borderRadius: 20,
    paddingVertical: 5,
    paddingHorizontal: 10,
    marginRight: 5,
    backgroundColor: '#ffe5e5',
    color: '#cd2829',
    textAlign: 'center',
  },
  controlBtnSave: {
    backgroundColor: '#cd2829',
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderRadius: 10,
    minWidth: 130,
    maxWidth: 130,
    marginLeft: 'auto'
  },
  controlBtnSaveText: {
    color: '#fff',
    fontSize: 16,
    textAlign: 'center',
  },
  controlBtnCancel: {
    backgroundColor: '#eee',
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderRadius: 10,
    minWidth: 100,
    maxWidth: 130,
    marginRight: 'auto'
  },
  controlBtnCancelText: {
    color: '#333',
    fontSize: 16,
    textAlign: 'center',
  },

  envrow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'normal',
    marginVertical: 10,
  },

  Calendar: {
    marginBottom: 20,
    paddingBottom: 10,
    backgroundColor: '#fff'
  },
  CalendarBlock: {
    maxWidth: 800,
    marginHorizontal: 'auto',
    width: '100%',
  },
  mainContainer: {
    width: '100%',
    maxWidth: 800,
    paddingHorizontal: 20,
    marginHorizontal: 'auto',
  },
  sectionContainer: {
    marginBottom: 10,
  },
  sectionContent: {
    marginBottom: 30,
    backgroundColor: '#fff',
    borderRadius: 10,
    paddingVertical: 20,
    paddingHorizontal: 20,
  },
  sectionContent2: {
    marginBottom: 30,
    paddingVertical: 10,
  },
  envItem: {
    flexDirection: 'row',
    textAlign: 'center',
    marginHorizontal: 'auto',
    alignItems: 'baseline',
  },
  column: {
    borderBottomWidth: 1,
    borderColor: '#ddd',
    width: '49%',
  },
  column2: {
    width: '49%',
  },
  formContainer: {
    marginBottom: 15,
  },
  formContainerrow: {
    flexDirection: 'row',
    textAlign: 'center',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    marginBottom: isMobileScreen ? 5 : 20,
    flexWrap: 'wrap'
  },
  formblock: {
    borderBottomWidth: 1,
    borderColor: '#ddd',
    marginBottom: isMobileScreen ? 15 : 0,
    height: isMobileScreen ? 'auto' : '100%',
  },
  formText: {
    color: '#8d8d8d',
    fontSize: 18,
    marginBottom: isMobileScreen ? 5 : 15,
  },
  inputTime: {
    fontSize: isMobileScreen ? 20 : 22,
    padding: 5,
    borderWidth: 0,
    marginBottom: 5,
  },
  foodPicker: {
    fontSize: isMobileScreen ? 20 : 22,
    padding: 5,
    borderWidth: 0,
    marginBottom: 5,
  },
  inputAmount: {
    fontSize: 22,
    color: '#ccc',
    padding: 5,
    marginBottom: 5,
  },
  inputActive: {
    color: 'black', // アクティブ時のスタイル
  },
  inputPlaceholder: {
    color: '#ccc', // プレースホルダーのスタイル
  },
  iconImage: {
    width: 30,
    height: 30,
    resizeMode: 'contain',
  },
  iconImage2: {
    width: 30,
    height: 30,
    resizeMode: 'contain',
  },
  SwiperBlock:{
    maxWidth: 880,
    width: '100%',
    height: isMobileScreen ? 260 : 480,
    marginHorizontal: 'auto',
    overflow: 'hidden',
    paddingHorizontal: 0,
    backgroundColor: "#FFF"
  },
  SwiperCon:{
    width: '100%',
    backgroundColor: "#fff",
  },
  swiperSlide:{
    maxWidth: 770,
    height: isMobileScreen ? 210 : 420,
    width: isMobileScreen ? '90%' : '95%',
    marginTop: 35,
    backgroundColor: '#fff',
    marginHorizontal: 'auto',
    borderRadius: 15,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.15,
    shadowRadius: 10,
    elevation: 10, // Android用の影
  },
  SwiperBtnBlock1: {
    width: isMobileScreen ? 35 : 60,
    height: isMobileScreen ? 35 : 60,
    position: 'absolute',
    bottom: isMobileScreen ? 90 : 200,
    right:  isMobileScreen ? -10 : 5,
    borderRadius: '50%',
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.15,
    shadowRadius: 10,
  },
  SwiperBtnBlock2: {
    width: isMobileScreen ? 35 : 60,
    height: isMobileScreen ? 35 : 60,
    position: 'absolute',
    bottom: isMobileScreen ? 90 : 200,
    left:  isMobileScreen ? -10 : 5,
    borderRadius: '50%',
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.15,
    shadowRadius: 10,
  },
  SwiperBtn: {
    width: isMobileScreen ? 35 : 60,
    height: isMobileScreen ? 35 : 60,
    borderRadius: '50%',
  },
  backgroundImage: {
    width: '100%',
    height: '100%',
    resizeMode: 'cover',
  },
  name: {
    fontSize: 22,
    color: '#555555',
    textAlign: 'center',
  },
  PhotoBlock1: {
    width: '49%',
    backgroundColor: '#f5d4d4',
    borderRadius: 10,
    flexDirection: 'row',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    aspectRatio: 1,
  },
  PhotoBlock2: {
    width: '49%',
    borderRadius: 10,
    flexDirection: 'row',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#ffffff',
    shadowColor: '#ffffff',
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.5,
    shadowRadius: 8,
    elevation: 5,
    aspectRatio: 1,
  },
  uploadButton: {
  },
  memoInput: {
    width: '49%',
    fontSize: 16,
    color: '#555555',
    backgroundColor: '#fff',
    padding: 10,
    borderRadius: 10,
  },
  iconPhoto: {
    width: 40,
    height: 35,
    resizeMode: 'contain',
    marginHorizontal: 'auto',
    marginBottom: 15,
  },
  uploadButtonText: {
    fontSize: isMobileScreen ? 16 : 20,
    color: '#555555',
    textAlign: 'center',
  },
  FoodSection: {
    maxWidth: 800,
    marginHorizontal: 'auto',
    width: '100%',
  },
  FoodListBlock: {
    backgroundColor: '#ffffff',
    borderRadius: 10,
    padding: 20,
    marginBottom: 30,
    marginHorizontal: 15,
  },
  editFoodButtonBlock: {
    maxWidth: 280,
    marginHorizontal: 'auto',
    width: '100%',
  },
  editFoodButton: {
    flexDirection: 'row',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    backgroundColor: '#f5d4d4',
    borderRadius: 30,
    paddingVertical: 10,
    paddingHorizontal: 40,
  },
  iconCamera: {
    width: 40,
    height: 35,
    resizeMode: 'contain',
    marginRight: 10,
  },
  editFoodButtonText: {
    fontSize: 18,
    color: '#555555'
  },
  editFoodlistItem: {
    marginBottom: 15,
    borderBottomWidth: 1,
    borderBottomColor: '#f8f8f8',
  },
  editFoodlistItemHeader: {
    fontSize: 20,
    color: '#555555',
    marginBottom: 10,
  },
  editfoodPicker: {
    fontSize: 18,
    color: '#ccc',
    padding: 5,
    marginTop: 'auto',
    borderWidth: 0,
  },
  foodCardContainer: {
    marginBottom: 20,
  },
  foodCard: {
    backgroundColor: '#fff',
    padding: 10,
    marginTop: 0,
  },
  foodTimeContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 5,
    borderBottomWidth: 1,
    borderBottomColor: '#eaeaea',
    paddingBottom: 5,
  },
  foodDetailsBlock: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  foodTypeBlock: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  foodTime: {
    backgroundColor: '#f0bebe',
    borderRadius: 15,
    paddingHorizontal: 8,
    color: '#555555',
    fontSize: 16,
    marginRight: 10,
  },
  foodType: {
    fontSize: 20,
    color: '#555555',
    marginRight: 5,
  },
  foodAmount: {
    fontSize: 20,
    color: '#555555',
  },
  foodDetails: {
    fontSize: 18,
    color: '#555555',
  },
  removeFeedRecord: {
    paddingVertical: 5,
    paddingHorizontal: 10,
    backgroundColor: '#cd2829',
    borderRadius: 5,
  },
  removeFeedRecordText: {
    color: '#ffffff',
  },
  premiumLinkBtn: {
    width: '100%',
    maxWidth: 600,
    marginHorizontal: 'auto'
  },
  premiumImage1: {
    width: '100%',
    height: 40,
    resizeMode: 'contain',
  },
  premiumImage2: {
    width: '100%',
    height: 240,
    resizeMode: 'contain',
  },
  uploadedPhoto: {
    width: '100%',
    height: '100%',
    resizeMode: 'contain',
    marginHorizontal: 'auto',
  },
  uploadButton2Block: {
    width: '100%',
  },
  uploadButton2: {
    backgroundColor: '#f5d4d4',
    padding: 10,
    width: '100%',
    borderRadius: 30,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconPhoto2: {
    width: 30,
    height: 30,
    resizeMode: 'contain',
    marginRight: 10,
  },
  inputActive: {
    color: '#555555',
  },
  inputPlaceholder: {
    color: '#bfbfbf',
  },
  droppingsphotoBlock: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 10,
  },
  droppingsphotoImg: {
    width: '32%',
    marginRight: '2%',
    aspectRatio: 1,
  },
  specialDroppingsPhoto: {
    marginRight: 0,
  },
  inputSpawning: {
    padding: 5,
    fontSize: 18,
    textAlign: 'right',
    maxWidth: 40,
    fontWeight: 'bold',
  },
  rowSpawning: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  textSpawning: {
    color: '#555555',
    fontSize: 16,
  },
  loadingView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%'
  },
  loadingText: {
    textAlign: 'center',
    color: '#555555',
    marginBottom: 10,
  },
  loadingOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgb(242, 242, 242)',
  },
  loadingText: {
    textAlign: 'center',
    color: '#555555',
    marginTop: 10,
  },
};

export default UpdateScreen;
