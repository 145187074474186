import { getFirestore, collection, query, where, onSnapshot, addDoc, updateDoc, doc, deleteDoc, getDocs } from 'firebase/firestore';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { app } from '../../plugins/firebase';

const db = getFirestore(app);
const birdsRef = collection(db, 'birds');

// 初期状態
const initialState = {
  birds: [],
  index: 0,
  loading: false,
  error: null
};

export function initBirds() {
  return (dispatch) => {
      dispatch(resetBirds()); // 鳥データをリセット
  };
}

// 非同期アクション：リアルタイムで鳥データを同期
export const syncBirds = createAsyncThunk(
  'birds/syncBirds',
  async ({ user }, { dispatch, rejectWithValue }) => {
    try {
      const q = query(birdsRef, where('uid', '==', user.uid));

      return new Promise((resolve, reject) => {
        const unsubscribe = onSnapshot(q, (snapshot) => {
          try {
            let birds = [];
            snapshot.forEach((doc) => {
              let bird = doc.data();
              bird.bid = doc.id;

              // FirestoreのTimestampをシリアライズ可能な形式に変換
              bird.createAt = bird.create_date?.toDate
                ? bird.create_date.toDate().toISOString()
                : bird.create_date;
              bird.updateAt = bird.update_date?.toDate
                ? bird.update_date.toDate().toISOString()
                : bird.update_date;

              birds.push({
                bid: bird.bid,
                name: bird.name || "",
                gender: bird.gender || "",
                age: bird.age || "",
                birthday: bird.birthday || "",
                category: bird.category || "",
                type: bird.type || "",
                welcome: bird.welcome || "",
                strong: bird.strong || "",
                weak: bird.weak || "",
                favorite: bird.favorite || "",
                dislikedFood: bird.dislikedFood || "",
                favoriteToy: bird.favoriteToy || "",
                birdPersonality: bird.birdPersonality || "",
                medicalHistory: bird.medicalHistory || "",
                comment: bird.comment || "",
                photo: bird.photo || "https://firebasestorage.googleapis.com/v0/b/trinity-kurose.appspot.com/o/system%2Fno_image.png?alt=media&token=71bc161e-dfbd-4f66-a810-f721dc813e51",
                order: bird.order !== undefined ? bird.order : 9999, // デフォルトで最後に並べる
                createAt: bird.createAt,
                updateAt: bird.updateAt
              });
            });

            // 分岐: orderがあればその順に、なければ作成日時順にソート
            const sortedBirds = birds.sort((a, b) => a.order - b.order);

            dispatch(setBirds(sortedBirds)); // Reduxストアに反映
            resolve();
          } catch (error) {
            console.error("データ処理中にエラー:", error);
            reject(error);
          }
        }, (error) => {
          console.error("Firestore の監視中にエラー:", error);
          reject(error);
        });

        return () => unsubscribe();
      });
    } catch (error) {
      console.error("syncBirds のエラー:", error);
      return rejectWithValue(error.message);
    }
  }
);



// 非同期アクション：鳥データの取得
export const getBirds = createAsyncThunk(
  'birds/getBirds',
  async ({ user }, { rejectWithValue }) => {
    try {
      const q = query(birdsRef, where('uid', '==', user.uid));
      const snapshot = await getDocs(q);
      let birds = [];

      snapshot.forEach((doc) => {
        let bird = doc.data();
        bird.bid = doc.id;

        // FirestoreのTimestampをシリアライズ可能な形式に変換
        bird.createAt = bird.create_date?.toDate
          ? bird.create_date.toDate().toISOString()
          : bird.create_date;
        bird.updateAt = bird.update_date?.toDate
          ? bird.update_date.toDate().toISOString()
          : bird.update_date;

        birds.push({
          bid: bird.bid,
          name: bird.name || "",
          gender: bird.gender || "",
          age: bird.age || "",
          birthday: bird.birthday || "",
          category: bird.category || "",
          type: bird.type || "",
          welcome: bird.welcome || "",
          strong: bird.strong || "",
          weak: bird.weak || "",
          favorite: bird.favorite || "",
          dislikedFood: bird.dislikedFood || "",
          favoriteToy: bird.favoriteToy || "",
          birdPersonality: bird.birdPersonality || "",
          medicalHistory: bird.medicalHistory || "",
          comment: bird.comment || "",
          photo: bird.photo || "https://firebasestorage.googleapis.com/v0/b/trinity-kurose.appspot.com/o/system%2Fno_image.png?alt=media&token=71bc161e-dfbd-4f66-a810-f721dc813e51",
          order: bird.order !== undefined ? bird.order : 9999, // デフォルトで最後に並べる
          createAt: bird.createAt,
          updateAt: bird.updateAt
        });
      });

      // 分岐: orderがあればその順に、なければ作成日時順にソート
      birds.sort((a, b) => a.order - b.order);

      return birds;
    } catch (error) {
      console.error("getBirds のエラー:", error);
      return rejectWithValue(error.message);
    }
  }
);


// 非同期アクション：鳥データの追加
export const addBird = createAsyncThunk('birds/addBird', async ({ user, bird }, { rejectWithValue }) => {
  try {
    const docRef = await addDoc(birdsRef, {
      uid: user.uid,
      name: bird.name,
      gender: bird.gender,
      age: bird.age,
      birthday: bird.birthday,
      category: bird.category,
      type: bird.type,
      welcome: bird.welcome,
      strong: bird.strong,
      weak: bird.weak,
      favorite: bird.favorite,
      dislikedFood: bird.dislikedFood || '', // 嫌いなご飯
      favoriteToy: bird.favoriteToy || '',   // 好きなおもちゃ
      birdPersonality: bird.birdPersonality || '', // 愛鳥の性格を追加
      medicalHistory: bird.medicalHistory || '',  // 病気や治療の記録を追加
      comment: bird.comment || '',           // コメント
      photo: bird.photo || 'https://firebasestorage.googleapis.com/v0/b/trinity-kurose.appspot.com/o/system%2Fno_image.png?alt=media&token=71bc161e-dfbd-4f66-a810-f721dc813e51',
      create_date: bird.create_date ? new Date(bird.create_date).toISOString() : new Date().toISOString(),
      update_date: bird.update_date ? new Date(bird.update_date).toISOString() : new Date().toISOString()
    });
    return { id: docRef.id, bird };  // 鳥のデータも一緒に返す
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

// 非同期アクション：鳥データの編集
export const editBird = createAsyncThunk('birds/editBird', async ({ bird }, { rejectWithValue }) => {
  try {
    await updateDoc(doc(birdsRef, bird.bid), {
      name: bird.name,
      gender: bird.gender,
      age: bird.age,
      birthday: bird.birthday,
      category: bird.category,
      type: bird.type,
      welcome: bird.welcome,
      strong: bird.strong,
      weak: bird.weak,
      favorite: bird.favorite,
      dislikedFood: bird.dislikedFood || '', // 嫌いなご飯
      favoriteToy: bird.favoriteToy || '',   // 好きなおもちゃ
      birdPersonality: bird.birdPersonality || '', // 愛鳥の性格を追加
      medicalHistory: bird.medicalHistory || '',  // 病気や治療の記録を追加
      comment: bird.comment || '',           // コメント
      photo: bird.photo || 'https://firebasestorage.googleapis.com/v0/b/trinity-kurose.appspot.com/o/system%2Fno_image.png?alt=media&token=71bc161e-dfbd-4f66-a810-f721dc813e51',
      update_date: new Date().toISOString(), // 更新日時を変更
    });
    return bird;  // 編集した鳥のデータを返す
  } catch (error) {
    console.error('editBirdでエラーが発生しました:', error);
    return rejectWithValue(error.message);
  }
});

// 非同期アクション：鳥データの削除
export const removeBird = createAsyncThunk('birds/removeBird', async ({ bird }, { rejectWithValue }) => {
  try {
    await deleteDoc(doc(birdsRef, bird.bid));
    return bird.bid;  // 削除した鳥のIDを返す
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

// Redux ToolkitのSlice
const birdsSlice = createSlice({
  name: 'birds',
  initialState,
  reducers: {
    setIndex(state, action) {
      state.index = action.payload;
    },
    resetBirds(state) {
      state.birds = [];
    },
    setBirds(state, action) {
      state.birds = action.payload;  // syncBirds からデータを受け取るための reducer
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(syncBirds.pending, (state) => {
        state.loading = true;
      })
      .addCase(syncBirds.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(syncBirds.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getBirds.fulfilled, (state, action) => {
        state.birds = action.payload;
      })
      .addCase(addBird.fulfilled, (state, action) => {
        state.birds.push({ ...action.payload.bird, bid: action.payload.id });
      })
      .addCase(editBird.fulfilled, (state, action) => {
        const index = state.birds.findIndex(bird => bird.bid === action.payload.bid);
        if (index !== -1) {
          state.birds[index] = {
            ...action.payload,
            birdPersonality: action.payload.birdPersonality || '',
            medicalHistory: action.payload.medicalHistory || '',
          };
        }
      })
      .addCase(removeBird.fulfilled, (state, action) => {
        state.birds = state.birds.filter(bird => bird.bid !== action.payload);
      });
  }
});

// ActionsとReducerをエクスポート
export const { setIndex, resetBirds, setBirds } = birdsSlice.actions;
export default birdsSlice.reducer;
