import React, { useEffect } from 'react';
import { View, Text, Image, TouchableOpacity, Keyboard, StyleSheet, Platform } from 'react-native';
import { setLoginScreenName } from '../plugins/firebase-utils';

const ThankYouScreen = ({ navigation }) => {

  useEffect(() => {
    Keyboard.dismiss();
  }, []);

  const goToHome = () => {
    setLoginScreenName('Contents');
    if (Platform.OS === 'web') {
      // ウェブ環境の場合は/homeにリダイレクト
      window.location.href = '/login';
    } else {
      // モバイル環境の場合はReact Navigationで遷移
      navigation.navigate("Contents");
    }
  };

  return (
    <View style={styles.container}>
      <View style={styles.toplogo}>
        <Image style={styles.toplogo2} source={require('../assets/images/toplogo2.png')} />
      </View>

      <View style={styles.thanks}>
        <Text style={styles.thanks_text}>Thank you!</Text>
        <Text style={styles.texta}>利用登録が完了しました。</Text>
        <Text style={styles.texta}>以下からログインをお願いします。</Text>
        <TouchableOpacity style={styles.login} onPress={goToHome}>
          <Text style={styles.login_btn}>ログイン</Text>
        </TouchableOpacity>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#eeeeee',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    padding: 50,
  },
  toplogo2: {
    marginBottom: 10,
    width: 200,
  },
  login: {
    marginTop: 20,
    padding: 0,
    borderRadius: 30,
    backgroundColor: '#cd2829',
    width: 240,
    height: 50,
    marginBottom: 0,
    alignItems: 'center',
    justifyContent: 'center',
  },
  login_btn: {
    color: '#fff',
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 16,
    width: '100%',
  },
  thanks: {
    padding: 10,
    borderRadius: 5,
    width: 270,
    height: 270,
    marginBottom: 20,
    alignItems: 'center',
    justifyContent: 'center',
  },
  thanks_text: {
    color: '#cd2829',
    fontSize: 30,
    paddingBottom: 20,
  },
  texta: {
    fontSize: 14,
    padding: 4,
  },
});

export default ThankYouScreen;
