import React, { useState } from 'react';
import { View, Text, TextInput, ActivityIndicator, TouchableOpacity, StyleSheet, Platform } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { Toast } from 'native-base';  // モバイル用
import { useDispatch } from 'react-redux';
import { signIn, accountsReducer } from '../store/modules/accounts';
import { checkLogin } from '../plugins/firebase-utils';
import { useNavigation } from '@react-navigation/native';  // モバイルナビゲーション
import { useNavigate } from 'react-router-dom';  // Webナビゲーション
import { VscEye,VscEyeClosed } from 'react-icons/vsc';
import { Alert } from 'react-native';
import { configureStore } from '@reduxjs/toolkit';

const LoginScreen = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  // フックは関数の最上部で呼び出す必要があります
  const webNavigate = Platform.OS === 'web' ? useNavigate() : null;
  const mobileNavigation = Platform.OS !== 'web' ? useNavigation() : null;

  const showToast = (message) => {
    if (Platform.OS === 'web') {
      alert(message);  // Webの場合はAlertを使用
    } else {
      Toast.show({
        position: 'top',
        type: 'warning',
        text: message,
        buttonText: 'OK',
        duration: 3000,
      });
    }
  };

  const handleLogin = async () => {
    if (email === '' || !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      showToast('メールアドレスを入力してください');
      return;
    }
  
    if (password === '' || !/^([a-zA-Z0-9]{6,})$/i.test(password)) {
      showToast('正しいパスワードを入力してください');
      return;
    }
  
    setIsLoading(true);
  
    try {
      const result = await dispatch(signIn({ email, password })).unwrap();
  
      if (result) {
        localStorage.setItem('account', JSON.stringify(result));
        await dispatch(checkLogin(webNavigate, mobileNavigation)).unwrap();
      } else {
        if (Platform.OS === 'web') {
          window.alert('ログインに失敗しました。メールアドレスまたはパスワードを確認してください。');
        } else {
          Alert.alert(
            'ログインエラー',
            'ログインに失敗しました。メールアドレスまたはパスワードを確認してください。',
            [{ text: 'OK' }]
          );
        }
      }
    } catch (error) {
      // エラーコードに応じてメッセージを出し分ける
      const errorMessage = (() => {
        switch (error.code) {
          case 'auth/wrong-password':
            return 'パスワードが間違っています';
          case 'auth/user-not-found':
            return 'ユーザーが見つかりません';
          case 'auth/invalid-credential':
            return 'メールアドレスまたはパスワードが間違っています';
          case 'auth/too-many-requests':
            return '試行回数が多すぎます。しばらくしてからもう一度お試しください。';
          default:
            return error.message || '詳細情報はありません';
        }
      })();
  
      // 特定のエラーは無視してアラートを表示しない
      const ignoredErrors = [
        'Actions must be plain objects',
        'Minified Redux error #7'
      ];
  
      if (ignoredErrors.some((ignoredError) => error.message.includes(ignoredError))) {
        console.warn(`無視されたエラー: ${error.message}`);
      } else {
        if (Platform.OS === 'web') {
          window.alert(`ログインエラー: ${errorMessage}`);
        } else {
          Alert.alert(
            'ログインエラー',
            `ログイン中にエラーが発生しました: ${errorMessage}`,
            [{ text: 'OK' }]
          );
        }
      }
    } finally {
      setIsLoading(false);
    }
  };

  const goToPassreset = () => {
    if (Platform.OS === 'web') {
      webNavigate('/passreset');  // Webナビゲーション
    } else {
      mobileNavigation.navigate('Passreset');  // モバイルナビゲーション
    }
  };
  if (isLoading) {
    return (
      <View style={styles.loadingContainer}>
        <Text style={styles.loadingText}>ログイン中</Text>
        <ActivityIndicator size="large" color="#cd2829" />
      </View>
    );
  }
  return (
    <View style={styles.container}>
      <View style={styles.content}>
        <KeyboardAwareScrollView extraScrollHeight={28}>
          <Text style={styles.toptitle}>メールアドレスでログイン</Text>
          <Text style={styles.heading1}>メールアドレス入力</Text>
          <TextInput
            style={styles.login_input}
            value={email}
            onChangeText={setEmail}
            autoCapitalize="none"
            autoFocus={true}
          />
          <Text style={styles.heading1}>パスワード入力</Text>
          <View style={styles.passwordContainer}>
            <TextInput
              style={styles.login_password}
              autoCompleteType="password"
              secureTextEntry={!isPasswordVisible}
              value={password}
              onChangeText={setPassword}
            />
            <View style={styles.PasswordVisible}>
              <TouchableOpacity onPress={() => setIsPasswordVisible(!isPasswordVisible)}>
                {isPasswordVisible ? (
                  <VscEyeClosed size={20} color="#555"/>
                ) : (
                  <VscEye size={20} color="#555"/>
                )}
              </TouchableOpacity>
            </View>
          </View>

          <TouchableOpacity style={styles.btn} onPress={handleLogin}>
            <Text style={styles.send_btn}>ログイン</Text>
          </TouchableOpacity>

          <TouchableOpacity style={styles.reset} onPress={goToPassreset}>
            <Text style={styles.reset_btn}>パスワードをお忘れの方はこちら</Text>
          </TouchableOpacity>
        </KeyboardAwareScrollView>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%'
  },
  content: {
    alignItems: 'center',
    justifyContent: 'center',
    width: '70%',
    marginLeft: '15%',
    marginRight: '15%',
  },
  toptitle: {
    fontSize: 18,
    color: '#8b8b8b',
    margin: 30,
  },
  login_input: {
    height: 35,
    width: '100%',
    backgroundColor: '#fff',
    borderRadius: 5,
    padding: 10,
    fontSize: 16,
  },
  login_password: {
    height: 35,
    width: '100%',
    backgroundColor: '#fff',
    borderRadius: 5,
    padding: 10,
    fontSize: 16,
    position: "relative",
  },
  PasswordVisible:{
    position: "absolute",
    top: '8px',
    right: '5px',
  },
  heading1: {
    fontSize: 16,
    color: '#8b8b8b',
    margin: 5,
    alignItems: 'center',
  },
  btn: {
    width: '100%',
    height: 50,
    backgroundColor: '#cd2829',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 30,
    marginTop: 30,
  },
  send_btn: {
    fontSize: 18,
    color: '#fff',
    fontWeight: 'bold',
  },
  reset: {
    padding: 15,
    marginTop: 10,
  },
  reset_btn: {
    color: '#a1a1a1',
    textAlign: 'center',
    borderBottomWidth: 1,
    borderBottomColor: '#a1a1a1',
    paddingBottom: 2,
  },
  passwordContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#fff',
    borderRadius: 5,
    marginBottom: 10,
  },
  eyeIcon: {
    marginLeft: 10,
  },
  loadingText: {
    color: '#555555',
    marginBottom: 10,
  }
});

export default LoginScreen;
