import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getFirestore, doc, collection, getDoc, setDoc } from 'firebase/firestore';
import { app } from '../../plugins/firebase';

const db = getFirestore(app);

// 初期状態
const initialState = {
  updates: 1,
  lastUpdateVersion: null,
  loading: false,
  error: null,
};

// 非同期アクション: 1.2.3 のアップデートチェック
export const getUpdates = createAsyncThunk('updates/getUpdates', async ({ user }, { rejectWithValue }) => {
  try {
    const updatesRefGet = await getDoc(doc(collection(db, 'updates'), '1.2.3', user.uid, 'updateTime'));
    if (!updatesRefGet.exists()) {
      console.log('update履歴なし');
      return 0;
    } else {
      console.log('update履歴あり');
      return 1;
    }
  } catch (error) {
    console.error(error);
    return rejectWithValue(error.message);
  }
});

// 非同期アクション: 最後のアップデートバージョンを取得
export const getLastUpdates = createAsyncThunk('updates/getLastUpdates', async (_, { rejectWithValue }) => {
  try {
    const updatesLastVerRefGet = await getDoc(doc(db, 'updates', 'lastupdates'));
    const updatesLastVer = updatesLastVerRefGet.data();
    return updatesLastVer.ver;
  } catch (error) {
    console.error(error);
    return rejectWithValue(error.message);
  }
});

// 非同期アクション: DBにアップデート情報を設定
export const setDBUpdates = createAsyncThunk('updates/setDBUpdates', async ({ user }, { rejectWithValue }) => {
  try {
    const timestamp = Date.now();
    const set_time = { updateTime: timestamp };
    await setDoc(doc(collection(db, 'updates'), '1.2.3', user.uid, 'updateTime'), set_time);
    return timestamp;
  } catch (error) {
    console.error(error);
    return rejectWithValue(error.message);
  }
});

// Redux Toolkit の Slice
const updatesSlice = createSlice({
  name: 'updates',
  initialState,
  reducers: {
    initUpdates(state) {
      state.updates = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUpdates.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUpdates.fulfilled, (state, action) => {
        state.updates = action.payload;
        state.loading = false;
      })
      .addCase(getUpdates.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getLastUpdates.pending, (state) => {
        state.loading = true;
      })
      .addCase(getLastUpdates.fulfilled, (state, action) => {
        state.lastUpdateVersion = action.payload;
        state.loading = false;
      })
      .addCase(getLastUpdates.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(setDBUpdates.pending, (state) => {
        state.loading = true;
      })
      .addCase(setDBUpdates.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(setDBUpdates.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

// Reducerとアクションをエクスポート
export const { initUpdates } = updatesSlice.actions;
export default updatesSlice.reducer;
