import React, { useState, useEffect } from 'react';
import { View, Text, TouchableOpacity, StyleSheet, Image, Linking, ScrollView, ImageBackground, Dimensions } from 'react-native';
import { useSelector } from 'react-redux'; // Reduxから状態を取得

const ServiceScreen = () => {
  // Reduxからログイン状態を取得
  const account = useSelector((state) => state.accounts.account);
  const isLoggedIn = !!account; // accountが存在する場合にtrueになる

  // width と height の状態を管理するためのステートを作成
  const [windowDimensions, setWindowDimensions] = useState({ width: 0, height: 0 });

  // コンポーネントがマウントされたときに、画面サイズを取得する
  useEffect(() => {
    const { width, height } = Dimensions.get('window');
    setWindowDimensions({ width, height });

    // リサイズ時に画面サイズを更新するリスナーを追加
    const onChange = ({ window }) => {
      setWindowDimensions({ width: window.width, height: window.height });
    };
    Dimensions.addEventListener('change', onChange);

    // クリーンアップ
    return () => {
      Dimensions.removeEventListener('change', onChange);
    };
  }, []);

  const isMobileScreen = windowDimensions.width < 768;

  const links = [
    { label: '公式ホームページ', url: 'http://www.kurose-pf.co.jp/', icon: require('../assets/images/serviceicon1.png') },
    { label: '', url: 'http://avis-kurose-pf.shop-pro.jp/', icon: require('../assets/images/serviceicon2.png') },
    { label: 'とりにてぃ', url: isLoggedIn ? 'Home' : 'Top', icon: require('../assets/images/serviceicon3.png'), internal: true },
    { label: 'スタンプカード', url: '/stamp', icon: require('../assets/images/serviceicon4.png') },
  ];

  const socials = [
    { icon: require('../assets/images/insta.png'), url: 'https://www.instagram.com/kurosepf' },
    { icon: require('../assets/images/facebook.png'), url: 'https://www.facebook.com/kurose.pf' },
    { icon: require('../assets/images/X.png'), url: 'https://x.com/kurosepf' },
  ];

  const openLink = (url, internal = false) => {
    if (typeof window !== 'undefined') {
      // ウェブ環境の場合
      if (internal) {
        window.location.href = url;
      } else {
        // 直接開く形に
        window.location.href = url;
        // window.open(url, '_blank');
      }
    } else {
      // React Native アプリの場合
      Linking.openURL(url).catch(() => alert('リンクを開けませんでした'));
    }
  };


  return (
    <ScrollView contentContainerStyle={styles.contentContainer}>
      <ImageBackground
        source={require('../assets/images/service_back.png')}
        style={[styles.backgroundImage, { height: '100%' }]}
        resizeMode="repeat"
      >
        <View style={styles.serviceLogo}>
          <Image
            source={require('../assets/images/logo.png')}
            style={[
              styles.serviceImage,
              { marginTop: isMobileScreen ? 30 : 50 },
              { marginBottom: isMobileScreen ? 10 : 30 },
            ]}
          />
        </View>
        <View style={styles.ContainerInr}>
          {links.map((link, index) => (
            <TouchableOpacity
              key={index}
              style={styles.linkButton}
              onPress={() => openLink(link.url, link.internal)}
            >
              <View style={styles.iconContainer}>
                <Image
                  source={link.icon}
                  style={[
                    styles.icon,
                    { marginRight: isMobileScreen ? 5 : 20 },
                    { marginLeft: isMobileScreen ? 15 : 30 },
                  ]}
                />
                <View style={styles.dottedLine}>
                  {Array.from({ length: 12 }).map((_, dotIndex) => (
                    <View key={dotIndex} style={styles.dot} />
                  ))}
                </View>
              </View>
              <View
                style={[
                  styles.textContainer,
                  { paddingLeft: isMobileScreen ? 0 : 45 },
                  { paddingRight: isMobileScreen ? 5 : 45 },
                ]}
              >
                <Text
                  style={[
                    styles.linkButtonText,
                    index === 0 && styles.firstLabelText,
                  ]}
                >
                  {link.label}
                </Text>
                {index === 1 && (
                  <>
                    <Image source={require('../assets/images/avis_logo.png')} style={styles.avisImage} />
                    <Text style={styles.subText}>小鳥のフード、飼育用品の{"\n"}通信販売オンラインショップです。</Text>
                  </>
                )}
                {index === 2 && (
                  <Text style={styles.subText}>愛鳥の体調管理をして、{"\n"}健康チェックに役立つアプリです。</Text>
                )}
                {index === 3 && (
                  <Text style={styles.subText}>イベントに来場した際には{"\n"}スタンプを貯めてくださいね。</Text>
                )}
              </View>
            </TouchableOpacity>
          ))}

          <View style={styles.socialsContainer}>
            {socials.map((social, index) => (
              <TouchableOpacity key={index} onPress={() => openLink(social.url)}>
                <Image
                  source={social.icon}
                  style={[
                    styles.socialIcon,
                    { width: isMobileScreen ? 55 : 80 },
                    { height: isMobileScreen ? 55 : 80 },
                  ]}
                />
              </TouchableOpacity>
            ))}
          </View>
        </View>
        <View
          style={[
            styles.footer,
            { paddingBottom: isMobileScreen ? 50 : 80 },
          ]}
        >
          <View style={{ height: windowDimensions.width * 0.3 }} />
          <Image
            source={require('../assets/images/service_bottom.png')}
            style={[styles.footerImage,
              { bottom: isMobileScreen ? 50 : 80 },
              { height: windowDimensions.width * 0.3 }]
            }
          />
        </View>
      </ImageBackground>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  contentContainer: {
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#fff',
  },
  ContainerInr: {
    padding: 20,
    maxWidth: 800,
    width: '100%',
    marginHorizontal: 'auto',
  },
  linkButton: {
    color: '#656565',
    fontSize: 18,
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#fff',
    borderColor: '#cd2829',
    borderWidth: 1,
    borderRadius: 70,
    maxWidth: 715,
    width: '100%',
    padding: 5,
    marginBottom: 20,
    marginHorizontal: 'auto',
    paddingVertical: 10,
  },
  iconContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingRight: 15,
  },
  icon: {
    width: 50,
    height: 40,
    resizeMode: 'contain',
  },
  dottedLine: {
    width: 1,
    height: '100%',
    marginLeft: 10,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  dot: {
    width: 1,
    height: 5,
    backgroundColor: '#ccc',
    marginVertical: 2,
  },
  textContainer: {
    flex: 1,
    paddingVertical: 5,
  },
  linkButtonText: {
    color: '#656565',
    fontSize: 24,
  },
  firstLabelText: {
    color: '#656565',
    fontSize: 18,
  },
  subText: {
    color: '#888',
    fontSize: 14,
    marginTop: 5,
  },
  socialsContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '60%',
    marginVertical: 20,
    marginHorizontal: 'auto',
  },
  socialIcon: {
    resizeMode: 'contain',
  },
  footer: {
    width: '100%',
    position: 'relative',
    maxWidth: 800,
    marginHorizontal: 'auto',
  },
  footerImage: {
    resizeMode: 'contain',
    width: '100%',
    position: 'absolute',
  },
  avisImage: {
    width: 90,
    height: 40,
    resizeMode: 'contain'
  },
  backgroundImage: {
    width: '100%',
  },
  serviceLogo: {
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  serviceImage: {
    width: '70%',
    maxWidth: 468,
    height: 65,
    resizeMode: 'contain',
  },
});

export default ServiceScreen;
