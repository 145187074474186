function emptyHandler(){
    return true;
}

const state = {
    editScreen: null,
    onEdit: emptyHandler
}

const getters = {
    editScreen: state => {
        return state.editScreen
    },
    onEdit : state => {
        return state.onEdit
    },
}

// mutations
const mutations = {
    setEditScreenHandler( state, { screen, handler }) {
        state.editScreen = screen;
        if( handler == null ){
            state.onEdit = emptyHandler;
        }
        else {
            state.onEdit = handler;
        }
    }
}

// actions
const actions = {
    setEditScreenHandler( {commit}, { screen, handler }){
        commit('setEditScreenHandler', { screen: screen, handler: handler })
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}