import React, { useState, useEffect } from 'react';
import { ScrollView, Text, View, TouchableOpacity, Linking, Image, StyleSheet, Platform, Dimensions, Alert } from 'react-native';  
import { Toast } from 'native-base';
import Dialog, { DialogTitle, DialogContent, DialogButton, DialogFooter } from 'react-native-popup-dialog';
import { useDispatch } from 'react-redux'; // Reduxのdispatchをインポート
import { signOut } from '../store/modules/accounts';
import { useNavigate } from 'react-router-dom'; // Web用のナビゲーション
import { MdChevronLeft } from 'react-icons/md';
import { auth } from '../plugins/firebase'; // Firebase認証
import { getFirestore, doc, getDoc, collection, updateDoc, query, where, getDocs } from 'firebase/firestore';
import { onAuthStateChanged } from 'firebase/auth';

const firestore = getFirestore();

const Settings = ({ navigation }) => {
  const isWeb = Platform.OS === 'web'; // Webかどうかを判定
  const webNavigate = isWeb ? useNavigate() : null; // Web用のナビゲーション
  const dispatch = useDispatch(); // dispatch関数を取得

  const [isPremium, setIsPremium] = useState(false);

  // 🔹 Firestore からプレミアムフラグを取得
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        console.log("ログインユーザー:", user.uid);
        const userRef = doc(firestore, 'accounts', user.uid);
        const userSnap = await getDoc(userRef);

        if (userSnap.exists()) {
          const userData = userSnap.data();
          console.log("Firestoreのアカウント情報:", userData);
          setIsPremium(userData.premiumFlag || false);
        } else {
          console.warn("Firestoreにユーザー情報が見つかりませんでした");
        }
      } else {
        console.warn("ログインユーザーが存在しません");
      }
    });

    return () => unsubscribe();
  }, []);

  // 🔹 プレミアム解約確認
  const handleCancelPremium = () => {
    if (isWeb) {
      if (window.confirm("プレミアム機能を解約してよろしいですか？")) {
        performCancelPremium();
      }
    } else {
      Alert.alert(
        "プレミアム機能解約",
        "プレミアム機能を解約してよろしいですか？",
        [
          { text: "キャンセル", style: "cancel" },
          { text: "OK", onPress: performCancelPremium }
        ]
      );
    }
  };

  // 🔹 プレミアム解約処理（autoPayment を false に更新）
  const performCancelPremium = async () => {
    const user = auth.currentUser;
    if (!user) {
      console.warn("ログインしていません");
      return;
    }
  
    try {
      const premiumCollectionRef = collection(firestore, `accounts/${user.uid}/premium`);
      const premiumQuery = query(premiumCollectionRef);
      const premiumSnapshot = await getDocs(premiumQuery);
  
      let contractRenewDate = null;
      let alreadyCancelled = true; // 🔹 すでに autoPayment が false か判定するフラグ
  
      if (!premiumSnapshot.empty) {
        premiumSnapshot.forEach(async (docSnap) => {
          const premiumDocRef = doc(firestore, `accounts/${user.uid}/premium`, docSnap.id);
          const premiumData = docSnap.data();
  
          // 🔹 `contractRenewDate` を取得（ISO8601形式）
          if (premiumData.contractRenewDate) {
            contractRenewDate = new Date(premiumData.contractRenewDate);
          }
  
          if (premiumData.autoPayment) {
            alreadyCancelled = false; // 🔹 autoPayment が true の場合、解約処理が必要
            await updateDoc(premiumDocRef, { autoPayment: false });
          }
        });
  
        console.log("Firestore の autoPayment を false に更新しました");
      }
  
      // 🔹 `contractRenewDate` をフォーマット
      let renewalMessage = "";
      if (contractRenewDate instanceof Date && !isNaN(contractRenewDate)) {
        const formattedDate = `${contractRenewDate.getFullYear()}/${(contractRenewDate.getMonth() + 1)
          .toString().padStart(2, '0')}/${contractRenewDate.getDate().toString().padStart(2, '0')}`;
        renewalMessage = `\n${formattedDate} まではプレミアム機能が使えます。`;
      }
  
      // 🔹 すでに解約済みかを判定してメッセージを変更
      let message = alreadyCancelled
        ? `プレミアム機能はすでに解約済みです。${renewalMessage}`
        : `プレミアム機能を解約しました。${renewalMessage}`;
  
      // 🔹 解約完了の通知
      if (isWeb) {
        alert(message);
      } else {
        Alert.alert("プレミアム機能解約", message);
      }
    } catch (error) {
      console.error("プレミアム解約の更新エラー:", error);
    }
  };
  

  const [screenWidth, setScreenWidth] = useState(Dimensions.get('window').width);
  const [isMobileScreen, setIsMobileScreen] = useState(screenWidth < 768);
  useEffect(() => {
    // 画面サイズ変更を監視
    const onChange = ({ window }) => {
      setScreenWidth(window.width);
      setIsMobileScreen(window.width < 768);
    };

    const subscription = Dimensions.addEventListener('change', onChange);

    return () => {
      // クリーンアップ
      subscription?.remove();
    };
  }, []);

  const [isShowDialog, setIsShowDialog] = useState(false);
  const [dialogTitle, setDialogTitle] = useState('');
  const [dialogMessage, setDialogMessage] = useState('');
  const [dialogEvent, setDialogEvent] = useState({
    onSuccess: null,
    onCancel: null,
  });

  const kurose_url = "http://www.kurose-pf.co.jp/";
  const avis_url = "http://avis-kurose-pf.shop-pro.jp/";
  const mail_url = "http://www.kurose-pf.co.jp/lovenoteentry";

  const showToast = (message) => {
    if (isWeb) {
      alert(message); // Web用にアラートを使用
    } else {
      Toast.show({
        position: 'top',
        type: 'warning',
        text: message,
        buttonText: "OK",
        duration: 3000,
      });
    }
  };

  const handleSignOut = () => {
    if (isWeb) {
      if (window.confirm("ログアウトしてよろしいですか？")) {
        performSignOut();
      }
    } else {
      setDialogTitle("ログアウト");
      setDialogMessage("ログアウトしてよろしいですか");
      setDialogEvent({
        onSuccess: performSignOut,
        onCancel: () => setIsShowDialog(false),
      });
      setIsShowDialog(true);
    }
  };

  const performSignOut = () => {
    setIsShowDialog(false);
    dispatch(signOut()) // Reduxの非同期アクションを呼び出す
      .unwrap() // 成功またはエラーをキャッチするためにunwrapを使用
      .then(() => {
        showToast("ログアウトしました");
        if (isWeb) {
          webNavigate('/login'); // Webの場合はuseNavigateで/loginに遷移
        } else {
          navigation.reset({
            index: 0,
            routes: [{ name: 'Login' }], // モバイルの場合はReact Navigationで遷移
          });
        }
      })
      .catch((error) => {
        showToast(`ログアウトに失敗しました: ${error}`);
      });
  };

  const handleOpenURL = (url) => {
    if (isWeb) {
      window.open(url, '_blank');
    } else {
      Linking.openURL(url).catch(() => showToast("URLを開けませんでした。"));
    }
  };

  const handleNavigation = (route) => {
    if (isWeb) {
      webNavigate(`/${route}`); // Web用ナビゲーションで絶対パスを使用
    } else {
      navigation.navigate(route); // モバイル用ナビゲーション
    }
  };

  return (
      <ScrollView>
          <TouchableOpacity style={styles.listItemBack} onPress={() => handleNavigation('Home')}>
            <MdChevronLeft size={20} color="#cd2829" />
            <Text style={styles.controlBtnBackText}>戻る</Text>
          </TouchableOpacity>

        <View style={styles.container}>
          <TouchableOpacity style={styles.listItem} onPress={() => handleNavigation('Mypage')}>
            <Text style={styles.listItemHeader}>マイページ</Text>
          </TouchableOpacity>
          <TouchableOpacity style={styles.listItem} onPress={() => handleNavigation('Terms')}>
            <Text style={styles.listItemHeader}>利用規約</Text>
          </TouchableOpacity>
          <TouchableOpacity style={styles.listItem} onPress={() => handleNavigation('Privacy')}>
            <Text style={styles.listItemHeader}>プライバシーポリシー</Text>
          </TouchableOpacity>
          <TouchableOpacity style={styles.listItem} onPress={() => handleNavigation('Disclaimer')}>
            <Text style={styles.listItemHeader}>免責事項</Text>
          </TouchableOpacity>
          <TouchableOpacity style={styles.listItem} onPress={() => handleOpenURL(mail_url)}>
            <Text style={styles.listItemHeader}>お問い合わせ</Text>
          </TouchableOpacity>
          <TouchableOpacity style={styles.listItem} onPress={() => handleNavigation('Qa')}>
            <Text style={styles.listItemHeader}>Q&A</Text>
          </TouchableOpacity>
          <TouchableOpacity style={styles.listItem} onPress={handleSignOut}>
            <Text style={styles.listItemHeader}>ログアウト</Text>
          </TouchableOpacity>
          {isPremium && (
          <TouchableOpacity style={styles.listItem} onPress={handleCancelPremium}>
            <Text style={styles.listItemHeader}>プレミアム解約</Text>
          </TouchableOpacity>
          )}
          <View style={styles.subTitle}>
            <Text style={styles.subTitleText}>運営会社</Text>
          </View>

          <TouchableOpacity style={styles.listItemBig} onPress={() => handleOpenURL(kurose_url)}>
            <View style={styles.itemLeft}>
              <Image source={require('../assets/images/kurose_icon.png')} style={styles.image} />
            </View>
            <View style={styles.itemRight}>
            <Text style={styles.listItemHeader}>
              {screenWidth < 768
                ? "株式会社 黒瀬ペットフード\nオフィシャルサイト"
                : "株式会社 黒瀬ペットフードオフィシャルサイト"}
            </Text>
            </View>
          </TouchableOpacity>

          <TouchableOpacity style={styles.listItemBig} onPress={() => handleOpenURL(avis_url)}>
            <View style={styles.itemLeft}>
              <Image source={require('../assets/images/avis_icon.png')} style={styles.image} />
            </View>
            <View style={styles.itemRight}>
              <Text style={styles.listItemHeader}>
                {screenWidth < 768
                  ? "公式オンラインショップ\nアウィス小鳥のフード・飼育用品通販サイト"
                  : "公式オンラインショップ アウィス小鳥のフード・飼育用品通販サイト"}
              </Text>
            </View>
          </TouchableOpacity>
        </View>
        {!isWeb && (
        <Dialog
          visible={isShowDialog}
          onTouchOutside={() => setIsShowDialog(false)}
          width={0.8}
        >
          <DialogTitle title={dialogTitle || '確認'} align="center" />
          <DialogContent style={{ justifyContent: 'center', padding: 10 }}>
            <Text style={{ textAlign: 'left' }}>{dialogMessage}</Text>
          </DialogContent>
          <DialogFooter>
            <DialogButton text="キャンセル" onPress={dialogEvent.onCancel || (() => setIsShowDialog(false))} />
            <DialogButton text="OK" bordered onPress={dialogEvent.onSuccess || (() => setIsShowDialog(false))} />
          </DialogFooter>
        </Dialog>
      )}
      </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    width: '100%',
    maxWidth: 800,
    marginHorizontal: 'auto',
  },
  listItem: {
    flexDirection: 'row',
    paddingTop: 15,
    paddingBottom: 15,
    paddingLeft: 20,
    width: '100%',
    borderBottomColor: '#ddd',
    borderBottomWidth: 1,
  },
  listItemHeader: {
    color: '#555555',
    fontSize: 15,
    width: '100%',
  },
  subTitle: {
    flexDirection: 'row',
    paddingTop: 30,
    paddingBottom: 14,
    paddingLeft: 20,
    width: '100%',
    height: 70,
    borderBottomColor: '#ddd',
    borderBottomWidth: 1,
  },
  subTitleText: {
    color: '#444444',
    fontSize: 16,
    fontWeight: 'bold',
  },
  listItemBig: {
    flexDirection: 'row',
    paddingTop: 15,
    paddingBottom: 15,
    paddingLeft: 20,
    width: '100%',
    borderBottomColor: '#ddd',
    borderBottomWidth: 1,
    alignItems: 'center',
  },
  itemLeft: {
    width: '15%',
  },
  itemRight: {
    width: '85%',
  },
  image: {
    marginLeft: 5,
    width: 30,
    height: 30,
    resizeMode: 'contain',
  },
  listItemBack: {
    flexDirection: 'row',
    paddingTop: 15,
    paddingBottom: 15,
    paddingLeft: 20,
    width: '100%',
    alignItems: 'center',
  },
  controlBtnBackText: {
    color: '#cd2829',
    fontSize: 18,
    marginLeft: 10,
  },
});

export default Settings;
