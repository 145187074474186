import React from 'react';
import { View, Text, ScrollView, StyleSheet } from 'react-native';

const FAQ = () => {
  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <View style={styles.separator} />
      </View>

      <ScrollView>
        <View style={styles.systtlA}>
          <View style={styles.separator} />
          <Text style={styles.termstitle}>Q：オカメインコの項目がありません。</Text>
          <View style={styles.separator} />
          <Text style={styles.texta}>A：オカメインコは、オウム類のためオウムの方にあります。</Text>

          <View style={styles.separator} />
          <Text style={styles.termstitle}>Q：誕生日入力はどんな機能ですか</Text>
          <View style={styles.separator} />
          <Text style={styles.texta}>A：愛鳥登録画面にて、誕生日を入力すると自動的に年齢が入ります。年齢は編集可能ですが、編集後のデータが採用になります。</Text>

          <View style={styles.separator} />
          <Text style={styles.termstitle}>Q：始め方が分かりません。</Text>
          <View style={styles.separator} />
          <Text style={styles.texta}>A：愛鳥登録を１羽以上ご登録後にご利用いただけます。</Text>

          <View style={styles.separator} />
          <Text style={styles.termstitle}>Q：食事の入力方法がわからない。</Text>
          <View style={styles.separator} />
          <Text style={styles.texta}>A：記録画面の「フード編集」からフードをご登録後に情報を呼び出せます。</Text>

          <View style={styles.separator} />
          <Text style={styles.termstitle}>Q：複数羽での利用方法を教えてください。</Text>
          <View style={styles.separator} />
          <Text style={styles.texta}>A：記録画面の「フード編集」からフードをご登録後に情報を呼び出せます。</Text>

          <View style={styles.separator} />
          <Text style={styles.termstitle}>Q：複数の食事入力方法がわからない。</Text>
          <View style={styles.separator} />
          <Text style={styles.texta}>A：記録画面の食事箇所にて「＋」ボタンでごはん入力後、続けて「＋」ボタンで複数のごはんや副食などをお選びいただけます。</Text>

          <View style={styles.separator} />
          <Text style={styles.termstitle}>Q：カメラ・アルバムが起動しない</Text>
          <View style={styles.separator} />
          <Text style={styles.texta}>A：愛鳥登録画面にて、カメラ・アルバムアイコンタップ時にカメラ機能へのアクセスを許可してください。</Text>

          <View style={styles.separator} />
          <Text style={styles.termstitle}>Q：対応バージョンを教えてください。</Text>
          <View style={styles.separator} />
          <Text style={styles.texta}>A：iOSは11以上を推奨しています。</Text>

          <View style={styles.separator} />
          <Text style={styles.termstitle}>Q：誕生日を把握していません。</Text>
          <View style={styles.separator} />
          <Text style={styles.texta}>A：誕生日、年齢がわからなくても愛鳥登録は可能です。なお年齢は自動入力されますが、編集で変更可能です。年齢の手動入力が優先されます。</Text>
        </View>
      </ScrollView>

      <View style={styles.footer}></View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#fff',
    justifyContent: 'center',
    flex: 1,
    padding: 20,
  },
  header: {
    alignItems: 'center',
  },
  headerinr: {
    fontSize: 24,
    fontWeight: 'bold',
    padding: 3,
    color: '#777',
  },
  termstitle: {
    fontSize: 18,
    fontWeight: 'bold',
    padding: 3,
    color: '#777',
  },
  texta: {
    textAlign: 'left',
    fontSize: 16,
    color: '#777',
    padding: 14,
  },
  separator: {
    marginTop: 10,
    marginBottom: 14,
    borderBottomColor: '#bbb',
    borderBottomWidth: 1,
  },
  systtlA: {
    marginBottom: 20,
  },
  footer: {
    height: 50,
    justifyContent: 'center',
  },
});

export default FAQ;
