import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getFirestore, collection, doc, setDoc, updateDoc, deleteDoc, onSnapshot, getDocs } from 'firebase/firestore';
import { app } from '../../plugins/firebase';
import { dateToStr } from '../../plugins/utils';

const db = getFirestore(app);
const birdsRef = (birdId) => collection(db, 'birds', birdId, 'records');
const todayStr = dateToStr(new Date(), 'YYYY-MM-DD');

// 初期状態
const initialState = {
  records: [],
  recordedDays: {},
  loading: false,
  error: null,
  selectedDateString: todayStr,
};

export function initRecords() {
  return (dispatch) => {
    dispatch(setRecords({ records: [] }));  // レコードデータをリセット
    dispatch(setRecordedDays({ recordedDays: {} }));  // 日付情報もリセット
  };
}

// 非同期アクション: レコードのリアルタイム同期
export const syncRecords = createAsyncThunk('records/syncRecords', async ({ bird }, { dispatch, rejectWithValue }) => {
  try {
    const ref = birdsRef(bird.bid);

    // リアルタイム同期のため、onSnapshotを使う
    const unsubscribe = onSnapshot(ref, (snapshot) => {
      const records = [];
      const recordedDays = {};
      snapshot.forEach((doc) => {
        const record = doc.data();
        record.rid = doc.id;
        records.push(record);

        // カレンダーなどに使用する日付情報
        recordedDays[record.rid] = { selected: true, marked: false, selectedColor: '#cd2829' };
      });

      // 状態をReduxに反映
      dispatch(setRecords({ records }));
      dispatch(setRecordedDays({ recordedDays }));
    });

    return unsubscribe; // 必要に応じてunsubscribeを返却
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

// 非同期アクション: レコードの取得
export const getRecords = createAsyncThunk('records/getRecords', async (bird, { rejectWithValue }) => {
  try {
    if (!bird || !bird.bid) {
      throw new Error("bird.bid is undefined");
    }
    const ref = birdsRef(bird.bid); // bird.bidを使ってFirestoreリファレンスを取得
    const snapshot = await getDocs(ref); // サブコレクションのデータを取得
    const records = [];
    snapshot.forEach((doc) => {
      const record = doc.data();
      record.rid = doc.id;
      records.push(record);
    });
    return records;
  } catch (error) {
    return rejectWithValue(error.message); // エラー時にrejectする
  }
});



// 非同期アクション: レコードの追加
export const addRecord = createAsyncThunk('records/addRecord', async ({ bird, record }, { rejectWithValue }) => {
  try {
    const date = new Date();
    const timestamp = date.getTime();
    const newRecordRef = doc(birdsRef(bird.bid), record.rid);  // record.ridを指定してdocを作成

    await setDoc(newRecordRef, {
      ...record,
      update_date: timestamp,
    }, { merge: true });  // merge: true を追加

    return { rid: newRecordRef.id, record }; // 追加したレコードを返す
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

// 非同期アクション: レコードの編集
export const editRecord = createAsyncThunk('records/editRecord', async ({ bird, record }, { rejectWithValue }) => {
  try {
    const date = new Date();
    const timestamp = date.getTime();
    const recordRef = doc(birdsRef(bird.bid), record.rid);

    await updateDoc(recordRef, {
      ...record,
      update_date: timestamp,
    });

    return { rid: record.rid, record }; // 編集したレコードを返す
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

// 非同期アクション: レコードの削除
export const removeRecord = createAsyncThunk('records/removeRecord', async ({ bird, record }, { rejectWithValue }) => {
  try {
    const recordRef = doc(birdsRef(bird.bid), record.rid);
    await deleteDoc(recordRef);
    return record.rid; // 削除したレコードのIDを返す
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

// Redux ToolkitのSlice
const recordsSlice = createSlice({
  name: 'records',
  initialState,
  reducers: {
    setRecords(state, action) {
      state.records = action.payload.records;
    },
    setRecordedDays(state, action) {
      state.recordedDays = action.payload.recordedDays;
    },
    setSelectedDateString(state, action) {
      state.selectedDateString = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(syncRecords.pending, (state) => {
        state.loading = true;
      })
      .addCase(syncRecords.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(syncRecords.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getRecords.pending, (state) => {
        state.loading = true;
      })
      .addCase(getRecords.fulfilled, (state, action) => {
        state.records = action.payload;
        state.loading = false;
      })
      .addCase(getRecords.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(addRecord.pending, (state) => {
        state.loading = true;
      })
      .addCase(addRecord.fulfilled, (state, action) => {
        state.loading = false;
        state.records.push({ ...action.payload.record, rid: action.payload.rid });
      })
      .addCase(addRecord.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(editRecord.pending, (state) => {
        state.loading = true;
      })
      .addCase(editRecord.fulfilled, (state, action) => {
        state.loading = false;
        const index = state.records.findIndex((record) => record.rid === action.payload.rid);
        if (index !== -1) {
          state.records[index] = action.payload.record;
        }
      })
      .addCase(editRecord.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(removeRecord.pending, (state) => {
        state.loading = true;
      })
      .addCase(removeRecord.fulfilled, (state, action) => {
        state.loading = false;
        state.records = state.records.filter((record) => record.rid !== action.payload);
      })
      .addCase(removeRecord.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

// ActionsとReducerをエクスポート
export const {
  setRecords,
  setRecordedDays,
  setSelectedDateString,
} = recordsSlice.actions;
export default recordsSlice.reducer;
