if (typeof setImmediate === 'undefined') {
  global.setImmediate = (callback) => setTimeout(callback, 0);
}

import React, { useEffect, useState } from 'react';
import { View, Image, TouchableOpacity, Platform, ActivityIndicator, Linking, StyleSheet, useWindowDimensions } from 'react-native';
import { NavigationContainer, useNavigation } from '@react-navigation/native';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { createStackNavigator } from '@react-navigation/stack';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { FontAwesome, FontAwesome5 } from '@expo/vector-icons';
import { MaterialIcons } from '@expo/vector-icons';
import { BrowserRouter as Router, Route, Routes, Navigate, useNavigate, useLocation } from 'react-router-dom'; // Web用Routerをインポート
import { setLeftBtnPressed } from './store/modules/headers';
import store from './store';
import { restoreLoginState, setAccount } from './store/modules/accounts';
import { getBirds } from './store/modules/birds';
import { getRecords } from './store/modules/records';
import { auth } from './plugins/firebase';
import { MdClose } from 'react-icons/md';

// スクリーンのインポート
import HomeScreen from './screens/HomeScreen';
import UpdateScreen from './screens/UpdateScreen';
import GraphScreen from './screens/GraphScreen';
import BirdScreen from './screens/BirdScreen';
import LoginScreen from './screens/LoginScreen';
import RegisterScreen from './screens/RegisterScreen';
import ThanksScreen from './screens/ThanksScreen';
import PassresetScreen from './screens/PassresetScreen';
import PasscompScreen from './screens/PasscompScreen';
import MessageScreen from './screens/MessageScreen';
import QuestionScreen from './screens/QuestionScreen';
import QuestionDetailScreen from './screens/QuestionDetailScreen';
import QuestionFirstRunScreen from './screens/QuestionFirstRunScreen';
import SettingsScreen from './screens/SettingsScreen';
import MypageScreen from './screens/MypageScreen';
import TermsScreen from './screens/TermsScreen';
import PrivacyScreen from './screens/PrivacyScreen';
import QaScreen from './screens/QaScreen';
import DisclaimerScreen from './screens/DisclaimerScreen';
import LoadingScreen from './screens/LoadingScreen';
import TopScreen from './screens/TopScreen';
import PaymentScreen from './screens/PaymentScreen';
import ServiceScreen from './screens/ServiceScreen';
import PremiumScreen from './screens/PremiumScreen';


const Tab = createBottomTabNavigator();
const Stack = createStackNavigator();

function PrivateRoute({ children }) {
  const account = useSelector((state) => state.accounts.account);
  const [isLoading, setIsLoading] = useState(true); // ローディング状態を追加

  useEffect(() => {
    if (account !== undefined) {
      setIsLoading(false); // accountの値が確認できたらローディングを終了
    }
  }, [account]);

  if (isLoading) {
    return <ActivityIndicator size="large" color="#cd2829" />; // ローディング表示
  }

  if (!account) {
    return <Navigate to="/login" />;
  }

  return children;
}

// 各ページごとのスタックナビゲーションスタイルを設定
const StackRoute = ({ component: Component, title }) => {
  const location = useLocation(); // 現在のURLを取得
  const navigate = useNavigate(); // Web用のナビゲーション

  useEffect(() => {
    document.title = title; // ページタイトルを設定
  }, [title, location]);

  // `settings`ページ判定
  const isSettingsPage = location.pathname === '/settings';

  // バツボタンのクリック処理
  const handleClose = () => {
    navigate('/home'); // `/home`に遷移
  };

  return (
    <div style={{
      width: '100%',
      height: '100%',
      backgroundColor: '#eee',
    }}>
      {/* 共通ヘッダー */}
      <header style={{
        backgroundColor: '#fff',
        color: '#888',
        padding: '10px',
        textAlign: 'center',
        fontSize: '18px',
        width: '100%',
        borderBottom: 'solid 1px #d8d8d8',
        boxSizing: 'border-box',
        position: 'relative', // 絶対配置用
        fontFamily: Platform.OS === 'ios' ? 'Helvetica' : 'sans-serif',
        fontSize: 18,
        height: 50,
      }}>
        {title}
        {isSettingsPage && (
          <MdClose
            onClick={handleClose}
            style={{
              position: 'absolute',
              top: '8px',
              right: '10px',
              fontSize: '35px',
              cursor: 'pointer',
              color: '#cd2829',
            }}
          />
        )}
      </header>
      <Component />
    </div>
  );
};

const HeaderSettings = () => {
  const isWeb = Platform.OS === 'web';
  let navigate;

  if (isWeb) {
    navigate = useNavigate(); // Webナビゲーション
  } else {
    const mobileNavigation = useNavigation(); // モバイルナビゲーション
    navigate = (path) => mobileNavigation.navigate(path);
  }

  const handleSettingsPress = async () => {
    navigate(isWeb ? '/settings' : 'Settings');
  };

  return (
    <View style={{ flexDirection: 'row', marginRight: 10 }}>
      <TouchableOpacity onPress={handleSettingsPress}>
        <Image
          source={require('./assets/images/menu.png')}
          style={{ width: 30, height: 30, resizeMode: 'contain', }}
        />
      </TouchableOpacity>
    </View>
  );
};

const HeaderLeft = () => {
  const handlePressKurose = () => {
    Linking.openURL('https://www.kurose-pf.co.jp/')
  };

  const handlePressAvis = () => {
    Linking.openURL('https://avis-kurose-pf.shop-pro.jp/')
  };

  return (
    <React.Fragment>
      <View style={styles.leftMenu}>
        <TouchableOpacity onPress={handlePressKurose}>
          <Image
            source={require('./assets/images/kosiki_icon_home.png')}
            style={{ width: 35, height: 35}}
          />
        </TouchableOpacity>
        <TouchableOpacity onPress={handlePressAvis}>
          <Image
            source={require('./assets/images/avis_icon_home.png')}
            style={{ width: 35, height: 35, marginLeft: 10 }}
          />
        </TouchableOpacity>
      </View>
    </React.Fragment>
  );
};


const HeaderAddBird = () => {
  const dispatch = useDispatch();

  return (
    <TouchableOpacity
      onPress={() => {
        dispatch(setLeftBtnPressed(true));
      }}
      style={{ marginLeft: 10 }}
    >
      <MaterialIcons
        name="add-circle-outline"
        size={30}
        color="#fff"
      />
    </TouchableOpacity>
  );
};

// ボトムタブナビゲーションの設定（Contents 相当）
const TabNavigator = () => {
  const { width } = useWindowDimensions(); // JSX の外で useWindowDimensions を呼び出す

  return (
    <Tab.Navigator
      screenOptions={{
        headerStyle: {
          backgroundColor: '#fff',
          height: 50,
        },
        headerTintColor: '#555',
        headerTitleAlign: 'center',
        headerTitleStyle: {
          color: '#555',
          fontFamily: Platform.OS === 'ios' ? 'Helvetica' : 'sans-serif',
          fontSize: 18,
        },
        tabBarActiveTintColor: '#cd2829', // アクティブなタブの色を赤に設定
        tabBarInactiveTintColor: '#bfbfbf', // 非アクティブなタブの色を灰色に設定
        tabBarLabelStyle: {
          marginTop: 0,
          marginBottom: width < 768 ? 5 : 0,
        },
        tabBarIconStyle: {
          marginTop: width < 768 ? 5 : 0,
        },
        headerLeft: () => <HeaderLeft />,
      }}
    >
      <Tab.Screen
        name="Home"
        component={HomeScreen}
        options={{
          tabBarIcon: ({ focused }) => (
            <Image
              source={
                focused
                  ? require('./assets/images/home_active.png') // アクティブ時の画像
                  : require('./assets/images/home_inactive.png') // 非アクティブ時の画像
              }
              style={{ width: 24, height: 24 }}
            />
          ),
          title: 'ホーム',
          headerLeft: () => <HeaderLeft />,
          headerRight: () => <HeaderSettings />,
        }}
      />
      <Tab.Screen
        name="Update"
        component={UpdateScreen}
        options={{
          tabBarIcon: ({ focused }) => (
            <Image
              source={
                focused
                  ? require('./assets/images/update_active.png') // アクティブ時の画像
                  : require('./assets/images/update_inactive.png') // 非アクティブ時の画像
              }
              style={{ width: 24, height: 24 }}
            />
          ),
          title: '記録する',
          headerRight: () => <HeaderSettings />,
        }}
      />
      <Tab.Screen
        name="Graph"
        component={GraphScreen}
        options={{
          tabBarIcon: ({ focused }) => (
            <Image
              source={
                focused
                  ? require('./assets/images/graph_active.png') // アクティブ時の画像
                  : require('./assets/images/graph_inactive.png') // 非アクティブ時の画像
              }
              style={{ width: 24, height: 24 }}
            />
          ),
          title: 'グラフ',
          headerRight: () => <HeaderSettings />,
        }}
      />
      <Tab.Screen
        name="Bird"
        component={BirdScreen}
        options={{
          tabBarIcon: ({ focused }) => (
            <Image
              source={
                focused
                  ? require('./assets/images/bird_active.png') // アクティブ時の画像
                  : require('./assets/images/bird_inactive.png') // 非アクティブ時の画像
              }
              style={{ width: 24, height: 24 }}
            />
          ),
          tabBarLabel: '愛鳥管理',
          headerTitle: '',
          headerRight: () => <HeaderSettings />,
          title: '愛鳥管理',
        }}
      />
    </Tab.Navigator>
  );
};

// スタックナビゲーションの設定（モバイル用）
const StackNavigator = () => (
  <Stack.Navigator
    initialRouteName="Loading"
    screenOptions={{
      headerStyle: { backgroundColor: '#cd2829' },
      headerTintColor: '#fff',
      headerTitleAlign: 'center',
      headerTitleStyle: { color: '#fff' },
    }}
  >
    <Stack.Screen
      name="Loading"
      component={LoadingScreen}
      options={{ headerShown: false }}
    />
    <Stack.Screen
      name="Top"
      component={TopScreen}
      options={{ headerShown: false }}
    />
    <Stack.Screen
      name="Login"
      component={LoginScreen}
      options={{ title: 'ログイン' }}
    />
    <Stack.Screen
      name="Register"
      component={RegisterScreen}
      options={{ title: '新規登録' }}
    />
    <Stack.Screen
      name="Thanks"
      component={ThanksScreen}
      options={{ headerShown: false }}
    />
    <Stack.Screen
      name="Passreset"
      component={PassresetScreen}
      options={{ title: 'パスワードリセット' }}
    />
    <Stack.Screen
      name="Passcomp"
      component={PasscompScreen}
      options={{ title: 'パスワード完了' }}
    />
    <Stack.Screen
      name="Message"
      component={MessageScreen}
      options={{ title: 'メッセージ' }}
    />
    <Stack.Screen
      name="Question"
      component={QuestionScreen}
      options={{ title: 'アンケート一覧' }}
    />
    <Stack.Screen
      name="QuestionDetail"
      component={QuestionDetailScreen}
      options={{ title: 'アンケート詳細' }}
    />
    <Stack.Screen
      name="QuestionFirstRun"
      component={QuestionFirstRunScreen}
      options={{ headerShown: false }}
    />
    <Stack.Screen
      name="Settings"
      component={SettingsScreen}
      options={{ title: '設定' }}
    />
    <Stack.Screen
      name="Mypage"
      component={MypageScreen}
      options={{ title: 'マイページ' }}
    />
    <Stack.Screen
      name="Terms"
      component={TermsScreen}
      options={{ title: '利用規約' }}
    />
    <Stack.Screen
      name="Privacy"
      component={PrivacyScreen}
      options={{ title: 'プライバシーポリシー' }}
    />
    <Stack.Screen
      name="Qa"
      component={QaScreen}
      options={{ title: 'Q&A' }}
    />
    <Stack.Screen
      name="Disclaimer"
      component={DisclaimerScreen}
      options={{ title: '免責事項' }}
    />
    <Stack.Screen
      name="Contents"
      component={TabNavigator}  // ContentsとしてTabNavigatorを使用
      options={{ headerShown: false }}
    />
    <Stack.Screen
      name="Payment"
      component={PaymentScreen}
      options={{ title: 'お支払い' }}
    />
  </Stack.Navigator>
);

// Web用のルーティング設定
const WebApp = () => (
  <Router>
    <Routes>
      <Route path="/" element={<StackRoute component={ServiceScreen} title="総合ページ" />} />
      <Route path="/top" element={<TopScreen />} />
      <Route path="/login" element={<StackRoute component={LoginScreen} title="ログイン" />} />
      <Route path="/register" element={<StackRoute component={RegisterScreen} title="新規登録" />} />
      <Route path="/passreset" element={<StackRoute component={PassresetScreen} title="パスワードリセット" />} />
      <Route path="/passcomp" element={<StackRoute component={PasscompScreen} title="パスワード完了" />} />
      <Route path="/thanks" element={<StackRoute component={ThanksScreen} title="新規登録-完了" />} />
      <Route path="/home" element={
        <PrivateRoute>
          <TabNavigator />
        </PrivateRoute>
        } />
      <Route path="/settings" element={
        <PrivateRoute>
          <StackRoute component={SettingsScreen} title="設定" />
        </PrivateRoute>
      } />
      <Route path="/mypage" element={
        <PrivateRoute>
          <StackRoute component={MypageScreen} title="マイページ" />
        </PrivateRoute>
      } />
      <Route path="/terms" element={
        <StackRoute component={TermsScreen} title="利用規約" />
      } />
      <Route path="/privacy" element={
        <StackRoute component={PrivacyScreen} title="プライバシーポリシー" />
      } />
      <Route path="/qa" element={
        <PrivateRoute>
          <StackRoute component={QaScreen} title="Q&A" />
        </PrivateRoute>
      } />
      <Route path="/question" element={
        <PrivateRoute>
          <StackRoute component={QuestionScreen} title="アンケート一覧" />
        </PrivateRoute>
      } />
      <Route path="/disclaimer" element={
        <PrivateRoute>
          <StackRoute component={DisclaimerScreen} title="免責事項" />
        </PrivateRoute>
      } />
      <Route path="/payment" element={
        <PrivateRoute>
          <StackRoute component={PaymentScreen} title="お支払い情報入力" />
        </PrivateRoute>
      } />
      <Route path="/premium" element={
        <PrivateRoute>
          <StackRoute component={PremiumScreen} title="とりにてぃプレミアム" />
        </PrivateRoute>
      } />
    </Routes>
  </Router>
);

// メインのAppコンポーネント
function AppContent() {
  useEffect(() => {
    document.title = "とりにてぃ";
  }, []);
  const dispatch = useDispatch();

  useEffect(() => {
    const savedAccount = localStorage.getItem('account');
    if (savedAccount) {
      const accountData = JSON.parse(savedAccount);
      dispatch(setAccount(accountData)); // ローカルストレージのデータをReduxに設定

      // 必要に応じて鳥や記録のデータを取得
      if (accountData.birds && accountData.birds.length > 0) {
        dispatch(getBirds({ user: accountData }));
        dispatch(getRecords({ user: accountData, bird: accountData.birds[0] }));
      }
    } else {
      // Firebaseのログイン状態を確認
      dispatch(restoreLoginState());
    }
  }, [dispatch]);

  return (
    Platform.OS === 'web' ? (
      <NavigationContainer>
        <WebApp />
      </NavigationContainer>
    ) : (
      <NavigationContainer>
        <StackNavigator />
      </NavigationContainer>
    )
  );
}

const styles = StyleSheet.create({
  leftMenu: {
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 20,
  },
});

export default function App() {
  return (
    <Provider store={store}> {/* ReduxのProviderでラップ */}
      <AppContent />
    </Provider>
  );
}
