import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { auth } from '../../plugins/firebase';
import { signInWithEmailAndPassword, onAuthStateChanged, signOut as firebaseSignOut, sendPasswordResetEmail } from 'firebase/auth';
import { getFirestore, collection, doc, setDoc, query, where, getDocs, updateDoc } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';

// Firestoreの初期化
const firestore = getFirestore();
const functions = getFunctions();
const accountsRef = collection(firestore, 'accounts');

// Firebase Functions の初期化
const fbfuncCreateAccount = httpsCallable(functions, 'createAccount');
const fbfuncDeleteAccount = httpsCallable(functions, 'deleteAccount');
const fbfuncEditAccount = httpsCallable(functions, 'editAccount');

// 非同期アクション: ログイン状態の復元
export const restoreLoginState = createAsyncThunk('accounts/restoreLoginState', async (_, { dispatch }) => {
  const savedAccount = localStorage.getItem('account');
  if (savedAccount) {
    const accountData = JSON.parse(savedAccount);

    // Firestoreから詳細情報を取得
    const snapshot = await getDocs(query(accountsRef, where('uid', '==', accountData.uid)));
    if (!snapshot.empty) {
      const userData = snapshot.docs[0].data();
      const detailedAccount = { ...accountData, ...userData };
      
      dispatch(setAccount(detailedAccount));
      return detailedAccount;
    } else {
      console.error('Firestoreに該当するアカウントがありません');
      dispatch(setAccount(null));
      return null;
    }
  } else {
    dispatch(setAccount(null));
    return null;
  }
});

// ログイン処理
export const signIn = createAsyncThunk('accounts/signIn', async ({ email, password }, { rejectWithValue }) => {
  try {
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    const user = userCredential.user;
    const userInfo = { uid: user.uid, email: user.email };

    localStorage.setItem('account', JSON.stringify(userInfo));
    return userInfo;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

// ログアウト処理
export const signOut = createAsyncThunk('accounts/signOut', async (_, { dispatch }) => {
  await firebaseSignOut(auth);
  localStorage.removeItem('account');
  dispatch(setAccount(null));
});

// パスワードリセット
export const signForgot = createAsyncThunk('accounts/signForgot', async ({ email }, { rejectWithValue }) => {
  try {
    await sendPasswordResetEmail(auth, email);
    return null;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

// アカウント作成
export const addAccount = createAsyncThunk('accounts/addAccount', async ({ account }, { rejectWithValue }) => {
  try {
    const result = await fbfuncCreateAccount(account);
    return result.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

// アカウント編集
export const editAccount = createAsyncThunk('accounts/editAccount', async ({ account }, { rejectWithValue }) => {
  try {
    const result = await fbfuncEditAccount(account);
    await updateDoc(doc(accountsRef, account.uid), {
      ...account,
      update_date: Date.now() / 1000,
    });
    return result.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

// アカウント削除
export const removeAccount = createAsyncThunk('accounts/removeAccount', async ({ account }, { rejectWithValue }) => {
  try {
    const result = await fbfuncDeleteAccount(account);
    return result.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

// スライスの作成
const accountsSlice = createSlice({
  name: 'accounts',
  initialState: {
    account: null,
    status: 'idle',
    error: null,
  },
  reducers: {
    setAccount(state, action) {
      state.account = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(restoreLoginState.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(restoreLoginState.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.account = action.payload;
      })
      .addCase(restoreLoginState.rejected, (state) => {
        state.status = 'failed';
        state.account = null;
      })
      .addCase(signIn.fulfilled, (state, action) => {
        state.account = action.payload;
        state.status = 'succeeded';
      })
      .addCase(signOut.fulfilled, (state) => {
        state.account = null;
        state.status = 'idle';
      })
      .addCase(addAccount.fulfilled, (state, action) => {
        state.status = 'succeeded';
      })
      .addCase(addAccount.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(editAccount.fulfilled, (state, action) => {
        state.account = action.payload;
      })
      .addCase(editAccount.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(removeAccount.fulfilled, (state) => {
        state.account = null;
        state.status = 'idle';
      })
      .addCase(removeAccount.rejected, (state, action) => {
        state.error = action.payload;
      });
  },
});

export const { setAccount } = accountsSlice.actions;
export default accountsSlice.reducer;
