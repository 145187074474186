import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getFirestore, doc, updateDoc, onSnapshot, setDoc } from 'firebase/firestore';
import { app } from '../../plugins/firebase';

const db = getFirestore(app);
const foodsRef = (uid) => doc(db, 'foods', uid);

// 初期状態
const initialState = {
  main_seed: [],
  main_pellet: [],
  sub: [],
  snack: [],
  supplement: [],
  loading: false,
  error: null,
};

export function initFoods() {
  return (dispatch) => {
    dispatch(resetFoods());  // 食事データをリセット
  };
}
// 非同期アクション: 食事データの同期
export const syncFoods = createAsyncThunk('foods/syncFoods', async ({ user }, { rejectWithValue, dispatch }) => {
  try {
    const ref = foodsRef(user.uid);
    onSnapshot(ref, (doc) => {
      const foods = doc.data();
      dispatch(setMainSeed(foods.main_seed));
      dispatch(setMainPellet(foods.main_pellet));
      dispatch(setSub(foods.sub));
      dispatch(setSnack(foods.snack));
      dispatch(setSupplement(foods.supplement));
    });
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

// 非同期アクション: 食事データの更新
export const editFood = createAsyncThunk('foods/editFood', async ({ user, food }, { rejectWithValue }) => {
  try {
    const date = new Date();
    const timestamp = date.getTime();
    const docRef = foodsRef(user.uid);

    console.log("Updating or creating Firestore document with:", {
      main_seed: food.main_seed,
      main_pellet: food.main_pellet,
      sub: food.sub,
      snack: food.snack,
      supplement: food.supplement,
      update_date: timestamp,
    });

    // ドキュメントを更新または新規作成
    await setDoc(docRef, {
      main_seed: food.main_seed,
      main_pellet: food.main_pellet,
      sub: food.sub,
      snack: food.snack,
      supplement: food.supplement,
      update_date: timestamp,
    }, { merge: true }); // merge: true で既存のデータにマージ

    return null;
  } catch (error) {
    console.error("Error updating or creating document:", error);
    return rejectWithValue(error.message);
  }
});

// 非同期アクション: 食事データのリセット
export const removeFood = createAsyncThunk('foods/removeFood', async ({ user }, { rejectWithValue }) => {
  try {
    const date = new Date();
    const timestamp = date.getTime();
    await updateDoc(foodsRef(user.uid), {
      main_seed: [],
      main_pellet: [],
      sub: [],
      snack: [],
      supplement: [],
      update_date: timestamp,
    });
    return null;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

// Redux ToolkitのSlice
const foodsSlice = createSlice({
  name: 'foods',
  initialState,
  reducers: {
    setMainSeed(state, action) {
      state.main_seed = action.payload;
    },
    setMainPellet(state, action) {
      state.main_pellet = action.payload;
    },
    setSub(state, action) {
      state.sub = action.payload;
    },
    setSnack(state, action) {
      state.snack = action.payload;
    },
    setSupplement(state, action) {
      state.supplement = action.payload;
    },
    resetFoods(state) {
      state.main_seed = [];
      state.main_pellet = [];
      state.sub = [];
      state.snack = [];
      state.supplement = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(syncFoods.pending, (state) => {
        state.loading = true;
      })
      .addCase(syncFoods.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(syncFoods.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(editFood.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(removeFood.rejected, (state, action) => {
        state.error = action.payload;
      });
  },
});

// ActionsとReducerをエクスポート
export const { setMainSeed, setMainPellet, setSub, setSnack, setSupplement, resetFoods } = foodsSlice.actions;
export default foodsSlice.reducer;
