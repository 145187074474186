import { configureStore } from '@reduxjs/toolkit';
import headersReducer from './modules/headers';
import tabsReducer from './modules/tabs';
import accountsReducer from './modules/accounts';
import birdsReducer from './modules/birds';
import foodsReducer from './modules/foods';
import recordsReducer from './modules/records';
import updatesReducer from './modules/updates';
import questionsReducer from './modules/questions';

const store = configureStore({
  reducer: {
    headers: headersReducer,
    tabs: tabsReducer,
    accounts: accountsReducer,
    birds: birdsReducer,
    foods: foodsReducer,
    records: recordsReducer,
    updates: updatesReducer,
    questions: questionsReducer,
  },
  middleware: (getDefaultMiddleware) => 
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;
