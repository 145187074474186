// 初期状態
const initialState = {
    leftBtnPressed: false,
};

// Reducer
const headersReducer = (state = initialState, action) => {
    switch (action.type) {
        // `SET_NAVIGATION` の処理を削除
        case 'SET_LEFT_BTN_PRESSED':
            return {
                ...state,
                leftBtnPressed: action.payload,
        };
        case 'SET_EVENT_HANDLER':
            return {
                ...state,
                onLeftBtnPressed: action.payload.onLeftBtnPressed,
                onRightBtnPressed: action.payload.onRightBtnPressed,
            };
        case 'SET_EVENT_HANDLER_HOME':
            return {
                ...state,
                onLeftBtnPressed2: action.payload.onLeftBtnPressed2,
                onRightBtnPressed: action.payload.onRightBtnPressed,
            };
        default:
            return state;
    }
};

// Actions
export const setLeftBtnPressed = (pressed) => ({
    type: 'SET_LEFT_BTN_PRESSED',
    payload: pressed,
});

export const setEventHandler = (handlers) => ({
    type: 'SET_EVENT_HANDLER',
    payload: handlers,
});

export const setEventHandlerHome = (handlers) => ({
    type: 'SET_EVENT_HANDLER_HOME',
    payload: handlers,
});

export default headersReducer;
