import React from 'react';
import { ScrollView, View, Text, StyleSheet } from 'react-native';

const TermsScreen = () => {
  return (
    <View style={styles.container}>
      <ScrollView>
        <View style={styles.systtlA}>
          <View style={styles.separator}></View>
          <Text style={styles.termstitle}>利用規約</Text>
          <View style={styles.separator}></View>
          <Text style={styles.texta}>
            この規約は、お客様が、株式会社黒瀬ペットフード（以下「当社」）が提供する「愛鳥ノート とりにてぃ」サービス（以下「本サービス」）をご利用頂く際の取扱いにつき定めるものです。本規約に同意した上で本サービスをご利用ください。
          </Text>
        </View>

        <View style={styles.systtlA}>
          <View style={styles.separator}></View>
          <Text style={styles.termstitle}>第1条（定義）</Text>
          <View style={styles.separator}></View>
          <Text style={styles.texta}>
            本規約上で使用する用語の定義は、次に掲げるとおりとします。
          </Text>
          <Text style={styles.textb}>1.　本サービス</Text>
          <Text style={styles.textc}>当社が運営するサービス及び関連するサービス</Text>
          <Text style={styles.textb}>2.　本サイト</Text>
          <Text style={styles.textc}>本サービスのコンテンツが掲載されたウェブサイト</Text>
          <Text style={styles.textb}>3.　本コンテンツ</Text>
          <Text style={styles.textc}>本サービス上で提供される文字、音、静止画、動画、ソフトウェアプログラム、コード等の総称（投稿情報を含む）</Text>
          <Text style={styles.textb}>4.　利用者</Text>
          <Text style={styles.textc}>当社が運営するサービス及び関連するサービス</Text>
          <Text style={styles.textb}>5.　登録利用者</Text>
          <Text style={styles.textc}>本サイトの利用者登録が完了した方</Text>
          <Text style={styles.textb}>6.　ID</Text>
          <Text style={styles.textc}>本サービスの利用のために登録利用者が固有に持つ文字列</Text>
          <Text style={styles.textb}>7.　パスワード</Text>
          <Text style={styles.textc}>ＩＤに対応して登録利用者が固有に設定する暗号</Text>
          <Text style={styles.textb}>8.　個人情報</Text>
          <Text style={styles.textc}>住所、氏名、職業、電話番号等個人を特定することのできる情報の総称</Text>
          <Text style={styles.textb}>9.　登録情報</Text>
          <Text style={styles.textc}>登録利用者が本サイトにて登録した情報の総称（投稿情報は除く）</Text>
          <Text style={styles.textb}>10.　知的財産</Text>
          <Text style={styles.textc}>
            発明、考案、植物の新品種、意匠、著作物その他の人間の創造的活動により生み出されるもの（発見または解明がされた自然の法則または現象であって、産業上の利用可能性があるものを含む）、商標、商号その他事業活動に用いられる商品または役務を表示するもの及び営業秘密その他の事業活動に有用な技術上または営業上の情報
          </Text>
          <Text style={styles.textb}>11.　知的財産権</Text>
          <Text style={styles.textc}>特許権、実用新案権、育成者権、意匠権、著作権、商標権その他の知的財産に関して法令により定められた権利または法律上保護される利益に係る権利</Text>
        </View>

        <View style={styles.systtlA}>
          <View style={styles.separator}></View>
          <Text style={styles.termstitle}>第2条（本規約への同意）</Text>
          <View style={styles.separator}></View>
          <View style={styles.textRow}>
            <Text style={styles.textb}>（1）</Text>
            <Text style={styles.textc}>
              利用者は、本利用規約に同意頂いた上で、本サービスを利用できるものとします。
            </Text>
          </View>
          <View style={styles.textRow}>
            <Text style={styles.textb}>（2）</Text>
            <Text style={styles.textc}>利用者が、本利用規約に違反した場合、当社は利用者の利用を停止または制限する権利を有します。</Text>
          </View>
          <View style={styles.textRow}>
            <Text style={styles.textb}>（3）</Text>
            <Text style={styles.textc}>利用者は、常に本規約に従って、本サービスを利用するものとします。</Text>
          </View>
          <View style={styles.textRow}>
            <Text style={styles.textb}>（4）</Text>
            <Text style={styles.textc}>本サービスの利用は、個人利用を目的としたものに限られます。</Text>
          </View>
          <View style={styles.textRow}>
            <Text style={styles.textb}>（5）</Text>
            <Text style={styles.textc}>利用者は、当社が定める利用条件に従い、本サービスを利用するものとします。</Text>
          </View>
        </View>

        <View style={styles.systtlA}>
          <View style={styles.separator}></View>
          <Text style={styles.termstitle}>第3条（規約の変更）</Text>
          <View style={styles.separator}></View>
          <View style={styles.textRow}>
            <Text style={styles.textb}>（1）</Text>
            <Text style={styles.textc}>
              当社は、利用者の承諾を得ることなく、いつでも、本規約の内容を改定することができるものとし、利用者はこれを異議なく承諾するものとします。
            </Text>
          </View>
          <View style={styles.textRow}>
            <Text style={styles.textb}>（2）</Text>
            <Text style={styles.textc}>当社は、本規約を改定するときは、その内容について当社所定の方法により利用者に通知します。</Text>
          </View>
          <View style={styles.textRow}>
            <Text style={styles.textb}>（3）</Text>
            <Text style={styles.textc}>本規約の改定の効力は、当社が前項により通知を行った時点から生じるものとします。</Text>
          </View>
          <View style={styles.textRow}>
            <Text style={styles.textb}>（4）</Text>
            <Text style={styles.textc}>利用者は、本規約変更後、本サービスを利用した時点で、変更後の本利用規約に異議なく同意したものとみなされます。</Text>
          </View>
        </View>

        <View style={styles.systtlA}>
          <View style={styles.separator}></View>
          <Text style={styles.termstitle}>第4条（会員の利用手続）</Text>
          <View style={styles.separator}></View>
          <View style={styles.textRow}>
            <Text style={styles.textb}>（1）</Text>
            <Text style={styles.textc}>
              本サービスへの利用を希望する方（以下「登録希望者」）は、本規約に同意した上で、所定の方法で入会の申込を行ってください。
            </Text>
          </View>
          <View style={styles.textRow}>
            <Text style={styles.textb}>（2）</Text>
            <Text style={styles.textc}>
              利用申込をした方は、弊社がその申込を承諾し、ＩＤ登録が完了した時点から登録ユーザとなります。
            </Text>
          </View>
          <View style={styles.textRow}>
            <Text style={styles.textb}>（3）</Text>
            <Text style={styles.textc}>
              弊社は、登録ユーザ向けにメールで連絡事項の告知や広告その他の情報提供を行います。
            </Text>
          </View>
          <View style={styles.textRow}>
            <Text style={styles.textb}>（4）</Text>
            <Text style={styles.textc}>
              弊社は、登録希望者が次の各号のいずれかに該当する場合は、弊社の判断により入会申込を承諾しないことがあります。
            </Text>
          </View>
          <View style={styles.textRow}>
            <Text style={styles.textb}>一</Text>
            <Text style={styles.textc}>
              登録希望者が、過去に本規約または弊社の定めるその他の利用規約等に違反したことを理由として退会処分を受けた者である場合
            </Text>
          </View>
          <View style={styles.textRow}>
            <Text style={styles.textb}>二</Text>
            <Text style={styles.textc}>
              登録希望者が、不正な手段をもって登録を行っていると弊社が判断した場合
            </Text>
          </View>
          <View style={styles.textRow}>
            <Text style={styles.textb}>三</Text>
            <Text style={styles.textc}>
              登録希望者が、弊社の定める方法によらず入会の申込を行った場合
            </Text>
          </View>
          <View style={styles.textRow}>
            <Text style={styles.textb}>四</Text>
            <Text style={styles.textc}>登録希望者が、本人以外の情報を登録している場合</Text>
          </View>
          <View style={styles.textRow}>
            <Text style={styles.textb}>五</Text>
            <Text style={styles.textc}>その他弊社が不適切と判断した場合</Text>
          </View>
        </View>

        <View style={styles.systtlA}>
          <View style={styles.separator}></View>
          <Text style={styles.termstitle}>第5条（アカウントの管理）</Text>
          <View style={styles.separator}></View>
          <View style={styles.textRow}>
            <Text style={styles.textb}>（1）</Text>
            <Text style={styles.textc}>
              利用者は、利用に際して登録した情報（以下、「登録情報」といいます。メールアドレスやID・パスワード等を含みます）について、自己の責任の下、任意に登録、管理するものとします。利用者は、これを第三者に利用させ、または貸与、譲渡、名義変更、売買などをしてはならないものとします。
            </Text>
          </View>
          <View style={styles.textRow}>
            <Text style={styles.textb}>（2）</Text>
            <Text style={styles.textc}>
              当社は、登録情報によって本サービスの利用があった場合、利用登録をおこなった本人が利用したものと扱うことができ、当該利用によって生じた結果ならびにそれに伴う一切の責任については、利用登録を行った本人に帰属するものとします。
            </Text>
          </View>
          <View style={styles.textRow}>
            <Text style={styles.textb}>（3）</Text>
            <Text style={styles.textc}>
              利用者は、登録情報の不正使用によって当社または第三者に損害が生じた場合、当社および第三者に対して、当該損害を賠償するものとします。
            </Text>
          </View>
          <View style={styles.textRow}>
            <Text style={styles.textb}>（4）</Text>
            <Text style={styles.textc}>
              登録情報の管理は、利用者が自己の責任の下で行うものとし、登録情報が不正確または虚偽であったために利用者が被った一切の不利益および損害に関して、当社は責任を負わないものとします。
            </Text>
          </View>
          <View style={styles.textRow}>
            <Text style={styles.textb}>（5）</Text>
            <Text style={styles.textc}>
              登録情報が盗用されまたは第三者に利用されていることが判明した場合、利用者は直ちにその旨を当社に通知するとともに、当社からの指示に従うものとします。
            </Text>
          </View>
        </View>

        <View style={styles.systtlA}>
          <View style={styles.separator}></View>
          <Text style={styles.termstitle}>第6条（個人情報等の取り扱い）</Text>
          <View style={styles.separator}></View>
          <Text style={styles.texta}>
            個人情報及び利用者情報については、当社が別途定める「愛鳥ノート とりにてぃサービスプライバシーポリシー」に則り、適正に取り扱うこととします。
          </Text>
        </View>

        <View style={styles.systtlA}>
          <View style={styles.separator}></View>
          <Text style={styles.termstitle}>第7条（禁止事項）</Text>
          <View style={styles.separator}></View>
          <Text style={styles.texta}>
            本サービスの利用に際し、当社は、利用者に対し、次に掲げる行為を禁止します。
          </Text>
          <View style={styles.textRow}>
            <Text style={styles.textb}>（1）</Text>
            <Text style={styles.textc}>当社または第三者の知的財産権を侵害する行為</Text>
          </View>
          <View style={styles.textRow}>
            <Text style={styles.textb}>（2）</Text>
            <Text style={styles.textc}>
              当社または第三者の名誉・信用を毀損または不当に差別もしくは誹謗中傷する行為
            </Text>
          </View>
          <View style={styles.textRow}>
            <Text style={styles.textb}>（3）</Text>
            <Text style={styles.textc}>当社または第三者の財産を侵害する行為、または侵害する恐れのある行為</Text>
          </View>
          <View style={styles.textRow}>
            <Text style={styles.textb}>（4）</Text>
            <Text style={styles.textc}>当社または第三者に経済的損害を与える行為</Text>
          </View>
          <View style={styles.textRow}>
            <Text style={styles.textb}>（5）</Text>
            <Text style={styles.textc}>当社または第三者に対する脅迫的な行為</Text>
          </View>
          <View style={styles.textRow}>
            <Text style={styles.textb}>（6）</Text>
            <Text style={styles.textc}>コンピューターウィルス、有害なプログラムを仕様またはそれを誘発する行為</Text>
          </View>
          <View style={styles.textRow}>
            <Text style={styles.textb}>（7）</Text>
            <Text style={styles.textc}>本サービス用インフラ設備に対して過度な負担となるストレスをかける行為</Text>
          </View>
          <View style={styles.textRow}>
            <Text style={styles.textb}>（8）</Text>
            <Text style={styles.textc}>当サイトのサーバーやシステム、セキュリティへの攻撃</Text>
          </View>
          <View style={styles.textRow}>
            <Text style={styles.textb}>（9）</Text>
            <Text style={styles.textc}>当社提供のインターフェース以外の方法で当社サービスにアクセスを試みる行為</Text>
          </View>
          <View style={styles.textRow}>
            <Text style={styles.textb}>（10）</Text>
            <Text style={styles.textc}>一人の利用者が、複数の利用者IDを取得する行為</Text>
          </View>
          <View style={styles.textRow}>
            <Text style={styles.textb}>（11）</Text>
            <Text style={styles.textc}>上記の他、当社が不適切と判断する行為</Text>
          </View>
        </View>

        <View style={styles.systtlA}>
          <View style={styles.separator}></View>
          <Text style={styles.termstitle}>第8条（免責）</Text>
          <View style={styles.separator}></View>
          <View style={styles.textRow}>
            <Text style={styles.textb}>（1）</Text>
            <Text style={styles.textc}>
              当社は、本サービスの内容変更、中断、終了によって生じたいかなる損害についても、一切責任を負いません。
            </Text>
          </View>
          <View style={styles.textRow}>
            <Text style={styles.textb}>（2）</Text>
            <Text style={styles.textc}>
              当社は、利用者の本サービスの利用環境について一切関与せず、また一切の責任を負いません。
            </Text>
          </View>
          <View style={styles.textRow}>
            <Text style={styles.textb}>（3）</Text>
            <Text style={styles.textc}>
              当社は、本サービスが利用者の特定の目的に適合すること、期待する機能・商品的価値・正確性・有用性を有すること、利用者による本サービスの利用が利用者に適用のある法令または業界団体の内部規則等に適合すること、および不具合が生じないことについて、何ら保証するものではありません。
            </Text>
          </View>
          <View style={styles.textRow}>
            <Text style={styles.textb}>（4）</Text>
            <Text style={styles.textc}>
              当社は、本サービスが全ての情報端末に対応していることを保証するものではなく、本サービスの利用に供する情報端末のＯＳのバージョンアップ等に伴い、本サービスの動作に不具合が生じる可能性があることにつき、利用者はあらかじめ了承するものとします。当社は、かかる不具合が生じた場合に当社が行うプログラムの修正等により、当該不具合が解消されることを保証するものではありません。
            </Text>
          </View>
          <View style={styles.textRow}>
            <Text style={styles.textb}>（5）</Text>
            <Text style={styles.textc}>
              利用者は、AppStore、GooglePlay等のサービスストアの利用規約および運用方針の変更等に伴い、本サービスの一部又は全部の利用が制限される可能性があることをあらかじめ了承するものとします。
            </Text>
          </View>
          <View style={styles.textRow}>
            <Text style={styles.textb}>（6）</Text>
            <Text style={styles.textc}>
              当社は、本サービスを利用したことにより直接的または間接的に利用者に発生した損害について、一切賠償責任を負いません。
            </Text>
          </View>
          <View style={styles.textRow}>
            <Text style={styles.textb}>（7）</Text>
            <Text style={styles.textc}>
              当社は、利用者その他の第三者に発生した機会逸失、業務の中断その他いかなる損害（間接損害や逸失利益を含みます）に対して、当社が係る損害の可能性を事前に通知されていたとしても、一切の責任を負いません。
            </Text>
          </View>
          <View style={styles.textRow}>
            <Text style={styles.textb}>（8）</Text>
            <Text style={styles.textc}>
              第1項乃至前項の規定は、当社に故意または重過失が存する場合又は契約書が消費者契約法上の消費者に該当する場合には適用しません。
            </Text>
          </View>
          <View style={styles.textRow}>
            <Text style={styles.textb}>（9）</Text>
            <Text style={styles.textc}>
              前項が適用される場合であっても、当社は、過失（重過失を除きます。）による行為によって利用者に生じた損害のうち、特別な事情から生じた損害については、一切賠償する責任を負わないものとします。
            </Text>
          </View>
          <View style={styles.textRow}>
            <Text style={styles.textb}>（10）</Text>
            <Text style={styles.textc}>
              本サービスの利用に関し当社が損害賠償責任を負う場合、当該損害が発生した月に利用者から受領した利用額を限度として賠償責任を負うものとします。
            </Text>
          </View>
          <View style={styles.textRow}>
            <Text style={styles.textb}>（11）</Text>
            <Text style={styles.textc}>
              利用者と他の利用者との間の紛争及びトラブルについて、当社は一切責任を負わないものとします。利用者と他の利用者でトラブルになった場合でも、両者同士の責任で解決するものとし、当社には一切の請求をしないものとします。
            </Text>
          </View>
          <View style={styles.textRow}>
            <Text style={styles.textb}>（12）</Text>
            <Text style={styles.textc}>
              利用者は、本サービスの利用に関連し、他の利用者に損害を与えた場合または第三者との間に紛争を生じた場合、自己の費用と責任において、かかる損害を賠償またはかかる紛争を解決するものとし、当社には一切の迷惑や損害を与えないものとします。
            </Text>
          </View>
          <View style={styles.textRow}>
            <Text style={styles.textb}>（13）</Text>
            <Text style={styles.textc}>
              利用者の行為により、第三者から当社が損害賠償等の請求をされた場合には、利用者の費用（弁護士費用）と責任で、これを解決するものとします。当社が、当該第三者に対して、損害賠償金を支払った場合には、利用者は、当社に対して当該損害賠償金を含む一切の費用（弁護士費用及び逸失利益を含む）を支払うものとします。
            </Text>
          </View>
          <View style={styles.textRow}>
            <Text style={styles.textb}>（14）</Text>
            <Text style={styles.textc}>
              利用者が本サービスの利用に関連して当社に損害を与えた場合、利用者の費用と責任において当社に対して損害を賠償（訴訟費用及び弁護士費用を含む）するものとします。
            </Text>
          </View>
        </View>

        <View style={styles.systtlA}>
          <View style={styles.separator}></View>
          <Text style={styles.termstitle}>第9条（個人情報等の取り扱い）</Text>
          <View style={styles.separator}></View>
          <Text style={styles.texta}>
            利用者は、本サービス上にあらゆる広告が含まれる場合があること、当社またはその提携先があらゆる広告を掲載する場合があることを理解しこれを承諾したものとみなします。本サービス上の広告の形態や範囲は、当社によって随時変更されます。
          </Text>
        </View>

        <View style={styles.systtlA}>
          <View style={styles.separator}></View>
          <Text style={styles.termstitle}>第10条（権利譲渡の禁止）</Text>
          <View style={styles.separator}></View>
          <View style={styles.textRow}>
            <Text style={styles.textb}>（1）</Text>
            <Text style={styles.textc}>
              利用者は、予め当社の書面による承諾がない限り、本規約上の地位および本規約に基づく権利または義務の全部または一部を第三者に譲渡してはならないものとします。
            </Text>
          </View>
          <View style={styles.textRow}>
            <Text style={styles.textb}>（2）</Text>
            <Text style={styles.textc}>
              当社は、本サービスの全部または一部を当社の裁量により第三者に譲渡することができ、その場合、譲渡された権利の範囲内で利用者のアカウントを含む、本サービスに係る利用者の一切の権利が譲渡先に移転するものとします。
            </Text>
          </View>
        </View>

        <View style={styles.systtlA}>
          <View style={styles.separator}></View>
          <Text style={styles.termstitle}>第11条（分離可能性）</Text>
          <View style={styles.separator}></View>
          <Text style={styles.texta}>
            本規約のいずれかの条項又はその一部が、消費者契約法その他の法令等により無効又は執行不能と判断された場合であっても、本規約の残りの規定及び一部が無効又は執行不能と判断された規定の残りの部分は、継続して完全に効力を有するものとします。
          </Text>
        </View>

        <View style={styles.systtlA}>
          <View style={styles.separator}></View>
          <Text style={styles.termstitle}>第12条（当社への連絡方法）</Text>
          <View style={styles.separator}></View>
          <Text style={styles.texta}>
            本サービスに関する利用者の当社へのご連絡・お問い合わせは、本サービスまたは当社が運営するwebサイト内の適宜の場所に設置するお問い合わせフォームからの送信または当社が別途指定する方法により行うものとします。
          </Text>
        </View>

        <View style={styles.systtlA}>
          <View style={styles.separator}></View>
          <Text style={styles.termstitle}>第13条（準拠法、管轄裁判所）</Text>
          <View style={styles.separator}></View>
          <View style={styles.textRow}>
            <Text style={styles.textb}>（1）</Text>
            <Text style={styles.textc}>
              本規約の有効性，解釈及び履行については，日本法に準拠し，日本法に従って解釈されるものとする。
            </Text>
          </View>
          <View style={styles.textRow}>
            <Text style={styles.textb}>（2）</Text>
            <Text style={styles.textc}>
              当社と利用者等との間での論議・訴訟その他一切の紛争については、訴額に応じて、東京簡易裁判所又は東京地方裁判所を専属的合意管轄裁判所とします｡
            </Text>
          </View>
        </View>

        <View style={styles.systtlA}>
          <View style={styles.separator}></View>
          <Text style={styles.termstitle}>2019年10月1日　施行</Text>
        </View>
      </ScrollView>

      <View style={styles.footer}></View>
    </View>
  );
};


const styles = StyleSheet.create({
  container: {
    backgroundColor: '#fff',
    justifyContent: 'center',
    flex: 1,
    padding: 20,
  },
  header: {
    // ヘッダースタイル
  },
  separator: {
    marginTop: 10,
    marginBottom: 14,
    borderBottomColor: '#bbb',
    borderBottomWidth: 1,
  },
  headerinr: {
    fontSize: 24,
    fontWeight: 'bold',
    padding: 3,
    color: '#777',
  },
  systtlA: {
    // 規約セクションのスタイル
  },
  termstitle: {
    fontSize: 20,
    fontWeight: 'bold',
    padding: 3,
    color: '#777',
  },
  texta: {
    textAlign: 'left',
    fontSize: 14,
    color: '#777',
    padding: 14,
  },
  textb: {
    textAlign: 'left',
    fontSize: 14,
    fontWeight: 'bold',
    color: '#777',
    padding: 4,
  },
  textc: {
    textAlign: 'left',
    fontSize: 14,
    color: '#777',
    padding: 4,
    width: '97%',
  },
  textRow: {
    flexDirection: 'row',
    width: '100%',
    padding: 5,
  },
  footer: {
    // フッタースタイル
  },
});

export default TermsScreen;
