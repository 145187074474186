import React, { useState, useEffect, useRef } from 'react';
import { View, Text, ScrollView, Image, TextInput, TouchableOpacity, ActivityIndicator, Platform, ImageBackground, Alert, Dimensions } from 'react-native';
import { Picker } from '@react-native-picker/picker';
import DateTimePicker from '@react-native-community/datetimepicker';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { useDispatch, useSelector } from 'react-redux';
import { setBirds, syncBirds, addBird, editBird, removeBird } from '../store/modules/birds';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { bird_list_table } from '../plugins/table';
import { fileUploadPromise } from '../plugins/firebase-utils';
import { setLeftBtnPressed } from '../store/modules/headers';
import * as ImagePicker from 'expo-image-picker';
import Sortable from 'sortablejs';
import { he, ja } from "date-fns/locale";
import { useNavigation } from '@react-navigation/native';
import { MdPhotoCamera, MdCollections } from 'react-icons/md';
import { maxTime } from 'date-fns/constants';
import { updateDoc, doc, getFirestore, collection, getDocs, query, where } from 'firebase/firestore';
import heic2any from "heic2any";
import { parse, format } from "date-fns";

const screenWidth = Dimensions.get('window').width;
const isMobileScreen = screenWidth < 768; // モバイル端末の判定

const BirdScreen = () => {
  const [windowDimensions, setWindowDimensions] = useState({ width: 0, height: 0 });
  // コンポーネントがマウントされたときに、画面サイズを取得する
  useEffect(() => {
    const { width, height } = Dimensions.get('window');
    setWindowDimensions({ width, height });

    // リサイズ時に画面サイズを更新するリスナーを追加
    const onChange = ({ window }) => {
      setWindowDimensions({ width: window.width, height: window.height });
    };
    Dimensions.addEventListener('change', onChange);

    // クリーンアップ
    return () => {
      Dimensions.removeEventListener('change', onChange);
    };
  }, []);

  const db = getFirestore();
  const birdsRef = collection(db, 'birds');
  const navigation = useNavigation();
  const dispatch = useDispatch();
  const listRef = useRef(null);
  const [bid, setBid] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(true);
  const [isNew, setIsNew] = useState(false);
  const [progress, setProgress] = useState(0);
  const [index, setIndex] = useState(0);
  const [name, setName] = useState('');
  const [photo, setPhoto] = useState(require('../assets/images/noimage_photo.png'));
  const [birthday, setBirthday] = useState('');
  const [age, setAge] = useState('');
  const [gender, setGender] = useState('');
  const [category, setCategory] = useState('インコ');
  const [type, setType] = useState('');
  const [welcome, setWelcome] = useState('');
  const [strong, setStrong] = useState('');
  const [weak, setWeak] = useState('');
  const [favorite, setFavorite] = useState('');
  const [dislikedFood, setDislikedFood] = useState(''); // 嫌いなご飯
  const [favoriteToy, setFavoriteToy] = useState('');  // 好きなおもちゃ
  const [birdPersonality, setBirdPersonality] = useState(''); // 愛鳥の性格
  const [medicalHistory, setMedicalHistory] = useState('');  // 病気や治療の記録
  const [comment, setComment] = useState('');          // コメント
  const [isBirthdayPickerVisible, setIsBirthdayPickerVisible] = useState(false);
  const [isWelcomePickerVisible, setIsWelcomePickerVisible] = useState(false);
  const reduxBirds = useSelector((state) => state.birds.birds || []);  // Reduxの鳥データを取得
  const leftBtnPressed = useSelector((state) => state.headers.leftBtnPressed);

  const [inputFocused, setInputFocused] = useState(false);
  const [ageFocused, setAgeFocused] = useState(false);
  const [genderSelected, setGenderSelected] = useState(false);

  useEffect(() => {
    const title = isNew ? '新規登録' : isEdit ? 'プロフィール編集' : '';
    navigation.setOptions({ headerTitle: title }); // ヘッダーのタイトルを設定
  }, [isNew, isEdit, navigation]);

  useEffect(() => {
    if (reduxBirds.length === 0) {
      // 鳥のデータがない場合は新規追加モードに設定
      setIsNew(true);
      setIsEdit(true);
      initBirdState();
    }
  }, [reduxBirds]);

  useEffect(() => {
    if (leftBtnPressed) {
      showAdd();
      dispatch(setLeftBtnPressed(false));
    }
  }, [leftBtnPressed, dispatch]);

  const showAdd = () => {
    setIsNew(true);
    setIsEdit(true);
    initBirdState();
  };

  const showEdit = () => {
    setIsEdit(true);
  };

  // フォームの初期化
  const initBirdState = () => {
    setBid('');
    setName('');
    setPhoto(require('../assets/images/noimage_photo.png'));
    setBirthday('誕生日');
    setAge('');
    setGender('');
    setCategory('インコ');
    setType('セキセイインコ');
    setWelcome('迎え日');
    setStrong('');
    setWeak('');
    setFavorite('');
    setDislikedFood(''); // 嫌いなご飯
    setFavoriteToy('');  // 好きなおもちゃ
    setBirdPersonality(''); // 愛鳥の性格
    setMedicalHistory('');  // 病気や治療の記録
    setComment('');      // コメント
    setIsEdit(true);
  };

  const saveForm = async () => {
    const auth = getAuth();
    const user = auth.currentUser;
  
    setIsLoading(true);
  
    try {
      let uploadedPhotoUrl = photo;
  
      // 画像が変更されている場合のみアップロード処理を実行
      if (
        photo &&
        (isNew || photo.startsWith('file://') || Platform.OS === 'web') &&
        !photo.includes("firebasestorage.googleapis.com") // 既にFirebaseのURLならアップロードしない
      ) {
        let blob;
        if (Platform.OS === 'web') {
          const response = await fetch(photo);
          blob = await response.blob(); // URLからBlobを作成
        } else {
          const response = await fetch(photo);
          blob = await response.blob(); // モバイル環境でのBlob
        }
        uploadedPhotoUrl = await fileUploadPromise(blob, `birds/${bid || Date.now()}.jpg`, setProgress);
      }
  
      const birdData = {
        bid: bid || Date.now().toString(),
        name,
        photo: uploadedPhotoUrl, // 変更なしの場合はそのままのURL
        birthday,
        age,
        gender,
        category,
        type,
        welcome,
        strong,
        weak,
        favorite,
        dislikedFood,  // 嫌いなご飯
        favoriteToy,   // 好きなおもちゃ
        birdPersonality, // 愛鳥の性格
        medicalHistory, // 病気や治療の記録
        comment,       // コメント
        uid: user.uid,
      };
  
      if (isNew) {
        await dispatch(addBird({ user, bird: birdData }));
      } else {
        await dispatch(editBird({ bird: birdData }));
      }
  
      const updatedQuerySnapshot = await getDocs(
        query(collection(db, 'birds'), where('uid', '==', user.uid))
      );
      const updatedBirds = updatedQuerySnapshot.docs.map(doc => ({
        ...doc.data(),
        bid: doc.id
      }));
      updatedBirds.sort((a, b) => (a.order ?? 0) - (b.order ?? 0));
      dispatch(setBirds(updatedBirds));
  
    } catch (error) {
      console.error('エラー:', error);
      alert('保存に失敗しました');
    }
  
    setIsLoading(false);
    setIsNew(false);
  };

  // カメラを使って写真を撮影（HEIC対応）
  const takePhoto = async () => {
    try {
      let existingInput = document.getElementById("cameraInput");
      if (existingInput) {
        existingInput.remove();
      }

      const input = document.createElement("input");
      input.id = "cameraInput";
      input.type = "file";
      input.accept = "image/*";
      input.capture = "environment"; // バックカメラを指定
      input.style.display = "none";

      input.onchange = async (event) => {
        console.log("onchange 発火");

        const file = event.target.files[0];

        if (file) {
          console.log("選択したファイル:", file);

          let fileType = file.type || "";
          const isHEIC =
            fileType.includes("heic") ||
            fileType.includes("heif") ||
            file.name.toLowerCase().endsWith(".heic") ||
            file.name.toLowerCase().endsWith(".heif");

          let imageUri;

          if (isHEIC) {
            console.log("HEIC/HEIF 画像を検出 - 変換開始");

            try {
              const convertedBlob = await heic2any({
                blob: file,
                toType: "image/jpeg",
                quality: 0.8,
              });

              console.log("変換された JPEG Blob:", convertedBlob);
              imageUri = URL.createObjectURL(convertedBlob);
            } catch (error) {
              console.error("HEIC変換エラー:", error);
              alert("HEIC 画像の変換に失敗しました。");
              return;
            }
          } else {
            imageUri = URL.createObjectURL(file);
          }

          console.log("最終的な画像URL:", imageUri);
          setPhoto(imageUri);
        } else {
          console.warn("ファイルが選択されませんでした");
        }

        input.remove();
      };

      document.body.appendChild(input);
      input.click();
    } catch (error) {
      console.error("カメラ起動エラー:", error);
    }
  };

  // 画像ライブラリから画像を選択（HEIC対応）
  const selectImage = async () => {
    try {
      let existingInput = document.getElementById("imageInput");
      if (existingInput) {
        existingInput.remove();
      }

      const input = document.createElement("input");
      input.id = "imageInput";
      input.type = "file";
      input.accept = "image/*";
      input.style.display = "none";

      input.onchange = async (event) => {
        console.log("onchange 発火");

        const file = event.target.files[0];

        if (file) {
          console.log("選択したファイル:", file);

          let fileType = file.type || "";
          const isHEIC =
            fileType.includes("heic") ||
            fileType.includes("heif") ||
            file.name.toLowerCase().endsWith(".heic") ||
            file.name.toLowerCase().endsWith(".heif");

          let imageUri;

          if (isHEIC) {
            console.log("HEIC/HEIF 画像を検出 - 変換開始");

            try {
              const convertedBlob = await heic2any({
                blob: file,
                toType: "image/jpeg",
                quality: 0.8,
              });

              console.log("変換された JPEG Blob:", convertedBlob);
              imageUri = URL.createObjectURL(convertedBlob);
            } catch (error) {
              console.error("HEIC変換エラー:", error);
              alert("HEIC 画像の変換に失敗しました。");
              return;
            }
          } else {
            imageUri = URL.createObjectURL(file);
          }

          console.log("最終的な画像URL:", imageUri);
          setPhoto(imageUri);
        } else {
          console.warn("ファイルが選択されませんでした");
        }

        input.value = "";
        input.remove();
      };

      document.body.appendChild(input);
      setTimeout(() => input.click(), 0);
    } catch (error) {
      console.error("画像選択エラー:", error);
    }
  };


  const hideForm = () => {
    if (isNew) {
      // 新規登録の場合、編集状態を終了して新規フラグをリセット
      setIsNew(false);
      initBirdState(); // フォームを初期化
    }
  };

  const deleteBird = async () => {
    // 確認アラートを表示
    const confirmed = Platform.OS === 'web'
      ? window.confirm("愛鳥の登録を削除しますか。")
      : await showCustomDialog("愛鳥の登録を削除しますか。");

    if (!confirmed) return; // ユーザーがキャンセルした場合、処理を終了

    // ローディング開始
    setIsLoading(true);
  
    try {
      // user を取得
      const auth = getAuth();
      const user = auth.currentUser;
      if (!user) {
        alert("ログインしていません");
        return;
      }
  
      // 「今フォームで選択されている bid と一致する鳥」を取得
      const currentBird = birds.find((bird) => bird.bid === bid);
      if (!currentBird) {
        alert("削除対象の鳥が見つかりません");
        return;
      }
  
      // bird を削除
      await dispatch(removeBird({ bird: currentBird }));
  
      // Firestore から再取得 → Redux へ反映
      const updatedQuerySnapshot = await getDocs(
        query(collection(db, 'birds'), where('uid', '==', user.uid))
      );
      const updatedBirds = updatedQuerySnapshot.docs.map(doc => ({
        ...doc.data(),
        bid: doc.id
      }));
      updatedBirds.sort((a, b) => (a.order ?? 0) - (b.order ?? 0));
      dispatch(setBirds(updatedBirds));
  
      alert("削除が完了しました");
    } catch (error) {
      console.error("削除エラー:", error);
      alert("削除に失敗しました");
    } finally {
      // ローディング終了
      setIsLoading(false);
    }
  };

  // モバイル用にカスタムダイアログを表示
  const showCustomDialog = async (message) => {
    return new Promise((resolve) => {
      Alert.alert(
        "確認",
        message,
        [
          { text: "キャンセル", onPress: () => resolve(false), style: "cancel" },
          { text: "OK", onPress: () => resolve(true) },
        ],
        { cancelable: true }
      );
    });
  };

  const birds = useSelector((state) => {
    const unsortedBirds = state.birds.birds || [];
    return [...unsortedBirds].sort((a, b) => {
      // `order` が存在しない場合は最後に配置
      if (a.order === undefined && b.order === undefined) return 0;
      if (a.order === undefined) return 1;
      if (b.order === undefined) return -1;
      // `order` に基づいてソート
      return a.order - b.order;
    });
  });
  const filteredBirds = bird_list_table.filter(bird => bird.type === category);
  const sortableRef = useRef(null);
  const updateForm = (bird) => {
    setBid(bird.bid || "");
    setName(bird.name || "");
    setPhoto(bird.photo || require('../assets/images/noimage_photo.png'));
    setBirthday(bird.birthday || "誕生日");
    setAge(bird.age || "");
    setGender(bird.gender || "オス");
    setCategory(bird.category || "インコ");
    setType(bird.type || "セキセイインコ");
    setWelcome(bird.welcome || "迎え日");
    setStrong(bird.strong || "");
    setWeak(bird.weak || "");
    setFavorite(bird.favorite || "");
    setDislikedFood(bird.dislikedFood || "");
    setFavoriteToy(bird.favoriteToy || "");
    setBirdPersonality(bird.birdPersonality || "");
    setMedicalHistory(bird.medicalHistory || "");
    setComment(bird.comment || "");
  };
  useEffect(() => {
    if (listRef.current) {
      if (sortableRef.current) {
        sortableRef.current.destroy();
      }
      sortableRef.current = new Sortable(listRef.current, {
        animation: 150,
        onEnd: async (evt) => {
          const reorderedBirds = [...birds];
          const [movedItem] = reorderedBirds.splice(evt.oldIndex, 1);
          reorderedBirds.splice(evt.newIndex, 0, movedItem);

          // 新しい順序をFirestoreに保存
          const updatedBirds = reorderedBirds.map((bird, index) => {
            return { ...bird, order: index }; // 新しいオブジェクトを作成してorderを更新
          });

          try {
            const db = getFirestore();
            await Promise.all(
              updatedBirds.map((bird) =>
                updateDoc(doc(db, "birds", bird.bid), { order: bird.order })
              )
            );
          } catch (error) {
            console.error("Error updating order in Firestore:", error);
          }

          // Reduxの状態を更新
          dispatch(setBirds(updatedBirds));

          // 一番上のデータをフォームに反映
          if (updatedBirds.length > 0) {
            updateForm(updatedBirds[0]); // フォームを更新
          }
        },
      });
    }

    return () => {
      if (sortableRef.current) {
        sortableRef.current.destroy();
        sortableRef.current = null;
      }
    };
  }, [birds, dispatch]);

  useEffect(() => {
    if (reduxBirds.length > 0) {
      const firstBird = reduxBirds.find(bird => bird.order === 0) || reduxBirds[0]; // デフォルトで配列の最初の要素
      setBid(firstBird.bid || "");
      setName(firstBird.name || "");
      setPhoto(firstBird.photo || require('../assets/images/noimage_photo.png'));
      setBirthday(firstBird.birthday || "誕生日");
      setAge(firstBird.age || "");
      setGender(firstBird.gender || "");
      setCategory(firstBird.category || "");
      setType(firstBird.type || "");
      setWelcome(firstBird.welcome || "迎え日");
      setStrong(firstBird.strong || "");
      setWeak(firstBird.weak || "");
      setFavorite(firstBird.favorite || "");
      setDislikedFood(firstBird.dislikedFood || "");
      setFavoriteToy(firstBird.favoriteToy || "");
      setBirdPersonality(firstBird.birdPersonality || "");
      setMedicalHistory(firstBird.medicalHistory || "");
      setComment(firstBird.comment || "");
    }
  }, [reduxBirds]);

  const handleEditBird = (bird) => {
    setIsNew(false); // 新規登録モードをオフ
    setIsEdit(true); // 編集モードをオン
    updateForm(bird); // フォームに選択した鳥のデータを反映
  };

  useEffect(() => {
    if (!birthday || !birthday.match(/^\d{4}年\d{2}月\d{2}日$/)) {
      setAge(''); // 誕生日が無効な場合は年齢をクリア
      return;
    }

    const today = new Date();
    const birthDate = parse(birthday, "yyyy年MM月dd日", new Date()); // 文字列をDateに変換
    let calculatedAge = today.getFullYear() - birthDate.getFullYear();

    // 誕生日がまだ来ていなければ年齢を1引く
    const hasBirthdayPassed =
      today.getMonth() > birthDate.getMonth() ||
      (today.getMonth() === birthDate.getMonth() && today.getDate() >= birthDate.getDate());

    if (!hasBirthdayPassed) {
      calculatedAge -= 1;
    }

    setAge(calculatedAge.toString()); // 計算した年齢をセット
  }, [birthday]);

  if (isLoading) {
    return (
      <View style={styles.loadingView}>
        <Text style={styles.loadingText}>送信中... ({Math.floor(progress)} % 完了)</Text>
        <ActivityIndicator size="large" color="#cd2829" />
      </View>
    );
  }
  return (
    <View style={styles.container}>
      <KeyboardAwareScrollView
        extraScrollHeight={28}
        contentContainerStyle={{ flexGrow: 1 }}
        nestedScrollEnabled={true}
        showsVerticalScrollIndicator={false}
      >
        {!isNew && (
          <View  style={styles.listContainerBack}>
            <View ref={listRef} style={styles.listContainer}>
              {birds.map((bird, idx) => (
                <TouchableOpacity
                  style={styles.listbirdbox}
                  key={bird.bid || idx}
                >
                  <Image
                    source={require('../assets/images/birdlist.png')}
                    style={styles.birdlistIcon}
                  />
                  <View
                    style={[
                      styles.listItem,
                      bird.bid === bid && styles.listItemEditing // 編集中のアイテムに赤背景を適用
                    ]}
                  >
                    <View style={styles.listbirdblock}>
                      {/* 鳥の画像 */}
                      <Image
                        source={{ uri: bird.photo }}
                        style={styles.image}
                        resizeMode="cover"
                      />
                      {/* 鳥の名前 */}
                      <Text style={styles.text}>
                        {bird.name.length > 16 ? bird.name.slice(0, 16) + "..." : bird.name}
                      </Text>
                    </View>
                    {/* 編集ボタン */}
                    <TouchableOpacity
                      style={styles.editButton}
                      onPress={() => handleEditBird(bird)}
                      onStartShouldSetResponder={() => true} // ドラッグ対象外に設定
                    >
                      <Image
                        source={require('../assets/images/bridedit_icon.png')}
                        style={styles.brideditIcon}
                      />
                      <Text style={styles.editButtonText}>編集</Text>
                    </TouchableOpacity>
                  </View>
                </TouchableOpacity>
              ))}
            </View>
            <TouchableOpacity
              style={styles.addBirdButton}
              onPress={showAdd} // 新規登録フォームを表示
            >
              <View style={styles.addBirdButtonText}>
                <Image
                  source={require('../assets/images/plus_icon.png')}
                  style={styles.plusIcon}
                />
                <Text style={[styles.addText]}>
                  追加する
                </Text>
              </View>
            </TouchableOpacity>
          </View>
        )}
          <View style={styles.Container20}>
            <View style={styles.Container}>
            <ImageBackground
              source={require('../assets/images/swiper_back.png')} // 修正: require を使用
              style={styles.photo_space_back} // スタイルを追加
            >
                <View style={styles.photo_space}>
                  <View>
                    <View style={[styles.editTitleblock]}>
                      <Image
                        source={require('../assets/images/edit_icon.png')}
                        style={styles.editingIcon}
                      />
                      <Text style={[styles.title]}>
                        編集中
                      </Text>
                    </View>
                    <View style={styles.photo_space_middle}>
                      <View style={styles.photo_space_circle}>
                        <Image
                          source={{ uri: photo }}
                          style={styles.photo_space_image}
                        />
                        <TouchableOpacity style={styles.btn_add_image} onPress={selectImage}>
                          {/* コレクションアイコン */}
                          <MdCollections size={20} color="#FFF" />
                        </TouchableOpacity>
                        <TouchableOpacity style={styles.btn_add_photo} onPress={takePhoto}>
                          {/* カメラアイコン */}
                          <MdPhotoCamera size={20} color="#FFF" />
                        </TouchableOpacity>
                      </View>
                    </View>
                    <TextInput
                      style={[
                        styles.name_edit,
                        { color: inputFocused || name ? '#555555' : '#bfbfbf' } // 条件に応じた色変更
                      ]}
                      placeholder="名前"
                      placeholderTextColor="#bfbfbf"
                      value={name}
                      onFocus={() => setInputFocused(true)} // フォーカス時の状態変更
                      onBlur={() => setInputFocused(false)} // フォーカス解除時の状態変更
                      onChangeText={(input) => setName(input)} // 入力値の反映
                    />
                  </View>
                </View>
              </ImageBackground>
              <View style={styles.mainContainer}>
                <Text style={styles.mainContainerTitle}>プロフィール</Text>
                {/* 生年月日フィールド */}
                <View style={styles.list_item}>
                  <Text style={styles.list_item_header}>生年月日</Text>
                  {Platform.OS === 'web' ? (
                    <>
                    <DatePicker
                      selected={
                        birthday && birthday.match(/^\d{4}年\d{2}月\d{2}日$/) // "YYYY年MM月DD日" の形式か確認
                          ? parse(birthday, "yyyy年MM月dd日", new Date()) // 変換
                          : null // 無効なら空欄
                      }
                      onChange={(date) => {
                        if (date) {
                          setBirthday(format(date, "yyyy年MM月dd日", { locale: ja })); // "YYYY年MM月DD日" 形式で保存
                        } else {
                          setBirthday(""); // 削除時は空欄
                        }
                      }}
                      dateFormat="yyyy/MM/dd"
                      placeholderText="誕生日を選択"
                      className="custom-datepicker"
                      popperClassName="custom-popper"
                      popperPlacement="bottom-start"
                      portalId="datepicker-portal"
                      locale={ja} // 日本語ロケールを設定
                      showYearDropdown
                      dropdownMode="select"
                    />
                    <style>{`
                      .custom-datepicker::placeholder {
                        font-size: 20px;
                      }
                      .custom-datepicker {
                        font-size: 20px;
                        border: none;
                        outline: none;
                        background-color: transparent;
                        padding: 5px;
                      }
                      .react-datepicker__triangle {
                        display: none !important;
                      }
                    `}</style>
                  </>
                  ) : (
                    <>
                      <TouchableOpacity onPress={() => setIsBirthdayPickerVisible(true)}>
                        <Text style={birthday ? { color: 'black' } : { color: '#ccc' }}>
                          {birthday ? new Date(birthday).toLocaleDateString() : '誕生日を選択'}
                        </Text>
                      </TouchableOpacity>
                      {isBirthdayPickerVisible && (
                        <DateTimePicker
                          value={birthday ? new Date(birthday) : new Date()}
                          mode="date"
                          display="default"
                          onChange={(event, selectedDate) => {
                            setIsBirthdayPickerVisible(false);
                            if (selectedDate) {
                              setBirthday(selectedDate.toISOString());
                            }
                          }}
                        />
                      )}
                    </>
                  )}
                </View>

                <View style={styles.row}>
                  {/* 年齢フィールド */}
                  <View style={styles.list_item2}>
                    <Text style={styles.list_item_header}>年齢</Text>
                    <View style={styles.list_item_row}>
                    <TextInput
                      style={[
                        styles.list_item_edit_picker,
                        { color: ageFocused || age ? '#555555' : '#bfbfbf' }
                      ]}
                      placeholder="0才"
                      placeholderTextColor="#bfbfbf"
                      value={age}
                      onFocus={() => setAgeFocused(true)}
                      onBlur={() => setAgeFocused(false)}
                      onChangeText={(input) => setAge(input)}
                      editable={false}
                      pointerEvents="none"
                    />
                    </View>
                  </View>

                  {/* 性別フィールド */}
                  <View style={styles.list_item2}>
                    <Text style={styles.list_item_header}>性別</Text>
                    <Picker
                      selectedValue={gender}
                      style={[
                        styles.list_item_edit_picker,
                        { color: genderSelected || gender ? '#555555' : '#bfbfbf' }, // 色の変更
                      ]}
                      onValueChange={(itemValue) => {
                        setGender(itemValue);
                        setGenderSelected(itemValue !== ''); // プレースホルダー選択時はfalse
                      }}
                    >
                      <Picker.Item label="不明" value="" style={{ color: '#bfbfbf' }} />
                      <Picker.Item label="男の子" value="オス" />
                      <Picker.Item label="女の子" value="メス" />
                    </Picker>
                  </View>
                </View>

                {/* 鳥種カテゴリフィールド */}
                <View style={styles.list_item}>
                  <Text style={styles.list_item_header}>鳥種カテゴリー</Text>
                  <Picker
                    selectedValue={category}
                    style={[
                      styles.list_item_edit_picker,
                      category ? styles.inputActive : styles.inputPlaceholder, // 条件に応じたスタイルを適用
                    ]}
                    enabled={isEdit}
                    onValueChange={(itemValue) => setCategory(itemValue)}
                  >
                    <Picker.Item label="選択してください" value="" />
                    <Picker.Item label="インコ" value="インコ" />
                    <Picker.Item label="オウム" value="オウム" />
                    <Picker.Item label="ローリーロリキート" value="ローリーロリキート" />
                    <Picker.Item label="フィンチ" value="フィンチ" />
                    <Picker.Item label="他" value="他" />
                  </Picker>
                </View>

                {/* 鳥種フィールド */}
                <View style={styles.list_item}>
                  <Text style={styles.list_item_header}>鳥種</Text>
                  <Picker
                    selectedValue={type}
                    style={[
                      styles.list_item_edit_picker,
                      type ? styles.inputActive : styles.inputPlaceholder, // 条件に応じたスタイルを適用
                    ]}
                    enabled={isEdit}
                    onValueChange={(itemValue) => setType(itemValue)}
                  >
                    {filteredBirds.map((bird, index) => (
                      <Picker.Item key={index} label={bird.item} value={bird.item} />
                    ))}
                  </Picker>
                </View>

                {/* お迎え日フィールド */}
                <View style={styles.list_item}>
                  <Text style={styles.list_item_header}>お迎え日</Text>
                  {Platform.OS === 'web' ? (
                    <>
                      <DatePicker
                        selected={
                          welcome && welcome.match(/^\d{4}年\d{2}月\d{2}日$/) // "YYYY年MM月DD日" の形式か確認
                            ? parse(welcome, "yyyy年MM月dd日", new Date()) // 変換
                            : null // 無効なら空欄
                        }
                        onChange={(date) => {
                          if (date) {
                            setWelcome(format(date, "yyyy年MM月dd日", { locale: ja })); // "YYYY年MM月DD日" 形式で保存
                          } else {
                            setWelcome(""); // 削除時は空欄
                          }
                        }}
                        dateFormat="yyyy/MM/dd"
                        placeholderText="お迎え日を選択"
                        className="custom-datepicker"
                        popperClassName="custom-popper"
                        popperPlacement="bottom-start"
                        portalId="datepicker-portal"
                        locale={ja}
                        showYearDropdown
                        dropdownMode="select"
                      />
                      <style>{`
                        .custom-datepicker::placeholder {
                          font-size: 20px;
                        }
                        .custom-datepicker {
                          font-size: 20px;
                          border: none;
                          outline: none;
                          background-color: transparent;
                          padding: 5px;
                        }
                        .react-datepicker__triangle {
                          display: none !important;
                        }
                      `}</style>
                    </>
                  ) : (
                    <>
                      <TouchableOpacity onPress={() => setIsWelcomePickerVisible(true)}>
                        <Text style={welcome ? { color: 'black' } : { color: '#ccc' }}>
                          {welcome ? new Date(welcome).toLocaleDateString() : 'お迎え日を選択'}
                        </Text>
                      </TouchableOpacity>
                      {isWelcomePickerVisible && (
                        <DateTimePicker
                          value={welcome ? new Date(welcome) : new Date()}
                          mode="date"
                          display="default"
                          onChange={(event, selectedDate) => {
                            setIsWelcomePickerVisible(false);
                            if (selectedDate) {
                              setWelcome(selectedDate.toISOString());
                            }
                          }}
                        />
                      )}
                    </>
                  )}
                </View>

                {/* 得意なことフィールド */}
                <View style={styles.list_item}>
                  <Text style={styles.list_item_header}>得意なこと</Text>
                  <TextInput
                    style={[
                      styles.list_item_data,
                      strong ? styles.inputActive : styles.inputPlaceholder, // 条件に応じたスタイルを適用
                    ]}
                    placeholder="入力してください"
                    value={strong}
                    onChangeText={input => setStrong(input)}
                    editable={isEdit}
                  />
                </View>

                {/* 苦手なことフィールド
                <View style={styles.list_item}>
                  <Text style={styles.list_item_header}>苦手なこと</Text>
                  <TextInput
                    style={[
                      styles.list_item_data,
                      weak ? styles.inputActive : styles.inputPlaceholder, // 条件に応じたスタイルを適用
                    ]}
                    placeholder="入力してください"
                    value={weak}
                    onChangeText={input => setWeak(input)}
                    editable={isEdit}
                  />
                </View>
                 */}

                {/* 好きなごはんフィールド */}
                <View style={styles.list_item}>
                  <Text style={styles.list_item_header}>好きなごはん</Text>
                  <TextInput
                    style={[
                      styles.list_item_data,
                      favorite ? styles.inputActive : styles.inputPlaceholder, // 条件に応じたスタイルを適用
                    ]}
                    placeholder="入力してください"
                    value={favorite}
                    onChangeText={input => setFavorite(input)}
                    editable={isEdit}
                  />
                </View>

                {/* 苦手なことフィールド
                <View style={styles.list_item}>
                  <Text style={styles.list_item_header}>嫌いなご飯</Text>
                  <TextInput
                    style={[
                      styles.list_item_data,
                      dislikedFood ? styles.inputActive : styles.inputPlaceholder, // 条件に応じたスタイルを適用
                    ]}
                    placeholder="入力してください"
                    value={dislikedFood}
                    onChangeText={input => setDislikedFood(input)}
                    editable={isEdit}
                  />
                </View>
                 */}

                <View style={styles.list_item}>
                  <Text style={styles.list_item_header}>好きなおもちゃ</Text>
                  <TextInput
                    style={[
                      styles.list_item_data,
                      favoriteToy ? styles.inputActive : styles.inputPlaceholder, // 条件に応じたスタイルを適用
                    ]}
                    placeholder="入力してください"
                    value={favoriteToy}
                    onChangeText={input => setFavoriteToy(input)}
                    editable={isEdit}
                  />
                </View>

                <View style={styles.list_item}>
                  <Text style={styles.list_item_header}>愛鳥の性格</Text>
                  <TextInput
                    style={[
                      styles.list_item_data,
                      birdPersonality ? styles.inputActive : styles.inputPlaceholder, // 条件に応じたスタイルを適用
                    ]}
                    placeholder="入力してください"
                    value={birdPersonality}
                    onChangeText={input => setBirdPersonality(input)}
                    editable={isEdit}
                  />
                </View>

                <View style={styles.list_item}>
                  <Text style={styles.list_item_header}>病気や治療の記録</Text>
                  <TextInput
                    style={[
                      styles.list_item_data, { height: 200 },
                      medicalHistory ? styles.inputActive : styles.inputPlaceholder, // 条件に応じたスタイルを適用
                    ]}
                    placeholder="入力してください"
                    value={medicalHistory}
                    onChangeText={input => setMedicalHistory(input)}
                    editable={isEdit}
                    multiline
                  />
                </View>

                <View style={styles.list_item}>
                  <Text style={styles.list_item_header}>コメント</Text>
                  <TextInput
                    style={[
                      styles.list_item_data, { height: 200 },
                      comment ? styles.inputActive : styles.inputPlaceholder, // 条件に応じたスタイルを適用
                    ]}
                    placeholder="入力してください"
                    value={comment}
                    onChangeText={input => setComment(input)}
                    editable={isEdit}
                    multiline
                  />
                </View>

                <View style={styles.control}>
                  {/* 保存または登録ボタン */}
                  <TouchableOpacity style={styles.control_btn_save} onPress={saveForm}>
                    <Text style={styles.control_btn_save_text}>
                      {isNew ? '登録' : '保存'}
                    </Text>
                  </TouchableOpacity>

                  {/* キャンセルボタン（新規ページの時だけ表示） */}
                  {isNew && (
                    <TouchableOpacity style={styles.control_btn_cancel} onPress={hideForm}>
                      <Text style={styles.control_btn_cancel_text}>キャンセル</Text>
                    </TouchableOpacity>
                  )}

                  {/* 削除ボタン（編集ページの時だけ表示） */}
                  {!isNew && isEdit && (
                    <TouchableOpacity style={styles.control_btn_cancel} onPress={deleteBird}>
                      <Text style={styles.control_btn_cancel_text}>削 除</Text>
                    </TouchableOpacity>
                  )}
                </View>
            </View>
          </View>
        </View>
      </KeyboardAwareScrollView>
    </View>
  );
};

const styles = {
  container: {
    flex: 1,
    padding: 0,
    position: 'relative',
  },
  photo_space: {
    width: '100%',
    height: isMobileScreen ? 425 : 500,
    padding: 30,
  },
  no_bird: {
    flex: 1,
    justifyContent: 'center',
    alignSelf: 'center',
  },
  no_bird_message: {
    alignSelf: 'center',
    fontSize: 16,
    color: '#cd2829',
  },
  editTitleblock: {
    flexDirection: 'row',
    marginBottom: 13,
    marginRight: 'auto',
    textAlign: 'center',
    alignSelf: 'center',
    backgroundColor: '#f3d2d2', // 背景色
    paddingHorizontal: 15, // 左右のパディング
    borderRadius: 15, // 角丸
    alignItems: 'center',
  },
  editingIcon: {
    width: 15,
    height: 15,
    resizeMode: 'contain',
    marginRight: 5,
  },
  title: {
    fontSize: 21,
    color: '#555555',
  },
  photo_space_middle: {
    justifyContent: 'center',
    alignSelf: 'center',
    flexDirection: 'row',
  },
  photo_space_circle: {
    backgroundColor: '#fff',
    borderRadius: '50%',
    width: 270,
    height: 270,
    marginBottom: 10,
  },
  photo_space_image: {
    borderRadius: '50%',
    width: 270,
    height: 270,
  },
  btn_add_photo: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    padding: 10,
    backgroundColor: '#cd2829',
    borderRadius: 20,
  },
  btn_add_image: {
    position: 'absolute',
    left: 0,
    bottom: 0,
    padding: 10,
    backgroundColor: '#5ac759',
    borderRadius: 20,
  },
  list_item: {
    paddingTop: 20,
    paddingBottom: 15,
    width: '100%',
    borderBottomColor: '#ddd',
    borderBottomWidth: 1,
  },
  list_item2: {
    paddingTop: 20,
    paddingBottom: 15,
    width: '49%',
    height: '100%',
    borderBottomColor: '#ddd',
    borderBottomWidth: 1,
  },
  list_item_header: {
    color: '#8d8d8d',
    fontSize: isMobileScreen ? 18 : 20,
    width: '100%',
    paddingTop: 3,
    marginBottom: 20,
  },
  list_item_data: {
    color: 'black',
    width: '100%',
    marginRight: 10,
    padding: 3,
    fontSize: isMobileScreen ? 20 : 25,
    width: '100%',
    height: 35,
  },
  list_item_edit_picker: {
    padding: 3,
    fontSize: isMobileScreen ? 20 : 25,
    width: '100%',
    height: 35,
    borderWidth: 0,
    position: 'relative',
  },
  control: {
    marginTop: 20,
    flexDirection: 'row',
    alignSelf: 'center',
    marginBottom: 20,
  },
  control_btn_edit: {
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#cd2829',
    borderRadius: 10,
    padding: 10,
    paddingLeft: 20,
    paddingRight: 20,
  },
  control_btn_edit_text: {
    color: '#cd2829',
    fontSize: 14,
  },
  control_btn_save: {
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#cd2829',
    borderRadius: 10,
    padding: 10,
    paddingLeft: 20,
    paddingRight: 20,
  },
  control_btn_save_text: {
    color: '#cd2829',
    fontSize: 14,
  },
  control_btn_cancel: {
    marginLeft: 20,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: 'gray',
    borderRadius: 10,
    padding: 10,
    paddingLeft: 20,
    paddingRight: 20,
  },
  control_btn_cancel_text: {
    color: 'gray',
    fontSize: 14,
  },
  name: {
    fontSize: 18,
    fontWeight: 'bold',
    color: '#555555',
    textAlign: 'center',
  },
  listContainerBack: {
    backgroundColor: '#ffffff',
  },
  listContainer: {
    padding: 10,
  },
  listbirdbox: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    flex: 1,
    marginBottom: 10,
  },
  birdlistIcon: {
    resizeMode: 'contain',
    width: isMobileScreen ? '6%' : '3%',
    marginRight: '2%',
    height: 70,
  },
  listItem: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#f9f9f9',
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderRadius: 8,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.2,
    shadowRadius: 2,
    width: isMobileScreen ? '92%' : '95%',
  },
  listbirdblock: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
  },
  listItemEditing: {
    backgroundColor: '#f5d4d4',
  },
  image: {
    width: 60,
    height: 60,
    borderRadius: 30,
    marginRight: 10,
  },
  text: {
    fontSize: isMobileScreen ? 18 : 20,
    color: '#555555'
  },
  Container20:{
    marginHorizontal: 20,
  },
  Container:{
    width: '100%',
    maxWidth: 800,
    marginHorizontal: 'auto',
    backgroundColor: '#fff',
    borderRadius: 15,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 4 },
    shadowOpacity: 0.2,
    shadowRadius: 10,
    elevation: 5,
    marginTop: 35,
    marginBottom: 35,
  },
  mainContainer:{
    paddingHorizontal: isMobileScreen ? 20 : 50,
    paddingBottom: isMobileScreen ? 0 : 80,
  },
  mainContainerTitle:{
    marginHorizontal: 'auto',
    marginBottom: isMobileScreen ? 10 : 50,
    color: '#555555',
    fontSize: 24,
  },
  name_edit:{
    textAlign: 'center',
    fontSize: isMobileScreen ? 21 : 30,
    maxWidth: 270,
    padding: 5,
    marginTop: isMobileScreen ? 0 : 10,
    marginHorizontal: 'auto',
  },
  row: {
    flexDirection: 'row', // 横並び
    justifyContent: 'space-between', // 要素間のスペースを均等に
    alignItems: 'center', // 垂直方向の位置を中央揃え
    marginBottom: 15,
  },
  list_item_row: {
    flexDirection: 'row', // 横並び
    justifyContent: 'space-between', // 要素間のスペースを均等に
    alignItems: 'center', // 垂直方向の位置を中央揃え
  },
  list_item_age: {
  },
  btn_add_photo: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    padding: 10,
    backgroundColor: '#cd2829',
    borderRadius: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  btn_add_image: {
    position: 'absolute',
    left: 0,
    bottom: 0,
    padding: 10,
    backgroundColor: '#5ac759',
    borderRadius: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  photo_space_back: {
    flex: 1,
    resizeMode: 'cover',
  },
  addBirdButton: {
    maxWidth: 200,
    marginHorizontal: 'auto',
    marginTop: isMobileScreen ? 10 : 30,
    marginBottom: isMobileScreen ? 20 : 40,
    flexDirection: 'row',
    textAlign: 'center',
    alignSelf: 'center',
    alignItems: 'center',
  },
  addBirdButtonText: {
    flexDirection: 'row',
    textAlign: 'center',
    alignSelf: 'center',
    alignItems: 'center',
  },
  plusIcon: {
    width: 30,
    height: 30,
    resizeMode: 'contain',
    marginRight: 10,
  },
  addText: {
    fontSize: isMobileScreen ? 18 : 24,
    color: '#555555',
  },
  inputActive: {
    color: '#555555',
  },
  inputPlaceholder: {
    color: '#bfbfbf',
  },
  editButton: {
    flexDirection: 'row',
    textAlign: 'center',
    alignSelf: 'center',
    alignItems: 'center',
  },
  editButtonText: {
    color: '#555555',
    fontSize: 18,
  },
  brideditIcon: {
    width: 25,
    height: 25,
    resizeMode: 'contain',
    marginRight: 10,
  },
  loadingView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%'
  },
  loadingText: {
    textAlign: 'center',
    color: '#555555',
    marginBottom: 10,
  }
};

export default BirdScreen;
