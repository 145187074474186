import React, { useState, useEffect } from 'react';
import {
  View,
  Text,
  TouchableOpacity,
  StyleSheet,
  Image,
  ImageBackground,
  ScrollView,
  Dimensions,
  Platform,
  useWindowDimensions
} from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { useNavigate } from 'react-router-dom';

const PremiumScreen = () => {
  const navigation = useNavigation(); // モバイル用
  const webNavigate = useNavigate();  // Web用

  const [windowDimensions, setWindowDimensions] = useState({ width: 0, height: 0 });
  const { width } = useWindowDimensions();
  const isMobileScreen = width < 768;

  useEffect(() => {
    const { width, height } = Dimensions.get('window');
    setWindowDimensions({ width, height });
    const onChange = ({ window }) => {
      setWindowDimensions({ width: window.width, height: window.height });
    };
    Dimensions.addEventListener('change', onChange);
    return () => {
      Dimensions.removeEventListener('change', onChange);
    };
  }, []);

  const handleSubscribePress = () => {
    if (Platform.OS === 'web') {
      window.location.href = '/payment';
    } else {
      navigation.navigate('Payment');
    }
  };

  const handleSubscribePress2 = () => {
    if (Platform.OS === 'web') {
      webNavigate('/home');
    } else {
      navigation.navigate('Home');
    }
  };

  return (
    <ScrollView>
      <View style={styles.container}>
        <ImageBackground
          source={{ uri: 'https://www.kurose-pf.co.jp/wp/wp-content/themes/kurose-theme/images/premium_back.png' }}
          style={{ flex: 1, width: '100%' }}  // サイズ指定
          imageStyle={{ opacity: 0.5 }}
          resizeMode="cover"
        >
          <View style={[styles.content, { marginBottom: isMobileScreen ? 35 : 65 }]}>
            {/* Block 1: ロゴと説明 */}
            <View style={[styles.block1, { marginBottom: isMobileScreen ? 50 : 150 }]}>
              <Text style={[styles.logoTopText, { fontSize: isMobileScreen ? 16 : 20 }]}>
                WEB版だけでご利用いただける
              </Text>
              <Image
                source={require('../assets/images/premium_title.png')}
                style={[styles.logoImage, { height: isMobileScreen ? windowDimensions.width * 0.09 : windowDimensions.width * 0.06 },]}
              />
              <Text style={[styles.text, { fontSize: isMobileScreen ? 16 : 20 }]}>
                とりにてぃプレミアムでは、愛鳥の健康をより詳細に記録したい愛鳥家の皆さまの声にお応えして画像アップロード機能を追加いたしました。
              </Text>
            </View>

            {/* Block 2: 比較テーブル */}
            <View style={styles.block2}>
              <View style={styles.comparisonTable}>
                <Image
                  source={require('../assets/images/premium_fukidashi.png')}
                  style={[
                    styles.fukidashiImage,
                    { top: isMobileScreen ? -20 : -110 },
                    { left: isMobileScreen ? 5 : 15 },
                    { width: isMobileScreen ? 100 : 205 },
                    { height: isMobileScreen ? 80 : 215 },
                  ]}
                />
                <View style={styles.tableHeader}>
                  <View style={[
                    styles.tableCell, styles.CellBorder,
                    { minWidth: isMobileScreen ? 0 : 273.33 },
                    { marginRight: isMobileScreen ? 20 : 10 },
                  ]} />
                  <View style={[
                    styles.tableCell, styles.regularHeader,
                    { paddingVertical: isMobileScreen ? 10 : 20 },
                  ]}>
                    <Text style={[
                      styles.tableHeaderinr,
                      { fontSize: isMobileScreen ? 16 : 20 },
                    ]}>
                      レギュラー{"\n"}会員
                    </Text>
                  </View>
                  <View style={[
                    styles.tableCell, styles.premiumHeader,
                    { paddingVertical: isMobileScreen ? 10 : 20 },
                  ]}>
                    <Text style={[
                      styles.tableHeaderinr,
                      { fontSize: isMobileScreen ? 16 : 20 },
                    ]}>
                      プレミアム{"\n"}会員
                    </Text>
                  </View>
                </View>
                <View style={styles.tableRow}>
                  <Text style={[
                    styles.tableCellBody, styles.CellBorder,
                    { fontSize: isMobileScreen ? 12 : 20 },
                    { paddingHorizontal: isMobileScreen ? 5 : 20 },
                    { paddingVertical: isMobileScreen ? 10 : 30 },
                  ]}>
                    {isMobileScreen
                      ? "愛鳥の体調やごはんの記録・グラフでの確認"
                      : "愛鳥の体調やごはんの記録・グラフでの確認"}
                  </Text>
                  <View style={[styles.tableCellBody, styles.CellBorder]}>
                    <View style={[
                      styles.circleRegular,
                      { width: isMobileScreen ? 40 : 80 },
                      { height: isMobileScreen ? 40 : 80 },
                      { borderWidth: isMobileScreen ? 5 : 10 },
                    ]} />
                  </View>
                  <View style={[styles.tableCellBody, styles.BackPremium]}>
                    <View style={[
                      styles.circlePremium,
                      { width: isMobileScreen ? 40 : 80 },
                      { height: isMobileScreen ? 40 : 80 },
                      { borderWidth: isMobileScreen ? 5 : 10 },
                    ]} />
                  </View>
                </View>
                <View style={styles.tableRow}>
                  <Text style={[
                    styles.tableCellBody, styles.CellBorder,
                    { fontSize: isMobileScreen ? 12 : 20 },
                    { paddingHorizontal: isMobileScreen ? 5 : 20 },
                    { paddingVertical: isMobileScreen ? 10 : 30 },
                  ]}>
                    日記と今日のベストショットの保存
                  </Text>
                  <View style={[styles.tableCellBody, styles.CellBorder]} />
                  <View style={[styles.tableCellBody, styles.BackPremium]}>
                    <View style={[
                      styles.circlePremium,
                      { width: isMobileScreen ? 40 : 80 },
                      { height: isMobileScreen ? 40 : 80 },
                      { borderWidth: isMobileScreen ? 5 : 10 },
                    ]} />
                  </View>
                </View>
                <View style={styles.tableRow}>
                  <Text style={[
                    styles.tableCellBody, styles.CellBorder,
                    { fontSize: isMobileScreen ? 12 : 20 },
                    { paddingHorizontal: isMobileScreen ? 5 : 20 },
                    { paddingVertical: isMobileScreen ? 10 : 30 },
                  ]}>
                    {isMobileScreen ? "うんちの写真を保存" : "うんちの写真を保存"}
                  </Text>
                  <View style={[styles.tableCellBody, styles.CellBorder]} />
                  <View style={[styles.tableCellBody, styles.BackPremium]}>
                    <View style={[
                      styles.circlePremium,
                      { width: isMobileScreen ? 40 : 80 },
                      { height: isMobileScreen ? 40 : 80 },
                      { borderWidth: isMobileScreen ? 5 : 10 },
                    ]} />
                  </View>
                </View>
              </View>
              <Text style={[styles.textSmall, { fontSize: isMobileScreen ? 12 : 16 }]}>
                ※写真はさかのぼってのアップロード、差し替えも可能です
              </Text>
            </View>

            {/* Block 3: キャンペーン説明 */}
            <View style={[styles.block3, { paddingHorizontal: isMobileScreen ? 0 : 0 }]}>
              <Text style={[styles.text, { fontSize: isMobileScreen ? 16 : 20 }]}>
                プレミアム会員登録をしていただいた方には、オンラインショッピングサイト「avis」にて同額のポイントバックが適用されるキャンペーンを実施中です。
              </Text>
            </View>

            {/* Block 4: タイトルとプレミアムブロック */}
            {/* PC: 横並び, スマホ: 縦並び */}
            <View style={[styles.block4, { flexDirection: isMobileScreen ? 'column' : 'row' }]}>
              <Image
                source={require('../assets/images/premium_block_back1.png')}
                style={[
                  styles.premium_img_block,
                  { width: isMobileScreen ? '100%' : '50%' },
                  { height: isMobileScreen ? 300 : 350 },
                ]}
              />
              <Image
                source={require('../assets/images/premium_block_back2.png')}
                style={[
                  styles.premium_img_block,
                  { width: isMobileScreen ? '100%' : '50%' },
                  { height: isMobileScreen ? 300 : 350 },
                ]}
              />
            </View>

            {/* Block 5: 料金情報 */}
            <View style={[styles.block5, { marginBottom: isMobileScreen ? 20 : 30 }]}>
              <Image
                source={require('../assets/images/premium_ryokin.png')}
                style={[styles.ryokinttlImage, { height: windowDimensions.width * 0.09 }]}
              />
              <View style={styles.border2} />
              <View style={styles.ryokinBlock}>
                <Image
                  source={require('../assets/images/premium_nenkan.png')}
                  style={[styles.nenkanImage, { height: isMobileScreen ? windowDimensions.width * 0.12 : windowDimensions.width * 0.08 },]}
                />
                <View style={[styles.ryokinBlock2, { width: isMobileScreen ? 'auto' : '80%' }]}>
                  <Text style={[styles.Ryokintext, { fontSize: isMobileScreen ? 70 : 100 }]}>
                    5,500
                  </Text>
                  <Text style={[styles.RyokintextSmall, { fontSize: 16 }]}>
                    円(税込)
                  </Text>
                </View>
              </View>

              <Image
                source={require('../assets/images/ryokin_img.png')}
                style={[styles.ryokinImage, { height: windowDimensions.width * 0.5 }]}
              />
              <View style={styles.textBottomBlock}>
                <Image
                  source={require('../assets/images/premium_icon.png')}
                  style={[styles.iconImage, { height: windowDimensions.width * 0.07, width: windowDimensions.width * 0.07 }]}
                />
                <Text style={[styles.textBottom, { width: isMobileScreen ? '90%' : '94%', fontSize: isMobileScreen ? 16 : 20 }]}>
                  とりにてぃプレミアムにご登録いただくと、avisにて5,500円分のポイントをプレゼントいたします。※ポイントはavis内で1ポイント1円でご利用いただけます。
                  <Text
                    style={{ textDecorationLine: 'underline', color: 'red' }}
                    onPress={() => window.open('https://avis-kurose-pf.shop-pro.jp/', '_blank')}
                  >
                    （事前にavisへのご登録が必要となります。）
                  </Text>
                </Text>
              </View>
              <View style={styles.textBottomBlock}>
                <Image
                  source={require('../assets/images/premium_icon.png')}
                  style={[styles.iconImage, { height: windowDimensions.width * 0.07, width: windowDimensions.width * 0.07 }]}
                />
                <Text style={[styles.textBottom, { width: isMobileScreen ? '90%' : '94%', fontSize: isMobileScreen ? 16 : 20 }]}>
                  登録は1年ごとの自動更新となり、更新ごとに5,500円分のポイントをプレゼントいたします。プレミアムの解約はメニュー内の「とりにてぃプレミアム解約」よりいつでも可能で、解約後もレギュラー会員として引き続きご利用いただけます。
                </Text>
              </View>
            </View>

            {/* 購読ボタン */}
            <TouchableOpacity style={styles.subscribeButton1} onPress={handleSubscribePress}>
              <Text style={[styles.subscribeButtonText1, { fontSize: isMobileScreen ? 16 : 22 }]}>
                とりにてぃプレミアムを利用する
              </Text>
            </TouchableOpacity>
            <TouchableOpacity style={styles.subscribeButton2} onPress={handleSubscribePress2}>
              <Text style={[styles.subscribeButtonText2, { fontSize: isMobileScreen ? 16 : 22 }]}>
                レギュラー会員で利用する
              </Text>
            </TouchableOpacity>
          </View>
        </ImageBackground>
      </View>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
  },
  content: {
    maxWidth: 800,
    alignSelf: 'center',
    paddingHorizontal: 20,
  },
  block1: {
    marginTop: 40,
  },
  logoTopText: {
    textAlign: 'center',
    marginBottom: 20,
    color: '#555555',
  },
  logoImage: {
    maxWidth: 420,
    width: '100%',
    resizeMode: 'contain',
    alignSelf: 'center',
    marginBottom: 20,
  },
  text: {
    color: '#555555',
  },
  block2: {
    marginBottom: 30,
  },
  textSmall: {
    color: '#555555'
  },
  block3: {
    paddingHorizontal: 20,
    marginBottom: 10,
  },
  block4: {
    alignItems: 'center',
    width: '100%',
    alignSelf: 'center',
    justifyContent: 'center',
    marginBottom: 30,
  },
  premium_img_block: {
    width: '100%',
    // 高さは必要に応じて指定してください
    height: 350,
    margin: 5,
    resizeMode: 'contain',
  },
  sukusyoImage: {
    maxWidth: 745,
    width: '100%',
    maxHeight: 420,
    resizeMode: 'contain',
    alignSelf: 'center',
  },
  title: {
    fontSize: 30,
    color: '#555555',
    paddingHorizontal: 45,
    marginTop: 20,
    marginBottom: 60,
  },
  border: {
    backgroundColor: '#cd2829',
    height: 0.6,
  },
  border2: {
    backgroundColor: '#cd2829',
    height: 0.6,
    maxWidth: 450,
    alignSelf: 'center',
    width: '100%',
    marginBottom: 20,
  },
  subtitle: {
    color: '#f29c9f',
    fontFamily: 'Arial, sans-serif',
    marginBottom: 20,
  },
  block5: {
    marginBottom: 80,
  },
  ryokinttlImage: {
    maxWidth: 450,
    width: '100%',
    maxHeight: 80,
    alignSelf: 'center',
    resizeMode: 'contain',
    marginBottom: 10,
  },
  ryokinBlock: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    maxWidth: 480,
    alignSelf: 'center',
    justifyContent: 'center',
    marginBottom: 30,
  },
  nenkanImage: {
    width: '15%',
    maxWidth: 110,
    maxHeight: 110,
    resizeMode: 'contain',
  },
  ryokinBlock2: {
    maxWidth: 330,
    flexDirection: 'row',
    width: '80%',
    alignItems: 'baseline',
  },
  Ryokintext: {
    fontSize: 100,
    color: '#555555',
  },
  RyokintextSmall: {
    fontSize: 16,
    color: '#555555',
  },
  ryokinImage: {
    width: '100%',
    maxHeight: 465,
    resizeMode: 'contain',
    marginBottom: 30,
  },
  textBottomBlock: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 15,
  },
  iconImage: {
    maxWidth: 30,
    maxHeight: 30,
    height: 100,
    marginTop: 5,
  },
  textBottom: {
    width: '94%',
    fontSize: 20,
    color: '#555555',
  },
  subscribeButton1: {
    backgroundColor: '#cd2829',
    paddingVertical: 20,
    alignItems: 'center',
    marginBottom: 20,
    borderRadius: 45,
    maxWidth: 660,
    width: '100%',
    alignSelf: 'center',
  },
  subscribeButtonText1: {
    fontWeight: 'bold',
    color: '#fff',
    textAlign: 'center',
  },
  subscribeButton2: {
    backgroundColor: '#bfbfbf',
    paddingVertical: 20,
    alignItems: 'center',
    marginBottom: 20,
    borderRadius: 45,
    maxWidth: 660,
    width: '100%',
    alignSelf: 'center',
  },
  subscribeButtonText2: {
    color: '#fff',
    textAlign: 'center',
  },
  comparisonTable: {
    position: 'relative',
  },
  tableHeader: {
    flexDirection: 'row',
  },
  tableRow: {
    flexDirection: 'row',
    marginBottom: 10,
  },
  tableCell: {
    flex: 1,
    padding: 10,
    justifyContent: 'center',
    alignItems: 'center',
  },
  tableCellBody: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#fff',
    color: '#555555',
  },
  CellBorder: {
    marginRight: 10,
  },
  regularHeader: {
    backgroundColor: '#83bbd5',
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    marginRight: 10,
  },
  premiumHeader: {
    backgroundColor: '#cd2829',
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
  },
  tableHeaderinr: {
    textAlign: 'left',
    color: '#fff',
  },
  circleRegular: {
    width: 80,
    height: 80,
    borderRadius: 40,
    borderColor: '#83bbd5',
  },
  circlePremium: {
    width: 80,
    height: 80,
    borderRadius: 40,
    borderColor: '#cd2829',
  },
  BackPremium: {
    backgroundColor: '#fff',
  },
  fukidashiImage: {
    position: 'absolute',
    resizeMode: 'contain',
  },
  ttlrow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  titleImage: {
    resizeMode: 'contain',
  },
});

export default PremiumScreen;
