import React, { useState, useEffect, useRef } from 'react';
import { View, Text, ScrollView, TextInput, Image, StyleSheet, Platform, TouchableOpacity, ImageBackground, Dimensions, ActivityIndicator } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { getBirds, setIndex } from '../store/modules/birds';
import { getRecords, setSelectedDateString } from '../store/modules/records';
import Icons from '../components/Icons';
import { useFocusEffect, useNavigation } from '@react-navigation/native';  // モバイル専用
import Swiper from 'react-native-web-swiper';
import { Calendar, LocaleConfig } from 'react-native-calendars';
import { dateToStr } from '../plugins/utils';
import { useNavigate } from 'react-router-dom'; // React Router用
import { onSnapshot, doc, getDoc, collection } from "firebase/firestore";
import { firestore } from '../plugins/firebase';
import Modal from 'react-native-modal';

LocaleConfig.locales['ja'] = {
  monthNames: [
    '1月', '2月', '3月', '4月', '5月', '6月',
    '7月', '8月', '9月', '10月', '11月', '12月'
  ],
  monthNamesShort: [
    '1月', '2月', '3月', '4月', '5月', '6月',
    '7月', '8月', '9月', '10月', '11月', '12月'
  ],
  dayNames: [
    '日曜日', '月曜日', '火曜日', '水曜日', '木曜日', '金曜日', '土曜日'
  ],
  dayNamesShort: [
    '日', '月', '火', '水', '木', '金', '土'
  ],
  today: '今日'
};

// 日本語をデフォルトに設定
LocaleConfig.defaultLocale = 'ja';

const HomeScreen = () => {
  const [windowDimensions, setWindowDimensions] = useState({ width: 0, height: 0 });
  // コンポーネントがマウントされたときに、画面サイズを取得
  useEffect(() => {
    const { width, height } = Dimensions.get('window');
    setWindowDimensions({ width, height });
    // リスナーで画面サイズを監視
    const onChange = ({ window }) => {
      setWindowDimensions({ width: window.width, height: window.height });
    };
    Dimensions.addEventListener('change', onChange);
    // クリーンアップ
    return () => {
      Dimensions.removeEventListener('change', onChange);
    };
  }, []);
  const isMobileScreen = windowDimensions.width < 768;

  const dispatch = useDispatch();
  const setSelectedDateString = (val) => {
    dispatch({ type: 'records/setSelectedDateString', payload: val });
  };
  // Reduxから鳥とアカウント情報を取得
  const unsortedBirds = useSelector((state) => state.birds.birds || []);
  const birds = React.useMemo(() => {
    const sorted = [...unsortedBirds].sort((a, b) => {
      if (a.order === undefined && b.order === undefined) return 0;
      if (a.order === undefined) return 1;
      if (b.order === undefined) return -1;
      return a.order - b.order;
    });
    return sorted;
  }, [unsortedBirds]);
  const index = useSelector((state) => state.birds.index || 0);

  const account = useSelector((state) => state.accounts.account);  // アカウント情報を取得
  const [record, setRecord] = useState(null);
  const today = new Date().toISOString().split('T')[0];
  const selectedDateString = useSelector((state) => state.records.selectedDateString);
  const [unsubscribe, setUnsubscribe] = useState(null);

  const [premiumFlag, setPremiumFlag] = useState(false);
  useEffect(() => {
    const fetchPremiumFlag = async () => {
      if (account?.uid) {
        try {
          const accountDocRef = doc(firestore, "accounts", account.uid); // accountsコレクションから特定のドキュメントを取得
          const accountDocSnap = await getDoc(accountDocRef);

          if (accountDocSnap.exists()) {
            const accountData = accountDocSnap.data();
            setPremiumFlag(accountData.premiumFlag || false); // premiumFlag を更新、デフォルトは false
          } 
        } catch (error) {
          console.error("プレミアムフラグの取得中にエラーが発生しました:", error);
        }
      } else {
        console.log("アカウント情報がありません。ログインしているか確認してください。");
      }
    };
    fetchPremiumFlag();
  }, [account]);

  useEffect(() => {
    setSelectedDateString(today);
  }, []);

  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const fetchData = async (index) => {
    try {
      if (account && account.uid) {  // アカウント情報がある場合のみ鳥とレコードを取得
        const birdsResponse = await dispatch(getBirds({ user: account })); // 鳥のデータを取得
        let birdsData = birdsResponse.payload || [];

        // `order` の順に並べ替え。`order` がない場合は最後に配置
        birdsData = birdsData.sort((a, b) => {
          if (a.order === undefined && b.order === undefined) return 0; // 両方とも `order` がない
          if (a.order === undefined) return 1; // `a` の `order` がない場合、`b` より後ろ
          if (b.order === undefined) return -1; // `b` の `order` がない場合、`a` より前
          return a.order - b.order; // `order` の昇順で並び替え
        });

        if (birdsData.length > 0 && birdsData[index]?.bid) {
          const selectedBird = birdsData[index];

          const birdRecordsResponse = await dispatch(getRecords(selectedBird)); // 鳥に紐づいたレコードを取得
          const allRecords = birdRecordsResponse.payload || [];

          // 今日の日付を取得 (YYYY-MM-DD形式)
          const today = new Date().toISOString().split('T')[0];

          // 今日の日付のレコードを探す
          const todayRecord = allRecords.find((record) => record.rid === today);

          if (todayRecord) {
            setRecord(todayRecord); // 今日の日付のレコードをセット
          } else {
            setRecord({}); // 空のレコードを設定
          }
        } else {
          setRecord(null); // 鳥データがない場合は null に設定
        }
      } else {
        console.log("アカウント情報が不足しています。");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsInitialLoading(false); // 初期ロード終了時にローディングを非表示
    }
  };
  // 初回ロード時にデータを取得
  useEffect(() => {
    if (account) {
      fetchData(index); // index を明示的に渡す
    }
  }, [dispatch, index, account]);

  // モバイルではフォーカス時にデータを再取得
  if (Platform.OS !== 'web') {
    useFocusEffect(
      React.useCallback(() => {
        fetchData();
        return () => {};
      }, [birds, index, dispatch])
    );
  }

  const [sortedBirds, setSortedBirds] = useState([...birds]);
  const [currentIndex, setCurrentIndex] = useState(0);

  // Swiperのインデックスが変わった際の処理
  const onIndexChanged = (idx) => {
    dispatch(setIndex(idx)); // Reduxでインデックスを更新
    setCurrentIndex(idx); // Swiperの現在のインデックスを更新
  };

  useEffect(() => {
    if (!birds[index] || !birds[index].bid) {
      return;
    }
    const bird = birds[index];
    const birdRef = doc(firestore, "birds", bird.bid);
    const recordRef = doc(firestore, "birds", bird.bid, "records", selectedDateString);

    // 鳥データのリアルタイム監視
    const unsubscribeBird = onSnapshot(
      birdRef,
      (docSnapshot) => {
        if (docSnapshot.exists()) {
          const updatedBirdData = docSnapshot.data();
          // Reduxの状態を更新
          dispatch({
            type: "UPDATE_BIRD",
            payload: { ...updatedBirdData, bid: bird.bid },
          });
          const updatedBirds = birds.map((b) =>
            b.bid === bird.bid ? { ...b, ...updatedBirdData } : b
          );
          // 並び替え処理
          const sortedBirdsData = updatedBirds.slice().sort((a, b) => {
            if (a.order === undefined && b.order === undefined) return 0;
            if (a.order === undefined) return 1;
            if (b.order === undefined) return -1;
            return a.order - b.order;
          });
          // 現在の鳥のインデックスを再計算
          const currentBird = sortedBirds[currentIndex];
          const newIndex = sortedBirdsData.findIndex((b) => b.bid === currentBird?.bid);

          setSortedBirds(sortedBirdsData);

        } else {
          console.log("鳥データが存在しません");
        }
      },
      (error) => {
        console.error("鳥データのリアルタイム監視エラー:", error);
      }
    );

    // レコードデータのリアルタイム監視
    const unsubscribeRecord = onSnapshot(
      recordRef,
      (docSnapshot) => {
        if (docSnapshot.exists()) {
          const updatedRecordData = docSnapshot.data();
          setRecord(updatedRecordData);
        } else {
          setRecord(null);
        }
      },
      (error) => {
        console.error("レコードデータのリアルタイム監視エラー:", error);
      }
    );

    // クリーンアップ
    return () => {
      unsubscribeBird();
      unsubscribeRecord();
    };
  }, [birds, index, selectedDateString, dispatch, currentIndex]);

  const [isModalVisible, setModalVisible] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const openModal = (url) => {
    setSelectedImage(url); // クリックされた画像のURLを保存
    setModalVisible(true); // モーダルを表示
  };

  const closeModal = () => {
    setModalVisible(false); // モーダルを非表示
    setSelectedImage(null); // 選択された画像をリセット
  };

  // フン用モーダルの表示制御とスライドに使うステート
  const [isDroppingsModalVisible, setDroppingsModalVisible] = useState(false);
  const [droppingsPhotos, setDroppingsPhotos] = useState([]);
  const [droppingsIndex, setDroppingsIndex] = useState(0);

  // フン写真をクリックしたときに呼ぶ関数
  function openDroppingsModal(photos, startIndex) {
    setDroppingsPhotos(photos);
    setDroppingsIndex(startIndex);
    setDroppingsModalVisible(true);
  }

  // モーダルを閉じる
  function closeDroppingsModal() {
    setDroppingsModalVisible(false);
    setDroppingsPhotos([]);
    setDroppingsIndex(0);
  }
  
  const swiperRef = useRef(null);
  useEffect(() => {
    if (swiperRef.current && typeof swiperRef.current.goTo === 'function') {
      swiperRef.current.goTo(index, true); // true でアニメーションあり
    }
  }, [index]);

  // Reduxから取得
  const reduxDate = useSelector((state) => state.records.selectedDateString);

  // Redux → ローカル同期（初期表示 or 他画面から変更されたとき）
  useEffect(() => {
    if (reduxDate && reduxDate !== selectedDateString) {
      setSelectedDateString(reduxDate);
    }
  }, [reduxDate]);

  // ローカル → Redux同期（HomeScreen内で日付変更したとき）

  const renderSwiper = () => {
    if (sortedBirds.length === 0) {
      return (
        <View style={{ alignItems: 'center', marginTop: 20 }}>
          <Text>表示する鳥データがありません。</Text>
        </View>
      );
    }

    return (
      <View style={{ maxWidth: 880, width: '100%', height: isMobileScreen ? 260 : 480, marginHorizontal: 'auto', overflow: 'hidden', paddingHorizontal: 0 }}>
        <Swiper
          ref={swiperRef}
          key={sortedBirds.map(bird => bird.bid).join('-')}
          from={currentIndex}
          showsButtons={true}
          loop={false}
          onIndexChanged={onIndexChanged}
          controlsProps={{
            dotActiveStyle: {
              backgroundColor: '#cd2829',
            },
            dotsWrapperStyle: {
              position: 'absolute',
              bottom: isMobileScreen ? 17 : 70,
              right:
                windowDimensions.width >= 1600
                  ? -windowDimensions.width * 0.15 // 1600px以上の場合
                  : windowDimensions.width >= 1280
                  ? -windowDimensions.width * 0.22 // 1280px以上の場合
                  : isMobileScreen
                  ? -windowDimensions.width * 0.36 // モバイル画面の場合
                  : -windowDimensions.width * 0.28, // その他の場合
            },
            NextComponent: ({ onPress }) => (
              <TouchableOpacity
                style={{
                  width: isMobileScreen ? 35 : 60,
                  height: isMobileScreen ? 35 : 60,
                  position: 'absolute',
                  bottom: isMobileScreen ? 90 : 200,
                  right:  isMobileScreen ? -10 : 5,
                  shadowColor: '#000',
                  shadowOffset: { width: 0, height: 0 },
                  shadowOpacity: 0.15,
                  shadowRadius: 10,
                  borderRadius: '50%',
                }}
                onPress={onPress}
              >
                <Image
                  source={require('../assets/images/next_icon.png')}
                  style={{
                    width: isMobileScreen ? 35 : 60,
                    height: isMobileScreen ? 35 : 60,
                  }}
                  resizeMode="contain"
                />
              </TouchableOpacity>
            ),
            PrevComponent: ({ onPress }) => (
              <TouchableOpacity
                style={{
                  width: isMobileScreen ? 35 : 60,
                  height: isMobileScreen ? 35 : 60,
                  position: 'absolute',
                  bottom: isMobileScreen ? 90 : 200,
                  left: isMobileScreen ? -10 : 5,
                  shadowColor: '#000',
                  shadowOffset: { width: 0, height: 0 },
                  shadowOpacity: 0.15,
                  shadowRadius: 10,
                  borderRadius: '50%',
                }}
                onPress={onPress}
              >
                <Image
                  source={require('../assets/images/prev_icon.png')}
                  style={{
                    width: isMobileScreen ? 35 : 60,
                    height: isMobileScreen ? 35 : 60,
                  }}
                  resizeMode="contain"
                />
              </TouchableOpacity>
            ),
          }}
        >
          {sortedBirds.map((bird, idx) => (
            <View
              key={idx}
              style={[
                styles.swiperSlide,
                { height: isMobileScreen ? 210 : 420 },
                { width: isMobileScreen ? '90%' : '95%' }
              ]}
            >
              <ImageBackground
                source={require('../assets/images/swiper_back.png')}
                style={styles.backgroundImage}
              >
                <View style={styles.contentContainer}>
                  <View style={styles.photo_space_middle}>
                    <View
                      style={[
                        styles.photo_space_circle,
                        { marginVertical: isMobileScreen ? 15 : 30 },
                      ]}
                    >
                      <Image
                        source={{ uri: bird?.photo || '' }}
                        style={[
                          styles.photo_space_image,
                          { height: isMobileScreen ? 130 : 270 },
                          { width: isMobileScreen ? 130 : 270 }
                        ]}
                      />
                    </View>
                  </View>
                  <Text style={styles.name}>
                    {(bird?.name || '名前').length > (isMobileScreen ? 8 : 16)
                      ? (bird?.name || '名前').slice(0, isMobileScreen ? 8 : 16) + "..."
                      : bird?.name || '名前'}
                  </Text>
                </View>
              </ImageBackground>
            </View>
          ))}
        </Swiper>
      </View>
    );
  };


  const navigation = useNavigation(); // React Navigation用
  const navigate = useNavigate(); // React Router用
  const linkPremium = () => {
    if (Platform.OS === 'web') {
      navigate('/premium'); // Web用のナビゲーション
    } else {
      navigation.navigate('Premium'); // モバイル用のナビゲーション
    }
  };
  const HealthSection = () => (
    <View
      style={[
        styles.healthSection,
        { paddingHorizontal: isMobileScreen ? 10 : 10 },
      ]}
    >
      {/* タイトル */}
      <View style={styles.healthTitleContainer}>
        <Image
          source={require('../assets/images/home_icon1.png')}
          style={styles.iconImage}
        />
        <Text style={styles.conTitle}>体調</Text>
      </View>

      {/* 各カード */}
      <View style={styles.healthCardsContainer}>
        {/* 体重カード */}
        <View
        style={[
          styles.healthCard,
          { paddingHorizontal: isMobileScreen ? 5 : 15 },
          { marginRight: isMobileScreen ? 5 : 15 },
        ]}
        >
          <Text style={styles.healthCardTitle}>体重</Text>
          <Text
            style={[
            styles.healthCardValue,
            { fontSize: isMobileScreen ? 27 : 35 },
          ]}
          >
            {record?.weight?.weight_1st || '00.0'}
            <Text style={styles.smallText}> g</Text>
          </Text>
        </View>

        {/* うんちカード */}
        <View
          style={[
            styles.healthCard,
            { paddingHorizontal: isMobileScreen ? 5 : 15 },
            { marginRight: isMobileScreen ? 5 : 15 },
          ]}
          >
          <Text style={styles.healthCardTitle}>うんち</Text>
          <View style={styles.healthCardRow}>
          <Text
            style={[
              styles.healthCardLabel,
              { fontSize: isMobileScreen ? 12 : 16 },
              { paddingHorizontal: isMobileScreen ? 5 : 15 },
              { minWidth: isMobileScreen ? 45 : 70 },
            ]}
          >
            状態
          </Text>
            <View style={styles.healthTag}>
              <Text
                style={[
                  styles.healthTagText,
                  { fontSize: isMobileScreen ? 13 : 16 },
                ]}
              >
                {record?.healty?.droppings_status || '通常'}
              </Text>
            </View>
          </View>
          <View style={styles.healthCardRow}>
            <Text
              style={[
                styles.healthCardLabel,
                { fontSize: isMobileScreen ? 13 : 16 },
                { paddingHorizontal: isMobileScreen ? 5 : 15 },
                { minWidth: isMobileScreen ? 45 : 70 },
              ]}
            >
              量
            </Text>
            <View style={styles.healthTag}>
              <Text
                style={[
                  styles.healthTagText,
                  { fontSize: isMobileScreen ? 13 : 16 },
                ]}
              >
                {record?.healty?.droppings_amount || '通常'}
              </Text>
            </View>
          </View>
          {premiumFlag ? (
          Array.isArray(record?.droppingsphotoURL) && record.droppingsphotoURL.length > 0 ? (
            <View style={styles.droppingsphotoBlock}>
              {record.droppingsphotoURL.map((url, index) => (
                <View
                  key={index}
                  style={[
                    styles.droppingsphotoImg,
                    index === 2 && styles.specialDroppingsPhoto,
                  ]}
                >
                  <TouchableOpacity onPress={() => openDroppingsModal(record.droppingsphotoURL, index)}>
                    <Image
                      source={{ uri: url }}
                      style={[
                        styles.droppingsPhoto,
                        { height: isMobileScreen ? 35 : 70 },
                      ]}
                    />
                  </TouchableOpacity>
                </View>
              ))}
            </View>
            ) : (
              <View style={styles.errorTextBlock}>
                <Text style={styles.errorText}>Photo</Text>
              </View>
            )
          ) : (
            <TouchableOpacity
              onPress={linkPremium}
              style={styles.premiumLinkBtn}
              accessible={true}
              accessibilityLabel="プレミアムリンクに移動"
            >
              <Image
                source={require('../assets/images/home_premium.png')}
                style={[
                  styles.premiumImage,
                  { height: isMobileScreen ? 34 : 40 },
                ]}
              />
            </TouchableOpacity>
          )}
        </View>

        {/* 状態カード */}
        <View
          style={[
          styles.healthCard,
          { paddingHorizontal: isMobileScreen ? 5 : 15 },
        ]}
        >
          <Text style={styles.healthCardTitle}>状態</Text>
          <View style={styles.healthCardRow}>
            <Text
              style={[
                styles.healthCardLabel,
                { fontSize: isMobileScreen ? 13 : 16 },
                { paddingHorizontal: isMobileScreen ? 5 : 15 },
                { minWidth: isMobileScreen ? 45 : 70 },
              ]}
            >
              換羽
            </Text>
            <View style={styles.healthTag}>
              <Text
                style={[
                  styles.healthTagText,
                  { fontSize: isMobileScreen ? 13 : 16 },
                ]}
              >
                {record?.healty?.feather_change || '無'}
              </Text>
            </View>
          </View>
          <View style={styles.healthCardRow}>
            <Text
              style={[
                styles.healthCardLabel,
                { fontSize: isMobileScreen ? 13 : 16 },
                { paddingHorizontal: isMobileScreen ? 5 : 15 },
                { minWidth: isMobileScreen ? 45 : 70 },
              ]}
            >
              発情
            </Text>
            <View style={styles.healthTag}>
              <Text
                style={[
                  styles.healthTagText,
                  { fontSize: isMobileScreen ? 13 : 16 },
                ]}
              >
                {record?.healty?.estrus_status || '無'}
              </Text>
            </View>
          </View>
          <View style={styles.healthCardRow}>
            <Text
              style={[
                styles.healthCardLabel,
                { fontSize: isMobileScreen ? 13 : 16 },
                { paddingHorizontal: isMobileScreen ? 5 : 15 },
                { minWidth: isMobileScreen ? 45 : 70 },
              ]}
            >
              産卵
            </Text>
            <View style={styles.healthTag}>
              <Text
                style={[
                  styles.healthTagText,
                  { fontSize: isMobileScreen ? 13 : 16 },
                ]}
              >
                {record?.healty?.spawning_status === '有' ? `${record?.healty?.spawning_type || '0'}個` : '無'}
              </Text>
            </View>
          </View>
        </View>
      </View>
    </View>
  );

  const EnvironmentSection = () => (
    <View
      style={[
        styles.envSection,
        { paddingHorizontal: isMobileScreen ? 10 : 10 },
      ]}
    >
      <View style={styles.envTitleContainer}>
      <Image
        source={require('../assets/images/home_icon2.png')}
        style={styles.iconImage}
      />
        <Text style={styles.conTitle}>環境</Text>
      </View>

      {/* 環境カード */}
      <View
        style={[
          styles.envCardsContainer,
          { padding: isMobileScreen ? 5 : 10 },
        ]}
        >
        <View style={styles.envCard}>
          <Text
            style={[
              styles.envCardLabel,
              { fontSize: isMobileScreen ? 13 : 16 },
              { paddingHorizontal: isMobileScreen ? 10 : 15 },
            ]}
          >
            外気温
          </Text>
          <Text
            style={[
              styles.envCardValue,
              { fontSize: isMobileScreen ? 28 : 30 },
            ]}
          >
            {record?.environment?.outside_temperture || '00.0'}
              <Text
                style={[
                  styles.envCardUnit,
                  { fontSize: isMobileScreen ? 18 : 21 },
                ]}
              >
              ℃
              </Text>
          </Text>
        </View>
        <View style={styles.envCard}>
          <Text
            style={[
              styles.envCardLabel,
              { fontSize: isMobileScreen ? 13 : 16 },
              { paddingHorizontal: isMobileScreen ? 10 : 15 },
            ]}
          >
            室内温度
          </Text>
          <Text
            style={[
              styles.envCardValue,
              { fontSize: isMobileScreen ? 28 : 30 },
            ]}
          >
            {record?.environment?.inside_temperture || '00.0'}
            <Text
                style={[
                  styles.envCardUnit,
                  { fontSize: isMobileScreen ? 18 : 21 },
                ]}
              >
            ℃
            </Text>
          </Text>
        </View>
        <View style={styles.envCard2}>
          <Text
            style={[
              styles.envCardLabel,
              { fontSize: isMobileScreen ? 13 : 16 },
              { paddingHorizontal: isMobileScreen ? 10 : 15 },
            ]}
          >
            ケージ温度
          </Text>
          <Text
            style={[
              styles.envCardValue,
              { fontSize: isMobileScreen ? 28 : 30 },
            ]}
          >
            {record?.environment?.cage_temperture || '00.0'}
            <Text
                style={[
                  styles.envCardUnit,
                  { fontSize: isMobileScreen ? 18 : 21 },
                ]}
              >
            ℃
            </Text>
          </Text>
        </View>
        <View style={styles.envCard3}>
          <Text
            style={[
              styles.envCardLabel,
              { fontSize: isMobileScreen ? 13 : 16 },
              { paddingHorizontal: isMobileScreen ? 10 : 15 },
            ]}
          >
            清掃
          </Text>
          <Text style={styles.envCardValue2}>{record?.environment?.cage_clean || '未'}</Text>
        </View>
        <View style={styles.envCard3}>
          <Text
            style={[
              styles.envCardLabel,
              { fontSize: isMobileScreen ? 13 : 16 },
              { paddingHorizontal: isMobileScreen ? 10 : 15 },
            ]}
          >
            室内湿度
          </Text>
          <Text
            style={[
              styles.envCardValue,
              { fontSize: isMobileScreen ? 28 : 30 },
            ]}
          >
            {record?.environment?.inside_humidity || '00.0'}
            <Text
                style={[
                  styles.envCardUnit,
                  { fontSize: isMobileScreen ? 18 : 21 },
                ]}
              >
            %
            </Text>
          </Text>
        </View>
        <View style={styles.envCard4}>
          <Text
            style={[
              styles.envCardLabel,
              { fontSize: isMobileScreen ? 13 : 16 },
              { paddingHorizontal: isMobileScreen ? 10 : 15 },
            ]}
          >
            ケージ湿度
          </Text>
          <Text
            style={[
              styles.envCardValue,
              { fontSize: isMobileScreen ? 28 : 30 },
            ]}
          >
            {record?.environment?.cage_humidity || '00.0'}
            <Text
                style={[
                  styles.envCardUnit,
                  { fontSize: isMobileScreen ? 18 : 21 },
                ]}
              >
            %
            </Text>
          </Text>
        </View>
      </View>
    </View>
  );

  const FoodSection = () => (
    <View
      style={[
        styles.foodSection,
        { paddingHorizontal: isMobileScreen ? 10 : 10 },
      ]}
    >
      <View style={styles.foodTitleContainer}>
        <Image
          source={require('../assets/images/home_icon3.png')}
          style={styles.iconImage}
        />
        <Text style={styles.conTitle}>ごはん</Text>
      </View>

      {/* 食事データ */}
      <ScrollView style={styles.foodScrollContainer}>
        {record?.food?.length > 0 ? (
          record.food.map((feed, index) => (
            <View key={index} style={styles.foodCard}>
              {/* 時間と食事タイプ */}
              <View style={styles.foodTimeContainer}>
                <Text style={styles.foodTime}>{feed.time || ''}</Text>
                <Text style={styles.foodType}>{feed.type || ''}</Text>
                <Text style={styles.foodAmount}>{`${feed.amount || '0'}g`}</Text>
              </View>
              {/* 製品情報 */}
              <Text style={styles.foodDetails}>
                {`${feed.product || ''} ${feed.maker ? `(${feed.maker})` : ''}`}
              </Text>
            </View>
          ))
        ) : (
          <Text style={styles.noFoodData}>データがありません</Text>
        )}
      </ScrollView>
    </View>
  );

  const DiarySection = () => (
    <View
      style={[
        styles.diarySection,
        { paddingHorizontal: isMobileScreen ? 10 : 10 },
      ]}
    >
      {/* タイトル */}
      <View style={styles.diaryTitleContainer}>
        <Image
          source={require('../assets/images/home_icon4.png')}
          style={styles.iconImage}
        />
        <Text style={styles.conTitle}>日記・写真</Text>
      </View>

      {/* 日記内容 */}
      <View style={styles.diaryContainer}>
        {record?.photoURL ? (
          <TouchableOpacity
            style={styles.diaryPhotoBlock}
            onPress={() => openModal(record?.photoURL)} // 写真をクリックでモーダルを開く
          >
            <Image
              source={{ uri: record?.photoURL }}
              style={{
                backgroundColor: '#ffffff',
                borderRadius: 10,
                height: '100%',
                width: '100%',
              }}
            />
          </TouchableOpacity>
        ) :
          <View style={styles.diaryPhotoBlock}>
            <View
              style={{
                backgroundColor: '#ffffff',
                borderRadius: 10,
                height: '100%',
                width: '100%',
              }}
            />
          </View>
        }
        <View style={styles.diaryTextBlock}>
          <ScrollView style={styles.diaryTextScroll}>
            <Text style={styles.diaryText}>
              {record?.memo || '日記はありません'}
            </Text>
          </ScrollView>
        </View>
      </View>
    </View>
  );

  const [recordedDays, setRecordedDays] = useState({});
  useEffect(() => {
    const fetchRecordedDays = async () => {
      if (!birds[index]) return; // 選択中の鳥が存在するか確認

      try {
        const response = await dispatch(getRecords(birds[index]));
        const records = response.payload || [];

        const markers = {};
        records.forEach((record) => {
          const rid = record.rid; // 日付 (YYYY-MM-DD)
          markers[rid] = { marked: true, dotColor: "#cd2829", selectedColor: "#cd2829" };
        });

        setRecordedDays(markers); // カレンダー用のマーカーを設定
      } catch (error) {
        console.error("Error fetching records:", error);
      }
    };

    fetchRecordedDays();
  }, [birds, index, dispatch]);


  if (isInitialLoading) {
    return (
      <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
        <Text style={{ color: '#555555', marginBottom: 10, }}>データを読み込んでいます...</Text>
        <ActivityIndicator size="large" color="#cd2829" />
      </View>
    );
  }
  return (
    <View style={{ flex: 1,}}>
      <ScrollView
        showsVerticalScrollIndicator={false}
      >
        <View style={styles.swiperCon}>
          {birds.length === 0 ? (
            <View style={{ alignItems: 'center' }}>
              <Text style={{ fontSize: 16, color: '#cd2829', marginVertical: 40 }}>
                <TouchableOpacity onPress={() => navigation.navigate('Bird')}>
                  愛鳥管理画面から愛鳥登録をしてください
                </TouchableOpacity>
              </Text>
            </View>
          ) : (
            renderSwiper()
          )}
        </View>
        <View style={styles.calendar}>
          <View style={styles.calendarBlock}>
            <Calendar
              hideExtraDays={true}  // 空の日を非表示
              monthFormat={'yyyy年MM月'} // 年月の表示フォーマットを指定
              renderArrow={(direction) => {
                return direction === 'left' ? (
                  <Image
                    source={require('../assets/images/arrow_left.png')}
                    style={{
                      width: isMobileScreen ? 15 : 16,
                      height: isMobileScreen ? 15 : 16,
                      resizeMode: 'contain',
                    }}
                  />
                ) : (
                  <Image
                    source={require('../assets/images/arrow_right.png')}
                    style={{
                      width: isMobileScreen ? 15 : 16,
                      height: isMobileScreen ? 15 : 16,
                      resizeMode: 'contain',
                    }}
                  />
                );
              }}
              onPressArrowLeft={(subtractMonth) => subtractMonth()} // 必須
              onPressArrowRight={(addMonth) => addMonth()} // 必須
              dayComponent={({ date, state }) => {
                const isSunday = new Date(date.dateString).getDay() === 0; // 日曜日
                const isSaturday = new Date(date.dateString).getDay() === 6; // 土曜日
                const marker = recordedDays[date.dateString]; // マーカーの情報を取得

                // 今日の日付と選択された日付を比較
                const isToday = date.dateString === dateToStr(new Date(), 'YYYY-MM-DD'); // 今日の日付
                const isSelected = date.dateString === selectedDateString; // 選択された日付

                return (
                  <TouchableOpacity
                    onPress={async () => {
                      setSelectedDateString(date.dateString); // 日付を更新

                      // 鳥情報を取得
                      const selectedBird = birds[index];
                      if (!selectedBird || !selectedBird.bid) {
                        console.error("鳥の情報が不足しています");
                        return;
                      }

                      try {
                        // レコードを取得
                        const response = await dispatch(getRecords(selectedBird));
                        const allRecords = response.payload || [];

                        // ドキュメントIDを使って選択された日付のレコードを検索
                        const selectedRecord = allRecords.find((rec) => rec.rid === date.dateString);

                        // 選択されたレコードをセット
                        setRecord(selectedRecord || null);
                      } catch (error) {
                        console.error("レコード取得エラー:", error);
                      }
                    }}
                  >
                    <View style={{ position: 'relative' }}>
                      {/* 選択された日付にのみ背景を設定 */}
                      {isSelected && (
                        <View
                          style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            backgroundColor: '#cd2829', // 選択された日付の背景色
                            borderRadius: "50%",
                            justifyContent: 'center',
                            alignItems: 'center',
                            zIndex: 0,
                            width: 25,
                            height: 25,
                            left: '50%',
                            transform: [{ translateX: '-50%' }],
                          }}
                        />
                      )}

                      {/* マーカーの表示 */}
                      {marker && marker.marked && !isSelected && (
                        <View
                          style={{
                            position: 'absolute',
                            bottom: -5,
                            left: '50%',
                            transform: [{ translateX: -2 }],
                            width: 5,
                            height: 5,
                            borderRadius: 5,
                            backgroundColor: marker.dotColor,
                          }}
                        />
                      )}

                      {/* 日付の表示 */}
                      <Text
                        style={{
                          textAlign: 'center',
                          color:
                            isSelected
                            ? '#ffffff' // 選択された日付の文字色
                            : state === 'disabled'
                            ? '#d9e1e8' // 無効な日付の文字色
                            : isSunday
                            ? '#cd2829' // 日曜日
                            : isSaturday
                            ? '#02bbf2' // 土曜日
                            : '#555555', // 平日の文字色
                          zIndex: 1,
                          fontSize: 16,
                        }}
                      >
                        {date.day}
                      </Text>
                    </View>
                  </TouchableOpacity>
                );
              }}
              theme={{
                'stylesheet.calendar.header': {
                  dayTextAtIndex0: { color: '#cd2829' }, // 日曜日
                  dayTextAtIndex6: { color: '#02bbf2' }, // 土曜日
                  dayTextAtIndex1: { color: '#555555' }, // 月曜日
                  dayTextAtIndex2: { color: '#555555' }, // 火曜日
                  dayTextAtIndex3: { color: '#555555' }, // 水曜日
                  dayTextAtIndex4: { color: '#555555' }, // 木曜日
                  dayTextAtIndex5: { color: '#555555' }, // 金曜日
                },
                textMonthFontSize: isMobileScreen ? 18 : 21,
                textDayHeaderFontSize: isMobileScreen ? 16 : 16,
              }}
            />
          </View>
        </View>
        <View style={styles.mainContainer}>
          <HealthSection />
          <EnvironmentSection />
          <FoodSection />
          <DiarySection />
        </View>
        {/* モーダル */}
        <Modal
          isVisible={isDroppingsModalVisible}
          onBackdropPress={closeDroppingsModal}
          style={{
            margin: 0,
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.7)'
          }}
        >
          <View style={{ width: '90%', maxWidth: 800, height: '75vh', position: 'relative' }}>
            {/* 閉じるボタン */}
            <TouchableOpacity
              onPress={closeDroppingsModal}
              style={{ position: 'absolute', top: -25, right: 0, zIndex: 99 }}
            >
              <Text style={{ color: '#fff', fontSize: 20, fontWeight: 'bold' }}>✕</Text>
            </TouchableOpacity>

            {/* Swiper を配置 */}
            <Swiper
              loop={false}
              index={droppingsIndex}
              onIndexChanged={(idx) => setDroppingsIndex(idx)}
              showsButtons={true}
              controlsProps={{
                dotActiveStyle: { backgroundColor: '#cd2829' },
                // 鳥のスワイパーと同じ画像を使った Next/Prev ボタン
                NextComponent: ({ onPress }) => (
                  <TouchableOpacity
                    style={{
                      width: isMobileScreen ? 35 : 60,
                      height: isMobileScreen ? 35 : 60,
                      position: 'absolute',
                      bottom: isMobileScreen ? '35vh' : '35vh',
                      right: isMobileScreen ? -10 : 5,
                      shadowColor: '#000',
                      shadowOffset: { width: 0, height: 0 },
                      shadowOpacity: 0.15,
                      shadowRadius: 10,
                      borderRadius: '50%',
                    }}
                    onPress={onPress}
                  >
                    <Image
                      source={require('../assets/images/next_icon.png')}
                      style={{
                        width: isMobileScreen ? 35 : 60,
                        height: isMobileScreen ? 35 : 60,
                      }}
                      resizeMode="contain"
                    />
                  </TouchableOpacity>
                ),
                PrevComponent: ({ onPress }) => (
                  <TouchableOpacity
                    style={{
                      width: isMobileScreen ? 35 : 60,
                      height: isMobileScreen ? 35 : 60,
                      position: 'absolute',
                      bottom: isMobileScreen ? '35vh' : '35vh',
                      left: isMobileScreen ? -10 : 5,
                      shadowColor: '#000',
                      shadowOffset: { width: 0, height: 0 },
                      shadowOpacity: 0.15,
                      shadowRadius: 10,
                      borderRadius: '50%',
                    }}
                    onPress={onPress}
                  >
                    <Image
                      source={require('../assets/images/prev_icon.png')}
                      style={{
                        width: isMobileScreen ? 35 : 60,
                        height: isMobileScreen ? 35 : 60,
                      }}
                      resizeMode="contain"
                    />
                  </TouchableOpacity>
                ),
              }}
              style={{ width: '100%', height: '100%' }}
            >
              {droppingsPhotos.map((photoUrl, i) => (
                <View key={i} style={{ flex: 1 }}>
                  <ImageBackground
                    source={{ uri: photoUrl }}
                    style={{ width: '100%', height: '100%' }}
                    resizeMode="contain"
                  />
                </View>
              ))}
            </Swiper>
          </View>
        </Modal>
        <Modal
          isVisible={isModalVisible}
          onBackdropPress={closeModal}
          style={{ margin: 0, justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgba(0, 0, 0, 0.7)' }}
        >
          <View style={{ width: '90%', maxWidth: 800, height: '75vh', position: 'relative' }}>
            <ImageBackground
              source={{ uri: selectedImage }}
              style={{
                width: '100%',
                height: '100%',
                justifyContent: 'flex-start',
                alignItems: 'flex-end',
              }}
              resizeMode="contain"
            >
              <TouchableOpacity
                onPress={closeModal}
                style={{
                  position: 'absolute',
                  top: -25,
                  right: 0,
                }}
              >
                <Text style={{ color: '#fff', fontSize: 20, fontWeight: 'bold' }}>✕</Text>
              </TouchableOpacity>
            </ImageBackground>
          </View>
        </Modal>
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  title: {
    fontSize: 14,
    color: '#555555',
    marginBottom: 13,
    textAlign: 'center',
  },
  photo_space_middle: {
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
  },
  photo_space_circle: {
    backgroundColor: '#fff',
  },
  photo_space_image: {
    borderRadius: '50%',
  },
  name: {
    fontSize: 22,
    color: '#555555',
    textAlign: 'center',
  },
  record: {
    flex: 1,
    paddingTop: '3%',
    paddingLeft: '3%',
    paddingRight: '3%',
    paddingBottom: '3%',
  },
  record_item_row: {
    flexDirection: 'row',
    flex: 1,
  },
  record_item_card_md: {
    flex: 2,
    margin: 5,
  },
  record_item_card_sm: {
    flex: 1,
    margin: 5,
  },
  record_item_card_big: {
    flex: 3,
    margin: 5,
  },
  record_card_frame: {
    marginTop: 30,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#fefefe',
    borderWidth: 1,
    borderColor: '#cd292a',
    width: '100%',
    height: 130,
  },
  record_card_title_circle: {
    backgroundColor: '#cd292a',
    borderRadius: 30,
    width: 60,
    height: 60,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: -50,
  },
  record_card_content: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  separator: {
    marginTop: 8,
    marginBottom: 14,
    borderBottomColor: '#cd292a',
    borderBottomWidth: 1,
    width: '70%',
  },
  record_card_content_weight: {
    paddingTop: 20,
    flexDirection: 'row',
  },
  record_card_content_weight_item: {
    flex: 1,
    alignItems: 'center',
  },
  record_card_content_weight_title: {
    fontSize: 15,
    color: '#cd292a',
  },
  record_card_content_weight_content: {
    fontSize: 16,
    color: '#cd292a',
  },
  record_card_content_dropping: {
    paddingTop: 20,
  },
  record_card_content_dropping_item: {
    flexDirection: 'row',
    marginBottom: 10,
  },
  record_card_content_dropping_title: {
    fontSize: 15,
    color: '#cd292a',
  },
  record_card_content_dropping_content: {
    borderWidth: 1,
    borderColor: '#cd2829',
    borderRadius: 10,
    color: '#cd2829',
    padding: 3,
    textAlign: 'center',
    fontSize: 12,
  },
  record_card_title_circle_food: {
    backgroundColor: '#cd292a',
    borderRadius: 30,
    width: 60,
    height: 60,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: -30,
  },
  record_card_content_food: {
    paddingTop: 5,
    paddingBottom: 20,
  },
  record_card_content_food_item: {
    marginBottom: 5,
  },
  record_card_content_food_item_top: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  record_card_content_food_item_bottom: {
    marginLeft: 20,
    marginBottom: 10,
  },
  record_card_content_food_time: {
    fontSize: 15,
    color: '#cd292a',
    marginLeft: 5,
  },
  record_card_content_food_type: {
    fontSize: 15,
    color: '#cd2829',
    marginLeft: 5,
  },
  record_card_content_food_amount: {
    fontSize: 14,
    color: '#cd2829',
    marginLeft: 5,
  },
  record_card_content_food_product: {
    fontSize: 15,
    color: '#cd2829',
  },
  record_card_content_temperture: {
    paddingTop: 20,
  },
  record_card_content_temperture_item: {
    flexDirection: 'row',
    marginBottom: 10,
  },
  record_card_content_temperture_title: {
    fontSize: 15,
    color: '#cd292a',
  },
  record_card_content_temperture_content: {
    fontSize: 14,
    color: '#cd2829',
  },
  record_card_content_cage: {
    paddingTop: 20,
  },
  record_card_content_cage_item: {
    flexDirection: 'row',
    marginBottom: 10,
  },
  record_card_content_cage_title: {
    fontSize: 15,
    color: '#cd292a',
  },
  record_card_content_cage_content_temp: {
    fontSize: 14,
    color: '#cd2829',
  },
  record_card_content_cage_content_clean: {
    borderWidth: 1,
    borderColor: '#cd2829',
    borderRadius: 10,
    paddingTop: 0,
    paddingBottom: 3,
    paddingLeft: 10,
    paddingRight: 10,
    width: 40,
  },
  record_card_content_cage_content_clean_text: {
    fontSize: 12,
    color: '#cd2829',
    textAlign: 'center',
  },
  record_card_content_healty: {
    marginTop: 14,
  },
  record_card_content_healty_item: {
    flexDirection: 'row',
    marginBottom: 5,
  },
  record_card_content_healty_title: {
    fontSize: 15,
    color: '#cd292a',
  },
  record_card_content_healty_content_feather: {
    borderWidth: 1,
    borderColor: '#cd2829',
    borderRadius: 10,
    padding: 3,
    fontSize: 12,
    color: '#cd2829',
    textAlign: 'center',
    width: 43,
  },
  record_card_content_healty_content: {
    borderWidth: 1,
    borderColor: '#cd2829',
    borderRadius: 10,
    padding: 8,
    fontSize: 12,
    textAlign: 'center',
    width: 43,
  },
  record_card_content_healty_content_text: {
    fontSize: 12,
    color: '#cd2829',
    textAlign: 'center',
  },
  record_card_content_healty_content_text2: {
    fontSize: 12,
    color: '#cd2829',
    textAlign: 'center',
    paddingTop: 2,
  },

  healthSection: {
    borderRadius: 10,
    paddingTop: 15,
  },
  healthTitleContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 10,
  },
  conTitle: {
    fontSize: 20,
    marginLeft: 5,
    color: '#555555'
  },
  healthCardsContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  healthCard: {
    flex: 1,
    backgroundColor: '#fff',
    borderRadius: 10,
    padding: 15,
    alignItems: 'center',
    justifyContent: 'space-between',
    shadowColor: '#000',
    elevation: 2,
  },
  healthCardTitle: {
    fontSize: 21,
    color: '#555555',
    marginBottom: 10,
  },
  healthCardValue: {
    fontSize: 48,
    color: '#555555',
    fontWeight: 'bold',
    marginHorizontal: 'auto',
    marginVertical: 'auto',
  },
  smallText: {
    fontSize: 24,
    color: '#555555',
    textAlign: 'right',
  },
  healthCardRow: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    marginVertical: 5,
  },
  healthCardLabel: {
    backgroundColor: '#f0bebe', // ピンクの背景色
    borderRadius: 20,
    paddingVertical: 4,
    alignItems: 'center',
    justifyContent: 'center',
    color: '#555555',
    textAlign: 'center',
  },
  healthTag: {
    fontSize: 12,
    color: '#555',
  },
  healthTagText: {
    fontSize: 16,
    color: '#555555',
    marginLeft: 7,
  },
  premiumLinkBtn: {
    width: '100%',
    backgroundColor: '#eeeeee',
    borderRadius: 10,
    shadowColor: '#eee',
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 1,
    shadowRadius: 5,
  },
  premiumImage: {
    width: '85%',
    resizeMode: 'contain',
    marginHorizontal: 'auto'
  },
  envSection: {
    marginTop: 0,
    borderRadius: 10,
    paddingVertical: 15,
  },
  envTitleContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 15,
  },
  envCardsContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    backgroundColor: '#fff',
    borderRadius: 10,
  },
  envCard: {
    width: '33%', // カードの幅
    backgroundColor: '#fff',
    padding: 10,
    shadowColor: '#000',
    elevation: 2,
    alignItems: 'center',
    borderBottomWidth: 1,
    borderBottomColor: '#e0e0e0', // 下ボーダー
    borderRightWidth: 1, // 右ボーダー
    borderRightColor: '#e0e0e0',
  },
  envCard2: {
    width: '33%', // カードの幅
    backgroundColor: '#fff',
    padding: 10,
    shadowColor: '#000',
    elevation: 2,
    alignItems: 'center',
    borderBottomWidth: 1,
    borderBottomColor: '#e0e0e0', // 下ボーダー
  },
  envCard3: {
    width: '33%', // カードの幅
    backgroundColor: '#fff',
    padding: 10,
    shadowColor: '#000',
    elevation: 2,
    alignItems: 'center',
    borderRightWidth: 1, // 右ボーダー
    borderRightColor: '#e0e0e0',
  },
  envCard4: {
    width: '33%', // カードの幅
    backgroundColor: '#fff',
    padding: 10,
    shadowColor: '#000',
    elevation: 2,
    alignItems: 'center',
  },
  envCardLabel: {
    color: '#555555',
    backgroundColor: '#f0bebe', // ラベルの背景色
    borderRadius: 20,
    paddingVertical: 4,
    textAlign: 'center',
    marginBottom: 5,
  },
  envCardValue: {
    fontSize: 33,
    color: '#555555',
    fontWeight: 'bold',
  },
  envCardValue2: {
    fontSize: 22,
    color: '#555555',
  },
  envCardUnit: {
    fontSize: 21,
    color: '#555555',
  },


  foodSection: {
    marginTop: 0,
  },
  foodTitleContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 15,
  },
  foodScrollContainer: {
    backgroundColor: '#fff',
    Height: 300,
    maxHeight: 300, // スクロール可能な高さを制限
    borderRadius: 10,
    paddingHorizontal: 20,
    paddingVertical: 10,
  },
  foodCard: {
    backgroundColor: '#fff',
    padding: 10,
    marginBottom: 0,
  },
  foodTimeContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 5,
    borderBottomWidth: 1,
    borderBottomColor: '#eaeaea',
    paddingBottom: 5,
  },
  foodTime: {
    backgroundColor: '#f0bebe',
    borderRadius: 15,
    paddingHorizontal: 8,
    color: '#555555',
    fontSize: 16,
    marginRight: 10,
  },
  foodType: {
    fontSize: 20,
    color: '#555555',
    marginRight: 5,
  },
  foodAmount: {
    fontSize: 20,
    color: '#555555',
  },
  foodDetails: {
    fontSize: 16,
    color: '#555555',
  },
  noFoodData: {
    fontSize: 16,
    color: '#cd292a',
    textAlign: 'center',
    marginTop: 20,
    marginBottom: 20,
  },
  diaryTitleContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 10,
  },
  diarySection: {
    marginTop: 0,
    marginBottom: 20,
    paddingTop:15,
  },
  mainContainer: {
    width: '100%',
    maxWidth: 800,
    marginHorizontal: 'auto',
  },
  iconImage: {
    width: 30,
    height: 30,
    resizeMode: 'contain',
  },
  swiperCon:{
    width: '100%',
    backgroundColor: "#fff",
  },
  swiperSlide:{
    maxWidth: 770,
    height: 420,
    marginTop: 35,
    backgroundColor: '#fff',
    marginHorizontal: 'auto',
    borderRadius: 15,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.15,
    shadowRadius: 10,
    elevation: 10, // Android用の影
  },
  backgroundImage: {
    width: '100%',
    height: '100%',
    resizeMode: 'cover',
  },
  diaryContainer: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  diaryPhotoBlock: {
    width: '49%',
    aspectRatio: 1,
    borderRadius: 10,
  },
  diaryTextBlock: {
    width: '49%',
    aspectRatio: 1,
    overflow: 'hidden',
  },
  diaryTextScroll: {
    flexGrow: 1,
  },
  diaryText: {
    fontSize: 16,
    color: '#555555',
    textDecorationLine: 'underline',
    textDecorationStyle: 'dashed',
    textDecorationColor: '#cd2829',
    lineHeight: 40,
    textUnderlineOffset: 10,
  },
  droppingsPhotoBlock: {
    width: '100%',
  },
  droppingsPhoto: {
    width: '100%',
    margin: 'auto',
    resizeMode: 'contain'
  },
  errorTextBlock: {
    padding: 8,
    borderRadius: 10,
    backgroundColor: '#eeeeee',
    width: '100%'
  },
  errorText: {
    fontSize: 16,
    color: '#555555',
    textAlign: 'center',
  },
  calendar:{
    backgroundColor: '#fff',
    },
  calendarBlock: {
    maxWidth: 800,
    marginHorizontal: 'auto',
    width: '100%',
    paddingBottom: 10,
  },
  droppingsphotoBlock: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    marginVertical: 5,
  },
  droppingsphotoImg: {
    width: '32%',
    marginRight: '2%',
    aspectRatio: 1,
  },
  specialDroppingsPhoto: {
    marginRight: 0,
  }
});

export default HomeScreen;
