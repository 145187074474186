import React, { useState } from 'react';
import { View, Text, TextInput, TouchableOpacity, ActivityIndicator, ScrollView, StyleSheet, Platform, Linking } from 'react-native';
import { Picker } from '@react-native-picker/picker';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import Toast from 'react-native-toast-message';
import { useDispatch } from 'react-redux';
import { addAccount } from '../store/modules/accounts';
import FontAwesome from 'react-native-vector-icons/FontAwesome';
import { autoBatchEnhancer } from '@reduxjs/toolkit';
import { VscEye,VscEyeClosed } from 'react-icons/vsc';

const SignUp = ({ navigation }) => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [name, setName] = useState('');
  const [age, setAge] = useState('２０代');
  const [gender, setGender] = useState('女性');
  const [prefectures, setPrefectures] = useState('東京都');
  const [city, setCity] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  // パスワード表示切り替え用の状態
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isPasswordConfirmVisible, setIsPasswordConfirmVisible] = useState(false);

  const showToast = (type, message) => {
    if (Platform.OS === 'web') {
      alert(message); // Webでは alert() を使う
    } else {
      Toast.show({ type, text1: type === 'success' ? '成功' : 'エラー', text2: message });
    }
  };

  const signUp = async () => {
    if (!email || !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      showToast('error', '正しいメールアドレスを入力してください。');
      return;
    }
    
    if (!password || password.length < 6) {
      showToast('error', 'パスワードを6文字以上で入力してください。');
      return;
    }
    
    if (!/^[a-zA-Z0-9]+$/.test(password)) {
      showToast('error', 'パスワードは半角英数字のみ使用できます。');
      return;
    }
    
    if (password !== passwordConfirm) {
      showToast('error', 'パスワードが一致していません。');
      return;
    }
    
    if (!name) {
      showToast('error', 'ユーザー名を入力してください。');
      return;
    }
    
    if (!city) {
      showToast('error', '都市名を入力してください。');
      return;
    }
  
    // サインアップ処理
    setIsLoading(true);
    
    try {
      // アカウント情報をストアに追加
      const account = {
        email,
        password,
        name,
        gender,
        age,
        prefectures,
        city,
      };
  
      await dispatch(addAccount({ account })).unwrap();
      Toast.show({
        type: 'success',
        text1: '成功',
        text2: 'アカウントが作成されました。',
      });
  
      // プラットフォームに応じた処理
      if (Platform.OS === 'web') {
        // ウェブの場合はURLを変更
        window.location.href = '/Thanks'; // ウェブのThanksページへリダイレクト
      } else {
        // モバイルの場合はナビゲーションを使用
        navigation.navigate('Thanks');
      }
    } catch (error) {
      Toast.show({
        type: 'error',
        text1: 'エラー',
        text2: error.message || 'アカウント作成に失敗しました。',
      });
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      <View style={styles.loadingContainer}>
        <Text style={{ color: '#555555', marginBottom: 10 }}>送信中</Text>
        <ActivityIndicator size="large" color="#cd2829" />
    </View>
    );
  }

  return (
    <View style={styles.container}>
      <KeyboardAwareScrollView extraScrollHeight={28}>
        <View style={styles.alignLeft}>
          <Text style={styles.toptitle}>メールアドレスで登録</Text>
        </View>

        <View style={styles.listItem}>
          <Text style={styles.heading1}>メールアドレス入力</Text>
          <TextInput
            style={styles.loginInput}
            value={email}
            onChangeText={setEmail}
            autoCapitalize="none"
            autoFocus={true}
          />
        </View>
        <View style={styles.listItem}>
          <Text style={styles.heading1}>パスワード入力</Text>
          <View style={styles.passwordContainer}>
            <TextInput
              style={styles.loginPassword}
              placeholder="半角英数字のみ 6文字以上"
              placeholderTextColor="#bfbfbf"
              secureTextEntry={!isPasswordVisible}
              value={password}
              onChangeText={setPassword}
            />
            <View style={styles.PasswordVisible}>
              <TouchableOpacity onPress={() => setIsPasswordVisible(!isPasswordVisible)}>
                {isPasswordVisible ? (
                  <VscEyeClosed size={20} color="#555" />
                ) : (
                  <VscEye size={20} color="#555" />
                )}
              </TouchableOpacity>
            </View>
          </View>

          <View style={styles.passwordContainer}>
            <TextInput
              style={styles.loginPassword}
              placeholder="確認用"
              placeholderTextColor="#bfbfbf"
              secureTextEntry={!isPasswordConfirmVisible}
              value={passwordConfirm}
              onChangeText={setPasswordConfirm}
            />
            <View style={styles.PasswordVisible}>
              <TouchableOpacity onPress={() => setIsPasswordConfirmVisible(!isPasswordConfirmVisible)}>
                {isPasswordConfirmVisible ? (
                  <VscEyeClosed size={20} color="#555" />
                ) : (
                  <VscEye size={20} color="#555" />
                )}
              </TouchableOpacity>
            </View>
          </View>
          <Text style={styles.heading2}>※半角英数字のみ 6文字以上</Text>
        </View>

        <View style={styles.listItem}>
          <Text style={styles.listItemHeader}>ユーザー名</Text>
          <TextInput
            style={styles.listItemName}
            value={name}
            onChangeText={setName}
            placeholder="ユーザー名"
            placeholderTextColor="#bfbfbf"
          />
          <Text style={styles.heading2}>※日本語入力可、16文字以内</Text>
        </View>

        <View style={styles.listRow}>
          <View style={styles.listItem2}>
            <Text style={styles.listItemHeader}>性別</Text>
            <Picker
              selectedValue={gender}
              onValueChange={setGender}
              style={styles.picker}
            >
              <Picker.Item label="女性" value="女性" />
              <Picker.Item label="男性" value="男性" />
              <Picker.Item label="その他" value="その他" />
              <Picker.Item label="回答しない" value="回答しない" />
            </Picker>
          </View>

          <View style={styles.listItem2}>
            <Text style={styles.listItemHeader}>年代</Text>
            <Picker
              selectedValue={age}
              onValueChange={setAge}
              style={styles.picker}
            >
              <Picker.Item label="１０代" value="１０代" />
              <Picker.Item label="２０代" value="２０代" />
              <Picker.Item label="３０代" value="３０代" />
              <Picker.Item label="４０代" value="４０代" />
              <Picker.Item label="５０代" value="５０代" />
              <Picker.Item label="６０代" value="６０代" />
              <Picker.Item label="７０代以上" value="７０代以上" />
            </Picker>
          </View>
        </View>

        <View style={styles.listRow}>
          <View style={styles.listItem2}>
            <Text style={styles.listItemHeader}>都道府県</Text>
            <Picker
              selectedValue={prefectures}
              onValueChange={setPrefectures}
              style={styles.picker}
            >
              <Picker.Item label="北海道" value="北海道" />
              <Picker.Item label="青森県" value="青森県" />
              <Picker.Item label="岩手県" value="岩手県" />
              <Picker.Item label="宮城県" value="宮城県" />
              <Picker.Item label="秋田県" value="秋田県" />
              <Picker.Item label="山形県" value="山形県" />
              <Picker.Item label="福島県" value="福島県" />
              <Picker.Item label="茨城県" value="茨城県" />
              <Picker.Item label="栃木県" value="栃木県" />
              <Picker.Item label="群馬県" value="群馬県" />
              <Picker.Item label="埼玉県" value="埼玉県" />
              <Picker.Item label="千葉県" value="千葉県" />
              <Picker.Item label="東京都" value="東京都" />
              <Picker.Item label="神奈川県" value="神奈川県" />
              <Picker.Item label="新潟県" value="新潟県" />
              <Picker.Item label="富山県" value="富山県" />
              <Picker.Item label="石川県" value="石川県" />
              <Picker.Item label="福井県" value="福井県" />
              <Picker.Item label="山梨県" value="山梨県" />
              <Picker.Item label="長野県" value="長野県" />
              <Picker.Item label="岐阜県" value="岐阜県" />
              <Picker.Item label="静岡県" value="静岡県" />
              <Picker.Item label="愛知県" value="愛知県" />
              <Picker.Item label="三重県" value="三重県" />
              <Picker.Item label="滋賀県" value="滋賀県" />
              <Picker.Item label="京都府" value="京都府" />
              <Picker.Item label="大阪府" value="大阪府" />
              <Picker.Item label="兵庫県" value="兵庫県" />
              <Picker.Item label="奈良県" value="奈良県" />
              <Picker.Item label="和歌山県" value="和歌山県" />
              <Picker.Item label="鳥取県" value="鳥取県" />
              <Picker.Item label="島根県" value="島根県" />
              <Picker.Item label="岡山県" value="岡山県" />
              <Picker.Item label="広島県" value="広島県" />
              <Picker.Item label="山口県" value="山口県" />
              <Picker.Item label="徳島県" value="徳島県" />
              <Picker.Item label="香川県" value="香川県" />
              <Picker.Item label="愛媛県" value="愛媛県" />
              <Picker.Item label="高知県" value="高知県" />
              <Picker.Item label="福岡県" value="福岡県" />
              <Picker.Item label="佐賀県" value="佐賀県" />
              <Picker.Item label="長崎県" value="長崎県" />
              <Picker.Item label="熊本県" value="熊本県" />
              <Picker.Item label="大分県" value="大分県" />
              <Picker.Item label="宮崎県" value="宮崎県" />
              <Picker.Item label="鹿児島県" value="鹿児島県" />
              <Picker.Item label="沖縄県" value="沖縄県" />
            </Picker>
          </View>

          <View style={styles.listItem2}>
            <Text style={styles.listItemHeader}>都市名</Text>
            <TextInput
              style={styles.listItemName}
              value={city}
              onChangeText={setCity}
              placeholder="都市名"
              placeholderTextColor="#bfbfbf"
            />
          </View>
        </View>

        <View style={styles.terms}>
          <Text
            style={styles.termsBtn}
            onPress={() => {
              if (Platform.OS === 'web') {
                Linking.openURL('/Terms'); // ウェブアプリでは内部リンクを開く
              } else {
                navigation.navigate('Terms'); // モバイルアプリではナビゲーション
              }
            }}
          >
            利用規約
          </Text>
          <Text
            style={styles.termsBtn}
            onPress={() => {
              if (Platform.OS === 'web') {
                Linking.openURL('/Privacy'); // ウェブアプリでは内部リンクを開く
              } else {
                navigation.navigate('Privacy'); // モバイルアプリではナビゲーション
              }
            }}
          >
            プライバシーポリシー
          </Text>
        </View>

        <TouchableOpacity style={styles.btn} onPress={signUp}>
          <Text style={styles.sendBtn}>上記に同意し、送信する</Text>
        </TouchableOpacity>
      </KeyboardAwareScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#eee',
    flex: 1,
    padding: '2%',
    maxWidth: 600,
    marginHorizontal: 'auto',
  },
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%'
  },
  alignLeft: {},
  toptitle: {
    fontSize: 18,
    color: '#AEAEAE',
    margin: 20,
    textAlign: 'center',
  },
  loginInput: {
    height: 45,
    backgroundColor: '#fff',
    borderRadius: 5,
    padding: 10,
    fontSize: 14,
  },
  loginPassword: {
    height: 35,
    backgroundColor: '#fff',
    borderRadius: 5,
    padding: 10,
    fontSize: 16,
    width: '100%'
  },
  heading1: {
    fontSize: 16,
    color: '#8d8d8d',
    marginBottom: 5,
  },
  heading2: {
    fontSize: 10,
    color: '#8d8d8d',
    textAlign: 'right',
  },
  listItem: {
    marginVertical: 10,
  },
  listItem2: {
    marginVertical: 10,
    width: '48%'
  },
  listItemHeader: {
    fontSize: 16,
    color: '#8d8d8d',
    marginBottom: 5,
  },
  listItemName: {
    height: '100%',
    backgroundColor: '#fff',
    borderRadius: 5,
    padding: 10,
    fontSize: 16,
    height: 45,
  },
  listRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  picker: {
    backgroundColor: '#fff',
    borderRadius: 5,
    paddingVertical: 10,
    paddingHorizontal: 10,
    fontSize: 18,
    border: "none",
    height: 45,
  },
  terms: {
    flexDirection: 'row',
    alignSelf: 'center',
    marginTop: 10,
    padding: 15,
  },
  termsBtn: {
    color: '#AEAEAE',
    textDecorationLine: 'underline',
    marginRight: 20,
  },
  btn: {
    height: 70,
    backgroundColor: '#cd2829',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 40,
    marginTop: 20,
  },
  sendBtn: {
    fontSize: 20,
    color: '#fff',
  },
  passwordContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#fff',
    borderRadius: 5,
    paddingHorizontal: 10,
    paddingVertical: 5,
    marginBottom: 10,
  },
  PasswordVisible:{
    position: "absolute",
    top: '13px',
    right: '13px',
  },
});



export default SignUp;
