// MypageScreen.js
import React, { useState, useEffect } from 'react';
import { View, Text, TextInput, TouchableOpacity, StyleSheet, ActivityIndicator, Picker } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { restoreLoginState, editAccount, removeAccount } from '../store/modules/accounts'; // アクションインポート

const MypageScreen = () => {
  const dispatch = useDispatch();
  const account = useSelector(state => state.accounts.account); // Reduxからアカウント情報を取得
  const [loading, setLoading] = useState(true);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [gender, setGender] = useState('');
  const [age, setAge] = useState('');
  const [prefectures, setPrefectures] = useState('');
  const [city, setCity] = useState('');

  // コンポーネントがマウントされたときにユーザー情報を取得
  useEffect(() => {
    dispatch(restoreLoginState()).finally(() => setLoading(false));
  }, [dispatch]);

  useEffect(() => {
    if (account) {
      setName(account.name);
      setEmail(account.email);
      setGender(account.gender);
      setAge(account.age);
      setPrefectures(account.prefectures);
      setCity(account.city);
    }
  }, [account]);

  const [isSaving, setIsSaving] = useState(false);
  const saveForm = () => {
    if (name === '' || email === '' || gender === '' || age === '' || prefectures === '' || city === '') {
      alert('すべての項目を入力してください');
      return;
    }
  
    setIsSaving(true); // 保存開始時にスピナーを表示
  
    const updatedAccount = {
      uid: account.uid,
      email: email,
      name: name,
      gender: gender,
      age: age,
      prefectures: prefectures,
      city: city,
    };
  
    // editAccount アクションを dispatch してアカウントを更新
    dispatch(editAccount({ account: updatedAccount }))
      .then(() => {
        alert('アカウント情報が更新されました');
        setIsSaving(false); // 保存完了後にスピナーを非表示
      })
      .catch((error) => {
        console.error('Error updating account:', error);
        alert('更新に失敗しました');
        setIsSaving(false); // 保存失敗後にスピナーを非表示
      });
  };

  const [isDeleting, setIsDeleting] = useState(false); 
  const confirmDeleteAccount = () => {
    const confirmDelete = window.confirm(
      'アカウントを削除しますか？一度削除したデータは復元できません。'
    );
    
    if (confirmDelete) {
      deleteAccount();
    } else {
      console.log('削除キャンセル');
    }
  };
  const deleteAccount = () => {
    setIsDeleting(true);
    // removeAccount アクションを dispatch してアカウントを削除
    dispatch(removeAccount({ account: { uid: account.uid, email: account.email } }))
      .then(() => {
        alert('アカウントが削除されました');
        // サインアウト処理
        dispatch(restoreLoginState());
        setIsDeleting(false);
      })
      .catch((error) => {
        console.error('Error deleting account:', error);
        alert('削除に失敗しました');
        setIsDeleting(false);
      });
  };
  return (
    <View style={styles.container}>
        <View style={styles.list}>
          <View style={styles.listItem}>
            <Text style={styles.listItemHeader}>ユーザー名</Text>
            <TextInput
              style={styles.listItemData}
              placeholder="ユーザー名"
              value={name}
              onChangeText={setName}
            />
          </View>
          <View style={styles.listItem}>
            <Text style={styles.listItemHeader}>メールアドレス</Text>
            <TextInput
              style={styles.listItemData}
              placeholder="メールアドレス"
              value={email}
              onChangeText={setEmail}
            />
          </View>
          <View style={styles.listRow}>
            <View style={styles.listItem2}>
              <Text style={styles.listItemHeader}>性別</Text>
              <Picker
                selectedValue={gender}
                style={styles.listItemData}
                onValueChange={(itemValue) => setGender(itemValue)}
              >
                <Picker.Item label="男性" value="男性" />
                <Picker.Item label="女性" value="女性" />
                <Picker.Item label="その他" value="その他" />
                <Picker.Item label="回答しない" value="回答しない" />
              </Picker>
            </View>
            <View style={styles.listItem2}>
              <Text style={styles.listItemHeader}>年代</Text>
              <Picker
                selectedValue={age}
                style={styles.listItemData}
                onValueChange={(itemValue) => setAge(itemValue)}
              >
                <Picker.Item label="１０代" value="１０代" />
                <Picker.Item label="２０代" value="２０代" />
                <Picker.Item label="３０代" value="３０代" />
                <Picker.Item label="４０代" value="４０代" />
                <Picker.Item label="５０代" value="５０代" />
                <Picker.Item label="６０代" value="６０代" />
                <Picker.Item label="７０代以上" value="７０代以上" />
              </Picker>
            </View>
          </View>
          <View style={styles.listRow}>
            <View style={styles.listItem2}>
              <Text style={styles.listItemHeader}>都道府県</Text>
              <Picker
                selectedValue={prefectures}
                style={styles.listItemData}
                onValueChange={(itemValue) => setPrefectures(itemValue)}
              >
                <Picker.Item label="北海道" value="北海道" />
                <Picker.Item label="青森県" value="青森県" />
                <Picker.Item label="岩手県" value="岩手県" />
                <Picker.Item label="宮城県" value="宮城県" />
                <Picker.Item label="秋田県" value="秋田県" />
                <Picker.Item label="山形県" value="山形県" />
                <Picker.Item label="福島県" value="福島県" />
                <Picker.Item label="茨城県" value="茨城県" />
                <Picker.Item label="栃木県" value="栃木県" />
                <Picker.Item label="群馬県" value="群馬県" />
                <Picker.Item label="埼玉県" value="埼玉県" />
                <Picker.Item label="千葉県" value="千葉県" />
                <Picker.Item label="東京都" value="東京都" />
                <Picker.Item label="神奈川県" value="神奈川県" />
                <Picker.Item label="新潟県" value="新潟県" />
                <Picker.Item label="富山県" value="富山県" />
                <Picker.Item label="石川県" value="石川県" />
                <Picker.Item label="福井県" value="福井県" />
                <Picker.Item label="山梨県" value="山梨県" />
                <Picker.Item label="長野県" value="長野県" />
                <Picker.Item label="岐阜県" value="岐阜県" />
                <Picker.Item label="静岡県" value="静岡県" />
                <Picker.Item label="愛知県" value="愛知県" />
                <Picker.Item label="三重県" value="三重県" />
                <Picker.Item label="滋賀県" value="滋賀県" />
                <Picker.Item label="京都府" value="京都府" />
                <Picker.Item label="大阪府" value="大阪府" />
                <Picker.Item label="兵庫県" value="兵庫県" />
                <Picker.Item label="奈良県" value="奈良県" />
                <Picker.Item label="和歌山県" value="和歌山県" />
                <Picker.Item label="鳥取県" value="鳥取県" />
                <Picker.Item label="島根県" value="島根県" />
                <Picker.Item label="岡山県" value="岡山県" />
                <Picker.Item label="広島県" value="広島県" />
                <Picker.Item label="山口県" value="山口県" />
                <Picker.Item label="徳島県" value="徳島県" />
                <Picker.Item label="香川県" value="香川県" />
                <Picker.Item label="愛媛県" value="愛媛県" />
                <Picker.Item label="高知県" value="高知県" />
                <Picker.Item label="福岡県" value="福岡県" />
                <Picker.Item label="佐賀県" value="佐賀県" />
                <Picker.Item label="長崎県" value="長崎県" />
                <Picker.Item label="熊本県" value="熊本県" />
                <Picker.Item label="大分県" value="大分県" />
                <Picker.Item label="宮崎県" value="宮崎県" />
                <Picker.Item label="鹿児島県" value="鹿児島県" />
                <Picker.Item label="沖縄県" value="沖縄県" />
                <Picker.Item label="その他" value="その他" />
                <Picker.Item label="海外" value="海外" />
              </Picker>
            </View>
            <View style={styles.listItem2}>
              <Text style={styles.listItemHeader}>都市名</Text>
              <TextInput
                style={styles.listItemData}
                placeholder="都市名"
                value={city}
                onChangeText={setCity}
              />
            </View>
          </View>

          <Text style={styles.text}>※入力項目はすべて必須です。</Text>
          
          <View style={styles.control}>
            <TouchableOpacity 
              style={styles.controlBtnSave} 
              onPress={saveForm} 
              disabled={isSaving} // 保存中はボタンを無効にする
            >
              {isSaving ? (
                <ActivityIndicator size="small" color="#fff" />
              ) : (
                <Text style={styles.controlBtnSaveText}>保存</Text>
              )}
            </TouchableOpacity>
            <TouchableOpacity 
              style={styles.controlBtnCancel} 
              onPress={confirmDeleteAccount} 
              disabled={isDeleting} // 削除中はボタンを無効にする
            >
              {isDeleting ? (
                <ActivityIndicator size="small" color="#fff" />
              ) : (
                <Text style={styles.controlBtnCancelText}>アカウント削除</Text>
              )}
            </TouchableOpacity>
          </View>
        </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 20,
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: 600,
    marginHorizontal: 'auto',
  },
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  list: {
    width: '100%',
  },
  listItem: {
    marginVertical: 10,
  },
  listRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  listItem2: {
    marginVertical: 10,
    width: '48%'
  },
  listItemHeader: {
    fontSize: 16,
    color: '#8d8d8d',
    marginBottom: 5,
  },
  listItemData: {
    fontSize: 18,
    width: '100%',
    padding: 5,
    height: 45,
    backgroundColor: '#fff',
    borderRadius: 5,
    padding: 10,
    fontSize: 14,
    border: "none",
  },
  control: {
    flexDirection: 'row',
    marginTop: 20,
    justifyContent: 'center',
  },
  controlBtnSave: {
    backgroundColor: '#cd2829',
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderRadius: 5,
    marginRight: 10,
    width: 100,
    textAlign: 'center'
  },
  controlBtnSaveText: {
    color: '#fff',
    fontSize: 16,
    textAlign: 'center'
  },
  controlBtnCancel: {
    backgroundColor: '#ddd',
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderRadius: 5,
    width: 165,
  },
  controlBtnCancelText: {
    color: '#555',
    fontSize: 16,
    textAlign: 'center'
  },
  text: {
    marginTop: 10,
    fontSize: 14,
    color: '#555',
  },
  noAccountText: {
    fontSize: 16,
    color: 'gray',
  },
});

export default MypageScreen;
