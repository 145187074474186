import React from 'react';
import { View, Text, ScrollView, StyleSheet } from 'react-native';

const PrivacyScreen = () => {
  return (
    <ScrollView style={{ height: '100%' }}>
      <View style={styles.container}>
          <View style={styles.systtlA}>
            <View style={styles.separator} />
            <Text style={styles.termstitle}>プライバシーポリシー</Text>
            <View style={styles.separator} />
            <Text style={styles.texta}>
              株式会社黒瀬ペットフード（以下、「当社」といいます。）は、当社が提供するサービス「愛鳥ノートとりにてぃ」（以下、「本サービス」といいます。）における、ユーザーの個人情報の取扱いについて、以下のとおりプライバシーポリシー（以下、「本ポリシー」といいます。）を定めます。
            </Text>
          </View>

          <View style={styles.systtlA}>
            <View style={styles.separator} />
            <Text style={styles.termstitle}>第1条（個人情報）</Text>
            <View style={styles.separator} />
            <Text style={styles.texta}>
              「個人情報」とは、個人情報保護法にいう「個人情報」を指すものとし、生存する個人に関する情報であって、当該情報に含まれる氏名、生年月日、住所、電話番号、連絡先その他の記述等により特定の個人を識別できる情報及び容貌、指紋、声紋にかかるデータ、及び健康保険証の保険者番号などの当該情報単体から特定の個人を識別できる情報（個人識別情報）を指します。
            </Text>
          </View>

          <View style={styles.systtlA}>
            <View style={styles.separator} />
            <Text style={styles.termstitle}>第2条（個人情報の収集方法）</Text>
            <View style={styles.separator} />
            <Text style={styles.texta}>
              当社は、ユーザーが利用登録をする際に氏名、生年、居住場所（都道府県、市区）、メールアドレスの個人情報をお尋ねすることがあります。また、ユーザーと提携先などとの間でなされたユーザーの個人情報を含む取引記録や決済に関する情報を、当社の提携先（情報提供元、広告主、広告配信先などを含みます。）などから収集することがあります。
            </Text>
          </View>

          <View style={styles.systtlA}>
            <View style={styles.separator} />
            <Text style={styles.termstitle}>第3条（個人情報を収集・利用する目的）</Text>
            <View style={styles.separator} />
            <Text style={styles.texta}>当社が個人情報を収集・利用する目的は、以下のとおりです。</Text>

            <View style={styles.textRow}>
              <Text style={styles.textb}>1. </Text>
              <Text style={styles.textc}>当社サービスの提供・運営のため</Text>
            </View>
            <View style={styles.textRow}>
              <Text style={styles.textb}>2. </Text>
              <Text style={styles.textc}>ユーザーからのお問い合わせに回答するため（本人確認を行うことを含む）</Text>
            </View>
            <View style={styles.textRow}>
              <Text style={styles.textb}>3. </Text>
              <Text style={styles.textc}>
                ユーザーが利用中のサービスの新機能、更新情報、キャンペーン等及び当社が提供する他のサービスの案内のメールを送付するため
              </Text>
            </View>
            <View style={styles.textRow}>
              <Text style={styles.textb}>4. </Text>
              <Text style={styles.textc}>メンテナンス、重要なお知らせなど必要に応じたご連絡のため</Text>
            </View>
            <View style={styles.textRow}>
              <Text style={styles.textb}>5. </Text>
              <Text style={styles.textc}>
                利用規約に違反したユーザーや、不正・不当な目的でサービスを利用しようとするユーザーの特定をし、ご利用をお断りするため
              </Text>
            </View>
            <View style={styles.textRow}>
              <Text style={styles.textb}>6. </Text>
              <Text style={styles.textc}>
                ユーザーにご自身の登録情報の閲覧や変更、削除、ご利用状況の閲覧を行っていただくため
              </Text>
            </View>
            <View style={styles.textRow}>
              <Text style={styles.textb}>7. </Text>
              <Text style={styles.textc}>有料サービスにおいて、ユーザーに利用料金を請求するため</Text>
            </View>
            <View style={styles.textRow}>
              <Text style={styles.textb}>8. </Text>
              <Text style={styles.textc}>上記の利用目的に付随する目的</Text>
            </View>
          </View>

          <View style={styles.systtlA}>
            <View style={styles.separator} />
            <Text style={styles.termstitle}>第4条（利用目的の変更）</Text>
            <View style={styles.separator} />
            <View style={styles.textRow}>
              <Text style={styles.textb}>1. </Text>
              <Text style={styles.textc}>
                当社は、利用目的が変更前と関連性を有すると合理的に認められる場合に限り、個人情報の利用目的を変更するものとします。
              </Text>
            </View>
            <View style={styles.textRow}>
              <Text style={styles.textb}>2. </Text>
              <Text style={styles.textc}>
                利用目的の変更を行った場合には、変更後の目的について、当社所定の方法により、ユーザーに通知し、または本アプリ上に公表するものとします。
              </Text>
            </View>
          </View>

          <View style={styles.systtlA}>
            <View style={styles.separator} />
            <Text style={styles.termstitle}>第5条（個人情報の第三者提供）</Text>
            <View style={styles.separator} />
            <View style={styles.textRow}>
              <Text style={styles.textb}>1. </Text>
              <Text style={styles.textc}>
                当社は、次に掲げる場合を除いて、あらかじめユーザーの同意を得ることなく、第三者に個人情報を提供することはありません。ただし、個人情報保護法その他の法令で認められる場合を除きます。
              </Text>
            </View>
            <View style={styles.textRow}>
              <Text style={styles.textb}> 1. </Text>
              <Text style={styles.textc}>
                人の生命、身体または財産の保護のために必要がある場合であって、本人の同意を得ることが困難であるとき
              </Text>
            </View>
            <View style={styles.textRow}>
              <Text style={styles.textb}> 2. </Text>
              <Text style={styles.textc}>
                公衆衛生の向上または児童の健全な育成の推進のために特に必要がある場合であって、本人の同意を得ることが困難であるとき
              </Text>
            </View>
            <View style={styles.textRow}>
              <Text style={styles.textb}> 3. </Text>
              <Text style={styles.textc}>
                国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき
              </Text>
            </View>
          </View>

          <View style={styles.systtlA}>
            <View style={styles.separator} />
            <Text style={styles.termstitle}>第6条（個人情報の開示）</Text>
            <View style={styles.separator} />
            <Text style={styles.texta}>
              当社は、本人から個人情報の開示を求められたときは、本人に対し、遅滞なくこれを開示します。ただし、開示することにより次のいずれかに該当する場合は、その全部または一部を開示しないこともあり、開示しない決定をした場合には、その旨を遅滞なく通知します。
            </Text>
          </View>

          <View style={styles.systtlA}>
            <View style={styles.separator} />
            <Text style={styles.termstitle}>第7条（個人情報の訂正および削除）</Text>
            <View style={styles.separator} />
            <View style={styles.textRow}>
              <Text style={styles.textb}>1. </Text>
              <Text style={styles.textc}>
                ユーザーは、当社の保有する自己の個人情報が誤った情報である場合には、当社が定める手続きにより、当社に対して個人情報の訂正、追加または削除（以下、「訂正等」といいます。）を請求することができます。
              </Text>
            </View>
          </View>

          <View style={styles.systtlA}>
            <View style={styles.separator} />
            <Text style={styles.termstitle}>第8条（個人情報の利用停止等）</Text>
            <View style={styles.separator} />
            <Text style={styles.texta}>
              当社は、本人から、個人情報が、利用目的の範囲を超えて取り扱われているという理由、または不正の手段により取得されたものであるという理由により、その利用の停止または消去（以下、「利用停止等」といいます。）を求められた場合には、遅滞なく必要な調査を行います。
            </Text>
          </View>

          <View style={styles.systtlA}>
            <View style={styles.separator} />
            <Text style={styles.termstitle}>第9条（プライバシーポリシーの変更）</Text>
            <View style={styles.separator} />
            <View style={styles.textRow}>
              <Text style={styles.textb}>1. </Text>
              <Text style={styles.textc}>
                本ポリシーの内容は、法令その他本ポリシーに別段の定めのある事項を除いて、ユーザーに通知することなく、変更することができるものとします。
              </Text>
            </View>
            <View style={styles.textRow}>
              <Text style={styles.textb}>2. </Text>
              <Text style={styles.textc}>
                当社が別途定める場合を除いて、変更後のプライバシーポリシーは、本アプリに掲載したときから効力を生じるものとします。
              </Text>
            </View>
          </View>

          <View style={styles.systtlA}>
            <View style={styles.separator} />
            <Text style={styles.termstitle}>第10条（お問い合わせ窓口）</Text>
            <View style={styles.separator} />
            <Text style={styles.textb}>本ポリシーに関するお問い合わせ窓口</Text>
            <Text style={styles.textb}>住所：〒720-2124　広島県福山市神辺町川南396-1</Text>
            <Text style={styles.textb}>社名：株式会社黒瀬ペットフード</Text>
            <Text style={styles.textb}>Eメールアドレス：appmaster@kurose-pf.co.jp</Text>
          </View>

        <View style={styles.footer} />
      </View>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#fff',
    flex: 1,
    padding: 20,
  },
  header: {
    alignItems: 'center',
  },
  headerinr: {
    fontSize: 24,
    fontWeight: 'bold',
    padding: 3,
    color: '#777',
  },
  termstitle: {
    fontSize: 20,
    fontWeight: 'bold',
    padding: 3,
    color: '#777',
  },
  texta: {
    textAlign: 'left',
    fontSize: 14,
    color: '#777',
    padding: 14,
  },
  textb: {
    textAlign: 'left',
    fontSize: 14,
    fontWeight: 'bold',
    color: '#777',
    padding: 4,
  },
  textc: {
    textAlign: 'left',
    fontSize: 14,
    color: '#777',
    padding: 4,
  },
  separator: {
    marginTop: 10,
    marginBottom: 14,
    borderBottomColor: '#bbb',
    borderBottomWidth: 1,
  },
  systtlA: {
    marginBottom: 20,
  },
  textRow: {
    flexDirection: 'row',
    width: '100%',
    padding: 5,
  },
  footer: {
    height: 50,
    justifyContent: 'center',
  },
});

export default PrivacyScreen;
