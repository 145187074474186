// 必要なFirebase機能をモジュラー型でインポート
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";
import { getStorage } from "firebase/storage";
import Constants from "expo-constants"; // expo-constants をインポート

// `app.config.js` の `extra` から Firebase の設定を取得
const {
  firebaseApiKey,
  firebaseAuthDomain,
  firebaseDatabaseURL,
  firebaseProjectId,
  firebaseStorageBucket,
  firebaseMessagingSenderId,
  firebaseAppId,
} = Constants.expoConfig.extra;

// Firebase の設定情報
const firebaseConfig = {
  apiKey: firebaseApiKey,
  authDomain: firebaseAuthDomain,
  databaseURL: firebaseDatabaseURL || "",
  projectId: firebaseProjectId,
  storageBucket: firebaseStorageBucket,
  messagingSenderId: firebaseMessagingSenderId,
  appId: firebaseAppId,
};

// Firebaseを初期化
const app = initializeApp(firebaseConfig);

// Firebaseサービスを初期化
const auth = getAuth(app);
const firestore = getFirestore(app);
const functions = getFunctions(app);
const storage = getStorage(app);
const db = getFirestore(app);

auth.languageCode = "ja";

// 初期化したサービスをエクスポート
export { app, auth, firestore, functions, storage, db };
