import React from 'react';
import { View, Text, Image, TouchableOpacity, StyleSheet, Linking, Platform } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { useNavigation } from '@react-navigation/native';  // モバイル用
import { useNavigate } from 'react-router-dom';  // Web用

const WelcomeScreen = () => {
  const isWeb = Platform.OS === 'web';
  const navigation = isWeb ? null : useNavigation();  // モバイル用ナビゲーション
  const navigate = isWeb ? useNavigate() : null;  // Web用ナビゲーション

  // ログイン画面への遷移
  const goToLogin = () => {
    if (isWeb) {
      navigate("/login");  // Web用のページ遷移
    } else {
      navigation.navigate("Login");  // モバイル用のページ遷移
    }
  };

  // 新規登録画面への遷移
  const goToRegister = () => {
    if (isWeb) {
      navigate("/register");  // Web用
    } else {
      navigation.navigate("Register");  // モバイル用
    }
  };

  // プライバシーポリシー画面への遷移
  const goToPrivacy = () => {
    if (isWeb) {
      navigate("/privacy");  // Web用
    } else {
      navigation.navigate("Privacy");  // モバイル用
    }
  };

  // KUROSEのWebサイトを開く
  const openKuroseWebsite = () => {
    Linking.openURL('http://www.kurose-pf.co.jp');
  };

  return (
    <View style={styles.container}>
      <KeyboardAwareScrollView extraScrollHeight={28}>
        <View style={styles.content}>
          <View style={styles.toplogo}>
            <Image
              source={require('../assets/images/toplogo.png')}
              style={{ width: '100%', height: '100%' }}
            />
          </View>

          <TouchableOpacity style={styles.login} onPress={goToLogin}>
            <Text style={styles.login_btn}>ログイン</Text>
          </TouchableOpacity>

          <TouchableOpacity style={styles.register} onPress={goToRegister}>
            <Text style={styles.register_btn}>新規会員登録</Text>
          </TouchableOpacity>

          <View style={styles.footer}>
            <Text style={styles.privacy} onPress={goToPrivacy}>個人情報の取扱について</Text>
            <Text style={styles.company}>
              Produced by {' '}
              <Text style={styles.link} onPress={openKuroseWebsite}>
                Kurose Pet Food, Inc.
              </Text>
            </Text>
          </View>
        </View>
      </KeyboardAwareScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#CD2828',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    padding: 50,
  },
  content: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  toplogo: {
    width: 250,
    height: 250,
    borderRadius: '50%',
    marginBottom: 20,
  },
  login: {
    padding: 15,
    borderRadius: 30,
    backgroundColor: '#fff',
    width: '80%',
    height: 50,
    marginBottom: 20,
    alignItems: 'center',
    justifyContent: 'center',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#cd2829',
  },
  login_btn: {
    color: '#cd2829',
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 16,
    width: '100%',
  },
  register: {
    backgroundColor: '#cd2829',
    padding: 15,
    borderRadius: 30,
    width: '80%',
    height: 50,
    marginBottom: 20,
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 1,
    borderColor: '#fff',
  },
  register_btn: {
    color: '#fff',
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 16,
    width: '100%',
  },
  privacy: {
    textDecorationLine: 'underline',
    textAlign: 'center',
    color: '#fff',
    marginBottom: 5,
  },
  company: {
    fontSize: 16,
    textAlign: 'center',
    color: '#fff',
    padding: 15,
  },
  link: {
    color: '#fff',
    textDecorationLine: 'underline',
  },
});

export default WelcomeScreen;
