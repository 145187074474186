import React, { useState } from 'react';
import { View, Text, TextInput, TouchableOpacity, ActivityIndicator, StyleSheet } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { useDispatch } from 'react-redux';
import { signForgot } from '../store/modules/accounts';
import { useNavigate } from 'react-router-dom';

const PasswordReset = () => {
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // メールアドレスのバリデーション関数
  const isValidEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const sendResetPassword = async () => {
    if (!email || !isValidEmail(email)) {
      alert("メールアドレスを正しく入力してください。");
      return;
    }

    setIsLoading(true);
    try {
      await dispatch(signForgot({ email })).unwrap();
      navigate("/login");
    } catch (err) {
      alert(`送信に失敗しました。エラー：${err.message || err}`);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center', height: '100%' }}>
        <Text style={{ color: '#555555', marginBottom: 10, textAlign: 'center' }}>送信中</Text>
        <ActivityIndicator size="large" color="#cd2829" />
      </View>
    );
  }

  return (
    <View style={styles.container}>
      <View style={styles.content}>
        <KeyboardAwareScrollView extraScrollHeight={28}>
          <Text style={styles.toptitle}>パスワード再設定</Text>
          <Text style={styles.heading1}>再設定用のURLをメールでお送りします</Text>
          <Text style={styles.heading1}>メールアドレス入力</Text>
          <TextInput
            style={styles.login_input}
            defaultValue={email}
            onChangeText={setEmail}
            keyboardType="email-address"
            autoComplete="off"
            autoFocus
          />
          <TouchableOpacity style={styles.btn} onPress={sendResetPassword}>
            <Text style={styles.send_btn}>送　信</Text>
          </TouchableOpacity>
        </KeyboardAwareScrollView>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#eeeeee',
    height: '100%',
    width: '100%',
  },
  content: {
    alignItems: 'center',
    justifyContent: 'center',
    width: '80%',
    marginLeft: '10%',
    marginRight: '10%',
  },
  toptitle: {
    fontSize: 18,
    color: '#8b8b8b',
    margin: 30,
    textAlign: 'center',
  },
  login_input: {
    height: 35,
    width: 250,
    backgroundColor: '#fff',
    borderRadius: 5,
    padding: 10,
    fontSize: 14,
    marginTop: 10,
  },
  heading1: {
    fontSize: 12,
    color: '#8b8b8b',
    margin: 5,
    textAlign: 'center',
  },
  btn: {
    width: '100%',
    height: 50,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 30,
    marginTop: 30,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#cd2829',
  },
  send_btn: {
    fontSize: 16,
    color: '#cd2829',
    fontWeight: 'bold',
  },
});

export default PasswordReset;
