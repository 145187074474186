import React, { useEffect, useState, useRef } from 'react';
import { BrowserMultiFormatReader, NotFoundException } from '@zxing/library';
import { Platform } from 'react-native';

const BarcodeScannerWeb = ({ onScanComplete }) => {  // hideFoodSectionは使わない
  const [result, setResult] = useState('No result');
  const [permissionGranted, setPermissionGranted] = useState(false);
  const [scanned, setScanned] = useState(false);
  const [message, setMessage] = useState('バーコードを画面内に入れてください');
  const videoRef = useRef(null);
  const codeReader = useRef(null);

  useEffect(() => {
    codeReader.current = new BrowserMultiFormatReader();
  
    navigator.mediaDevices.getUserMedia({ video: { facingMode: 'environment' } })
      .then((stream) => {
        if (!stream) {
          console.error('カメラストリームが取得できませんでした。');
          return;
        }
        setPermissionGranted(true);
        console.log('Setting stream to video element:', stream);
  
        const checkVideoElement = () => {
          if (videoRef.current) {
            videoRef.current.srcObject = stream;
            videoRef.current.setAttribute('playsinline', true); // iOS用
            videoRef.current.muted = true;
            videoRef.current.onloadedmetadata = () => {
              videoRef.current.play();
            };
  
            // バーコードの読み取りを開始
            codeReader.current.decodeFromVideoDevice(undefined, videoRef.current, (result, err) => {
              if (result) {
                setScanned(true);
                setResult(result.text); // 読み取った結果を表示
                setMessage('バーコードがスキャンされました！');
                
                // スキャン完了後に結果を渡す
                onScanComplete(result.text);
                handleClose(); // カメラを閉じる
              }
              if (err && !(err instanceof NotFoundException)) {
                console.error("バーコード読み取りエラー:", err);
              }
            });
          } else {
            setTimeout(checkVideoElement, 100); // 再度チェック
          }
        };
  
        checkVideoElement();
      })
      .catch((err) => {
        console.error("カメラのアクセス許可エラー:", err);
      });
  
    return () => {
      codeReader.current.reset(); // スキャンのリセット
    };
  }, []);

  const handleClose = () => {
    codeReader.current.reset(); // スキャンのリセット
    setPermissionGranted(false);
    onScanComplete("_cancel_"); // 特別な値を渡す
  };

  return (
    <div>
      {permissionGranted ? (
        <div style={{ position: 'relative', width: '100%', height: '100%' }}>
          <video
            ref={videoRef}
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
            }}
            autoPlay
            muted
          ></video>
          <p  style={{
            position: 'absolute',
            bottom: 0,
            left: '50%',
            transform: 'translateX(-50%)',
            color: '#fff',
            background: 'rgba(0, 0, 0, 0.5)',
            padding: '5px',
            borderRadius: '5px',
            whiteSpace: 'nowrap',
            fontFamily: Platform.OS === 'ios' ? 'Helvetica' : 'sans-serif',
          }}>
            {message}
          </p>
          {scanned && (
            <button
              onClick={() => setScanned(false)}
              style={{
                position: 'absolute',
                bottom: '20px',
                left: '50%',
                transform: 'translateX(-50%)',
                padding: '10px 20px',
                background: '#cd2829',
                color: '#fff',
                border: 'none',
                borderRadius: '5px',
                cursor: 'pointer',
                fontFamily: Platform.OS === 'ios' ? 'Helvetica' : 'sans-serif',
              }}
            >
              再スキャン
            </button>
          )}
          <button
            onClick={handleClose}
            style={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              padding: '10px',
              background: '#fff',
              color: '#000',
              border: 'none',
              borderRadius: '50%',
              cursor: 'pointer',
              width: '35px',
              height: '35px'
            }}
          >
            ×
          </button>
        </div>
      ) : (
        <p style={{
          textAlign: 'center',
          marginTop: 10,
          fontFamily: Platform.OS === 'ios' ? 'Helvetica' : 'sans-serif',
        }}>
          カメラのアクセス許可をリクエストしています...
        </p>
      )}
    </div>
  );
};

export default BarcodeScannerWeb;
