import React, { useEffect } from 'react';
import { View, Text, Image, ActivityIndicator, StyleSheet } from 'react-native';
import { Toast } from 'native-base';
import { checkLogin } from '../plugins/firebase-utils';
import { checkInternetConnection } from 'react-native-offline';
import Store from '../store';

const LoadingScreen = ({ navigation }) => {
  useEffect(() => {
    login();
    const interval = setInterval(() => {
      login();
    }, 300000);
    return () => clearInterval(interval);
  }, []);

  const login = async () => {
    let isConnected = await checkInternetConnection();
    if (isConnected) {
      checkLogin(navigation);
    } else {
      Toast.show({
        position: 'top',
        type: 'warning',
        text: 'ネットワークに接続できません',
        buttonText: 'OK',
        duration: 3000,
      });
      navigation.navigate('Top');
    }
  };

  const goToSettings = () => {
    navigation.navigate('Settings');
  };

  return (
    <View style={styles.container}>
      <View style={styles.toplogo}>
        <Image source={require('../assets/images/loading.png')} style={styles.imageSize} resizeMode="contain" />
        <ActivityIndicator size="large" color="#cd2829" />
        <Text style={{ color: '#555555' }}>読込中 ...</Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#cd2829',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    padding: 50,
    width: '100%',
  },
  toplogo: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
  },
  imageSize: {
    height: '30%',
  },
});

export default LoadingScreen;
