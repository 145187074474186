import React from 'react';
import { View, Text, ScrollView, StyleSheet } from 'react-native';

const DisclaimerScreen = ({ navigation }) => {
  const goToLogin = () => {
    navigation.navigate("Login");
  };

  const goToRegister = () => {
    navigation.navigate("Register");
  };

  const goToPrivacy = () => {
    navigation.navigate("Privacy");
  };

  return (
    <View style={styles.container}>

      <ScrollView>
        <View style={styles.systtlA}>
          <View style={styles.separator}></View>
          <Text style={styles.termstitle}>免責事項</Text>
          <View style={styles.separator}></View>
          <Text style={styles.texta}>
            株式会社黒瀬ペットフード（以下、当社）が運営する愛鳥ノート とりにてぃ（以下、本アプリ）の情報は、一部のサービスを除き、無料で提供されています。本アプリを利用した閲覧や情報収集については、情報がユーザーの需要に適合するものか否か、情報の保存や複製その他ユーザーによる任意の利用方法により必要な法的権利を有しているか否か、著作権、秘密保持、名誉毀損、品位保持および輸出に関する法規その他法令上の義務に従うことなど、ユーザーご自身の責任において行っていただきますようお願いいたします。本アプリの御利用につき、何らかのトラブルや損失・損害等につきましては一切責任を問わないものとします。
          </Text>

          <View style={styles.separator}></View>
          <Text style={styles.termstitle}>外部コンテンツについて</Text>
          <View style={styles.separator}></View>
          <Text style={styles.texta}>
            本アプリが紹介しているウェブサイトやソフトウェア・アプリケーション・ツールの合法性、正確性、道徳性、最新性、適切性、著作権の許諾や有無など、その内容については一切の保証を致しかねます。本アプリからリンクやバナーなどによって他のサイトに移動された場合、移動先サイトで提供される情報、サービス等について一切の責任を負いません。
          </Text>

          <View style={styles.separator}></View>
          <Text style={styles.termstitle}>著作権について</Text>
          <View style={styles.separator}></View>
          <Text style={styles.texta}>
            本アプリに存在する、文章・画像・動画等の著作物の情報を無断転載することを禁止します。引用の範囲を超えるものについては、法的処置を行います。転載する際には、当社ホームページお問い合わせよりご連絡お願いします。
          </Text>

          <View style={styles.separator}></View>
          <Text style={styles.termstitle}>広告について</Text>
          <View style={styles.separator}></View>
          <Text style={styles.texta}>
            本アプリは Google を含む 第三者配信事業者が Cookie を使用して、本アプリへの過去のアクセス情報に基づいて広告を配信する可能性があります。これらの第三者配信事業者は、ユーザーの興味に応じた商品やサービスの広告を表示する目的で、本アプリや他のサイトへのアクセスに関する情報を使用することがあります（氏名、住所、メールアドレス、電話番号は含まれません）。このプロセスの詳細や、第三者配信事業者にこれらの情報が使用されないようにする方法については、「プライバシーポリシー」をご覧ください。
          </Text>

          <View style={styles.separator}></View>
          <Text style={styles.termstitle}>アクセス解析について</Text>
          <View style={styles.separator}></View>
          <Text style={styles.texta}>
            本アプリは、ご利用いただいた時間、お客様のアクセス元のホスト名、OSとブラウザのバージョン、リンク元のURL、モニターの情報等のパソコン環境情報を収集し、アクセス解析をしております。この目的はお客様が検索サイトからご訪問いただいた場合、どのような検索で本アプリに来訪いただいたかの解析にのみ使用しており、第3者に公開はいたしません。
          </Text>
        </View>
      </ScrollView>

      <View style={styles.footer}></View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#fff',
    justifyContent: 'center',
    flex: 1,
    padding: 20,
  },
  header: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  separator: {
    marginTop: 10,
    marginBottom: 14,
    borderBottomColor: '#bbb',
    borderBottomWidth: 1,
  },
  headerinr: {
    fontSize: 24,
    fontWeight: 'bold',
    color: '#777',
  },
  termstitle: {
    fontSize: 20,
    fontWeight: 'bold',
    color: '#777',
  },
  texta: {
    fontSize: 14,
    color: '#777',
    padding: 14,
    textAlign: 'left',
  },
  footer: {
    height: 50,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default DisclaimerScreen;