import React from 'react';
import { View, Text, TouchableOpacity, StyleSheet } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

const PasswordResetComplete = ({ navigation }) => {
  
  const goToTop = () => {
    navigation.navigate("Login");
  }

  return (
    <View style={styles.container}>
      <KeyboardAwareScrollView>
        <View style={styles.thanks}>
          <Text style={styles.thanksText}>送信完了</Text>
          <Text style={styles.texta}>パスワード再設定用メールを送信しました。</Text>
          <Text style={styles.texta}>メールからパスワードの再設定をお願いします。</Text>
          <TouchableOpacity style={styles.login} onPress={goToTop}>
            <Text style={styles.loginBtn}>ログイン画面に戻る</Text>
          </TouchableOpacity>
        </View>
      </KeyboardAwareScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#cd2829',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    padding: 50,
  },
  thanks: {
    padding: 10,
    borderRadius: 5,
    backgroundColor: '#fff',
    width: 270,
    height: 270,
    marginBottom: 20,
    alignItems: 'center',
    justifyContent: 'center',
  },
  thanksText: {
    color: '#cd2829',
    fontSize: 30,
    paddingBottom: 20,
  },
  texta: {
    fontSize: 14,
    padding: 4,
    textAlign: 'center',
  },
  login: {
    marginTop: 20,
    padding: 0,
    borderRadius: 30,
    backgroundColor: '#cd2829',
    width: 240,
    height: 50,
    alignItems: 'center',
    justifyContent: 'center',
  },
  loginBtn: {
    color: '#fff',
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 16,
    width: '100%',
  },
});

export default PasswordResetComplete;
