import React from 'react';
import { View, Text, ScrollView, Image, TouchableOpacity, StyleSheet } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import Store from '../store';

const UpdateScreen = () => {
  const navigation = useNavigation();

  const setUpdates = async () => {
    await Store.dispatch('setDBUpdates', { user: Store.getters.account, filter: null });
    let set_state = await Store.dispatch('getUpdates', { user: Store.getters.account, filter: null });
    navigation.navigate('Home');
    console.log(set_state);
  };

  return (
    <View style={styles.container}>
      <View style={styles.update_bg}>
        <View style={styles.update_wrapper}>
          <Text style={styles.update_title}>とりにてぃアプリを</Text>
          <Text style={styles.update_title}>ご愛用いただいている皆様へ</Text>
          <ScrollView style={styles.wrap__}>
            <Text style={styles.up_txt1}></Text>
            <Text style={[styles.up_txt1, styles.bold]}>【1:アンケート機能を実装いたしました。】</Text>
            <Image source={require('../assets/images/update_123_1.png')} style={styles.imageSize} resizeMode="contain" />
            <Text style={styles.up_txt1}>
              黒瀬ペットフードからのアンケートに回答できるようアップデートいたしました。
            </Text>
            <Text style={styles.up_txt1}></Text>
            <Text style={styles.up_txt1}>
              ホーム左上のアンケートボタンより開催中のアンケートについて再回答することができます。
            </Text>
            <Text style={styles.up_txt1}></Text>
            <Text style={styles.up_txt1}>
              回答いただいた内容は今後のアップデートの参考にさせていただきますのでご協力いただけますと幸いです。
            </Text>
            <Text style={styles.up_txt1}></Text>
            <Text style={[styles.up_txt1, styles.bold]}>【2:アップデート通知の実装】</Text>
            <Image source={require('../assets/images/update_123_2.png')} style={styles.imageSize} resizeMode="contain" />
            <Text style={styles.up_txt1}>
              新しいアップデート情報がストアに更新されている際にアップデート通知を表示する機能をアップデートいたしました。
            </Text>
            <Text style={styles.up_txt1}></Text>
            <Text style={styles.up_txt1}>更新するを選択することでストアページへ移動することができます。</Text>
            <Text style={styles.up_txt1}></Text>
            <TouchableOpacity style={styles.controlBtnSave} onPress={setUpdates}>
              <Text style={styles.controlBtnSaveText}>OK</Text>
            </TouchableOpacity>
          </ScrollView>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 0,
  },
  update_bg: {
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 9999,
    alignItems: 'center',
    justifyContent: 'center',
  },
  update_wrapper: {
    width: '90%',
    height: '90%',
    backgroundColor: '#fff',
    borderRadius: 5,
    padding: '5%',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
  update_title: {
    color: '#cd2829',
    fontWeight: '900',
    marginBottom: 5,
    fontSize: 18,
    textAlign: 'center',
  },
  wrap__: {
    width: '100%',
  },
  up_txt1: {
    textAlign: 'left',
    color: '#555555',
    lineHeight: 20,
    fontSize: 14,
    fontWeight: '400',
  },
  bold: {
    fontWeight: '900',
  },
  imageSize: {
    width: '100%',
    marginTop: -70,
    marginBottom: -70,
  },
  controlBtnSave: {
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#cd2829',
    backgroundColor: '#cd2829',
    borderRadius: 10,
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 20,
    paddingRight: 20,
    marginTop: 20,
    width: '90%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  controlBtnSaveText: {
    color: '#fff',
    fontWeight: '900',
    fontSize: 14,
  },
});

export default UpdateScreen;
