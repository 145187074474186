import { getAuth, setPersistence, browserLocalPersistence, onAuthStateChanged } from 'firebase/auth';
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';
import { getStorage, ref, uploadString, getDownloadURL ,uploadBytesResumable, } from 'firebase/storage';
import { app, storage } from './firebase';
import { setAccount, signOut } from '../store/modules/accounts'; 
import { syncBirds, initBirds } from '../store/modules/birds';
import { syncFoods, initFoods } from '../store/modules/foods';
import { initRecords } from '../store/modules/records';
import Store from '../store';
import { Platform } from 'react-native';
import imageCompression from 'browser-image-compression';

// Firebaseサービスのインスタンスを取得
const auth = getAuth(app);
const db = getFirestore(app);

// Firestoreコレクションの参照を取得
const accountsRef = collection(db, 'accounts');

let _loginScreenName = 'Contents';

// ログイン状態を確認する関数
export function checkLogin(webNavigate, mobileNavigation) {
  onAuthStateChanged(auth, async (user) => {
    if (user) {
      const q = query(accountsRef, where('uid', '==', user.uid));
      try {
        const snapshot = await getDocs(q);
        if (snapshot.empty) {
          Store.dispatch(setAccount(null));
          if (Platform.OS === 'web') {
            webNavigate('/');  // Webの場合はルートへ
          } else {
            mobileNavigation.navigate('Top');  // モバイルではトップへ
          }
          Store.dispatch(signOut());
          Store.dispatch(initBirds());
          Store.dispatch(initRecords());
          Store.dispatch(initFoods());
        } else {
          snapshot.docs.forEach(doc => {
            const data = doc.data();
            const userData = {
              uid: user.uid,
              email: user.email,
              name: data.name,
              gender: data.gender,
              age: data.age,
              prefectures: data.prefectures,
              city: data.city,
            };
            Store.dispatch(setAccount(userData));

            if (_loginScreenName !== '') {
              if (Platform.OS === 'web') {
                webNavigate('/home');  // Web用のナビゲーション
              } else {
                mobileNavigation.navigate(_loginScreenName);  // モバイル用のナビゲーション
              }
              Store.dispatch(syncBirds({ user: userData, filter: null }));
              Store.dispatch(syncFoods({ user: userData, filter: null }));
            }
          });
        }
      } catch (error) {
        console.error('Firestore error:', error);
      }
    } else {
      Store.dispatch(setAccount(null));
      if (Platform.OS === 'web') {
        webNavigate('/');  // Webではルートにリダイレクト
      } else {
        mobileNavigation.navigate('Top');  // モバイルではトップにリダイレクト
      }
      Store.dispatch(signOut());
      Store.dispatch(initBirds());
      Store.dispatch(initRecords());
      Store.dispatch(initFoods());
    }
  });
}

// ログイン画面名を設定する関数
export function setLoginScreenName(name) {
  _loginScreenName = name;
}

// ファイルアップロードのPromise関数
export function fileUploadPromise(file, path, callback) {
  return new Promise(async (resolve, reject) => {
    try {
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 800,
        useWebWorker: true,
      };

      const compressedFile = await imageCompression(file, options);

      const metadata = {
        contentType: 'image/jpeg',
      };

      const fileRef = ref(storage, path);
      const uploadTask = uploadBytesResumable(fileRef, compressedFile, metadata);

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          if (callback) {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            callback(progress);
          }
        },
        (error) => {
          console.error('ファイルアップロードエラー:', error);
          reject(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref)
            .then((downloadURL) => {
              resolve(downloadURL);
            })
            .catch((error) => {
              reject(error);
            });
        }
      );
    } catch (error) {
      console.error('圧縮またはアップロードエラー:', error);
      reject(error);
    }
  });
}