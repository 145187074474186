import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, FlatList, TouchableOpacity, Image, Platform, ScrollView, Dimensions, ImageBackground } from 'react-native';
import { getFirestore, collection, query, onSnapshot } from 'firebase/firestore';
import { useSelector } from 'react-redux';
import { app } from '../plugins/firebase';
import Modal from 'react-native-modal';

// Platformに応じたコンポーネントの分岐
const Graph = Platform.OS === 'web'
  ? require('../components/GraphWeb').default
  : require('../components/GraphNative').default;

const db = getFirestore(app);

// うんちの状態と量を数値に変換するマッピング
const droppingsStatusMapping = {
  "ゆるい": 1,
  "通常": 2,
  "水っぽい": 0,
  "固い": 3,
};

const droppingsAmountMapping = {
  "少ない": 1,
  "通常": 2,
  "多い": 3,
};

// 換羽、発情、産卵の状態を数値に変換するマッピング
const featherChangeMapping = {
  "無し": 0,
  "少ない": 1,
  "普通": 2,
  "多い": 3,
};

const estrusStatusMapping = {
  "無": 0,
  "弱い": 1,
  "普通": 2,
  "強い": 3,
};

const spawningStatusMapping = {
  "無": 0,
  "有": 1, // 実際の個数は別途処理
};

const GraphScreen = () => {
  const [selectedBird, setSelectedBird] = useState(null); // 選択された鳥
  const [selectedTab, setSelectedTab] = useState("体重"); // 選択されたタブ
  const [filter, setFilter] = useState("all"); // データのフィルター範囲
  const [filteredData, setFilteredData] = useState([]);
  const [birdRecords, setBirdRecords] = useState([]); // 選択された鳥のレコード
  const birds = useSelector((state) => {
    const unsortedBirds = state.birds.birds || [];
    return [...unsortedBirds].sort((a, b) => {
      if (a.order === undefined && b.order === undefined) return 0;
      if (a.order === undefined) return 1;
      if (b.order === undefined) return -1;
      return a.order - b.order;
    });
  });

  const [screenWidth, setScreenWidth] = useState(Dimensions.get('window').width);
  const [isMobileScreen, setIsMobileScreen] = useState(screenWidth < 768);
  useEffect(() => {
    // 画面サイズ変更を監視
    const onChange = ({ window }) => {
      setScreenWidth(window.width);
      setIsMobileScreen(window.width < 768);
    };

    const subscription = Dimensions.addEventListener('change', onChange);

    return () => {
      // クリーンアップ
      subscription?.remove();
    };
  }, []);
  const styles = createStyles(isMobileScreen);

  useEffect(() => {
    // デフォルトで最初の鳥を選択
    if (birds.length > 0 && !selectedBird) {
      setSelectedBird(birds[0]);
    }
  }, [birds]);

  useEffect(() => {
    let unsubscribe;
    if (selectedBird) {
      unsubscribe = fetchRecords(selectedBird.bid); // リスナーを登録
    }

    return () => {
      if (unsubscribe) {
        unsubscribe(); // クリーンアップ: リスナーを解除
      }
    };
  }, [selectedBird]);

  const fetchRecords = (birdId) => {
    const recordsRef = collection(db, "birds", birdId, "records"); // サブコレクション参照
    const q = query(recordsRef);

    // 現在の鳥のリスナーを保存
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const records = [];
      snapshot.forEach((doc) => {
        records.push({ rid: doc.id, ...doc.data() });
      });

      // 選択されている鳥とクエリ対象の鳥が一致している場合にのみ更新
      if (selectedBird?.bid === birdId) {
        setBirdRecords(records);
      }
    });

    // クリーンアップ関数を返す
    return unsubscribe;
  };

  useEffect(() => {
    console.log("Selected Bird Changed:", selectedBird);
    console.log("Bird Records Updated:", birdRecords);
  }, [selectedBird, birdRecords]);

  useEffect(() => {
    const filtered = filterRecords(); // フィルタリング
    const formatted = formatDataForGraph(filtered); // 整形
    setFilteredData(formatted); // 状態にセット
  }, [filter, birdRecords, selectedTab]);

  const renderFilterButtons = () => {
    const filterOptions = ["all", "week", "month", "three_months"];
    return (
      <View style={styles.filterContainer}>
        {filterOptions.map((option) => (
          <TouchableOpacity
            key={option}
            style={[
              styles.filterButton,
              filter === option && styles.selectedFilterButton, // 選択中のボタンにスタイルを適用
            ]}
            onPress={() => handleFilterChange(option)}
          >
            <Text
              style={[
                styles.filterButtonText,
                filter === option && styles.selectedFilterButtonText, // 選択中のボタンのテキストスタイル
              ]}
            >
              {option === "all"
                ? "すべて"
                : option === "week"
                ? "1週間"
                : option === "month"
                ? "1ヶ月"
                : "3ヶ月"}
            </Text>
          </TouchableOpacity>
        ))}
      </View>
    );
  };

  const filterRecords = () => {
    const now = new Date();
    const filtered = birdRecords.filter((record) => {
      const recordDate = new Date(record.rid); // 日付を取得
      if (filter === "week") {
        return now - recordDate <= 7 * 24 * 60 * 60 * 1000; // 1週間以内
      } else if (filter === "month") {
        return now - recordDate <= 30 * 24 * 60 * 60 * 1000; // 1ヶ月以内
      } else if (filter === "three_months") {
        return now - recordDate <= 90 * 24 * 60 * 60 * 1000; // 3ヶ月以内
      }
      return true; // "all"の場合すべてのデータ
    });
    return filtered.sort((a, b) => new Date(b.rid) - new Date(a.rid));
  };

  const handleFilterChange = (newFilter) => {
    setFilter(newFilter);
    const filtered = filterRecords(); // フィルタリング実行
    const formatted = formatDataForGraph(filtered); // フォーマット
    setFilteredData(formatted);
  };

  const renderGraphs = () => {
    if (selectedTab === "すべて") {
      const graphTabs = ["体重", "うんち", "状態", "環境", "ごはん"];

      return (
        <View style={styles.graphRow}>
          {graphTabs.map((tab) => (
            <View
              key={tab}
              style={[
                isMobileScreen
                  ? // モバイル向けのスタイル
                    tab === "体重"
                      ? { width: "100%" }
                      : tab === "うんち"
                      ? { width: "100%" }
                      : tab === "状態"
                      ? { width: "100%" }
                      : tab === "環境"
                      ? { width: "100%" }
                      : { width: "100%" }
                  : // PC向けのスタイル
                    tab === "体重"
                      ? { width: "33%", marginRight: '0.5%' }
                      : tab === "うんち"
                      ? { width: "66.5%" }
                      : tab === "状態"
                      ? { width: "100%" }
                      : tab === "環境"
                      ? { width: "100%" }
                      : { width: "33%" },
                styles.graphColumn, // 共通のスタイル
              ]}
            >
              <Graph
                data={formatDataForGraph(tab)}
                graphType={
                  tab === "体重"
                    ? "weight"
                    : tab === "うんち"
                    ? "poop"
                    : tab === "状態"
                    ? "state"
                    : tab === "環境"
                    ? "environment"
                    : "food"
                }
              />
            </View>
          ))}
        </View>
      );
    }

    return (
      <Graph
        data={formatDataForGraph(selectedTab)}
        graphType={
          selectedTab === "体重"
            ? "weight"
            : selectedTab === "うんち"
            ? "poop"
            : selectedTab === "状態"
            ? "state"
            : selectedTab === "環境"
            ? "environment"
            : "food"
        }
      />
    );
  };


  // グラフ用のデータを整形する関数
  const formatDataForGraph = (tab) => {
    const filteredData = filterRecords();

    switch (tab) {
      case "体重":
        return filteredData
          .map((record) => ({
            date: record.rid, // 日付（例: "2024-11-25"）
            y: parseFloat(record.weight?.weight_1st) || 0, // 体重1回目の値
          }))
          .filter((item) => item.y > 0) // 体重があるデータのみ
          .reverse(); // 逆順にする

      case "うんち":
        const statusData = filteredData
          .map((record) => ({
            x: record.rid,
            y: droppingsStatusMapping[record.healty?.droppings_status] || null,
          }))
          .filter((item) => item.y !== null && [0,1, 2, 3].includes(item.y)) // 必要な値のみ許可
          .reverse(); // 逆順にする

        const amountData = filteredData
          .map((record) => ({
            x: record.rid,
            y: droppingsAmountMapping[record.healty?.droppings_amount] || null,
          }))
          .filter((item) => item.y !== null && [1, 2, 3].includes(item.y)) // 必要な値のみ許可
          .reverse(); // 逆順にする

        return { status: statusData, amount: amountData };

      case "状態":
        const featherChangeData = filteredData
          .map((record) => ({
            x: record.rid, // 日付
            y: featherChangeMapping[record.healty?.feather_change] || 0, // 換羽データ
          }))
          .reverse(); // 逆順にする

        const estrusStatusData = filteredData
          .map((record) => ({
            x: record.rid, // 日付
            y: estrusStatusMapping[record.healty?.estrus_status] || 0, // 発情データ
          }))
          .reverse(); // 逆順にする

        const spawningCountData = filteredData
          .map((record) => ({
            x: record.rid, // 日付
            y: record.healty?.spawning_status === "有"
              ? parseInt(record.healty?.spawning_type || "0", 10) // 産卵データ（個数）
              : 0,
          }))
          .reverse(); // 逆順にする

        return {
          featherChange: featherChangeData,
          estrusStatus: estrusStatusData,
          spawningCount: spawningCountData,
        };

      case "環境":
        return {
          outsideTemperature: filteredData
            .map((record) => ({
              x: record.rid,
              y: parseFloat(record.environment?.outside_temperture) || 0,
            }))
            .reverse(), // 逆順にする

          insideTemperatureHumidity: filteredData
            .map((record) => ({
              x: record.rid,
              temperature: parseFloat(record.environment?.inside_temperture) || 0,
              humidity: parseFloat(record.environment?.inside_humidity) || 0,
            }))
            .reverse(), // 逆順にする

          cageTemperatureHumidity: filteredData
            .map((record) => ({
              x: record.rid,
              temperature: parseFloat(record.environment?.cage_temperture) || 0,
              humidity: parseFloat(record.environment?.cage_humidity) || 0,
            }))
            .reverse(), // 逆順にする
        };

      case "ごはん":
        const groupedData = {};

        filteredData.forEach((record) => {
          if (record.food && record.food.length > 0) {
            record.food.forEach((item) => {
              const date = record.rid; // 日付
              const category = item.type; // カテゴリ
              const amount = parseFloat(item.amount) || 0; // グラム数

              // groupedDataの初期化と加算
              if (!groupedData[date]) groupedData[date] = {};
              if (!groupedData[date][category]) groupedData[date][category] = 0;
              groupedData[date][category] += amount;
            });
          }
        });

        // グラフ用データを整形
        const formattedData = Object.keys(groupedData)
          .sort((a, b) => new Date(a) - new Date(b)) // 日付順に並べ替え
          .map((date) => ({
            x: date,
            y: groupedData[date],
          }))

        const allCategories = Object.keys(
          formattedData.reduce((acc, data) => {
            Object.keys(data.y).forEach((category) => {
              acc[category] = true;
            });
            return acc;
          }, {})
        );
        return { formattedData, allCategories };

      default:
        console.log("Empty Graph Data"); // 他のタブの場合
        return [];
    }
  };

  const [expandedRecords, setExpandedRecords] = useState({});

  const truncateName = (name, maxLength = 6) => {
    if (name.length > maxLength) {
      return name.substring(0, maxLength) + "…"; // 最大文字数を超えた場合に「…」を追加
    }
    return name; // 最大文字数以内の場合はそのまま返す
  };

  const truncateText = (text, isExpanded, isMobileScreen) => {
    const maxLength = isMobileScreen ? 15 : 37;
    if (isExpanded || text.length <= maxLength) {
      return text;
    }
    return text.substring(0, maxLength) + "…";
  };

  const toggleExpandedRecord = (rid) => {
    setExpandedRecords((prev) => ({
      ...prev,
      [rid]: !prev[rid],
    }));
  };

  const renderBirdList = () => (
    <FlatList
      style={styles.flatListContainer}
      data={birds}
      horizontal
      keyExtractor={(item) => item.bid}
      renderItem={({ item }) => (
        <TouchableOpacity
          style={[
            styles.birdItem,
            selectedBird?.bid === item.bid && styles.selectedBirdItem,
          ]}
          onPress={() => setSelectedBird(item)}
        >
          <Image source={{ uri: item.photo }} style={styles.birdImage} />
          <Text style={styles.birdName}>{truncateName(item.name)}</Text>
        </TouchableOpacity>
      )}
    />
  );

  const renderTabs = () => {
    const tabs = ["体重", "うんち", "状態", "環境", "ごはん", "日記・写真"];
    if (!isMobileScreen) {
      tabs.push("すべて");
    }
    return (
      <View style={styles.tabContainerBlock}>
        <View style={styles.tabContainer}>
          {tabs.map((tab) => (
            <TouchableOpacity
              style={[styles.tabBlock]}
              key={tab}
              onPress={() => setSelectedTab(tab)}
            >
              <View
                style={[
                  styles.tabItem,
                  selectedTab === tab && styles.selectedTabItem,
                ]}
              >
                <Text
                  style={[
                    styles.tabText,
                    selectedTab === tab && styles.selectedTabText,
                  ]}
                >
                  {tab}
                </Text>
              </View>
            </TouchableOpacity>
          ))}
        </View>
      </View>
    );
  };

  const formatRidDate = (rid) => {
    const date = new Date(rid);

    const year = date.getFullYear();
    const month = date.getMonth() + 1; // 月は0ベースなので+1
    const day = date.getDate();
    const weekday = ["日", "月", "火", "水", "木", "金", "土"][date.getDay()];

    return `${year}/${month}/${day} (${weekday})`;
  };

  const [isModalVisible, setModalVisible] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const openModal = (url) => {
    setSelectedImage(url); // クリックされた画像のURLをセット
    setModalVisible(true); // モーダルを表示
  };

  const closeModal = () => {
    setModalVisible(false); // モーダルを非表示
    setSelectedImage(null); // 選択された画像をリセット
  };

  return (
    <ScrollView style={styles.container}>
      {/* 鳥リスト */}
      <View style={styles.birdListContainer}>{renderBirdList()}</View>

      {/* 鳥の記録タイトル */}
      {/*<View style={styles.recordTitleContainer}>
        <Text style={styles.recordTitle}>
          {selectedBird
            ? `${truncateName(selectedBird.name, isMobileScreen ? 12 : 24)}の記録`
            : "記録を選択してください"}
        </Text>
      </View>
      */}

      {/* タブ切り替え */}
      {renderTabs()}

      {/* グラフまたは日記・写真 */}
      <View style={styles.graphContainer}>
      {/* フィルターボタン */}
      {renderFilterButtons()}

        {selectedTab === "日記・写真" ? (
        <>
        {filterRecords().some((record) => record.memo || record.photoURL) ? (
          <>
            {/* 日記セクション */}
            {filterRecords().some((record) => record.memo) && (
              <View style={styles.diarySection}>
                <Text style={styles.sectionTitle}>日記</Text>
                <ScrollView style={styles.scrollableContainer}>
                  {filterRecords().map((record) =>
                    record.memo ? (
                      record.memo.length <= (isMobileScreen ? 15 : 37) ? (
                        // 文字数が短い場合はクリック不可
                        <View key={record.rid} style={styles.diaryItem}>
                          <Text style={styles.diaryDate}>{formatRidDate(record.rid)}</Text>
                          <Text style={styles.memoText}>{record.memo}</Text>
                        </View>
                      ) : (
                        // 文字数が長い場合はアコーディオン形式
                        <TouchableOpacity
                          key={record.rid}
                          style={styles.diaryItem}
                          onPress={() => toggleExpandedRecord(record.rid)}
                        >
                          <Text style={styles.diaryDate}>{formatRidDate(record.rid)}</Text>
                          <Text
                            style={[
                              styles.memoText,
                              !expandedRecords[record.rid] && styles.preExpandedMemoText, // 展開前は赤色
                            ]}
                          >
                            {truncateText(
                              record.memo,
                              expandedRecords[record.rid],
                              isMobileScreen
                            )}
                          </Text>
                        </TouchableOpacity>
                      )
                    ) : null
                  )}
                </ScrollView>
              </View>
            )}

            {/* 写真セクション */}
            {filterRecords().some((record) => record.photoURL) && (
              <View style={styles.photoSection}>
                <Text style={styles.sectionTitle}>写真一覧</Text>
                <ScrollView style={styles.scrollableContainer}>
                  <View style={styles.photoRow}>
                    {(() => {
                      const sortedRecords = filterRecords();
                      let displayedIndex = 0;
                      return sortedRecords.map((record) => {
                        if (!record.photoURL) {
                          return null;
                        }
                        const isLastInRow = displayedIndex % 3 === 2;
                        displayedIndex++;
                        return (
                          <TouchableOpacity
                            key={record.rid}
                            style={[
                              styles.photoItem,
                              isLastInRow && styles.lastRowItem,
                            ]}
                            onPress={() => openModal(record.photoURL)}
                          >
                            <Image
                              source={{ uri: record.photoURL }}
                              style={styles.photoImage}
                            />
                            <Text style={styles.photoDate}>{formatRidDate(record.rid)}</Text>
                          </TouchableOpacity>
                        );
                      });
                    })()}
                  </View>
                </ScrollView>
              </View>
            )}
          </>
          ) : (
            <Text style={styles.noData}>記録がありません。</Text>
          )}
        </>
        ) : selectedTab === "すべて" ? (
          <View style={styles.graphsContainer}>
            {renderGraphs()}
          </View>
        ) : (
          <View
            style={[
              styles.graphBlock,
              selectedTab === "体重" && styles.weightTab,
              selectedTab === "うんち" && styles.poopTab,
              selectedTab === "状態" && styles.stateTab,
              selectedTab === "環境" && styles.environmentTab,
              selectedTab === "ごはん" && styles.foodTab,
            ]}
          >
            <View style={styles.graphBlockinr}>
              {birdRecords.length > 0 ? (
                <Graph
                  data={formatDataForGraph(selectedTab)}
                  graphType={
                    selectedTab === "体重"
                      ? "weight"
                      : selectedTab === "うんち"
                      ? "poop"
                      : selectedTab === "状態"
                      ? "state"
                      : selectedTab === "環境"
                      ? "environment"
                      : selectedTab === "ごはん"
                      ? "food"
                      : null
                  }
                />
              ) : (
                <Text style={styles.noData}>記録がありません。</Text>
              )}
            </View>
          </View>
        )}
      </View>
      {/* モーダル */}
        <Modal
          isVisible={isModalVisible}
          onBackdropPress={closeModal}
          style={{ margin: 0, justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgba(0, 0, 0, 0.7)' }}
        >
          <View style={{ width: '90%', maxWidth: 800, height: '75vh', position: 'relative' }}>
            <ImageBackground
              source={{ uri: selectedImage }}
              style={{
                width: '100%',
                height: '100%',
                justifyContent: 'flex-start',
                alignItems: 'flex-end',
              }}
              resizeMode="contain"
            >
              <TouchableOpacity
                onPress={closeModal}
                style={{
                  position: 'absolute',
                  top: -25,
                  right: 0,
                }}
              >
                <Text style={{ color: '#fff', fontSize: 20, fontWeight: 'bold' }}>✕</Text>
              </TouchableOpacity>
            </ImageBackground>
          </View>
        </Modal>
    </ScrollView>
  );
};

const createStyles = (isMobileScreen) =>
  StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#f2f2f2',
  },
  birdListContainer: {
    flexDirection: 'row',
    paddingVertical: 10,
    paddingHorizontal: isMobileScreen ? 20 : 40,
    backgroundColor: '#fff',
  },
  flatListContainer: {
  },
  birdItem: {
    paddingHorizontal: 5,
    paddingVertical: 10,
    alignItems: 'center',
    marginRight: 10,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#eaeaea',
    borderRadius: 10,
    width: isMobileScreen ? 100 : 130,
    height: isMobileScreen ? 100 : 130,
  },
  selectedBirdItem: {
    backgroundColor: '#f5d4d4',
    borderWidth: 0,
  },
  birdImage: {
    width: isMobileScreen ? 60 : 80,
    height: isMobileScreen ? 60 : 80,
    borderRadius: '50%',
    marginBottom: 5,
  },
  birdName: {
    fontSize: isMobileScreen ? 12 : 16,
    color: '#333',
    marginTop: 'auto'
  },
  recordTitleContainer: {
    backgroundColor: '#fff',
    padding: isMobileScreen ? 10 : 20,
    alignItems: 'center',
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderColor: '#eaeaea',
    borderStyle: 'solid',
  },
  recordTitle: {
    fontSize: isMobileScreen ? 20 : 24,
    color: '#555555',
  },
  tabContainerBlock: {
    marginHorizontal: 'auto',
    width: isMobileScreen ? '100%' : '100%',
    backgroundColor: '#fff',
  },
  tabContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    paddingTop: isMobileScreen ? 5 : 5,
    paddingBottom: 5,
    paddingHorizontal: isMobileScreen ? 5 : 0,
    width: '100%',
    maxWidth: isMobileScreen ? 768 : 1200,
    marginHorizontal: 'auto'
  },
  tabBlock: {
    // 表示領域拡大のため変更
    // width: isMobileScreen ? '33%' : '14%',
    width: isMobileScreen ? 'auto' : 'auto',
    marginBottom: 5,
  },
  tabItem: {
    width: '100%',
    maxWidth: 160,
    marginHorizontal: 'auto',
    paddingVertical: 5,
    paddingHorizontal: isMobileScreen ? 7 : 10,
    borderRadius: 25,
  },
  selectedTabItem: {
    backgroundColor: '#f5d4d4',
  },
  tabText: {
    fontSize: isMobileScreen ? 16 : 20,
    color: '#555555',
    textAlign: 'center'
  },
  selectedTabText: {
  },
  graphContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#f2f2f2',
    paddingHorizontal: 20,
    paddingBottom: isMobileScreen ? 20 : 20,
    paddingTop: isMobileScreen ? 20 : 20,
  },
  graphBlock: {
    borderRadius: 10,
    width: '100%',
    maxWidth: 800,
    marginHorizontal: 'auto',
  },
  graphBlockinr: {
    marginHorizontal: 0,
  },
  diarySection: {
    backgroundColor: '#fff',
    borderRadius: 10,
    width: '100%',
    maxWidth: 800,
    marginHorizontal: 'auto',
    marginBottom: 30,
    padding: 20,
  },
  photoSection: {
    backgroundColor: '#fff',
    borderRadius: 10,
    width: '100%',
    maxWidth: 800,
    marginHorizontal: 'auto',
    padding: 20,
  },
  sectionTitle: {
    fontSize: 24,
    marginBottom: 10,
  },
  scrollableContainer: {
    flexGrow: 1,
    maxHeight: 400,
  },
  diaryItem: {
    borderBottomWidth: 1,
    borderStyle: 'solid',
    borderColor: '#eaeaea',
    marginBottom: 20,
  },
  diaryDate: {
    backgroundColor: '#f5d4d4',
    paddingHorizontal: isMobileScreen ? 10 : 20,
    paddingVertical: isMobileScreen ? 2 : 5,
    borderRadius: 20,
    flexDirection: "row",
    alignItems: "center",
    width: 'fit-content',
    fontSize: isMobileScreen ? 13 : 18,
    marginBottom: 10,
    color: '#555555'
  },
  memoText: {
    fontSize: 20,
    paddingBottom: 5,
    color: '#555555'
  },
  preExpandedMemoText: {
    color: '#cd2829',
  },
  photoRow: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    flex: 1,
  },
  photoItem: {
    width: '32%',
    marginBottom: 10,
    borderRadius: 10,
    marginRight: '2%',
  },
  lastRowItem: {
    marginRight: 0,
  },
  photoImage: {
    borderRadius: 10,
    marginBottom: 10,
    aspectRatio: 1,
  },
  photoDate: {
    flexDirection: "row",
    alignItems: "center",
    fontSize: isMobileScreen ? 13 : 16,
    marginBottom: 20,
    color: '#555555',
    textAlign: 'center'
  },
  filterContainer: {
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    paddingVertical: 5,
    paddingHorizontal: 5,
    borderRadius: 30,
    borderWidth: 1,
    borderColor: "#bfbfbf",
    maxWidth: 700,
    marginHorizontal: 'auto',
    marginBottom: 20,
    width: '100%',
  },
  filterButton: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    paddingVertical: isMobileScreen ? 5 : 8,
    paddingHorizontal: 15,
    borderRadius: 30,
  },
  selectedFilterButton: {
    backgroundColor: "#cd2829",
    borderRadius: 30,
  },
  filterButtonText: {
    fontSize: isMobileScreen ? 12 : 13,
    color: "#555",
  },
  selectedFilterButtonText: {
    color: "#fff",
  },
  noData: {
    textAlign: 'center',
    color: '#555',
    marginVertical: 20,
  },
  graphsContainer: {
    width: '100%',
    paddingHorizontal: 10,
  },
  graphRow: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    marginBottom: 20,
  },
  weightTab: {
    maxWidth: 600,
  },
  poopTab: {
    maxWidth: 1000,
  },
  stateTab: {
    maxWidth: 1200,
  },
  environmentTab: {
    maxWidth: 1200,
  },
  foodTab: {
    maxWidth: 600,
  },
});

export default GraphScreen;
