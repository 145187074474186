import React, { useEffect, useState } from 'react';
import { View, Text, ScrollView, TouchableOpacity, StyleSheet } from 'react-native';
import Store from '../store';
import { useNavigation } from '@react-navigation/native';

const SurveyList = () => {
  const [questions, setQuestions] = useState([]);
  const navigation = useNavigation();

  useEffect(() => {
    const fetchQuestions = async () => {
      const fetchedQuestions = await Store.dispatch('getQuestions', { user: Store.getters.account, filter: null });
      setQuestions(fetchedQuestions);
    };

    fetchQuestions();
  }, []);

  const goToQuestionDetail = async (question) => {
    await Store.dispatch('setDatailQuestion', { question });
    navigation.navigate('QuestionDetail');
  };

  const omittedText = (text) => {
    return text.length > 15 ? text.slice(0, 15) + "…" : text;
  };

  return (
    <View style={styles.container}>
      <View style={styles.header}>
        {questions.length === 0 ? (
          <View style={styles.noQuestions}>
            <Text style={styles.noQuestionsMessage}>現在開催中のアンケートはございません</Text>
          </View>
        ) : (
          <ScrollView>
            {questions.map((question, index) => (
              <View key={index} style={styles.questionBox}>
                <View>
                  <Text style={styles.questionActiveTime}>開催期間：{question.start}~{question.end}</Text>
                  <Text style={styles.questionTitle}>{omittedText(question.title)}</Text>
                </View>
                <TouchableOpacity style={styles.questionBtn} onPress={() => goToQuestionDetail(question)}>
                  <Text style={styles.questionBtnText}>回答する</Text>
                </TouchableOpacity>
              </View>
            ))}
          </ScrollView>
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    padding: 20,
  },
  header: {
    justifyContent: 'center',
  },
  noQuestions: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  noQuestionsMessage: {
    textAlign: 'center',
    fontWeight: 'bold',
    marginTop: 20,
  },
  questionBox: {
    flexDirection: 'row',
    width: '100%',
    height: 55,
    padding: 5,
    marginBottom: 5,
    borderWidth: 1,
    borderColor: '#cd292a',
    position: 'relative',
  },
  questionActiveTime: {
    fontWeight: 'bold',
    fontSize: 13,
    marginBottom: 5,
  },
  questionTitle: {
    width: '100%',
    fontWeight: 'bold',
    fontSize: 15,
  },
  questionBtn: {
    position: 'absolute',
    top: 8,
    right: 5,
    backgroundColor: '#cd292a',
    padding: 10,
    borderRadius: 25,
  },
  questionBtnText: {
    color: '#FFF',
    fontWeight: 'bold',
  },
});

export default SurveyList;
