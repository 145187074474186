import React, { useState, useEffect, useRef } from 'react';
import { MdPadding } from 'react-icons/md';
import { View, Text, ScrollView, StyleSheet, Image, TextInput, Button, Alert, ImageBackground, Dimensions, TouchableOpacity, useWindowDimensions } from 'react-native-web'; // React Native Web対応
import { useSelector } from 'react-redux';
import { Picker } from '@react-native-picker/picker';
import { doc, collection, addDoc, runTransaction } from 'firebase/firestore'; // Firebase Firestore
import { db } from '../plugins/firebase';
import { TextAlign } from '@shopify/react-native-skia';

const PaymentScreen = () => {
  const [cardNumber, setCardNumber] = useState('');
  const [cardOwner, setCardOwner] = useState('');
  const [selectedMonth, setSelectedMonth] = useState('01');
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear().toString().slice(2)); // 現在の年の下2桁
  const [expiry, setExpiry] = useState('');
  const [cvc, setCvc] = useState('');
  const [checkSum, setCheckSum] = useState('');

  const [nameKanji, setNameKanji] = useState('');
  const [nameKatakana, setNameKatakana] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [address, setAddress] = useState('');
  const [street, setStreet] = useState('');
  const [building, setBuilding] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');

  const account = useSelector((state) => state.accounts.account);
  useEffect(() => {
    if (account) {
      console.log('ログイン中のユーザーUID:', account.uid);
    } else {
      console.log('account が存在しません');
    }
  }, [account]);

  // カタカナ専用バリデーション
  const handleInputBlur = () => {
    const katakanaRegex = /^[\u30A0-\u30FF]+$/; // カタカナのみを許容する正規表現
    if (nameKatakana && !katakanaRegex.test(nameKatakana)) {
      setNameKatakana('');
      alert('フリガナ(カタカナ)はカタカナのみ入力してください'); // アラートを表示
    }
  };
  // 郵便番号専用バリデーション
  const handlePostalCodeChange = (input) => {
    const filteredInput = input.replace(/[^0-9]/g, ''); // 数字以外を削除
    setPostalCode(filteredInput);
  };
  // 電話番号専用バリデーション
  const handlePhoneNumberChange = (input) => {
    const filteredInput = input.replace(/[^0-9]/g, ''); // 数字以外を削除
    setPhoneNumber(filteredInput);
  };

  const traderCode = '888888709'; // 加盟店コード
  const accessKey = '1111111'; // アクセスキー
  const authDiv = '2'; // 認証区分

  const [selectedMethod, setSelectedMethod] = useState('');

  const paymentMethods = [
    { id: '9', label: 'VISA', image: require('../assets/images/card_visa.png') },
    { id: '10', label: 'MASTER', image: require('../assets/images/card_mastercard.png') },
    { id: '3', label: 'JCB', image: require('../assets/images/card_jcb.png') },
    { id: '12', label: 'アメックス', image: require('../assets/images/card_amex.png') },
    { id: '2', label: 'ダイナース', image: require('../assets/images/card_diners.png') },
  ];

  const today = new Date();
  const scheduledShippingDate = `${today.getFullYear()}${(today.getMonth() + 1).toString().padStart(2, '0')}${today.getDate().toString().padStart(2, '0')}`;

  // width と height の状態を管理するためのステートを作成
  const [windowDimensions, setWindowDimensions] = useState({ width: 0, height: 0 });
  const { width } = useWindowDimensions();
  const isMobileScreen = width < 768;

  // コンポーネントがマウントされたときに、画面サイズを取得する
  useEffect(() => {
    const { width, height } = Dimensions.get('window');
    setWindowDimensions({ width, height });

    // リサイズ時に画面サイズを更新するリスナーを追加
    const onChange = ({ window }) => {
      setWindowDimensions({ width: window.width, height: window.height });
    };
    Dimensions.addEventListener('change', onChange);

    // クリーンアップ
    return () => {
      Dimensions.removeEventListener('change', onChange);
    };
  }, []);

  useEffect(() => {
    // WebCollectのライブラリを読み込み
    const loadScript = async () => {
      if (!window.WebcollectTokenLib) {
        try {
          const script = document.createElement('script');
          script.src = 'https://ptwebcollect.jp/test_gateway/token/js/embeddedTokenLib.js';
          script.async = true;
          document.body.appendChild(script);

          script.onload = () => console.log('WebCollect Library loaded successfully');
          script.onerror = () => console.error('Failed to load WebCollect Library');
        } catch (error) {
          console.error('Script load error:', error);
        }
      }
    };

    loadScript();

    // checkSumを計算
    const calculateChecksum = async () => {
      //const memberId = account.uid; // カード保有者を特定するID
      const memberId = 'TEST_MEMBER_005'; // カード保有者を特定するID
      const authKey = 'test'; // 認証キー
      const dataToHash = `${memberId}${authKey}${accessKey}${authDiv}`;

      try {
        const hashBuffer = await crypto.subtle.digest('SHA-256', new TextEncoder().encode(dataToHash));
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
        setCheckSum(hashHex);
        console.log('checkSum calculated:', hashHex);
      } catch (err) {
        console.error('Failed to calculate checkSum:', err);
      }
    };

    calculateChecksum();
  }, []);

  const handlePayment = async () => {
    if (!cardNumber || !cardOwner || !selectedMonth || !selectedYear || !cvc) {
      alert('すべての項目を入力してください');
      return;
    }

    const expiry = `${selectedMonth}${selectedYear}`;
    console.log('有効期限:', expiry);

    // 現在の年月を取得
    const today = new Date();
    const currentYear = today.getFullYear();
    const currentMonth = today.getMonth() + 1;
    // 有効期限のチェック
    const fullSelectedYear = parseInt(`20${selectedYear}`, 10);
    if (
      fullSelectedYear < currentYear ||
      (fullSelectedYear === currentYear && parseInt(selectedMonth, 10) < currentMonth)
    ) {
      alert('カード有効期限に過去日が設定されています。有効な有効期限を入力してください。');
      return; // 処理を中断
    }

    let regularOrderNo; // 受付番号の変数を用意

    try {
        // Firestore トランザクションで受付番号を生成
        const counterRef = doc(db, 'counters', 'orderCounter');
        await runTransaction(db, async (transaction) => {
          const counterDoc = await transaction.get(counterRef);
          if (!counterDoc.exists()) {
            throw new Error("カウンタが存在しません。Firestore に 'orderCounter' を初期設定してください。");
          }
          const currentValue = counterDoc.data().value || 1;
          regularOrderNo = String(currentValue).padStart(8, '0'); // 例: 00000001 のようにゼロ埋め
        });
        console.log('生成された受付番号:', regularOrderNo);

        const createTokenInfo = {
          traderCode,
          authDiv,
          cardNo: cardNumber,
          cardOwner,
          cardExp: expiry,
          securityCode: cvc,
          optServDiv: '01',
          checkSum,
          //memberId: account?.uid, // UIDを使用する場合はコメント解除
          memberId: 'TEST_MEMBER_005', // テスト用固定値
          authKey: "test",
          card_code_api: selectedMethod,
      };

      console.log('Sending the following data to WebCollect:', createTokenInfo);

      // トークン化処理
      window.WebcollectTokenLib.createToken(
        createTokenInfo,
        async (response) => {
          if (response.token) {
            console.log('Tokenization Success:', response.token);

            try {
              // 決済リクエスト送信
              const result = await fetch('https://us-central1-trinity-app-test.cloudfunctions.net/processPayment', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                  regular_order_no: regularOrderNo, // 生成した受付番号を使用
                  amount: '5500', // 必須項目：決済金額
                  buyer_name_kanji: nameKanji, // 必須項目：購入者名
                  buyer_tel: phoneNumber, // 必須項目：購入者TEL
                  buyer_email: email, // 必須項目：購入者E-Mail
                  token: response.token, // 必須項目：トークン
                  cycle_unit: '0', // 月単位
                  cycle_interval: '3', // 3ヶ月間隔
                  cycle_day: '1', // 毎月1日
                  goods_name: "TEST", // 商品名
                  scheduled_shipping_date: scheduledShippingDate, // 出荷予定日 (yyyyMMdd形式)
                  card_code_api: selectedMethod,
                }),
              });

              const data = await result.json();
              if (data.success) {
                alert('成功', '決済が成功しました');

                try {
                  // 決済成功後に受付番号をカウントアップ
                  const counterRef = doc(db, 'counters', 'orderCounter');
                  await runTransaction(db, async (transaction) => {
                    const counterDoc = await transaction.get(counterRef);

                    if (!counterDoc.exists()) {
                      throw new Error("カウンタが存在しません。Firestore に 'orderCounter' を初期設定してください。");
                    }

                    const currentValue = counterDoc.data().value || 1;
                    regularOrderNo = String(currentValue).padStart(8, '0'); // 例: 00000001 のようにゼロ埋め
                    transaction.update(counterRef, { value: currentValue + 1 });
                  });

                  console.log('受付番号をカウントアップしました:', regularOrderNo);

                  // 親コレクションに `premiumFlag: true` を設定
                  const userRef = doc(db, 'accounts', account.uid);
                  await runTransaction(db, async (transaction) => {
                    const userDoc = await transaction.get(userRef);
                    if (!userDoc.exists()) {
                      throw new Error("ユーザードキュメントが存在しません。");
                    }
                    transaction.update(userRef, { premiumFlag: true });
                  });

                  console.log('premiumFlag を true に設定しました');
                  // プレミアム情報を保存
                  const premiumCollectionRef = collection(userRef, 'premium'); // サブコレクション "premium"
                  const now = new Date(); // 現在の日時
                  const contractStartDate = now.toISOString(); // 契約開始日時（そのまま）
                  const contractRenewDate = new Date(now);
                  contractRenewDate.setFullYear(now.getFullYear() + 1); // 1年後を契約更新日に設定

                  await addDoc(premiumCollectionRef, {
                    nameKanji,
                    nameKatakana,
                    postalCode,
                    address,
                    street,
                    building,
                    phoneNumber,
                    email,
                    amount: '5500',
                    contractStartDate,
                    contractRenewDate: contractRenewDate.toISOString(),
                    autoPayment: true,
                  });

                  console.log('プレミアム情報を保存しました');
                } catch (error) {
                  console.error('Firestore保存エラー:', error);
                }
              } else {
                alert(`決済エラー: ${data.message}`);
              }
            } catch (error) {
              console.error('決済リクエストエラー:', error);
              alert('決済処理に失敗しました');
            }
          } else {
            alert('トークン化に失敗しました');
          }
        },
        (error) => {
          console.error('Tokenization error:', error);
          alert(`エラーコード: ${error.errorCode} メッセージ: ${error.errorMsg}`);
        }
      );
    } catch (error) {
      console.error('エラー:', error);
      alert('処理中にエラーが発生しました');
    }
  };

  const scrollViewRef = useRef(null);

  useEffect(() => {
    if (scrollViewRef.current) {
      scrollViewRef.current.scrollTo({ y: 0, animated: false }); // ScrollViewのスクロール位置をリセット
    }
  }, []);

  return (
    <ScrollView ref={scrollViewRef}>
      <View style={styles.container}>
      {/*
        <View style={styles.TopBlock}>
        <ImageBackground
          source={{ uri: 'https://www.kurose-pf.co.jp/wp/wp-content/themes/kurose-theme/images/premium_back.png' }}
          style={{ flex: 1, width: '100%' }}  // サイズ指定
          imageStyle={{ opacity: 0.5 }}
          resizeMode="cover"
        >
          <View style={styles.TopBlockInr}>
            <View style={styles.ryokinBlock}>
              <Image
                source={require('../assets/images/premium_nenkan.png')}
                style={[styles.nenkanImage, { height: windowDimensions.width * 0.15 }]} // 動的に幅を変更
              />
              <View style={styles.ryokinBlockInr}>
                <Text
                  style={[styles.Ryokintext,
                    { fontSize: isMobileScreen ? 70 : 100 },
                  ]}
                >
                  5,500
                </Text>
                <Text style={styles.RyokintextSmall}>
                  円(税込)
                </Text>
              </View>
            </View>
            <Image
              source={require('../assets/images/ryokin_img.png')}
              style={[styles.ryokinImage, { height: windowDimensions.width * 0.5 }]} // 動的に幅を変更
            />
            <View>
              <View style={styles.textBottomBlock}>
              <Image
                source={require('../assets/images/premium_icon.png')}
                style=
                {[styles.iconImage,
                  { height: windowDimensions.width * 0.5 },
                  { width: windowDimensions.width * 0.5 }
                ]}
              />
                <Text style={[
                  styles.textBottom,
                    { width: isMobileScreen ? '90%' : '94%' },
                    { fontSize: isMobileScreen ? 14 : 18 },
                  ]}
                >
                  とりにてぃプレミアムにご登録いただくと、avisにて5,500円分のポイントをプレゼントいたします。{'\n'}（事前にavisへのご登録が必要となります。）
                </Text>
              </View>
              <View style={styles.textBottomBlock}>
                <Image
                  source={require('../assets/images/premium_icon.png')}
                  style=
                  {[styles.iconImage,
                    { height: windowDimensions.width * 0.5 },
                    { width: windowDimensions.width * 0.5 }
                  ]}
                />
                <Text style={[
                  styles.textBottom,
                    { width: isMobileScreen ? '90%' : '94%' },
                    { fontSize: isMobileScreen ? 14 : 18 },
                  ]}
                >
                  登録は1年ごとの自動更新となり、更新ごとに5,500円分のポイントをプレゼントいたします。
                </Text>
              </View>
            </View>
          </View>
          </ImageBackground>
        </View>
        */}

        <View style={styles.mainBlock}>
          <Image
            source={require('../assets/images/premium_ryokin.png')}
            style={[styles.ryokinttlImage, { height: windowDimensions.width * 0.09 }]}
          />
          <View style={styles.border2} />
          <View style={styles.ryokinBlock}>
            <Image
              source={require('../assets/images/premium_nenkan.png')}
              style={[styles.nenkanImage, { height: isMobileScreen ? windowDimensions.width * 0.12 : windowDimensions.width * 0.08 },]}
            />
            <View style={[styles.ryokinBlock2, { width: isMobileScreen ? 'auto' : '80%' }]}>
              <Text style={[styles.Ryokintext, { fontSize: isMobileScreen ? 70 : 100 }]}>
                5,500
              </Text>
              <Text style={[styles.RyokintextSmall, { fontSize: 16 }]}>
                円(税込)
              </Text>
            </View>
          </View>

          <View style={styles.mainBlockInr}>
            <View style={styles.InputBlock}>
              <Text style={[
                styles.InputBlockHeader,
                  { fontSize: isMobileScreen ? 14 : 18 },
                ]}
              >
                お名前(漢字)
              </Text>
              <TextInput
                style={styles.input}
                value={nameKanji}
                onChangeText={setNameKanji}
              />
            </View>
            <View style={styles.InputBlock}>
              <Text style={[
                styles.InputBlockHeader,
                  { fontSize: isMobileScreen ? 14 : 18 },
                ]}
              >
                お名前(カタカナ)
              </Text>
              <TextInput
                style={styles.input}
                value={nameKatakana}
                onChangeText={setNameKatakana}
                onBlur={handleInputBlur}
              />
            </View>
            <View style={styles.InputBlock}>
              <Text style={[
                styles.InputBlockHeader,
                  { fontSize: isMobileScreen ? 14 : 18 },
                ]}
              >
                郵便番号
              </Text>
              <TextInput
                style={styles.input}
                value={postalCode}
                onChangeText={handlePostalCodeChange}
              />
            </View>
            <View style={styles.InputBlock}>
              <Text style={[
                styles.InputBlockHeader,
                  { fontSize: isMobileScreen ? 14 : 18 },
                ]}
              >
                都道府県・市区町村
              </Text>
              <TextInput
                style={styles.input}
                value={address}
                onChangeText={setAddress}
              />
            </View>
            <View style={styles.InputBlock}>
              <Text style={[
                styles.InputBlockHeader,
                  { fontSize: isMobileScreen ? 14 : 18 },
                ]}
              >
                丁目・番地
              </Text>
              <TextInput
                style={styles.input}
                value={street}
                onChangeText={setStreet}
              />
            </View>
            <View style={styles.InputBlock}>
              <Text style={[
                styles.InputBlockHeader,
                  { fontSize: isMobileScreen ? 14 : 18 },
                ]}
              >
                建物名・部屋番号
              </Text>
              <TextInput
                style={styles.input}
                value={building}
                onChangeText={setBuilding}
              />
            </View>
            <View style={styles.InputBlock}>
              <Text style={[
                styles.InputBlockHeader,
                  { fontSize: isMobileScreen ? 14 : 18 },
                ]}
              >
                電話番号
              </Text>
              <TextInput
                style={styles.input}
                value={phoneNumber}
                onChangeText={(input) => {
                  const filteredInput = input.replace(/[^0-9]/g, ''); // 数字以外を削除
                  if (filteredInput.length <= 13) {
                    setPhoneNumber(filteredInput); // 13桁以下のみセット
                  }
                }}
                onBlur={() => {
                  if (phoneNumber.length < 8 || phoneNumber.length > 13) {
                    alert('電話番号は8桁以上13桁以下である必要があります');
                    setPhoneNumber(''); // 不正な入力をクリア
                  }
                }}
                keyboardType="numeric"
              />
            </View>
            <View style={styles.InputBlock}>
              <Text style={[
                styles.InputBlockHeader,
                { fontSize: isMobileScreen ? 14 : 18 },
              ]}>
                {isMobileScreen
                  ? 'メールアドレス\n(avisにご登録いただいたもの)'
                  : 'メールアドレス(avisにご登録いただいたもの)'}
              </Text>
              <TextInput
                style={styles.input}
                value={email}
                onChangeText={setEmail}
                keyboardType="email-address"
                onBlur={() => {
                  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // メールアドレス形式の正規表現
                  if (!emailRegex.test(email)) {
                    alert('正しいメールアドレス形式を入力してください');
                    setEmail(''); // 不正な入力をクリア
                  }
                }}
              />
            </View>
            <Text style={[
              styles.InputBlockHeader,
                { fontSize: isMobileScreen ? 14 : 18 },
              ]}
            >
              カード情報入力
            </Text>
            <View
              style={[
                styles.CardBlock,
                  { paddingHorizontal: isMobileScreen ? 20 : 20 },
                  { paddingVertical: isMobileScreen ? 30 : 30 },
                ]}
            >
              <View style={styles.paymentMethodsContainer}>
                {paymentMethods.map((method) => (
                  <TouchableOpacity
                    key={method.id}
                    onPress={() => setSelectedMethod(method.id)}
                    style={[
                      styles.paymentMethod,
                      selectedMethod === method.id && styles.selectedMethod,
                    ]}
                  >
                    <Image
                      source={method.image}
                      style={[
                        styles.card_image,
                          { width: isMobileScreen ? 43 : 60 },
                          { height: isMobileScreen ? 43 : 60 },
                        ]}
                      resizeMode="contain"
                    />
                  </TouchableOpacity>
                ))}
              </View>
              <View style={styles.CardInputBlock}>
                <Text style={[
                  styles.InputBlockHeader,
                    { fontSize: isMobileScreen ? 14 : 18 },
                  ]}
                >
                  カード番号
                </Text>
                <TextInput
                  value={cardNumber}
                  onChangeText={setCardNumber}
                  placeholder=""
                  style={styles.Cardinput}
                />
              </View>
              <View style={styles.CardInputBlock}>
              <Text style={[
                styles.InputBlockHeader,
                  { fontSize: isMobileScreen ? 14 : 18 },
                ]}
              >
                  カード名義人
                </Text>
                <TextInput
                  value={cardOwner}
                  onChangeText={setCardOwner}
                  placeholder=""
                  style={styles.Cardinput}
                />
              </View>
              <View style={styles.CardInputBlock}>
                <Text style={[
                  styles.InputBlockHeader,
                    { fontSize: isMobileScreen ? 14 : 18 },
                  ]}
                >
                有効期限
                </Text>
                <View style={styles.expiryContainer}>
                  {/* 月選択 */}
                  <View style={styles.expiryPicker}>
                    <Picker
                      selectedValue={selectedMonth}
                      onValueChange={(value) => setSelectedMonth(value)}
                      style={styles.picker}
                    >
                      {Array.from({ length: 12 }, (_, i) => {
                        const month = (i + 1).toString();
                        return <Picker.Item key={month} label={month} value={month} />;
                      })}
                    </Picker>
                    <Text style={styles.unitText}>月</Text>
                  </View>

                  {/* 年選択 */}
                  <View style={styles.expiryPicker}>
                    <Picker
                      selectedValue={selectedYear}
                      onValueChange={(value) => setSelectedYear(value)}
                      style={styles.picker}
                    >
                      {Array.from({ length: 10 }, (_, i) => {
                        const year = (new Date().getFullYear() + i).toString().slice(2); // 下2桁
                        return <Picker.Item key={year} label={year} value={year} />;
                      })}
                    </Picker>
                    <Text style={styles.unitText}>年</Text>
                  </View>
                </View>
              </View>
              <View style={styles.CardInputBlock}>
                <Text style={[
                  styles.InputBlockHeader,
                    { fontSize: isMobileScreen ? 14 : 18 },
                  ]}
                >
                  CVC
                </Text>
                <TextInput
                  value={cvc}
                  onChangeText={(input) => {
                    const filteredInput = input.replace(/[^0-9]/g, '');
                    if (filteredInput.length <= 4) {
                      setCvc(filteredInput);
                    }
                  }}
                  onBlur={() => {
                    if (cvc.length !== 3 && cvc.length !== 4) {
                      alert('セキュリティコードは3桁または4桁で入力してください。');
                      setCvc(''); // 不正な入力をクリア
                    }
                  }}
                  style={styles.Cardinput}
                />
              </View>
            </View>
            <TouchableOpacity
              onPress={handlePayment}
              style={[
                styles.paymentButton,
                  { paddingHorizontal: isMobileScreen ? 30 : 30 },
                  { paddingVertical: isMobileScreen ? 15 : 25 },
                ]}
            >
              <Text style={[
                styles.paymentButtonText,
                  { fontSize: isMobileScreen ? 15 : 18 },
                ]}
              >
                とりにてぃプレミアムに登録
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    width: '100%',
  },
  TopBlock: {
    backgroundColor: '#ffffff',
    // paddingHorizontal: 20,
    // paddingVertical: 50,
  },
  TopBlockInr: {
    maxWidth: 800,
    width: '100%',
    marginHorizontal: 'auto',
  },
  ryokinBlock: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    maxWidth: 480,
    marginHorizontal: 'auto',
    justifyContent: 'space-between',
    marginBottom: 30,
  },
  nenkanImage: {
    width: '20%',
    maxWidth: 110,
    maxHeight: 110,
    resizeMode: 'contain'
  },
  ryokinBlockInr: {
    maxWidth: 330,
    flexDirection: 'row',
    alignItems: 'baseline',
    marginHorizontal: 'auto',
  },
  Ryokintext: {
    color: '#555555'
  },
  RyokintextSmall: {
    fontSize: 18,
    color: '#555555'
  },
  ryokinImage: {
    width: '100%',
    maxHeight: 465,
    resizeMode: 'contain',
    marginBottom: 30,
  },
  textBottomBlock:{
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 15,
  },
  iconImage:{
    maxWidth: 30,
    maxHeight: 30,
    height: 100,
    marginTop: 5,
  },
  textBottom: {
    color: '#555555'
  },

  mainBlock: {
    paddingHorizontal: 20,
    paddingVertical: 50,
  },
  mainBlockInr: {
    maxWidth: 800,
    paddingHorizontal: 10,
    width: '100%',
    marginHorizontal: 'auto',
  },
  InputBlock: {
    marginBottom: 20,
  },
  InputBlockHeader: {
    color: '#8d8d8d',
    marginBottom: 10,
  },
  input: {
    fontSize: 16,
    paddingHorizontal: 10,
    paddingVertical: 12,
    backgroundColor: '#ffffff',
    borderRadius: 10,
  },
  CardBlock: {
    backgroundColor: '#ffffff',
    borderRadius: 10,
    marginBottom: 30,
  },
  paymentMethodsContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: 20,
  },
  paymentMethod: {
    alignItems: 'center',
    padding: 3,
    borderRadius: '50%',
    borderWidth: 3,
    borderColor: 'transparent',
  },
  selectedMethod: {
    borderColor: '#cd2829',
  },
  CardInputBlock: {
    borderBottomColor: '#555555',
    borderBottomWidth: 1,
    paddingBottom: 10,
    marginBottom: 15,
  },
  Cardinput: {
    padding: 10,
  },
  expiryContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginVertical: 10,
  },
  expiryPicker: {
    flexDirection: 'row',
    alignItems: 'center',
    borderBottomWidth: 1,
    borderBottomColor: '#ccc',
    paddingHorizontal: 10,
    paddingBottom: 5,
    width: '45%', // 各Pickerを横幅の45%に制限
  },
  picker: {
    flex: 1,
    fontSize: 16,
    outlineStyle: 'none',
    borderWidth: 0,
    borderStyle: 'none',
    appearance: 'none',      // Chrome や Firefox 用
    WebkitAppearance: 'none' // Safari 用
  },
  unitText: {
    marginLeft: 5,
    fontSize: 16,
    color: '#555',
  },
  paymentButton: {
    backgroundColor: '#cd2829',
    borderRadius: 50,
  },
  paymentButtonText: {
    fontSize: 24,
    color: '#fff',
    textAlign: 'center',
  },



  ryokinttlImage: {
    maxWidth: 450,
    width: '100%',
    maxHeight: 80,
    alignSelf: 'center',
    resizeMode: 'contain',
    marginBottom: 10,
  },
  border2: {
    backgroundColor: '#cd2829',
    height: 0.6,
    maxWidth: 450,
    alignSelf: 'center',
    width: '100%',
    marginBottom: 20,
  },
  subtitle: {
    color: '#f29c9f',
    fontFamily: 'Arial, sans-serif',
    marginBottom: 20,
  },
  ryokinBlock: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    maxWidth: 480,
    alignSelf: 'center',
    justifyContent: 'center',
    marginBottom: 30,
  },
  nenkanImage: {
    width: '15%',
    maxWidth: 110,
    maxHeight: 110,
    resizeMode: 'contain',
  },
  ryokinBlock2: {
    maxWidth: 330,
    flexDirection: 'row',
    width: '80%',
    alignItems: 'baseline',
  },
  Ryokintext: {
    fontSize: 100,
    color: '#555555',
  },
  RyokintextSmall: {
    fontSize: 16,
    color: '#555555',
  },
  ryokinImage: {
    width: '100%',
    maxHeight: 465,
    resizeMode: 'contain',
    marginBottom: 30,
  },
  textBottomBlock: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 15,
  },
});

export default PaymentScreen;
